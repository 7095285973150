import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const STATUS_CODE_OPTIONS = [
  { label: '전체', value: '' },
  { label: '활성화', value: 'Y' },
  { label: '비활성화', value: 'N' },
];

export const FULFILLMENT_OPTIONS = [
  { label: '선택', value: '' },
  { label: 'CJ대한통운', value: 'CJ대한통운' },
  { label: '팀프레시', value: '팀프레시' },
];

export const CENTER_MANAGE_STATUS_CODE_COLOR = {
  활성화: 'green.500',
  비활성화: 'red.500',
};

export const CAUTION_MESSAGES = [
  '센터 등록 전에 반드시 IT 담당자와 협의 후 진행 바랍니다.',
  '센터매핑코드는 펫프렌즈 센터 DB와 연결되는 코드이며, 수정할 수 없으므로 반드시 담당자와 협의 후 입력하십시오.',
  '가상센터는 임의의 가상 공간에 재고를 이동시켜야 할 때 사용하는 센터 종류입니다.',
];

export const CENTER_MANAGE_GRID_OPTIONS = {
  columns: [
    ACTIVE_COLUMN,
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('activated', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('type', textColumn),
      title: '센터유형',
      disabled: true,
    },
    {
      ...keyColumn('name', textColumn),
      title: '센터명',
      minWidth: 150,
    },
    {
      ...keyColumn('fulfillment', textColumn),
      title: '운영사',
      disabled: true,
    },
    {
      ...keyColumn('latitude', textColumn),
      title: '위도',
    },
    {
      ...keyColumn('longitude', textColumn),
      title: '경도',
    },
    {
      ...keyColumn('zipcode', textColumn),
      title: '우편번호',
    },
    {
      ...keyColumn('mainAddress', textColumn),
      title: '주소',
      minWidth: 200,
    },
    {
      ...keyColumn('subAddress', textColumn),
      title: '상세주소',
      minWidth: 200,
    },
    {
      ...keyColumn('contact', textColumn),
      title: '대표연락처',
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 120,
    },
  ],
};
