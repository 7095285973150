import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const STOCK_ADJUST_NEW_MODAL_INITIAL_STATE = {
  category: 'INBOUND',
  centerCode: '',
  adjustReason: 'LOSS',
  availableDay: '',
  newProductItemIds: '',
};

export const CAUTION_BOX_MESSAGES = [
  '동일한 구분항목(입/출고, 센터코드, 조정사유)으로 각각 등록해야 합니다.',
  '등록/저장 후 실행하셔야 조정이 완료됩니다.',
  '유효성 체크를 충족하지 못한 내역(Fail)이 포함되면 저장되지 않습니다.',
];

export const STOCK_ADJUST_GRID_OPTIONS = {
  columns: [
    ACTIVE_COLUMN,
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '현재고(가용)',
      disabled: true,
    },
    {
      ...keyColumn('adjustQuantity', textColumn),
      title: '조정수량',
    },
    {
      ...keyColumn('afterAdjustQuantity', textColumn),
      title: '조정 후 재고',
      disabled: true,
    },
    {
      ...keyColumn('remark', textColumn),
      title: '비고',
      minWidth: 400,
    },
    {
      ...keyColumn('result', textColumn),
      title: '유효성 체크',
      disabled: true,
      minWidth: 400,
    },
  ],
};

export const STOCK_ADJUST_REASON_NEW_OPTIONS = [
  { label: '감모손실', value: 'LOSS' },
  { label: '재고실사', value: 'STOCKTAKING' },
  { label: '폐기', value: 'DISPOSAL' },
  { label: '바코드오류', value: 'BARCODE_ERROR' },
];
