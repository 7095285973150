import axios from 'axios';
import Cookies from 'js-cookie';

import { reportError } from './Utils/error';
import { validateToken } from './Utils/token';

export const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

client.interceptors.request.use(
  (request) => {
    if (request.url?.includes('/login')) return request;
    if (request.url?.includes('/auth/server/refresh')) return request;
    validateToken();
    if (request.headers) {
      request.headers.Authorization = `Bearer ${Cookies.get('wms_tk')}`;
      request.headers['Content-Type'] = 'application/json';
    }
    return request;
  },
  (err) => Promise.reject(err)
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response.data) {
      validateToken();
    }

    const { status, message } = error.response.data;

    if (status < 200 || status > 299) {
      reportError(message);
    }

    return Promise.reject(error);
  }
);
