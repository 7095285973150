import { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import styled, { CSSProperties } from 'styled-components';

import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';

import { FileInputField } from './FileUploader.style';

type FileUploaderProps = {
  accept?: InputHTMLAttributes<HTMLInputElement>['accept'];
  direction?: CSSProperties['flexDirection'];
  align?: CSSProperties['alignItems'];
  multiple?: boolean;
  onChange: (fileList: FileList) => void;
};

const KB = 1024;
const MB = KB ** 2;

function FileUploader({
  // https://www.iana.org/assignments/media-types/media-types.xhtml
  // default accept: .xlsx, .xls
  accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  direction = 'row',
  align = 'center',
  multiple = false,
  onChange,
}: FileUploaderProps) {
  const [fileList, setFileList] = useState<FileList | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const fileTypes = accept.replace(/\s/g, '').split(',');

  useEffect(() => {
    if (fileList !== null && fileInputRef.current) {
      onChange(fileList);

      // 같은 파일 재업로드 허용
      fileInputRef.current.value = '';
    }
  }, [fileList]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files === null || files.length === 0) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      if (!fileTypes.includes(file.type)) {
        toastify('업로드한 파일 형식이 올바르지 않습니다. 엑셀 파일이 맞는지 확인 바랍니다.', {
          type: 'error',
        });
        return;
      }

      if (file.size > 10 * MB) {
        toastify('허용된 파일 용량 10MB를 초과하는 파일은 업로드할 수 없습니다.', {
          type: 'error',
        });
        return;
      }
    }

    let currentFileName = '';
    if (files.length === 1) {
      currentFileName = files[0].name;
    } else {
      currentFileName = `${files[0].name} 외 ${files.length - 1}개`;
    }

    setFileName(currentFileName);

    setFileList(files);
  };

  return (
    <Flex gap={5} direction={direction} align={align}>
      <FileInputField placeholder='파일을 선택해 주세요.' value={fileName} disabled />
      <FileInput
        ref={fileInputRef}
        type='file'
        accept={accept}
        multiple={multiple}
        onChange={handleFileChange}
      />
      <Button size='x-small' onClick={() => fileInputRef.current?.click()}>
        파일
      </Button>
    </Flex>
  );
}

const FileInput = styled.input`
  display: none;
`;

export default FileUploader;
