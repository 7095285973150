import { useMemo, useState } from 'react';

import Box from '@Components/Box';
import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import { RowInfo } from '@Components/DataSheetGrid/DataSheetGrid.type';
import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { SelectLabel } from '@Components/Forms/Select';
import Pagination from '@Components/Pagination';
import { toastify } from '@Components/Toast';
import { useDialog, useFormField } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import { useMutation } from '@tanstack/react-query';
import { performIfNotEmpty } from '@Utils/helper';

import { useStockAdjustItemList, useStockAdjustList } from './apis/queries';
import StockAdjustNewModal from './components/StockAdjustNewModal';
import { putStockAdjustCancellation, putStockAdjustCompletion } from './apis';
import {
  STOCK_ADJUST_CATEGORY_OPTIONS,
  STOCK_ADJUST_DETAIL_GRID_OPTIONS,
  STOCK_ADJUST_GRID_OPTIONS,
  STOCK_ADJUST_REASON_OPTIONS,
  STOCK_INQUIRE_INITIAL_STATE,
} from './constant';
import { StockAdjustCell } from './StockAdjustCell';

const PAGE_SIZE = 100;

function StockInquireAdjust() {
  const { columns: stockAdjustListColumns } = STOCK_ADJUST_GRID_OPTIONS;
  const { columns: stockAdjustItemColumns } = STOCK_ADJUST_DETAIL_GRID_OPTIONS;
  const { openDialog: openNewDialog, resetDialog: closeNewDialog } =
    useDialog('StockAdjustNewDialog');
  const { form, attributes } = useFormField(STOCK_INQUIRE_INITIAL_STATE);
  const [stockIndex, setStockIndex] = useState<number | undefined>(undefined);
  const { stockAdjust, stockAdjustRefetch } = useStockAdjustList({ ...form, pageNumber: 0 });
  const stockItemId = useMemo(
    () => (stockIndex === undefined ? null : stockAdjust.gridList[stockIndex].stockAdjustRequestId),
    [stockIndex]
  );
  const { stockAdjustItemList, totalCountRef, pageNumber, setPageNumber } = useStockAdjustItemList({
    stockId: stockItemId,
    pageSize: PAGE_SIZE,
  });
  const targetRows = stockAdjust.updatedRow.filter((it) => it.active);

  const selectStockId = (rowInfo: RowInfo) => {
    setStockIndex(rowInfo.row);
  };

  const { mutate: executeMutation } = useMutation(putStockAdjustCompletion, {
    onSettled() {
      search();
    },
  });
  const { mutate: cancellationMutation } = useMutation(putStockAdjustCancellation, {
    onSettled() {
      search();
    },
  });

  const search = () => {
    stockAdjust.setUpdatedRow([]);
    stockAdjustRefetch();
  };

  const open = (id?: number) => {
    openNewDialog({
      title: id ? '수정등록' : '신규등록',
      content: (
        <StockAdjustNewModal
          reset={closeNewDialog}
          id={id}
          stockAdjustRefetch={stockAdjustRefetch}
        />
      ),
      size: 'large',
    });
  };

  const modify = () => {
    if (targetRows.length !== 1) {
      toastify('수정은 1건만 처리할 수 있습니다.', { type: 'error' });
      return;
    }

    const [target] = targetRows;
    if (target.statusCodeValue !== '등록' && target.statusCodeValue !== '수정등록') {
      toastify('상태가 등록 혹은 수정등록인 경우만 수정 가능합니다.', { type: 'error' });
      return;
    }

    open(target.stockAdjustRequestId);
  };

  const cancel = () => {
    if (window.confirm('재고조정을 취소하시겠습니까?')) {
      cancellationMutation({ ids: targetRows.map((it) => it.stockAdjustRequestId) });
    }
  };

  const execute = () => {
    const validationRows = targetRows.map((it) => it.statusCodeValue);

    for (const item of validationRows) {
      if (item !== '수정등록' && item !== '등록') {
        toastify('상태가 "등록" 혹은 "수정등록"인 경우만 실행 가능합니다.', { type: 'error' });
        return;
      }
    }

    if (window.confirm('재고조정을 실행하시겠습니까?')) {
      executeMutation({ ids: targetRows.map((it) => it.stockAdjustRequestId) });
    }
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={search} isPermissionRequired={false}>
          조회
        </Button>
        <Button onClick={() => open()}>신규등록</Button>
        <Button onClick={performIfNotEmpty(targetRows, modify)}>수정</Button>
        <Button onClick={performIfNotEmpty(targetRows, cancel)}>취소</Button>
        <Button onClick={performIfNotEmpty(targetRows, execute)}>실행</Button>
      </FilterHeader>

      <FormField>
        <Flex align='flex-start' colGap={36} rowGap={16} wrap='wrap'>
          <DateRangePickerLabel
            label='오더 생성일'
            start={attributes.startRequestDay}
            end={attributes.endRequestDay}
          />
          <SelectLabel
            label='구분'
            {...attributes.stockAdjustCategory}
            options={STOCK_ADJUST_CATEGORY_OPTIONS}
          />
          <SelectLabel
            label='조정사유'
            {...attributes.stockAdjustReason}
            options={STOCK_ADJUST_REASON_OPTIONS}
          />
        </Flex>
      </FormField>

      <Box mt-20 mb-20>
        <Flex direction='column' rowGap={10}>
          <ResultHeader title='재고조정 내역' total={stockAdjust.gridList.length} />
          <DataSheetGrid
            {...mappingAllCheck(
              stockAdjustListColumns,
              stockAdjust.gridList,
              stockAdjust.setGridList
            )}
            value={stockAdjust.gridList}
            onChange={stockAdjust.rowChangeHandler}
            onActiveChange={selectStockId}
            customRendererList={[{ id: 'statusCodeValue', component: StockAdjustCell }]}
          />
        </Flex>
      </Box>
      {stockItemId !== null ? (
        <Flex direction='column' rowGap={10}>
          <ResultHeader title='상세내역' total={totalCountRef.current ?? 0} />
          <DataSheetGrid columns={stockAdjustItemColumns} value={stockAdjustItemList} />
          <Flex justify='center'>
            <Pagination
              total={totalCountRef.current}
              pageSize={PAGE_SIZE}
              currentPageIndex={pageNumber}
              onPageIndexChange={setPageNumber}
              surroundingPageCount={2}
            />
          </Flex>
        </Flex>
      ) : null}
    </div>
  );
}

export default StockInquireAdjust;
