import { ReactNode } from 'react';

import styled from 'styled-components';

import { flexColumn } from '@src/Styles/utils/flex';

function ModalWrapper({ children }: { children: ReactNode }) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  ${flexColumn('space-between')};
  height: 100%;
`;

export default ModalWrapper;
