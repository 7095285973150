import { ComponentProps, CSSProperties } from 'react';

import styled from 'styled-components';

import Flex from '@Components/Flex';

import { ButtonColors, ButtonSizes, ButtonVariants } from './Button/Button';
import { InputProps } from './Input/Input.type';
import Button from './Button';
import { Input } from './Input';
import { WithLabel, WithLabelProps } from './WithLabel';

interface InputButtonProps extends Omit<InputProps, 'size'> {
  colGap?: CSSProperties['columnGap'];
  buttonSize?: ButtonSizes;
  buttonText?: string;
  withButton?: boolean;
  color?: ButtonColors;
  variant?: ButtonVariants;
  type?: ComponentProps<'button'>['type'];
  disabled?: boolean;
  withInput?: boolean;
  buttonClick?: ComponentProps<'button'>['onClick'];
}

function InputButton({
  id,
  name,
  value,
  onChange,
  readOnly,
  error,
  span,
  colGap = 5,
  buttonSize = 'x-small',
  buttonText,
  variant,
  withButton = false,
  buttonClick,
  ...props
}: InputButtonProps) {
  return (
    <Flex direction='column' rowGap={8}>
      <Flex colGap={withButton ? 0 : colGap}>
        <Input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          error={error}
          span={span}
          withButton={withButton}
          {...props}
        />
        <Button
          size={buttonSize}
          variant={variant}
          withInput={withButton}
          onClick={buttonClick}
          isPermissionRequired={false}
        >
          {buttonText}
        </Button>
      </Flex>
      {span && <Error>{span}</Error>}
    </Flex>
  );
}

export type InputButtonLabelProps = Omit<WithLabelProps, 'children'> & InputButtonProps;

export function InputButtonLabel({
  label,
  buttonText = '검색',
  autoComplete = 'off',
  readOnly = true,
  ...props
}: InputButtonLabelProps) {
  return (
    <WithLabel label={label}>
      <InputButton
        {...props}
        buttonText={buttonText}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
    </WithLabel>
  );
}

const Error = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.sizes[1.2]};
  color: ${({ theme }) => theme.colors.red[300]};
`;
