import { useEffect } from 'react';

import { useFormField } from './useFormField';
import usePrevious from './usePrevious';

// A 선택 시 B 활성화, B 선택 시 C 활성화인 경우, initialValue를 의존 순서대로 작성
// ex) { A: 'ALL', B: 'ALL', C: 'ALL' }
export function useDependentSelect<T extends Record<string, string>>(initialValue: T) {
  const { form, setForm, attributes, resetForm } = useFormField(initialValue);
  const previousForm = usePrevious(form);

  useEffect(() => {
    if (!previousForm) return;

    const keys = Object.keys(initialValue);
    keys.forEach((key, index) => {
      if (index === 0) return;

      const previousKey = keys[index - 1];
      if (previousForm[previousKey] !== form[previousKey]) {
        setForm((prev) => ({ ...prev, [key]: initialValue[key] }));
      }
    });
  }, [form]);

  const keys = Object.keys(attributes);
  const manipulatedAttributes = keys.reduce((acc, key, index) => {
    if (index === 0) return acc;

    const previousKey = keys[index - 1];
    acc[key].disabled = form[previousKey] === initialValue[previousKey];
    return acc;
  }, attributes);

  return {
    form,
    attributes: manipulatedAttributes,
    resetForm,
  };
}
