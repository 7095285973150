import { useGridValue } from '@Hooks';
import { client } from '@src/client';
import { useQuery } from '@tanstack/react-query';
import { mapObject, replaceSpaceToNull } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { Nullable } from '@Utils/type';

import { UserRoleItem } from '../components/UserRoleResult/UserRoleResult.type';
import { UserGroupRow, UserRoleAuthorityRow } from '../UserGroupAndRoleManage.type';

import {
  PostUserGroupParams,
  PostUserGroupsParams,
  PutUserGroupRoleParams,
  PutUserGroupRolesParams,
} from './type';

const postUserGroupList = async ({
  status,
  groupCode,
  userGroupRoleCode,
  userGroupName,
}: PostUserGroupParams) => {
  const { data } = await client.post<UserGroupRow[]>(
    '/user-groups/list',
    mapObject({ status, groupCode, userGroupRoleCode, userGroupName }, nullIfEmptyOrALL)
  );
  const result = data.map((datum) => ({ ...datum, active: false }));
  return result;
};

export const useUserGroupListQuery = (form: PostUserGroupParams) => {
  const {
    gridList: userGroupList,
    setGridList: setUserGroupList,
    updatedRow: userGroupUpdatedList,
    setUpdatedRow: setUserGroupUpdatedList,
    rowChangeHandler: userGroupRowChange,
  } = useGridValue<UserGroupRow>([], 'userGroupId');

  const { refetch } = useQuery(['user-groups'], () => postUserGroupList(form), {
    enabled: false,
    onSuccess(data) {
      setUserGroupList(data);
    },
  });
  return {
    userGroupList,
    setUserGroupList,
    userGroupRowChange,
    userGroupUpdatedList,
    setUserGroupUpdatedList,
    refetch,
  };
};

export type UserRoleRow = {
  active: boolean;
} & UserRoleItem;

export const getUserRoleList = async (userGroupId: Nullable<number>) => {
  const { data } = await client.get<UserRoleItem[]>(`/user-groups/${userGroupId}/roles`);
  return data;
};

export const useUserRoleListQuery = (userGroupId: Nullable<number>) => {
  const {
    gridList: roleList,
    setGridList: setRoleList,
    updatedRow: roleUpdatedList,
    setUpdatedRow: setRoleUpdatedList,
    rowChangeHandler: roleRowChange,
  } = useGridValue<UserRoleRow>([], 'roleId');

  const { refetch } = useQuery(['user-role', userGroupId], () => getUserRoleList(userGroupId), {
    keepPreviousData: true,
    enabled: !!userGroupId,
    onSuccess: (data) => {
      if (data)
        setRoleList(
          data.filter((it) => it.activated !== '비활성화').map((it) => ({ ...it, active: false }))
        );
    },
  });

  const refresh = () => refetch();

  return { roleRowChange, roleList, setRoleList, roleUpdatedList, setRoleUpdatedList, refresh };
};

export const postUserGroups = async (params: PostUserGroupsParams) => {
  const body = replaceSpaceToNull({ ...params });
  const { data } = await client.post<number>('/user-groups', body);
  return data;
};

export const postUserGroupRoles = async (params: { groupId: number; roleName: string }) => {
  const { data } = await client.post<number>('/user-groups/roles', params);
  return data;
};

export const putUserGroupRoles = async (params: PutUserGroupRolesParams) => {
  const { data } = await client.put<{ groupIds: number[]; roleIds: number[] }>(
    '/user-groups',
    params
  );
  return data;
};

export const getUserGroupRoleList = async (roleId: Nullable<number>) => {
  const { data } = await client.get<UserRoleAuthorityRow[]>(
    `/user-groups/roles/${roleId}/authorities`
  );
  return data;
};

export const useUserGroupRoleListQuery = (roleId: Nullable<number>) => {
  const { gridList, setGridList, updatedRow, setUpdatedRow, rowChangeHandler } =
    useGridValue<UserRoleAuthorityRow>([], 'menuId');

  useQuery(['user-role-authority', roleId], () => getUserGroupRoleList(roleId), {
    keepPreviousData: true,
    enabled: !!roleId,
    onSuccess: (data) => {
      if (data) setGridList(data);
    },
  });

  return {
    userAuthorityList: gridList,
    userAuthorityUpdatedList: updatedRow,
    setUserAuthorityUpdatedList: setUpdatedRow,
    rowChangeHandler,
  };
};

export const putUserGroupRole = async (params: PutUserGroupRoleParams) => {
  const { roleId, ...body } = params;
  const { data } = await client.put<number>(`/user-groups/roles/${roleId}/authorities`, {
    ...body,
  });
  return data;
};

export const putUserGroupStatus = async ({ ids, isAbled }: { ids: number[]; isAbled: boolean }) => {
  const url = isAbled ? '/user-groups/able' : '/user-groups/disabled';
  const { data } = await client.put(url, { ids });
  return { ...data, isAbled };
};

export const putUserGroupRoleStatus = async ({ ids }: { ids: number[] }) => {
  const { data } = await client.put('/user-groups/roles/disabled', { ids });
  return data;
};
