import styled from 'styled-components';

import { disabled, inputStyle } from '@Styles/constant';
import { flexRow } from '@Styles/utils/flex';

export const Wrapper = styled.div`
  ${flexRow('normal', 'center')};
  gap: ${({ theme }) => theme.spaces['0.6']};

  input {
    ${inputStyle}

    &:read-only {
      ${disabled}
    }
  }
`;

export const DatePickerWrapper = styled.div`
  ${flexRow('normal', 'center')};
  height: 2.6rem;
  width: 100%;

  .react-datepicker-wrapper {
    width: inherit;
    height: inherit;

    & * {
      height: inherit;
    }
  }

  & .react-datepicker {
    border-color: ${({ theme }) => theme.colors.gray[400]};
  }

  & .react-datepicker__triangle::after {
    display: none;
  }

  & .react-datepicker__triangle::before {
    display: none;
  }

  & .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  & .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: ${({ theme }) => theme.colors.blue[700]};
    background-color: #fff;
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    color: ${({ theme }) => theme.colors.blue[700]};
    background-color: #fff;
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.blue[700]};
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background: transparent;
  }

  & .react-datepicker__header {
    background-color: #fff;
    border: 0;
    padding: 1.4rem 0;
  }

  & .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-weight: ${({ theme }) => theme.fontWeights[300]};
    margin-bottom: 0.8rem;
  }

  & .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  & .react-datepicker__navigation {
    top: 0.4rem;
  }

  & .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 0.1rem 0.1rem 0 0;
    content: '';
    display: block;
    height: 0.9rem;
    position: absolute;
    top: 0.8rem;
    width: 0.8rem;
  }

  & .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2rem;
  }

  & .react-datepicker-popper {
    z-index: 16;
  }
`;
