import { Dispatch, SetStateAction } from 'react';

import { useRecoilState } from 'recoil';

import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Pagination from '@Components/Pagination';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import {
  SEARCH_PROCESSING_COLUMN,
  SELECTED_INBOUND_COLUMN,
  SELECTED_OUTBOUND_COLUMN,
} from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageResult/constant';
import {
  processingListSelector,
  processingStateSelector,
} from '@Features/ProcessedCirculateManage/stores/atoms';
import ResultHeader from '@Layout/components/ResultHeader';
import { Nullable } from '@Utils/type';

import {
  ProcessingBothBoundsType,
  ProcessingInGridItem,
  ProcessingOutGridItem,
} from '../../ProcessedCirculateHistory.type';

import { ProcessedCirculateManageResultCell } from './ProcessedCirculateManageResultCell';

function ProcessedCirculateManageResult({
  processingId,
  setProcessingId,
  processingDetail,
}: {
  processingId: Nullable<number>;
  setProcessingId: Dispatch<SetStateAction<Nullable<number>>>;
  processingDetail:
    | ProcessingBothBoundsType<ProcessingInGridItem, ProcessingOutGridItem>
    | undefined;
}) {
  const [state, setState] = useRecoilState(processingStateSelector);
  const [processingList, setProcessingList] = useRecoilState(processingListSelector);

  const setPage = (page: number) => {
    setState({
      ...state,
      page,
    });
    setProcessingId(null);
  };

  return (
    <Flex direction='column' rowGap={10}>
      <ResultHeader title='조회내역' total={state.total} />
      <DataSheetGrid
        {...mappingAllCheck(SEARCH_PROCESSING_COLUMN, processingList, setProcessingList)}
        value={processingList}
        onChange={setProcessingList}
        onActiveChange={({ row: rowIndex }) => {
          setProcessingId(processingList[rowIndex].processingId);
        }}
        gutterColumn={CUSTOM_GUTTER_COLUMN}
        customRendererList={[
          { id: 'processingStatusCodeValue', component: ProcessedCirculateManageResultCell },
        ]}
      />
      <Flex justify='center'>
        <Pagination
          total={state.total}
          pageSize={100}
          currentPageIndex={state.page}
          onPageIndexChange={setPage}
        />
      </Flex>

      {processingId !== null && (
        <>
          <ResultHeader title='출고 품목 내역' total={processingDetail?.outbounds.length} />
          <DataSheetGrid value={processingDetail?.outbounds} columns={SELECTED_OUTBOUND_COLUMN} />
          <ResultHeader title='입고 품목 내역' total={processingDetail?.inbounds.length} />
          <DataSheetGrid value={processingDetail?.inbounds} columns={SELECTED_INBOUND_COLUMN} />
        </>
      )}
    </Flex>
  );
}

export default ProcessedCirculateManageResult;
