import { useEffect, useMemo, useState } from 'react';

import { Resetter } from 'recoil';

import CautionBox from '@Components/CautionBox';
import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import ExcelFileUpload from '@Features/InboundHistory/components/InboundHistoryNewModal/ExcelFileUpload';
import GridLayout from '@Layout/components/GridLayout';
import ModalFooter from '@Layout/components/ModalFooter';
import ModalWrapper from '@Layout/components/ModalWrapper';
import ResultHeader from '@Layout/components/ResultHeader';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import { useMutation } from '@tanstack/react-query';
import { performIfNotEmpty } from '@Utils/helper';

import { postOutboundDivisions, putOutboundDivisions } from '../../apis';
import { getMappingValidationRowParams } from '../../apis/helper';
import { useOutboundDivisionUpdateFormQuery } from '../../apis/queries';
import { OutboundDivisionRowAndActive } from '../../apis/type';
import { ValidationResultMessage } from '../OutboundDivisionCell';

import { setUploadedOutboundDivisionListColumns } from './constant';

interface OutboundDivisionEditModalProps {
  rows?: OutboundDivisionRowAndActive[];
  close: Resetter;
  search?: () => void;
}

function OutboundDivisionEditModal({ rows, close, search }: OutboundDivisionEditModalProps) {
  const [disabled, setDisabled] = useState(false);

  const isEdit = useMemo(() => rows && rows.length, [rows]);

  const { showActivityIndicator } = useFullScreenActivityIndicator();

  const { editRow, setEditRow, refetch, checkValidation, deleteRow, excelUpload } =
    useOutboundDivisionUpdateFormQuery({
      ids: rows?.map((it) => it.id) ?? [],
    });

  const onMutationCallbackFn = {
    onSuccess: () => {
      close();
      search && search();
    },
    onError: () => {
      toastify('수정에 실패하였습니다.', { type: 'error' });
    },
    onSettled: () => {
      setDisabled(false);
    },
  };

  const { mutate: update } = useMutation(putOutboundDivisions, onMutationCallbackFn);
  const { mutate: register } = useMutation(postOutboundDivisions, onMutationCallbackFn);

  const targetRows = editRow.filter((it) => it.active);

  useEffect(() => {
    if (rows) refetch();
  }, [rows]);

  const deleteHandler = performIfNotEmpty(targetRows, () => {
    const rows = targetRows.map((it) => it.id);
    deleteRow(rows);
  });

  const checkValidationHandler = performIfNotEmpty(editRow, () => {
    checkValidation(isEdit ? 'UPDATE' : 'REGISTER');
  });

  const updateOutboundDivision = performIfNotEmpty(editRow, () => {
    const body = getMappingValidationRowParams(editRow);
    setDisabled(true);
    isEdit
      ? update({ outboundDivisions: body })
      : // eslint-disable-next-line @typescript-eslint/no-unused-vars
        register({ outboundDivisions: body.map(({ id, ...item }) => item) });
  });

  const onFileUpload = (file: FileList) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    excelUpload(formData);
    showActivityIndicator();
  };

  return (
    <ModalWrapper>
      <div>
        <CautionBox
          title='참고사항'
          messages={[
            '저장된 출고 분할정보는 [비활성화] 상태로 생성됩니다.',
            '유효성 체크를 모두 통과해야 저장 가능합니다.',
            '유효성 체크 항목 - [품목코드, 재고상태, 기등록 내역, 출고센터]',
          ]}
        />
        <GridLayout
          header={
            <ResultHeader
              title={
                <Flex align='center' colGap={22}>
                  <Text>출고분할 정보 등록</Text>
                  {!isEdit && (
                    <ExcelFileUpload onFileUpload={onFileUpload} fileName='업로드_출고분할정보' />
                  )}
                </Flex>
              }
              right={
                <Button size='x-small' onClick={deleteHandler}>
                  삭제
                </Button>
              }
            />
          }
          grid={
            <DataSheetGrid
              {...mappingAllCheck(
                setUploadedOutboundDivisionListColumns(rows ? !rows.length : true),
                editRow,
                setEditRow
              )}
              value={editRow}
              onChange={setEditRow}
              customRendererList={[{ id: 'validationMessage', component: ValidationResultMessage }]}
            />
          }
        />
        <ModalFooter>
          <Button onClick={checkValidationHandler}>체크</Button>
          <Button
            onClick={updateOutboundDivision}
            disabled={
              disabled || editRow.some(({ validationMessage }) => validationMessage !== 'Success')
            }
          >
            저장
          </Button>
          <Button onClick={close}>취소</Button>
        </ModalFooter>
      </div>
    </ModalWrapper>
  );
}

export default OutboundDivisionEditModal;
