import style, { css } from 'styled-components';

import { convertRem } from '@Styles/utils/calc';

import { BoxContainerProps, BoxProps, Margin, Padding } from './Box.type';
import { spaceHandler } from './util';

const BoxContainer = style.div<BoxContainerProps>`
  ${(props) => {
    const { $width, $height, margin, padding } = props;
    return css`
      width: ${$width};
      height: ${$height};
      ${margin};
      ${padding};
    `;
  }}
`;

function Box({ $width, $height, children, as = 'div', ...rest }: BoxProps) {
  const margin = spaceHandler<Margin>({ type: 'margin', regExp: /^m/gi, ...rest });
  const padding = spaceHandler<Padding>({ type: 'padding', regExp: /^p/gi, ...rest });

  return (
    <BoxContainer
      as={as}
      $width={$width ? convertRem($width) : undefined}
      $height={$height ? convertRem($height) : undefined}
      margin={margin}
      padding={padding}
    >
      {children}
    </BoxContainer>
  );
}

export default Box;
