import { SelectHTMLAttributes } from 'react';

import styled from 'styled-components';

import { disabled, inputStyle } from '@Styles/constant';

import { WithLabel, WithLabelProps } from '../WithLabel';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  // TODO: options 전분 readonly로 변경 후 SelectOption[] 제거
  options: SelectOption[] | readonly SelectOption[];
  value: string;
  onChange: SelectHTMLAttributes<HTMLSelectElement>['onChange'];
  placeholder?: string;
  disabled?: boolean;
}

export interface SelectOption {
  value: string;
  label: string;
}

function Select({ name, options, value, onChange, placeholder, disabled, ...rest }: SelectProps) {
  return (
    <SelectWrapper name={name} value={value} onChange={onChange} disabled={disabled} {...rest}>
      {placeholder && (
        <option value='' disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </SelectWrapper>
  );
}

export function SelectLabel({ label, ...props }: Omit<WithLabelProps, 'children'> & SelectProps) {
  return (
    <WithLabel label={label}>
      <Select {...props} />
    </WithLabel>
  );
}

const SelectWrapper = styled.select<any>`
  ${inputStyle}
  &:disabled {
    ${disabled}
  }
`;
