import style from 'styled-components';

import * as LabelPrimitive from '@radix-ui/react-label';

export const RadioButton = style.input`
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
`;

export const Label = style(LabelPrimitive.Root)<{ disabled?: boolean }>`
    color: ${({
      theme: {
        colors: { gray },
      },
      disabled,
    }) => (disabled ? gray[400] : gray[800])};
    line-height: 2.6rem;
    font-size: 1.2rem;
    min-width: 4rem;
    cursor: pointer;
    position:relative;
    top: .1rem;
`;
