import styled from 'styled-components';

import Flex from '@Components/Flex';
import { LoginImage } from '@Images';
import { flexColumn } from '@Styles/utils/flex';

export const BackgroundSection = styled(Flex)`
  background: #f3f4f6;
  width: 100vw;
  height: 100vh;
`;

export const Banner = styled.div`
  width: 50rem;
  height: 10rem;
  background: #e2e5e9;
  border-radius: ${({ theme }) => theme.radiuses['0.6']};
  background-image: url(${LoginImage.loginBanner});
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 15rem;
  ${flexColumn('center')}
  row-gap: .6rem;
  box-shadow: 0.1rem 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.12);
`;
