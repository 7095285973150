import { useState } from 'react';

import { useFullScreenActivityIndicator } from '@src/Hooks';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getMappingValidationRowParams } from './helper';
import {
  CheckValidationParams,
  OutboundDivisionEditRowAndActive,
  OutboundDivisionRowAndActive,
} from './type';
import {
  postDivisionRegisterValidation,
  postOutboundDivisionExcelUpload,
  postOutboundDivisionList,
  PostOutboundDivisionListParams,
  postOutboundDivisionsValidation,
  postOutboundDivisionUpdateForm,
} from '.';

export const useOutboundDivisionListQuery = (params: PostOutboundDivisionListParams) => {
  const { hideActivityIndicator } = useFullScreenActivityIndicator();
  const [outboundDivisionList, setOutboundDivisionList] = useState<OutboundDivisionRowAndActive[]>(
    []
  );

  const { refetch } = useQuery(
    ['outbound-division-list', params],
    () => postOutboundDivisionList(params),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess: (data) => {
        setOutboundDivisionList(data.map((it) => ({ ...it, active: false })));
      },
      onSettled: () => {
        hideActivityIndicator();
      },
    }
  );

  return { outboundDivisionList, setOutboundDivisionList, refetch };
};

const useCheckValidation = () => {
  const { mutate: setUpdateValidation } = useMutation(postOutboundDivisionsValidation);
  const { mutate: setRegisterValidation } = useMutation(postDivisionRegisterValidation);

  const validationHandler = ({ type, params, cb }: CheckValidationParams) => {
    return type === 'REGISTER'
      ? setRegisterValidation(params, cb)
      : setUpdateValidation(params, cb);
  };

  return { handler: validationHandler };
};

export const useOutboundDivisionUpdateFormQuery = (params: { ids: number[] }) => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();
  const [editRow, setEditRow] = useState<OutboundDivisionEditRowAndActive[]>([]);

  const { handler } = useCheckValidation();

  const checkValidation = (type: CheckValidationParams['type']) => {
    const body =
      type === 'UPDATE'
        ? getMappingValidationRowParams(editRow)
        : // eslint-disable-next-line @typescript-eslint/no-unused-vars
          getMappingValidationRowParams(editRow).map(({ id, ...item }) => item);

    showActivityIndicator();

    handler({
      type,
      params: { outboundDivisions: body },
      cb: {
        onSuccess: (data) =>
          setEditRow(
            data.map(
              type === 'UPDATE'
                ? (it) => ({ ...it, active: false })
                : (it, index) => ({ ...it, active: false, id: index })
            )
          ),
        onSettled: () => hideActivityIndicator(),
      },
    });
  };

  const { refetch } = useQuery(
    ['outbound-division-update-form', params],
    () => postOutboundDivisionUpdateForm(params),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess: (data) => {
        setEditRow(data.map((it) => ({ ...it, active: false })));
      },
      onSettled: () => {
        hideActivityIndicator();
      },
    }
  );

  const deleteRow = (rows: number[]) => {
    setEditRow((prev) =>
      prev
        .map((it) => {
          if (rows.includes(it.id)) return undefined;
          return it;
        })
        .filter((it): it is OutboundDivisionEditRowAndActive => !!it)
    );
  };

  const { mutate: excelUpload } = useMutation(postOutboundDivisionExcelUpload, {
    onSuccess: (data) => {
      setEditRow(data.map((it, index) => ({ ...it, active: false, id: index })));
    },
    onSettled: () => hideActivityIndicator(),
  });

  return { refetch, editRow, setEditRow, checkValidation, deleteRow, excelUpload };
};
