import { FormContainer } from './Form.style';
import { FormProps } from './Form.type';

function Form({ children, onSubmit, width }: FormProps) {
  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  return (
    <FormContainer onSubmit={onSubmitHandler} width={width}>
      {children}
      <input type='submit' />
    </FormContainer>
  );
}

export default Form;
