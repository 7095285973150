import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useDialog } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';
import { performIfConfirmed } from '@src/Utils/helper';
import { useMutation } from '@tanstack/react-query';

import { postUserGroupRoles, putUserGroupRoleStatus } from '../../apis';
import { UserRoleResultProps } from '../../UserGroupAndRoleManage.type';
import UserRoleModal from '../modals/UserRoleModal';

import { USER_ROLE_COLUMNS } from './constant';

function UserRoleResult({
  userGroupId,
  roleList,
  setRoleList,
  roleRowChange,
  refresh,
}: UserRoleResultProps) {
  const { openDialog, resetDialog } = useDialog('UserRoleModal');

  const activatedList = roleList.filter((it) => it.active);

  const { mutate: addMutation } = useMutation(postUserGroupRoles, {
    onSuccess: (data) => {
      if (data) refresh();
      resetDialog();
    },
  });
  const { mutate: removeMutation } = useMutation(putUserGroupRoleStatus, {
    onSuccess: (data) => {
      if (data) {
        toastify('삭제되었습니다.');
        refresh();
      }
    },
  });

  const add = (roleName = '') => {
    const params = {
      groupId: userGroupId as number,
      roleName,
    };
    addMutation(params);
  };

  const remove = () => {
    if (!activatedList.length) {
      toastify('허용되지 않은 동작입니다.', { type: 'error' });
      return false;
    }
    performIfConfirmed('선택된 역할을 삭제하시겠습니까?', () => {
      const ids = activatedList.map((it) => it.roleId);
      removeMutation({ ids });
    });
  };

  const setAdd = () => {
    if (!userGroupId) {
      toastify('허용되지 않은 동작입니다.', { type: 'error' });
      return;
    }

    openDialog({
      title: '신규',
      content: <UserRoleModal close={resetDialog} add={add} />,
      size: 'small',
    });
  };

  const renderRight = () => {
    return (
      <Flex align='center' colGap={16}>
        <Text color='gray.800' fontWeight={500} size={1.4}>
          역할 추가/삭제
        </Text>
        <Flex align='center' colGap={8}>
          <Button onClick={setAdd} size='x-small'>
            추가
          </Button>
          <Button size='x-small' onClick={remove}>
            삭제
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction='column' rowGap={14}>
      <ResultHeader title='역할내역' total={roleList.length} right={renderRight()} />
      <DataSheetGrid
        {...mappingAllCheck(
          USER_ROLE_COLUMNS,
          roleList.filter((it) => it.activated !== '비활성화'),
          setRoleList
        )}
        value={roleList.filter((it) => it.activated !== '비활성화')}
        onChange={roleRowChange}
      />
    </Flex>
  );
}

export default UserRoleResult;
