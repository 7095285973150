import { client } from '@src/client';
import { useMutation } from '@tanstack/react-query';

const closeStock = async (monthEnd: string) => {
  const { data } = await client.post('/stats/ends', { monthEnd });

  return data;
};

export const useCloseStockMutation = () => {
  return useMutation(closeStock);
};
