import { useState } from 'react';

import { AxiosRequestConfig } from 'axios';

import { UseQueryOptions } from '@tanstack/react-query';

import useRequest, { TQueryKey } from './useRequest';

// TODO: TFilter 타입추론 안 되는 이슈 해결 필요
function useSearchRequest<TFilter, TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>({
  initialFilter,
  getRequest,
  options: { enabled: initialEnabled = false, keepPreviousData = true, onSettled, ...options },
}: {
  initialFilter: TFilter;
  getRequest: (filter: TFilter) => AxiosRequestConfig;
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
}) {
  const [filter, setFilter] = useState(initialFilter);
  const [enabled, setEnabled] = useState(initialEnabled);

  const useQueryResult = useRequest(getRequest(filter), {
    ...options,
    enabled,
    keepPreviousData,
    onSettled(data, error) {
      setEnabled(false);
      if (onSettled) {
        onSettled(data, error);
      }
    },
  });

  const setSearchFilter: typeof setFilter = (newFilter) => {
    setEnabled(true);
    setFilter(newFilter);
  };

  return { useQueryResult, filter, setSearchFilter };
}

export default useSearchRequest;
