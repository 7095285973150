import { Text } from '@src/Components/Typography';
import { ErrorCell } from '@src/Features/InboundHistory/components/InboundHistoryNewModal/InboundHistoryNewModal.style';
import { ExcelRow } from '@src/Features/InboundHistory/InboundHistory.type';

export function CheckMessage({ rowData }: { rowData: ExcelRow }) {
  return (
    <ErrorCell bg={rowData.errorMessage}>
      <Text color='gray.900' fontWeight={400} size={1.2}>
        {rowData.errorMessage}
      </Text>
    </ErrorCell>
  );
}
