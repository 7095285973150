import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}

    *, *::before, *::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: sans-serif;
    }

    html{
        font-size: 62.5%;
    }

    body{
        font-size: 1.6rem;
        background-color: #fcfcfc;
    }

    button, a, label {
        cursor: pointer;
    }

    button:disabled{
        cursor:not-allowed;
    }

    button, input, textarea, select {
        background-color: #fff;
        border: 0;
        color:inherit;

        &:focus {
            outline: none;
        }
    }

    button {
        background-color: transparent;
    }

    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ul, li {
        list-style: none;
    }

    .react-datepicker__portal {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .width-100 {
     width: 100%;
   }
`;

export default GlobalStyle;
