import { convertRem } from '@Styles/utils/calc';
import { PrefixType } from '@Utils/type';

import { Direction, Margin, Padding, SpaceArgsType } from './Box.type';

const SIDE_MAP: { [key in string]: string } = {
  b: 'bottom',
  l: 'left',
  r: 'right',
  t: 'top',
} as const;

export function spaceHandler<T>(args: SpaceArgsType): T {
  const { type, regExp, ...rest } = args;
  const spaces = Object.keys(rest)
    .filter((it) => it.match(regExp))
    .reduce((styles, cur) => {
      const result = { ...styles } as Margin & Padding;

      const [[, direction], value] = cur.split('-');
      const pxToRemValue = convertRem(Number(value));

      const styleKey = `${type}-${SIDE_MAP[direction]}` as PrefixType<typeof type, Direction>;

      result[styleKey] = `${pxToRemValue}`;

      return result;
    }, {});

  return spaces as T;
}
