import { ReactNode } from 'react';

import Flex from '../Flex';

function Center({
  children,
  className,
}: {
  children: ReactNode | ReactNode[];
  className?: string;
}) {
  return (
    <Flex justify='center' align='center' className={className}>
      {children}
    </Flex>
  );
}

export default Center;
