import { ActivityIndicatorImage } from '@Images';

import {
  ActivityIndicatorBackground,
  ActivityIndicatorImg,
  ActivityIndicatorWrapper,
} from './ActivityIndicator.style';
import { ActivityIndicatorProps, ActivityIndicatorSizeTag } from './ActivityIndicator.type';

function ActivityIndicator({
  isVisible,
  size = 'middle',
  fullScreen = false,
  backgroundColor = '#000000',
  backgroundOpacity = 0.4,
  zIndex = 3,
}: ActivityIndicatorProps) {
  if (!isVisible) return null;
  return (
    <ActivityIndicatorWrapper fullScreen={fullScreen} zIndex={zIndex}>
      <ActivityIndicatorBackground
        zIndex={zIndex}
        fullScreen={fullScreen}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
      />
      <ActivityIndicatorImg
        zIndex={zIndex}
        fullScreen={fullScreen}
        size={getIndicatorSize(size)}
        src={ActivityIndicatorImage.cupertinoActivityIndicator}
        alt='activity indicator'
      />
    </ActivityIndicatorWrapper>
  );
}

const INDICATOR_SIZE: Record<ActivityIndicatorSizeTag, number> = {
  small: 3,
  middle: 5,
  large: 10,
};

const getIndicatorSize = (size: number | ActivityIndicatorSizeTag): number => {
  if (typeof size === 'string') {
    return INDICATOR_SIZE[size];
  }

  return size;
};

export default ActivityIndicator;
