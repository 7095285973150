import Box from '@Components/Box';
import { Text } from '@Components/Typography';
import { ErrorCell } from '@Features/InboundHistory/components/InboundHistoryNewModal/InboundHistoryNewModal.style';

import { InboundRequestRow } from '../../apis/useInboundAdjustRequestListQuery';

export function CheckMessage({ rowData }: { rowData: InboundRequestRow }) {
  return (
    <ErrorCell bg={rowData.result}>
      <Text color='gray.900' fontWeight={400} size={1.2}>
        {rowData.result}
      </Text>
    </ErrorCell>
  );
}

export function SelectTestCell({
  rowData,
  setRowData,
}: {
  rowData: InboundRequestRow;
  setRowData: (rowData: InboundRequestRow) => void;
}) {
  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCell = { ...rowData };
    newCell.adjustReason = e.target.value;
    setRowData(newCell);
  };

  return (
    <Box pl-10 pr-10 $width='100%'>
      <select className='width-100' value={rowData.adjustReason ?? ''} onChange={onChangeHandler}>
        <option value=''>조정사유 선택</option>
        <option value='MISSPELLED'>실적 오기입</option>
        <option value='DEFECTIVE'>원천불량 반품</option>
        <option value='ETC'>기타</option>
      </select>
    </Box>
  );
}
