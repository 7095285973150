import { Resetter } from 'recoil';

import Button from '@Components/Forms/Button';
import { Input } from '@Components/Forms/Input';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useFormField } from '@Hooks';
import ModalFooter from '@Layout/components/ModalFooter';

import { ProductCodeType } from './ProductCodeModal';
import { ModalForm } from './ProductCodeModal.style';
import { ProductItemRow } from './ProductCodeModal.type';

interface AvailableCodeModalProps {
  type: ProductCodeType;
  close: Resetter;
  target: ProductItemRow;
  selectProductCode?: (rowData: ProductItemRow & { availableQuantity: number }) => void;
}

function AvailableCodeModal({ type, close, target, selectProductCode }: AvailableCodeModalProps) {
  const { form, attributes } = useFormField({ availableQuantity: '' });
  const onAddProductItemHandler = () => {
    const { availableQuantity } = form;

    if (!availableQuantity) {
      toastify(`${type === 'inbound' ? '입고' : '출고'}예정수량을 입력해주세요.`, {
        type: 'error',
      });
      return;
    }

    selectProductCode &&
      selectProductCode({ ...target, availableQuantity: Number(form.availableQuantity) });
    close();
  };

  return (
    <div>
      <ModalForm>
        <Text as='p' color='gray.900'>
          {`${type === 'inbound' ? '입고' : '출고'}예정수량을 입력해주세요.`}
        </Text>
        <Input {...attributes.availableQuantity} />
      </ModalForm>
      <ModalFooter>
        <Button onClick={onAddProductItemHandler}>선택</Button>
        <Button onClick={close}>취소</Button>
      </ModalFooter>
    </div>
  );
}

export default AvailableCodeModal;
