import { Text } from '@Components/Typography';

import LoginForm from './components/LoginForm';
import { BackgroundSection, Banner } from './Login.style';

function Login() {
  return (
    <BackgroundSection direction='column' align='center' justify='center' rowGap={32}>
      <LoginForm />
      <Banner>
        <Text as='p' size={1.4} color='gray.900' fontWeight={500}>
          이용 및 권한 관련 문의
        </Text>
        <Text as='p' size={1.4} color='gray.700' fontWeight={400}>
          펫프렌즈 WMS 이용안내 : po@pet-friends.co.kr
        </Text>
      </Banner>
    </BackgroundSection>
  );
}

export default Login;
