import { FlexContainerProps } from '@Components/Flex/Flex.type';

import { FormFieldBox } from './FormField.style';

function FormField({ children, ...props }: FlexContainerProps) {
  return <FormFieldBox {...props}>{children}</FormFieldBox>;
}

FormField.defaultProps = {
  wrap: 'wrap',
  align: 'flex-start',
  colGap: 20,
  rowGap: 14,
};

export default FormField;
