import { keyColumn, textColumn } from 'react-datasheet-grid';

export const COMPLETION_GRID_OPTIONS = {
  columns: [
    { ...keyColumn('productItemId', textColumn), title: '품목코드', disabled: true },
    { ...keyColumn('productItemName', textColumn), title: '품목명', disabled: true, minWidth: 300 },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '입고예정수량',
      disabled: true,
    },
    {
      ...keyColumn('receivedQuantity', textColumn),
      title: '입고실적수량',
    },
    {
      ...keyColumn('createDate', textColumn),
      title: '제조일자',
    },
    {
      ...keyColumn('itemRemark', textColumn),
      title: '비고',
      minWidth: 200,
    },
  ],
};
