export const EXCEL_START_POINT = {
  다운로드_입고내역: 2,
  다운로드_출고내역: 2,
  다운로드_입고실적조회: 2,
  다운로드_출고실적조회: 2,
  다운로드_마감재고조회: 3,
  다운로드_일별수불대사: 4,
  다운로드_월별수불대사: 4,
  다운로드_실시간재고조회: 2,
  다운로드_입고상세내역: 2,
  다운로드_출고상세내역: 2,
  다운로드_거래처조회: 2,
  다운로드_품목관리: 2,
  다운로드_출고분할정보: 2,
  다운로드_반품내역: 2,
};

export type PointType = keyof typeof EXCEL_START_POINT;
