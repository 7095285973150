import { client } from '@src/client';
import { useQuery } from '@tanstack/react-query';

export interface ProductGroupItem {
  id: number;
  name: string;
  sortOrder: number;
  parentId: number;
}

const fetchProductCategory = async () => {
  const { data } = await client.get<
    Record<'productGroup1Id' | 'productGroup2Id' | 'productGroup3Id', ProductGroupItem[]>
  >('/product-items/product-groups');

  return data;
};

const getCategoryOptions = (productGroupItems: ProductGroupItem[] | undefined) => {
  return productGroupItems?.map(({ id, name, parentId }) => ({
    value: String(id),
    label: name,
    parentId,
  }));
};

export const useProductCategoryQuery = () => {
  return useQuery(['product-items', 'product-groups'], fetchProductCategory, {
    staleTime: Infinity,
    select(data) {
      return {
        productGroup1Id: getCategoryOptions(data?.productGroup1Id),
        productGroup2Id: getCategoryOptions(data?.productGroup2Id),
        productGroup3Id: getCategoryOptions(data?.productGroup3Id),
      };
    },
  });
};
