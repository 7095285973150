import styled from 'styled-components';

import { ellipsis } from '@Styles/constant';
import { colors, sizes } from '@Styles/ThemeStyle';

import { ColorKey, Scale, TypographyProps } from './Typography.type';

export const StyledTypography = styled.p<Required<Omit<TypographyProps, 'as' | 'children'>>>`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ size }) => sizes[size]};
  color: ${({ color }) => {
    const [colorKey, scale] = color.split('.') as [ColorKey, Scale];
    return colors[colorKey][scale];
  }};

  text-align: ${({ align }) => align};
  ${({ isTruncated }) => isTruncated && ellipsis};
`;
