import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';
import { centerCodeColumn } from '@src/Components/DataSheetGrid/CenterCodeCell';
import { dateColumn } from '@src/Components/DataSheetGrid/DateCell';

export const UpdateProcessingOutGridColumn = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('errorMessage', textColumn),
    title: '체크메시지',
    minWidth: 400,
    disabled: true,
  },
  {
    ...centerCodeColumn(),
    title: '센터코드',
  },
  {
    ...keyColumn('processingCategoryValue', textColumn),
    title: '출고유형',
  },
  {
    ...dateColumn({
      key: 'availableDay',
      portalId: 'datepicker-modal-root',
    }),
    title: '출고예정일자',
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    minWidth: 300,
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '출고예정수량',
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    minWidth: 200,
    disabled: true,
  },
];

export const UpdateProcessingInGridColumn = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('errorMessage', textColumn),
    title: '체크메시지',
    minWidth: 400,
    disabled: true,
  },
  {
    ...centerCodeColumn(),
    title: '센터코드',
  },
  {
    ...keyColumn('processingCategoryValue', textColumn),
    title: '입고유형',
  },
  {
    ...dateColumn({
      key: 'availableDay',
      portalId: 'datepicker-modal-root',
    }),
    title: '입고예정일자',
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    minWidth: 300,
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '입고예정수량',
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    minWidth: 200,
    disabled: true,
  },
];
