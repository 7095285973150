import styled, { css } from 'styled-components';

import Flex from '@Components/Flex';

interface PaginationProps {
  total: number | undefined;
  pageSize: number;
  currentPageIndex: number;
  onPageIndexChange: (pageIndex: number) => void;
  surroundingPageCount?: number;
}

// pageIndex: 0, 1, 2, ...
// page: 1, 2, 3, ...
function Pagination({
  total = 0,
  pageSize,
  currentPageIndex,
  onPageIndexChange,
  surroundingPageCount = 4,
}: PaginationProps) {
  const pageCount = Math.ceil(total / pageSize);

  if (pageCount === 0) {
    return null;
  }

  const currentPage = currentPageIndex + 1;
  const pages = new Array(pageCount).fill(0).map((_, index) => index + 1);

  const handlePageIndexChange = (pageIndex: number) => () => {
    onPageIndexChange(pageIndex);
  };

  const renderPages = (pages: number[]) => {
    return pages.map((page) => {
      if (
        (currentPage < 1 + surroundingPageCount && page <= 1 + 2 * surroundingPageCount) ||
        (currentPage > pageCount - surroundingPageCount &&
          page >= pageCount - surroundingPageCount * 2) ||
        Math.abs(currentPage - page) <= surroundingPageCount
      ) {
        return (
          <PageItem
            type='button'
            isActive={page === currentPage}
            key={page}
            onClick={page === currentPage ? undefined : handlePageIndexChange(page - 1)}
          >
            {page}
          </PageItem>
        );
      }

      return null;
    });
  };

  return (
    <Flex gap={4}>
      <ArrowButton disabled={currentPage === 1} onClick={handlePageIndexChange(0)}>
        &lt;&lt;
      </ArrowButton>
      <ArrowButton
        disabled={currentPage === 1}
        onClick={handlePageIndexChange(currentPageIndex - 1)}
      >
        &lt;
      </ArrowButton>

      {renderPages(pages)}

      <ArrowButton
        disabled={currentPage === pageCount}
        onClick={handlePageIndexChange(currentPageIndex + 1)}
      >
        &gt;
      </ArrowButton>
      <ArrowButton
        disabled={currentPage === pageCount}
        onClick={handlePageIndexChange(pageCount - 1)}
      >
        &gt;&gt;
      </ArrowButton>
    </Flex>
  );
}

const PageItem = styled.button<{ isActive: boolean }>`
  ${({ theme: { colors, radiuses, spaces }, isActive }) => {
    return css`
      padding: ${spaces['0.6']} ${spaces['1.2']};
      background-color: ${isActive ? colors.blue[700] : 'transparent'};
      border-radius: ${radiuses['0.3']};
      color: ${isActive && 'white'};
      border: 0.1rem solid transparent;

      &:hover {
        border: 0.1rem solid ${isActive ? colors.blue[700] : colors.gray[300]};
      }
    `;
  }}
`;

const ArrowButton = styled.button`
  ${({ theme: { colors, radiuses, spaces } }) => {
    return css`
      color: ${colors.blue[700]};
      border-radius: ${radiuses['0.3']};
      padding: ${spaces['0.6']} ${spaces['1.2']};
      border: 0.1rem solid transparent;

      &:disabled {
        color: ${colors.gray[500]};
      }
      &:hover {
        border: 0.1rem solid ${colors.gray[300]};
      }
    `;
  }}
`;

export default Pagination;
