export const fontWeights = {
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
};

export const sizes = {
  1.0: '1rem',
  1.2: '1.2rem',
  1.4: '1.4rem',
  1.6: '1.6rem',
  1.8: '1.8rem',
  2.0: '2.0rem',
  2.2: '2.2rem',
  2.4: '2.4rem',
};

const radiuses = {
  0.2: '0.2rem',
  0.3: '0.3rem',
  0.4: '0.4rem',
  0.6: '0.6rem',
};

export const colors = {
  gray: {
    50: '#f9fafb',
    100: '#f2f4f6',
    200: '#e5e8eb',
    300: '#d1d6db',
    400: '#b0b8c1',
    500: '#8b95a1',
    600: '#6b7684',
    700: '#4e5968',
    800: '#333d4b',
    900: '#191f28',
  },
  red: {
    50: '#ffebee',
    100: '#ffcdd2',
    200: '#ef9a9a',
    300: '#e57373',
    400: '#ef5350',
    500: '#f44336',
    600: '#e53935',
    700: '#d32f2f',
    800: '#c62828',
    900: '#b71c1c',
  },
  blue: {
    50: '#e8f3ff',
    100: '#c9e2ff',
    200: '#90c2ff',
    300: '#64a8ff',
    400: '#4593fc',
    500: '#3182f6',
    600: '#2272eb',
    700: '#1b64da',
    800: '#1957c2',
    900: '#194aa6',
  },
  orange: {
    50: '#fff3e0',
    100: ' #ffe0b2',
    200: ' #ffcc80',
    300: ' #ffb74d',
    400: ' #ffa726',
    500: ' #ff9800',
    600: ' #fb8c00',
    700: ' #f57c00',
    800: '#ef6c00',
    900: '#e65100',
  },
  green: {
    50: '#e5fff4',
    100: '#abf2d4',
    200: '#5ae9ad',
    300: '#1cd98a',
    400: '#05c072',
    500: '#00a661',
    600: '#009959',
    700: '#008a50',
    800: '#007544',
    900: '#005c36',
  },
};

const spaces = {
  0.4: '0.4rem',
  0.6: '0.6rem',
  0.8: '0.8rem',
  1.0: '1rem',
  1.2: '1.2rem',
  1.4: '1.4rem',
  1.6: '1.6rem',
  1.8: '1.8rem',
  2.0: '2.0rem',
  2.2: '2.2rem',
  2.4: '2.4rem',
};

const deviceSizes = {
  tablet: '76.8rem',
  desktop: '120rem',
};

const theme = {
  fontWeights,
  sizes,
  radiuses,
  colors,
  spaces,
  deviceSizes,
};

export default theme;
