import { FocusEvent, forwardRef, SyntheticEvent } from 'react';

import dayjs from 'dayjs';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { WithLabel, WithLabelProps } from '@Components/Forms/WithLabel';

import { DatePickerWrapper, Wrapper } from './DatePicker.style';

export const isAllowedDate = (date: string) =>
  date ? dayjs(date).isBetween('2020-01-01', '2030-12-31', 'day') : false;

export type DatePickerOnChangeEvent = {
  target: {
    name: string;
    value?: string;
  };
};

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'selected' | 'onChange'> {
  id?: string;
  name: string;
  selected: string;
  onChange: (e: DatePickerOnChangeEvent) => void;
  placeholderText?: string;
  dateFormat?: string;
  dayjsFormat?: string;
  isClearable?: boolean;
}

// https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
// https://reactdatepicker.com/
export const DatePicker = forwardRef<ReactDatePicker, DatePickerProps>(
  (
    {
      id,
      name,
      selected,
      onChange,
      placeholderText,
      dateFormat = 'yyyy-MM-dd',
      dayjsFormat = 'YYYY-MM-DD',
      isClearable = true,
      ...props
    },
    ref
  ) => {
    const isValidDate = dayjs(selected).format(dayjsFormat) === selected;
    if (selected !== '' && !isValidDate) {
      throw new Error(`${selected}는 유효한 형식의 string date가 아닙니다.`);
    }

    const handleDateChange = (date: Date | null, e: SyntheticEvent) => {
      e.stopPropagation();
      const value = date ? dayjs(date).format(dayjsFormat) : '';
      onChange({ target: { name, value } });
    };

    const handleDateChangeRaw = (e: FocusEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const INVALID_VALUE_REGEX = /[^\d./-]/g;
      if (value) {
        const validValue = value.replace(INVALID_VALUE_REGEX, '');
        e.target.value = validValue.substring(0, 10);
      }
    };

    const handleDateBlur = (e: FocusEvent<HTMLInputElement>) => {
      const date = e.target.value;
      if (!isAllowedDate(date)) {
        onChange({ target: { name, value: '' } });
      }
    };

    return (
      <Wrapper>
        <DatePickerWrapper>
          <ReactDatePicker
            ref={ref}
            id={id}
            selected={selected ? dayjs(selected).toDate() : undefined}
            onChange={handleDateChange}
            onChangeRaw={handleDateChangeRaw}
            placeholderText={placeholderText}
            dateFormat={dateFormat}
            isClearable={isClearable}
            onBlur={handleDateBlur}
            autoComplete='off'
            {...props}
          />
        </DatePickerWrapper>
      </Wrapper>
    );
  }
);

export function DatePickerLabel({
  label,
  ...props
}: Omit<WithLabelProps, 'children'> & DatePickerProps) {
  return (
    <WithLabel label={label}>
      <DatePicker {...props} />
    </WithLabel>
  );
}
