import { useState } from 'react';

import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import VendorCodeModal from '@Components/Modals/VendorCodeModal';
import Pagination from '@Components/Pagination';
import { toastify } from '@Components/Toast';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import { useDialog, useFormField } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import GridLayout from '@Layout/components/GridLayout';
import ResultHeader from '@Layout/components/ResultHeader';
import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { INBOUND_CATEGORY_OPTIONS, INVALID_INBOUND_FILTER_MESSAGE } from '@src/Constants/inbound';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';
import {
  generateMaxDate,
  generateMinDate,
  performIfNotEmpty,
  performIfValidFilter,
} from '@Utils/helper';

import { useCancelInboundAdjustMutation } from './apis/useCancelInboundAdjustMutation';
import InboundAdjustRequestModal from './components/InboundAdjustRequestModal';
import { useInboundAdjustExcelDownloadMutation, useInboundsAdjustListQuery } from './apis';
import {
  INBOUND_ADJUST_GRID_OPTIONS,
  INBOUND_ADJUST_LIST_PAGE_SIZE,
  INBOUND_ADJUST_REQUEST_STATUS_CODE_OPTIONS,
  INITIAL_INBOUND_RESULT_ADJUST_FILTER,
  NOT_ALLOWED_REQUEST_STATUSES,
} from './constant';
import { PostInboundsAdjustListParams } from './InboundResultAdjust.type';
import { InboundResultAdjustStatusCell } from './InboundResultAdjustCell';

function InboundResultAdjust() {
  const { columns: inboundAdjustGridColumns } = INBOUND_ADJUST_GRID_OPTIONS;
  const { form, setForm, attributes } = useFormField(INITIAL_INBOUND_RESULT_ADJUST_FILTER);
  const [pageNumber, setPageNumber] = useState(0);
  const { data: centerCodeList } = useCenterCodeList();
  const centerId =
    centerCodeList?.find(({ centerCode }) => centerCode === form.centerCode)?.id ?? null;

  const inboundAdjustListQuery = useInboundsAdjustListQuery({
    ...form,
    centerId,
    pageNumber,
    pageSize: INBOUND_ADJUST_LIST_PAGE_SIZE,
  } as PostInboundsAdjustListParams);

  const cancelInboundAdjustMutation = useCancelInboundAdjustMutation();
  const useExcel = useInboundAdjustExcelDownloadMutation();
  const { openDialog: openAdjustRequestDialog } = useDialog('InboundAdjustRequestModal');
  const centerCodeModal = useDialog('CenterCodeModal');
  const vendorCodeModal = useDialog('VendorCodeModal');
  const excelHistoryModal = useExcelDownLoadHistory({ targetUrl: 'inbound_adjustment' });

  const inboundAjustListTotal = inboundAdjustListQuery.useQueryResult.data?.total;
  const activeInboundAdjustList = inboundAdjustListQuery.list.filter(({ active }) => active);
  const activeInboundItemIds = activeInboundAdjustList.map(({ inboundItemId }) => inboundItemId);

  const handleRequestClick = () => {
    if (
      activeInboundAdjustList.some(
        ({ inboundCategoryValue }) =>
          inboundCategoryValue && NOT_ALLOWED_REQUEST_STATUSES.includes(inboundCategoryValue)
      )
    ) {
      toastify('<B2C-반품> 유형은 포함될 수 없습니다.', {
        type: 'error',
      });
      return;
    }

    if (
      activeInboundAdjustList.some(
        ({ inboundAdjustStatusCodeValue }) =>
          inboundAdjustStatusCodeValue !== '미등록' &&
          inboundAdjustStatusCodeValue !== '실적조정 반려'
      )
    ) {
      toastify('실적조정상태가 <미등록>, <실적조정 반려>인 경우에만 가능합니다.', {
        type: 'error',
      });
      return;
    }

    openAdjustRequestDialog({
      title: '실적조정 요청',
      content: (
        <InboundAdjustRequestModal
          activeIds={activeInboundItemIds}
          refetch={inboundAdjustListQuery.useQueryResult.refetch}
        />
      ),
      size: 'large',
    });
  };

  const cancelInboundAdjust = async () => {
    await cancelInboundAdjustMutation.mutateAsync(
      activeInboundAdjustList.map(({ adjustId }) => adjustId!)
    );
    inboundAdjustListQuery.useQueryResult.refetch();
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '선택기',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            setForm((prevForm) => ({
              ...prevForm,
              centerCode: rowData.centerCode as string,
            }));
          }}
        />
      ),
    });
  };

  const openVendorCodeModal = () => {
    vendorCodeModal.openDialog({
      title: '코드 선택',
      content: (
        <VendorCodeModal
          selectVendorCode={({ vendorId }) => {
            setForm((prevForm) => ({
              ...prevForm,
              vendorId: String(vendorId),
            }));
          }}
        />
      ),
    });
  };

  const excelDownload = () => {
    useExcel.excelDownload({
      ...form,
      centerId,
      pageNumber: 0,
      pageSize: INBOUND_ADJUST_LIST_PAGE_SIZE,
    } as PostInboundsAdjustListParams);
  };

  const onClickExcelHistory = () => {
    excelHistoryModal.openDialog();
  };

  return (
    <Flex as='section' direction='column'>
      <FilterHeader>
        <Button
          onClick={performIfValidFilter({
            filter: form,
            callback: inboundAdjustListQuery.useQueryResult.refetch,
            message: INVALID_INBOUND_FILTER_MESSAGE,
          })}
          isPermissionRequired={false}
        >
          조회
        </Button>
        <Button onClick={performIfNotEmpty(activeInboundAdjustList, handleRequestClick)}>
          실적조정 요청
        </Button>
        <Button onClick={performIfNotEmpty(activeInboundAdjustList, cancelInboundAdjust)}>
          실적조정 요청취소
        </Button>
        <Button
          onClick={performIfNotEmpty(
            inboundAdjustListQuery.list,
            excelDownload,
            '조회된 데이터가 없습니다'
          )}
          isPermissionRequired={false}
        >
          엑셀
        </Button>
        <Button onClick={onClickExcelHistory} isPermissionRequired={false}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>
      <FormField>
        <Flex direction='column' rowGap={10}>
          <DateRangePickerLabel
            label='입고예정일'
            start={{
              ...attributes.startAvailableDay,
              minDate: generateMinDate(form.endAvailableDay, 3),
            }}
            end={{
              ...attributes.endAvailableDay,
              maxDate: generateMaxDate(form.startAvailableDay, 3),
            }}
          />
          <DateRangePickerLabel
            label='입고실적일'
            start={{
              ...attributes.startCompleteDay,
              minDate: generateMinDate(form.endCompleteDay, 3),
            }}
            end={{
              ...attributes.endCompleteDay,
              maxDate: generateMaxDate(form.startCompleteDay, 3),
            }}
          />
          <InputButtonLabel
            {...attributes.centerCode}
            label='센터코드'
            readOnly={false}
            buttonClick={openCenterCodeModal}
          />
        </Flex>
        <Flex direction='column' rowGap={10}>
          <SelectLabel
            label='입고유형'
            options={INBOUND_CATEGORY_OPTIONS}
            {...attributes.inboundCategory}
          />
          <SelectLabel
            label='실적조정 상태'
            options={INBOUND_ADJUST_REQUEST_STATUS_CODE_OPTIONS}
            {...attributes.statusCode}
          />
          <InputButtonLabel
            {...attributes.vendorId}
            label='거래처코드'
            readOnly={false}
            buttonClick={openVendorCodeModal}
          />
        </Flex>
        <TextAreaLabel label='입고번호' {...attributes.wikey} />
        <TextAreaLabel label='발주번호' {...attributes.productSupplyRequestId} />
        <Flex direction='column' rowGap={10}>
          <TextAreaLabel label='품목코드' {...attributes.productItemId} css='height:6.2rem' />
          <InputLabel label='참조번호' {...attributes.referenceCode} />
        </Flex>
      </FormField>
      <Flex direction='column' rowGap={10}>
        <GridLayout
          header={<ResultHeader title='입고 실적내역' total={inboundAjustListTotal} />}
          grid={
            <DataSheetGrid
              {...mappingAllCheck(
                inboundAdjustGridColumns,
                inboundAdjustListQuery.list,
                inboundAdjustListQuery.setList
              )}
              value={inboundAdjustListQuery.list}
              onChange={inboundAdjustListQuery.setList}
              gutterColumn={CUSTOM_GUTTER_COLUMN}
              customRendererList={[
                { id: 'inboundAdjustStatusCodeValue', component: InboundResultAdjustStatusCell },
              ]}
            />
          }
          pagination={
            <Pagination
              currentPageIndex={pageNumber}
              onPageIndexChange={setPageNumber}
              total={inboundAjustListTotal}
              pageSize={INBOUND_ADJUST_LIST_PAGE_SIZE}
            />
          }
        />
      </Flex>
    </Flex>
  );
}

export default InboundResultAdjust;
