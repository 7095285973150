import { useState } from 'react';

import { Resetter } from 'recoil';

import Box from '@Components/Box';
import DataSheetGrid from '@Components/DataSheetGrid';
import { RowInfo } from '@Components/DataSheetGrid/DataSheetGrid.type';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { Heading, Text } from '@Components/Typography';
import {
  putUserGroupRole,
  useUserGroupRoleListQuery,
  useUserRoleListQuery,
} from '@Features/UserGroupAndRoleManage/apis';
import ModalFooter from '@Layout/components/ModalFooter';
import { useMutation } from '@tanstack/react-query';
import { performIfConfirmed } from '@Utils/helper';
import { Nullable } from '@Utils/type';

import { UserGroupRow } from '../../UserGroupAndRoleManageFilter/UserGroupAndRoleManageFilter.type';

import { AUTHORITY_CODE_COLUMNS, USER_ROLE_CODE_COLUMNS } from './constant';

function AuthorityManageModal({
  close,
  userInfo,
}: {
  close: Resetter;
  userInfo: Nullable<UserGroupRow>;
}) {
  const [groupIndex, setGroupIndex] = useState<Nullable<number>>(null);
  const { roleList } = useUserRoleListQuery(userInfo?.id ?? null);

  const userRoleId = groupIndex === null ? null : roleList[groupIndex].roleId;

  const {
    userAuthorityList,
    rowChangeHandler,
    userAuthorityUpdatedList,
    setUserAuthorityUpdatedList,
  } = useUserGroupRoleListQuery(userRoleId);

  const { mutate } = useMutation(putUserGroupRole, {
    onSuccess: (data) => {
      if (data) {
        toastify('저장되었습니다.');
        close();
      }
    },
  });

  const selectGroupId = (rowInfo: RowInfo) => {
    setGroupIndex(rowInfo.row);
  };

  const save = () => {
    if (!userAuthorityUpdatedList.length) {
      toastify('변경된 사항이 존재하지 않습니다.', { type: 'error' });
      return;
    }

    performIfConfirmed('변경사항을 저장하시겠습니까?', () => {
      const params = {
        roleId: userRoleId,
        menuRoleMappingUpdateDtoList: userAuthorityUpdatedList.map(
          ({ menuId, selectPermission, updatePermission }) => ({
            menuId,
            selectPermission,
            updatePermission,
          })
        ),
      };

      mutate(params);
      setUserAuthorityUpdatedList([]);
    });
  };

  return (
    <>
      <Flex direction='column' align='flex-start'>
        <Box $width='100%' mb-16>
          <Flex direction='column' gap={10}>
            <Box mt-12 mb-12>
              <Heading>역할 별 권한관리</Heading>
            </Box>
            <Flex gap={40}>
              <Flex gap={20}>
                <Text fontWeight={700} as='p' size={1.6}>
                  사용자그룹코드
                </Text>
                <Text>{userInfo?.groupCode}</Text>
              </Flex>
              <Flex gap={20}>
                <Text fontWeight={700}>사용자그룹명</Text>
                <Text>{userInfo?.groupName}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Flex align='flex-start' colGap={10} rowGap={24} width='100%'>
          <DataSheetGrid
            columns={USER_ROLE_CODE_COLUMNS}
            value={roleList}
            className='flex-1'
            onActiveChange={selectGroupId}
          />
          <DataSheetGrid
            columns={AUTHORITY_CODE_COLUMNS}
            value={userAuthorityList}
            onChange={rowChangeHandler}
            className='flex-1'
          />
        </Flex>
      </Flex>
      <ModalFooter>
        <Button onClick={save}>저장</Button>
        <Button onClick={close}>취소</Button>
      </ModalFooter>
    </>
  );
}

export default AuthorityManageModal;
