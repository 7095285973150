import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { STOCK_ADJUST_STATUS_CODE_COLOR } from './constant';
import { StockAdjustRow } from './StockInquireAdjust.type';

export function StockAdjustCell({ rowData }: { rowData: StockAdjustRow }) {
  const { statusCodeValue } = rowData;

  const textColor = STOCK_ADJUST_STATUS_CODE_COLOR[
    statusCodeValue as keyof typeof STOCK_ADJUST_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={statusCodeValue} textColor={textColor} />;
}
