import { useEffect, useRef, useState } from 'react';

import { useGridValue } from '@src/Hooks';

import { StockAdjustItemRow, StockAdjustRow } from '../StockInquireAdjust.type';

import { useStockAdjustItems, useStockAdjustListQuery } from './index';
import { PostStockAdjustItemsParams, PostStockAdjustListParams } from './type';

export const useStockAdjustList = (form: PostStockAdjustListParams) => {
  const stockAdjust = useGridValue<StockAdjustRow>([], 'stockAdjustRequestId');

  const { refetch: stockAdjustRefetch } = useStockAdjustListQuery({ ...form }, (data) => {
    data && stockAdjust.setGridList(data.results);
  });

  return { stockAdjust, stockAdjustRefetch };
};

export const useStockAdjustItemList = (params: Omit<PostStockAdjustItemsParams, 'pageNumber'>) => {
  const { stockId, pageSize } = params;
  const totalCountRef = useRef(0);
  const [stockAdjustItemList, setStockAdjustItemList] = useState<StockAdjustItemRow[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { refetch: stockAdjustItemRefetch } = useStockAdjustItems({
    stockId,
    pageNumber,
    pageSize,
    onSuccess: (data) => {
      if (!data) return;
      totalCountRef.current = data.total;
      setStockAdjustItemList(data.results);
    },
  });

  useEffect(() => {
    if (stockId) stockAdjustItemRefetch();
  }, [pageNumber]);

  return { stockAdjustItemList, totalCountRef, pageNumber, setPageNumber };
};
