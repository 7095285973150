import { useState } from 'react';

import { useCenterCodeList } from '@Apis/useCenterCodeList';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { addIndexingData } from '@Utils/helper';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { IndexingData, Nullable, PageResponse } from '@Utils/type';

import { INITIAL_INBOUND_REQUEST_LIST_FILTER } from '../constant';

interface InboundRequestResult {
  adjustId: number;
  inboundItemId: number;
  wikey: string;
  inboundStatusCodeValue: string;
  inboundCategoryValue: string;
  productSupplyRequestId: string;
  availableDay: string;
  completeDay: string;
  centerCode: string;
  centerName: string;
  vendorId: number;
  vendorDisplayName: string;
  remark: string;
  productItemCount: number;
  allCount: number;
  productItemId: number;
  productItemName: string;
  availableQuantity: number;
  receivedQuantity: number;
  itemRemark: string;
  firstInboundQuantity: number;
  adjustQuantity: number;
  inboundAdjustStatusCodeValue: string;
  adjustReasonValue: string;
  otherReason: string;
  createdUserName: string;
  createdAt: string;
  updatedUserName: string;
  updatedAt: string;
}

export const INBOUND_REQUEST_LIST_PAGE_SIZE = 500;

export type InboundRequestRow = InboundRequestResult & { active: boolean };
type InboundRequestRowWithIndex = IndexingData<InboundRequestRow>;

const initialFilter = { ...INITIAL_INBOUND_REQUEST_LIST_FILTER, pageNumber: 0 };
export const useInboundRequestListQuery = () => {
  const [data, setData] = useState<Nullable<PageResponse<InboundRequestRowWithIndex[]>>>(null);
  const { data: centerCodeList } = useCenterCodeList();

  const {
    filter,
    setSearchFilter,
    useQueryResult: { refetch },
  } = useSearchRequest<typeof initialFilter, PageResponse<InboundRequestResult[]>>({
    initialFilter,
    getRequest: (params) => ({
      url: '/inbounds-adjustment/request/list',
      method: 'POST',
      data: mapObject(
        {
          ...params,
          centerId:
            centerCodeList?.find(({ centerCode }) => centerCode === params.centerCode)?.id ?? null,
          pageSize: INBOUND_REQUEST_LIST_PAGE_SIZE,
        },
        nullIfEmptyOrALL
      ),
    }),
    options: {
      onSuccess(data) {
        setData({
          ...data,
          results: addIndexingData(
            data?.results.map((item) => ({ ...item, active: false })),
            filter.pageNumber,
            INBOUND_REQUEST_LIST_PAGE_SIZE
          ),
        });
      },
    },
  });

  const setInboundRequestList = (inboundRequestList: InboundRequestRowWithIndex[]) =>
    setData((prevData) => (prevData ? { ...prevData, results: inboundRequestList } : null));

  const setPageNumber = (pageNumber: number) =>
    setSearchFilter((prevFilter) => ({ ...prevFilter, pageNumber }));

  return {
    data,
    setInboundRequestList,
    setSearchFilter,
    pageNumber: filter.pageNumber,
    setPageNumber,
    refetch,
  };
};
