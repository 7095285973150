import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { INBOUND_RESULT_ADJUST_STATUS_CODE_COLOR } from './constant';
import { InboundAdjustRowAndActive } from './InboundResultAdjust.type';

export function InboundResultAdjustStatusCell({ rowData }: { rowData: InboundAdjustRowAndActive }) {
  const { inboundAdjustStatusCodeValue } = rowData;

  const textColor = INBOUND_RESULT_ADJUST_STATUS_CODE_COLOR[
    inboundAdjustStatusCodeValue as keyof typeof INBOUND_RESULT_ADJUST_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={inboundAdjustStatusCodeValue} textColor={textColor} />;
}
