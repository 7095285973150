import { AxiosRequestConfig } from 'axios';

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { client } from '../client';

export type TQueryKey = readonly unknown[];

function useRequest<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  request: AxiosRequestConfig,
  config?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, TError> {
  const { url = '', data, params } = request;

  const queryKey = [...url.split('/').slice(1), data || params];
  return useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    () => client.request(request).then((response) => response.data),
    config
  );
}

export default useRequest;
