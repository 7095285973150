import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@src/Constants/grid';

export const SEARCH_PROCESSING_STATUS_CODE_COLOR = {
  등록: 'orange.500',
  수정등록: 'orange.500',
  취소: 'red.500',
  진행중: 'blue.500',
  완료: 'green.500',
};

export const SEARCH_PROCESSING_COLUMN = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('processingCode', textColumn),
    title: '유통가공 번호',
    disabled: true,
  },
  {
    ...keyColumn('requestDay', textColumn),
    title: '오더생성일',
    disabled: true,
  },
  {
    ...keyColumn('completeDay', textColumn),
    title: '실적완료일',
    disabled: true,
  },
  {
    ...keyColumn('processingStatusCodeValue', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('wokey', textColumn),
    title: '출고번호',
    disabled: true,
  },
  {
    ...keyColumn('wikey', textColumn),
    title: '입고번호',
    disabled: true,
  },
  {
    ...keyColumn('createdUserName', textColumn),
    title: '등록자',
    disabled: true,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
  },
  {
    ...keyColumn('updatedUserName', textColumn),
    title: '수정자',
    disabled: true,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
  },
];

export const SELECTED_OUTBOUND_COLUMN = [
  {
    ...keyColumn('wokey', textColumn),
    title: '출고번호',
    disabled: true,
  },
  {
    ...keyColumn('outboundCategoryValue', textColumn),
    title: '출고유형',
    disabled: true,
  },
  {
    ...keyColumn('outboundStatusCodeValue', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('avaliableDay', textColumn),
    title: '출고예정일',
    disabled: true,
  },
  {
    ...keyColumn('completeDay', textColumn),
    title: '출고실적일',
    disabled: true,
  },
  {
    ...keyColumn('centerName', textColumn),
    title: '출고센터',
    disabled: true,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '출고예정수량',
    disabled: true,
  },
  {
    ...keyColumn('receivedQuantity', intColumn),
    title: '출고실적수량',
    disabled: true,
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    disabled: true,
  },
];

export const SELECTED_INBOUND_COLUMN = [
  {
    ...keyColumn('wikey', textColumn),
    title: '입고번호',
    disabled: true,
  },
  {
    ...keyColumn('inboundCategoryValue', textColumn),
    title: '입고유형',
    disabled: true,
  },
  {
    ...keyColumn('inboundStatusCodeValue', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('avaliableDay', textColumn),
    title: '입고예정일',
    disabled: true,
  },
  {
    ...keyColumn('completeDay', textColumn),
    title: '입고실적일',
    disabled: true,
  },
  {
    ...keyColumn('centerName', textColumn),
    title: '입고센터',
    disabled: true,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '입고예정수량',
    disabled: true,
  },
  {
    ...keyColumn('receivedQuantity', intColumn),
    title: '입고실적수량',
    disabled: true,
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    disabled: true,
  },
];
