import { useState } from 'react';

import { useFullScreenActivityIndicator, useGridValue } from '@src/Hooks';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { addIndexingData } from '@src/Utils/helper';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import type { IndexingData, Nullable, PageResponse } from '@src/Utils/type';

import { INITIAL_CLAIMS_LIST_FILTER } from '../constant';

export interface GetClaimInquireListParams {
  startRequestDay: string;
  endRequestDay: string;
  startCompleteDay: string; // 반품완료 시작일자
  endCompleteDay: string; // 반품완료 끝일자
  category: string; // 접수유형
  status: string; // 반품 상태코드
  reasonType: string; // 반품사유
  externalIds: string; // 반품번호목록
  wokeys: string; // 출고번호목록
  wikeys: string; // 입고번호목록
  orderId: string; // 주문번호
  reverseCodes: string; // 반품번호

  pageNumber: number;
  pageSize?: number;
}

export interface ClaimRow {
  reverseId: number;
  reverseCode: string;
  externalId: number;
  category: string;
  status: string;
  reasonType: string;
  requestDay: string;
  completeDay: Nullable<string>;
  inboundId: number;
  wikey: string;
  outboundId: Nullable<number>;
  wokey: Nullable<string>;
  outboundStatus: Nullable<string>;
  orderId: Nullable<string>;
  deliveryType: Nullable<string>;
  createdAt: string;
  updatedAt: string;
}

export const CLAIM_LIST_PAGE_SIZE = 100;

const initialFilter = { ...INITIAL_CLAIMS_LIST_FILTER, pageNumber: 0 };

const getClaimInquireListRequest = (params: GetClaimInquireListParams) => {
  return {
    url: '/reverse/list',
    method: 'POST',
    data: mapObject(
      { ...params, pageSize: params.pageSize || CLAIM_LIST_PAGE_SIZE },
      nullIfEmptyOrALL
    ),
  };
};

export const useClaimInquireList = () => {
  const { hideActivityIndicator } = useFullScreenActivityIndicator();

  const [claimListTotal, setClaimListTotal] = useState(0);
  const { gridList, setGridList, updatedRow, setUpdatedRow, rowChangeHandler } = useGridValue<
    IndexingData<ClaimRow>
  >([], 'reverseId');

  const { filter, setSearchFilter, useQueryResult } = useSearchRequest<
    typeof initialFilter,
    PageResponse<ClaimRow[]>
  >({
    initialFilter,
    getRequest: getClaimInquireListRequest,
    options: {
      onSuccess(data) {
        setClaimListTotal(data.total);
        setGridList(addIndexingData(data.results, filter.pageNumber, CLAIM_LIST_PAGE_SIZE));
        setUpdatedRow([]);
      },
      onSettled() {
        hideActivityIndicator();
      },
    },
  });

  const setPageNumber = (pageNumber: number) =>
    setSearchFilter((prev) => ({ ...prev, pageNumber }));

  return {
    pageNumber: filter.pageNumber,
    setPageNumber,
    setSearchFilter,
    list: gridList,
    setList: setGridList,
    rowChangeHandler,
    updatedList: updatedRow,
    claimListTotal,
    refetch: useQueryResult.refetch,
  };
};
