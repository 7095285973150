import { Resetter } from 'recoil';

import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import Separator from '@Components/Separator';
import { Heading } from '@Components/Typography';
import { postUserGroups } from '@Features/UserGroupAndRoleManage/apis';
import { useFormField } from '@Hooks';
import { toastify } from '@src/Components/Toast';
import ModalFooter from '@src/Layout/components/ModalFooter';
import { useMutation } from '@tanstack/react-query';

function UserGroupModal({ close, search }: { close: Resetter; search: () => void }) {
  const { form, attributes } = useFormField({
    userGroupCode: '',
    statusCode: '',
    userGroupName: '',
    contact: '',
  });
  const { mutate } = useMutation(postUserGroups, {
    onSuccess: (data) => {
      if (data) {
        toastify('저장되었습니다.');
        close();
        search();
      }
    },
  });

  const userGroupNameValidation = (value: string) => value.length <= 50;
  const contactValidation = (value: string) => /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(value);

  const save = () => {
    if (!userGroupNameValidation(form.userGroupName)) {
      return false;
    }

    if (form.contact && !contactValidation(form.contact)) {
      return false;
    }

    const body = {
      groupName: form.userGroupName,
      representativePhoneNo: form.contact,
    };

    mutate(body);
  };

  return (
    <>
      <Flex direction='column' align='flex-start' rowGap={20}>
        <Heading>사용자 그룹 등록</Heading>
        <InputLabel
          label='사용자그룹코드**'
          disabled
          {...attributes.userGroupCode}
          placeholder='자동채번'
        />
        <InputLabel label='상태' disabled {...attributes.statusCode} placeholder='활성화' />
        <Separator />
        <InputLabel
          label='사용자그룹명*'
          {...attributes.userGroupName}
          validation={userGroupNameValidation}
          span='50자를 초과할수없습니다.'
        />
        <InputLabel
          label='대표 전화번호'
          {...attributes.contact}
          validation={contactValidation}
          span='02-1234-1234와 같은 형식으로 입력해 주십시오.'
        />
      </Flex>
      <ModalFooter>
        <Button isPermissionRequired={false} onClick={save}>
          저장
        </Button>
        <Button isPermissionRequired={false} onClick={close}>
          취소
        </Button>
      </ModalFooter>
    </>
  );
}

export default UserGroupModal;
