import { useState } from 'react';

import { useCenterCodeList } from '@Apis/useCenterCodeList';
import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import ProductCodeModal from '@Components/Modals/ProductCodeModal';
import Pagination from '@Components/Pagination';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import {
  DELIVERY_TYPE_OPTIONS,
  OUTBOUND_CATEGORY_OPTIONS,
  OUTBOUND_STATUS_CODE,
} from '@Constants/options';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import GridLayout from '@Layout/components/GridLayout';
import ResultHeader from '@Layout/components/ResultHeader';
import { DateCell } from '@src/Components/DataSheetGrid/DateCell';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { INVALID_OUTBOUND_FILTER_MESSAGE } from '@src/Constants/outbound';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';
import {
  generateMaxDate,
  generateMinDate,
  performIfConfirmed,
  performIfNotEmpty,
  performIfValidFilter,
} from '@Utils/helper';
import { Nullable } from '@Utils/type';

import { useAddOutboundItemMutation } from './apis/useAddOutboundItemMutation';
import { useCancelOutboundMutation } from './apis/useCancelOutboundMutation';
import { useCancelSendOutboundMutation } from './apis/useCancelSendOutboundMutation';
import { useDeleteOutboundItemMutation } from './apis/useDeleteOutboundItemMutation';
import { useOutboundItemListQuery } from './apis/useOutboundItemListQuery';
import {
  OUTBOUND_LIST_PAGE_SIZE,
  useDownloadOutboundListQuery,
  useOutboundListQuery,
} from './apis/useOutboundListQuery';
import { useSendOutboundMutation } from './apis/useSendOutboundMutation';
import { useUpdateOutboundListMutation } from './apis/useUpdateOutboundListMutation';
import CancelConfirmModal from './components/CancelConfirmModal';
import CompletionModal from './components/CompletionModal';
import RemoveConfirmModal from './components/RemoveConfirmModal';
import {
  INITIAL_OUTBOUND_LIST_FILTER,
  OUTBOUND_LIST_COLUMNS,
  OUTBOUND_PRODUCT_LIST_COLUMNS,
} from './constant';
import { OutboundStatusCell } from './OutboundHistoryCell';
import OutboundRegisterModal from './OutboundRegisterModal';
import { getUpdateOutboundListParams } from './util';
import {
  cancelOutBoundValidation,
  cancelSendOutBoundValidation,
  completeOutBoundValidation,
  removeOutBoundValidation,
  sendOutBoundValidation,
} from './validation';

function OutboundHistory() {
  const formField = useFormField(INITIAL_OUTBOUND_LIST_FILTER);
  const outboundListQuery = useOutboundListQuery();
  const { data: centerCodeList } = useCenterCodeList();

  const { showActivityIndicator } = useFullScreenActivityIndicator();

  const [selectedOutboundId, setSelectedOutboundId] = useState<Nullable<number>>(null);

  const outboundRegisterModal = useDialog('outboundRegister');
  const outboundCompletionModal = useDialog('outboundCompletion');
  const centerCodeModal = useDialog('CenterCodeModal');
  const productCodeModal = useDialog('ProductCodeModal');
  const cancelConfirmModal = useDialog('OutboundCancelConfirm');
  const removeConfirmModal = useDialog('OutboundProductRemoveConfirm');
  const outboundExcelHistoryModal = useExcelDownLoadHistory({ targetUrl: 'outbound' });

  const {
    outboundListTotal,
    list: outboundList,
    setList: setOutboundList,
    updatedList: updatedOutboundList,
  } = outboundListQuery;
  const {
    list: outboundItemList,
    setList: setOutboundItemList,
    onOutboundItemListChange,
    updatedList: updatedOutboundItemList,
  } = useOutboundItemListQuery(selectedOutboundId);
  const downloadOutboundListQuery = useDownloadOutboundListQuery();

  const deleteOutboundItemMutation = useDeleteOutboundItemMutation();
  const addOutboundItemMutation = useAddOutboundItemMutation();
  const updateOutboundListMutation = useUpdateOutboundListMutation();
  const sendOutboundMutation = useSendOutboundMutation();
  const cancelSendOutboundMutation = useCancelSendOutboundMutation();
  const cancelOutboundMutation = useCancelOutboundMutation();

  const activeOutboundList = outboundList.filter(({ active }) => active);
  const activeOutboundIds = activeOutboundList.map(({ outboundId }) => outboundId);
  const activeOutboundItemList = outboundItemList.filter(({ active }) => active);
  const checkedOutboundItemIds = activeOutboundItemList.map(({ outboundItemId }) => outboundItemId);

  const openProductCodeListModal = () => {
    const target = outboundList.find((it) => it.outboundId === selectedOutboundId);

    if (target?.outboundStatusCodeValue !== '출고예정') {
      toastify('품목 추가/삭제는 <출고예정> 상태만 가능합니다.', { type: 'error' });
      return;
    }

    productCodeModal.openDialog({
      title: '품목코드 조회',
      content: (
        <ProductCodeModal
          type='outbound'
          selectProductCode={({ productItemId, productItemName, availableQuantity }) => {
            addOutboundItemMutation.mutate(
              {
                outboundId: selectedOutboundId!,
                productItemId,
                productItemName,
                ...(availableQuantity !== undefined && availableQuantity !== null
                  ? { availableQuantity }
                  : {}),
              },
              {
                onSuccess() {
                  productCodeModal.resetDialog();
                },
              }
            );
          }}
        />
      ),
    });
  };

  const openOutboundRegisterModal = () => {
    outboundRegisterModal.openDialog({
      title: '신규',
      size: 'large',
      content: <OutboundRegisterModal refetchOutboundList={outboundListQuery.refetch} />,
    });
  };

  const trySaveOutboundList = () => {
    if (!outboundList.length) {
      toastify('출고데이터가 없습니다.', { type: 'error' });
      return;
    }

    if (!updatedOutboundList.length && !updatedOutboundItemList.length) {
      toastify('수정된 내역이 없습니다.', { type: 'error' });
      return false;
    }

    performIfConfirmed('모든 변경사항을 저장하시겠습니까?', () => {
      const selectedOutbound = outboundList.find(
        ({ outboundId }) => outboundId === selectedOutboundId
      );
      const updateOutboundListParams = getUpdateOutboundListParams({
        outboundList: updatedOutboundList,
        selectedOutboundListItem: selectedOutbound,
        outboundItemList: updatedOutboundItemList,
        centerCodeList,
      });
      updateOutboundListMutation.mutate(updateOutboundListParams, {
        onSettled() {
          outboundListQuery.refetch();
        },
      });
    });
  };

  const onDeleteMutation = (availableQuantityRestocked: boolean) => {
    deleteOutboundItemMutation.mutate(
      { checkedOutboundItemIds, availableQuantityRestocked },
      {
        onSettled: () => {
          removeConfirmModal.resetDialog();
          search();
        },
      }
    );
  };

  const tryRemoveOutboundItem = performIfNotEmpty(activeOutboundItemList, () => {
    const target = outboundList.find((it) => it.outboundId === selectedOutboundId);
    if (target && removeOutBoundValidation(target, activeOutboundItemList)) {
      removeConfirmModal.openDialog({
        title: '품목 삭제',
        content: <RemoveConfirmModal onRemoveConfirm={onDeleteMutation} />,
        size: 'xsmall',
      });
    }
  });

  const trySendOutbound = () => {
    if (sendOutBoundValidation(activeOutboundList)) {
      performIfConfirmed('선택한 출고를 전송하시겠습니까?', async () => {
        await sendOutboundMutation.mutateAsync({ outboundIds: activeOutboundIds });
        outboundListQuery.refetch();
      });
    }
  };

  const tryCancelSendOutbound = () => {
    if (cancelSendOutBoundValidation(activeOutboundList)) {
      performIfConfirmed('선택한 출고를 전송취소 하시겠습니까?', async () => {
        await cancelSendOutboundMutation.mutateAsync(activeOutboundList[0]?.outboundId);
        outboundListQuery.refetch();
      });
    }
  };

  const completeOutbound = async () => {
    if (completeOutBoundValidation(activeOutboundList)) {
      const [item] = activeOutboundList;
      outboundCompletionModal.openDialog({
        title: '완료처리',
        content: (
          <CompletionModal
            selectedOutboundId={item.outboundId}
            item={item}
            close={outboundCompletionModal.resetDialog}
            search={search}
          />
        ),
      });
    }
  };

  const confirmCancelOutboundList = async (availableQuantityRestocked: boolean) => {
    await cancelOutboundMutation.mutateAsync({ activeOutboundIds, availableQuantityRestocked });
    outboundListQuery.refetch();
    setSelectedOutboundId(null);
    cancelConfirmModal.resetDialog();
  };

  const cancelOutboundList = () => {
    if (cancelOutBoundValidation(activeOutboundList)) {
      cancelConfirmModal.openDialog({
        title: '취소처리',
        content: <CancelConfirmModal onCancelConfirm={confirmCancelOutboundList} />,
        size: 'xsmall',
      });
    }
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '센터코드 조회',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            formField.setForm((prevForm) => ({
              ...prevForm,
              centerCode: rowData.centerCode as string,
            }));
          }}
        />
      ),
    });
  };

  const search = () => {
    showActivityIndicator();
    outboundListQuery.setSearchFilter({ ...formField.form, pageNumber: 0 });
    setSelectedOutboundId(null);
  };

  const excelDownload = () => {
    const centerId =
      centerCodeList?.find(({ centerCode }) => centerCode === formField?.form?.centerCode)?.id ||
      null;
    downloadOutboundListQuery.excelDownload({ ...formField.form, centerId, pageNumber: 0 });
  };

  const onClickExcelHistory = () => {
    outboundExcelHistoryModal.openDialog();
  };

  return (
    <div>
      <FilterHeader>
        <Button
          onClick={performIfValidFilter({
            filter: formField.form,
            callback: search,
            message: INVALID_OUTBOUND_FILTER_MESSAGE,
          })}
          isPermissionRequired={false}
        >
          조회
        </Button>
        <Button onClick={openOutboundRegisterModal}>신규</Button>
        <Button onClick={trySaveOutboundList}>저장</Button>
        <Button onClick={performIfNotEmpty(activeOutboundList, trySendOutbound)}>전송</Button>
        <Button onClick={performIfNotEmpty(activeOutboundList, tryCancelSendOutbound)}>
          전송취소
        </Button>
        <Button onClick={performIfNotEmpty(activeOutboundList, completeOutbound)}>완료처리</Button>
        <Button onClick={performIfNotEmpty(activeOutboundList, cancelOutboundList)}>
          취소처리
        </Button>
        <Button
          onClick={performIfNotEmpty(outboundList, excelDownload, '조회된 데이터가 없습니다')}
          isPermissionRequired={false}
        >
          엑셀
        </Button>
        <Button onClick={onClickExcelHistory} isPermissionRequired={false}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>

      <FormField>
        <Flex direction='column' gap={10}>
          <DateRangePickerLabel
            label='출고예정일'
            id='availableDay'
            start={{
              ...formField.attributes.startAvailableDay,
              minDate: generateMinDate(formField.form.endAvailableDay, 3),
            }}
            end={{
              ...formField.attributes.endAvailableDay,
              maxDate: generateMaxDate(formField.form.startAvailableDay, 3),
            }}
          />
          <DateRangePickerLabel
            label='출고실적일'
            id='completeDay'
            start={{
              ...formField.attributes.startCompleteDay,
              minDate: generateMinDate(formField.form.endCompleteDay, 3),
            }}
            end={{
              ...formField.attributes.endCompleteDay,
              maxDate: generateMaxDate(formField.form.startCompleteDay, 3),
            }}
          />
          <InputButtonLabel
            {...formField.attributes.centerCode}
            label='센터코드'
            readOnly={false}
            buttonClick={openCenterCodeModal}
          />
        </Flex>
        <Flex direction='column' gap={10}>
          <SelectLabel
            {...formField.attributes.outboundCategory}
            label='출고유형'
            options={OUTBOUND_CATEGORY_OPTIONS}
          />
          <SelectLabel
            {...formField.attributes.deliveryType}
            label='배송유형'
            options={DELIVERY_TYPE_OPTIONS}
          />
          <SelectLabel
            {...formField.attributes.outboundStatusCode}
            label='상태'
            options={OUTBOUND_STATUS_CODE}
          />
        </Flex>
        <TextAreaLabel {...formField.attributes.wokey} label='출고번호' />
        <TextAreaLabel label='주문번호' {...formField.attributes.orderId} />
        <Flex direction='column' rowGap={10}>
          <TextAreaLabel
            {...formField.attributes.productItemId}
            label='품목코드'
            css='height:6.2rem'
          />
          <InputLabel label='참조번호' {...formField.attributes.referenceCode} />
        </Flex>
      </FormField>

      <GridLayout
        header={<ResultHeader title='출고내역' total={outboundListTotal} />}
        grid={
          <DataSheetGrid
            {...mappingAllCheck(OUTBOUND_LIST_COLUMNS, outboundList, setOutboundList)}
            value={outboundList}
            onChange={outboundListQuery.rowChangeHandler}
            gutterColumn={CUSTOM_GUTTER_COLUMN}
            onActiveChange={({ row: rowIndex }) =>
              setSelectedOutboundId(outboundList[rowIndex].outboundId)
            }
            customRendererList={[
              { id: 'outboundStatusCodeValue', component: OutboundStatusCell },
              { id: 'availableDay', component: DateCell },
            ]}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={outboundListQuery.pageNumber}
            onPageIndexChange={(pageNumber) => {
              showActivityIndicator();
              outboundListQuery.setPageNumber(pageNumber);
              setSelectedOutboundId(null);
            }}
            total={outboundListTotal}
            pageSize={OUTBOUND_LIST_PAGE_SIZE}
          />
        }
      />

      {selectedOutboundId !== null && (
        <GridLayout
          header={
            <ResultHeader
              title='품목내역'
              total={outboundItemList.length}
              right={
                <Flex align='center' gap={10}>
                  <Text color='gray.800' fontWeight={500} size={1.4}>
                    품목 추가/삭제
                  </Text>
                  <Button size='x-small' onClick={openProductCodeListModal}>
                    추가
                  </Button>
                  <Button size='x-small' onClick={tryRemoveOutboundItem}>
                    삭제
                  </Button>
                </Flex>
              }
            />
          }
          grid={
            <DataSheetGrid
              {...mappingAllCheck(
                OUTBOUND_PRODUCT_LIST_COLUMNS,
                outboundItemList,
                setOutboundItemList
              )}
              value={outboundItemList}
              onChange={onOutboundItemListChange}
            />
          }
        />
      )}
    </div>
  );
}

export default OutboundHistory;
