import { useState } from 'react';

import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { DELIVERY_TYPE_OPTIONS, OUTBOUND_CATEGORY_OPTIONS } from '@src/Constants/options';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { addIndexingData } from '@src/Utils/helper';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { getDecodeToken } from '@src/Utils/token';
import { Nullable, PageResponse } from '@src/Utils/type';
import { useMutation } from '@tanstack/react-query';

import { INITIAL_OUTBOUND_DETAIL_FILTER, OUTBOUND_DETAIL_LIST_PAGE_SIZE } from './constant';

interface FetchOutboundDetailParams {
  startAvailableDay: string;
  endAvailableDay: string;
  startCompleteDay?: string;
  endCompleteDay?: string;

  outboundCategory: typeof OUTBOUND_CATEGORY_OPTIONS[number]['value'];
  deliveryType: typeof DELIVERY_TYPE_OPTIONS[number]['value'];
  centerCode: string;

  outboundStatusCode: string;
  wokey: string;
  productItemId: string;

  pageNumber: number;
  pageSize?: number;
}

interface OutboundDetail {
  wokey: string;
  outboundStatusCodeValue: string;
  outboundCategoryValue: string;
  deliveryTypeValue: string;
  orderId: Nullable<string>;
  referenceCode: string;
  availableDay: string;
  completeDay: Nullable<string>;
  centerCode: string;
  centerName: string;
  productItemId: number;
  productItemName: string;
  availableQuantity: number;
  receivedQuantity: Nullable<number>;
  invoiceParcel: Nullable<string>;
  invoiceNumber: Nullable<string>;
  remark: Nullable<string>;
  createdUserName: string;
  createdAt: string;
  updatedUserName: Nullable<string>;
  updatedAt: Nullable<string>;
}

const initialFilter = { ...INITIAL_OUTBOUND_DETAIL_FILTER, pageNumber: 0 };
export const useOutboundDetailQuery = () => {
  const [outboundDetail, setOutboundDetail] = useState<Partial<PageResponse<OutboundDetail[]>>>({
    total: 0,
    results: [],
  });
  const useSearchRequestResult = useSearchRequest<
    typeof initialFilter,
    PageResponse<OutboundDetail[]>
  >({
    initialFilter,
    getRequest: (params: FetchOutboundDetailParams): AxiosRequestConfig => {
      const { data: centerCodeList } = useCenterCodeList();
      const centerId =
        centerCodeList?.find(({ centerCode }) => centerCode === params.centerCode)?.id || null;

      const data = mapObject(
        {
          ...params,
          centerId,
          pageSize: params.pageSize ?? OUTBOUND_DETAIL_LIST_PAGE_SIZE,
        },
        nullIfEmptyOrALL
      );

      return {
        url: '/outbound-items/list',
        method: 'POST',
        data,
      };
    },
    options: {
      onSuccess(data) {
        setOutboundDetail({
          ...data,
          results: addIndexingData(
            data.results,
            useSearchRequestResult.filter.pageNumber,
            OUTBOUND_DETAIL_LIST_PAGE_SIZE
          ),
        });
      },
    },
  });

  return { useSearchRequestResult, outboundDetail, setOutboundDetail };
};

export const downloadOutboundDetail = async (
  params: Omit<FetchOutboundDetailParams, 'centerCode'> & {
    centerId: Nullable<number>;
  }
) => {
  const accessToken = getDecodeToken(Cookies.get('wms_tk'));

  const body = mapObject(params, nullIfEmptyOrALL);
  const { data } = await client.post<{ send: string }>('/outbound-items/excel-download', {
    // success || fail
    ...body,
    email: accessToken ? accessToken.login_id : '',
    code: 'outbound_item',
  });
  return data;
};

export const useDownloadOutboundDetailMutation = () => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();
  const { mutate: excelDownload } = useMutation(downloadOutboundDetail, {
    onMutate: () => showActivityIndicator(),
    onSuccess: () => {
      hideActivityIndicator();
      toastify('완료되었습니다.');
    },
    onError: () => hideActivityIndicator(),
  });

  return { excelDownload };
};
