import React from 'react';

import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Flex from '@Components/Flex';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import { CenterCodeRow } from '@Components/Modals/CenterCodeModal/CenterCodeModal.type';
import VendorCodeModal from '@Components/Modals/VendorCodeModal';
import { VendorCodeRow } from '@Components/Modals/VendorCodeModal/VendorCodeModal.type';
import { FormFieldAttributes, useDialog } from '@Hooks';
import FormField from '@Layout/components/FormField';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { INBOUND_CATEGORY_OPTIONS, INBOUND_STATUS_CODE_OPTIONS } from '@src/Constants/inbound';
import { generateMaxDate, generateMinDate } from '@src/Utils/helper';

import { INITIAL_INBOUND_HISTORY_FILTER_FORM } from '../../constant';

export type InboundHistoryFilterForm = typeof INITIAL_INBOUND_HISTORY_FILTER_FORM;

interface InboundHistoryFormProps {
  form: typeof INITIAL_INBOUND_HISTORY_FILTER_FORM;
  setForm: React.Dispatch<React.SetStateAction<InboundHistoryFilterForm>>;
  attributes: FormFieldAttributes<keyof InboundHistoryFilterForm>;
}

function Form({ form, setForm, attributes }: InboundHistoryFormProps) {
  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');
  const { openDialog: openVendorCodeDialog } = useDialog('VendorCodeModal');

  const setCenterCode = ({ centerCode }: CenterCodeRow) => {
    if (!centerCode) return;
    setForm((prev) => ({
      ...prev,
      centerCode,
    }));
  };

  const openCenterCode = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: <CenterCodeModal selectCenterCode={setCenterCode} />,
      size: 'medium',
    });
  };

  const setVendorCode = (rowData: VendorCodeRow) => {
    setForm((prev) => ({
      ...prev,
      vendorId: String(rowData.vendorId),
    }));
  };

  const openVendorCode = () => {
    openVendorCodeDialog({
      title: '코드 선택',
      content: <VendorCodeModal selectVendorCode={setVendorCode} />,
      size: 'medium',
    });
  };

  return (
    <FormField>
      <Flex direction='column' rowGap={10}>
        <DateRangePickerLabel
          label='입고예정일'
          start={{
            ...attributes.startAvailableDay,
            minDate: generateMinDate(form.endAvailableDay, 3),
          }}
          end={{
            ...attributes.endAvailableDay,
            maxDate: generateMaxDate(form.startAvailableDay, 3),
          }}
        />
        <DateRangePickerLabel
          label='입고실적일'
          start={{
            ...attributes.startCompleteDay,
            minDate: generateMinDate(form.endCompleteDay, 3),
          }}
          end={{
            ...attributes.endCompleteDay,
            maxDate: generateMaxDate(form.startCompleteDay, 3),
          }}
        />
        <InputButtonLabel
          {...attributes.centerCode}
          label='센터코드'
          buttonText='검색'
          readOnly={false}
          buttonClick={openCenterCode}
        />
      </Flex>
      <Flex direction='column' rowGap={10}>
        <SelectLabel
          {...attributes.inboundCategory}
          label='입고유형'
          options={INBOUND_CATEGORY_OPTIONS}
        />
        <SelectLabel
          {...attributes.statusCode}
          label='상태'
          options={INBOUND_STATUS_CODE_OPTIONS}
        />
        <InputButtonLabel
          {...attributes.vendorId}
          readOnly={false}
          label='거래처코드'
          buttonText='검색'
          buttonClick={openVendorCode}
        />
      </Flex>
      <TextAreaLabel label='입고번호' {...attributes.wikey} />
      <TextAreaLabel label='발주번호' {...attributes.productSupplyRequestId} />
      <Flex direction='column' rowGap={10}>
        <TextAreaLabel label='품목코드' {...attributes.productItemId} css='height:6.2rem' />
        <InputLabel label='참조번호' {...attributes.referenceCode} />
      </Flex>
    </FormField>
  );
}

export default Form;
