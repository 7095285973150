import { client } from '@src/client';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';

import { FetchProductListParams, PAGE_SIZE } from './useProductListQuery';

export const downloadProductList = async ({
  productItemType,
  stockStatus,
  vendorId,
  productItemName,
  productItemId,
  productGroup1Id,
  productGroup3Id,
  productGroup2Id,
  pageNumber,
}: FetchProductListParams) => {
  const { data } = await client.post<{ send: string }>(
    '/product-items/excel-download',
    mapObject(
      {
        pageNumber,
        pageSize: PAGE_SIZE,
        productItemType,
        stockStatus,
        vendorId: Number(vendorId) || null,
        productItemName,
        productItemId,
        productGroup1Id,
        productGroup3Id,
        productGroup2Id,
      },
      nullIfEmptyOrALL
    )
  );

  return data;
};

export const useExcelProductList = () => {
  const { downloadExcel } = useMassiveExcelRequest(downloadProductList);

  return { downloadExcel };
};
