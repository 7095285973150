import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const setUploadedOutboundDivisionListColumns = (isDisabled: boolean) => [
  ACTIVE_COLUMN,
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
    minWidth: 300,
  },
  {
    ...keyColumn('unitQuantity', textColumn),
    title: '출고단위수량',
    disabled: isDisabled,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '출고센터코드',
    disabled: isDisabled,
  },
  {
    ...keyColumn('validationMessage', textColumn),
    title: '유효성 체크',
    disabled: true,
    minWidth: 400,
  },
];
