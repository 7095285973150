import useSearchRequest from '@src/Hooks/useSearchRequest';
import { Nullable } from '@src/Utils/type';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';

import { INITIAL_USER_ROLE_LIST_FILTER } from '../constant';

export type UserRole = {
  roleId: number;
  roleCode: string;
  active: Nullable<string>;
  groupName: Nullable<string>;
  roleName: Nullable<string>;
};

export const useUserRoleListQuery = () => {
  const { useQueryResult, setSearchFilter } = useSearchRequest<
    typeof INITIAL_USER_ROLE_LIST_FILTER,
    UserRole[]
  >({
    initialFilter: INITIAL_USER_ROLE_LIST_FILTER,
    getRequest: (params) => ({
      url: '/user-groups/roles/list/common',
      method: 'POST',
      data: mapObject(params, nullIfEmptyOrALL),
    }),
    options: {
      keepPreviousData: true,
    },
  });

  return { data: useQueryResult.data, setSearchFilter };
};
