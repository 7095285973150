import styled from 'styled-components';

import { Root, SeparatorProps } from '@radix-ui/react-separator';

function Separator(props: SeparatorProps) {
  return <StyledSeparator {...props} />;
}

const StyledSeparator = styled(Root)`
  background-color: ${({ theme }) => theme.colors.gray[300]};

  &[data-orientation='horizontal'] {
    width: 100%;
    height: 0.1rem;
  }
  &[data-orientation='vertical'] {
    width: 0.1rem;
    height: 100%;
  }
`;

export default Separator;
