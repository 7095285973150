import { checkboxColumn, keyColumn, textColumn } from 'react-datasheet-grid';

export const USER_ROLE_CODE_COLUMNS = [
  {
    ...keyColumn('roleCode', textColumn),
    title: '역할코드',
    disabled: true,
  },
  {
    ...keyColumn('roleName', textColumn),
    title: '역할명',
    disabled: true,
  },
];

export const AUTHORITY_CODE_COLUMNS = [
  {
    ...keyColumn('menuCode', textColumn),
    title: '권한코드',
    disabled: true,
  },
  {
    ...keyColumn('title', textColumn),
    title: '권한명',
    disabled: true,
    minWidth: 300,
  },
  {
    ...keyColumn('selectPermission', checkboxColumn),
    title: '접근 권한',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    ...keyColumn('updatePermission', checkboxColumn),
    title: '조작 권한',
    minWidth: 80,
    maxWidth: 80,
  },
];
