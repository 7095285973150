import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const INITIAL_INBOUND_HISTORY_FILTER_FORM = {
  startAvailableDay: dayjs().subtract(15, 'd').format('YYYY-MM-DD'), // 입고 예정일자 start
  endAvailableDay: dayjs().add(15, 'd').format('YYYY-MM-DD'), // 입고예정일자 end
  startCompleteDay: '', // 입고일자 start
  endCompleteDay: '', // 입고일자 end
  inboundCategory: 'ALL', // 입고유형
  centerCode: '', // 센터코드
  vendorId: '', // 거래처코드
  statusCode: '', // 상태코드
  wikey: '', // ?? 입고번호인지 ??
  productItemId: '', // 품목코드
  productSupplyRequestId: '',
  referenceCode: '',
};

export const INBOUND_GRID_OPTIONS = {
  columns: [
    ACTIVE_COLUMN,
    {
      ...keyColumn('wikey', textColumn),
      title: '입고번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('inboundStatusCodeValue', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('inboundCategoryValue', textColumn),
      title: '입고유형',
      disabled: true,
    },
    {
      ...keyColumn('transportTypeValue', textColumn),
      title: '운송유형',
      disabled: true,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      ...keyColumn('productSupplyRequestId', textColumn),
      title: '발주번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('referenceCode', textColumn),
      title: '참조번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '입고예정일',
    },
    {
      ...keyColumn('completeDay', textColumn),
      title: '입고실적일',
      disabled: true,
    },
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      minWidth: 120,
      maxWidth: 120,
    },
    {
      ...keyColumn('centerName', textColumn),
      title: '센터명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('vendorId', textColumn),
      title: '거래처코드',
      disabled: true,
    },
    {
      ...keyColumn('vendorDisplayName', textColumn),
      title: '거래처명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('productItemCount', textColumn),
      title: '품목수',
      disabled: true,
    },
    {
      ...keyColumn('allCount', textColumn),
      title: '총수량',
      disabled: true,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 120,
    },
  ],
};

export const PRODUCT_GRID_OPTIONS = {
  columns: [
    ACTIVE_COLUMN,
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '입고예정수량',
      disabled: true,
    },
    {
      ...keyColumn('receivedQuantity', textColumn),
      title: '입고실적수량',
      disabled: true,
    },
    {
      ...keyColumn('itemRemark', textColumn),
      title: '비고',
      minWidth: 200,
      disabled: true,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 120,
    },
  ],
};

export const EXCEPT_INBOUND_CATEGORY_VALUE = ['B2C - 반품'];
