import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { useDialog } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import { INVALID_INBOUND_FILTER_MESSAGE } from '@src/Constants/inbound';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';
import { performIfConfirmed, performIfNotEmpty, performIfValidFilter } from '@Utils/helper';

import { PutInboundsParams } from '../../apis/type';
import { EXCEPT_INBOUND_CATEGORY_VALUE } from '../../constant';
import { FilterProps } from '../../InboundHistory.type';
import CompletionModal from '../CompletionModal';
import InboundHistoryNewModal from '../InboundHistoryNewModal';

function Filter({ form, inboundItemId, centerId, inboundGrid, productGrid, ...rest }: FilterProps) {
  const { openDialog: openNewDialog } = useDialog('InboundHistoryNewDialog');
  const { openDialog: openCompletionDialog, resetDialog } = useDialog('completionModal');

  const { openDialog: openExcelDownLoadDetail } = useExcelDownLoadHistory({
    targetUrl: 'inbound',
  });

  const targetRows = inboundGrid.updatedRow.filter((it) => it.active);

  const search = performIfValidFilter({
    filter: form,
    callback: rest.search,
    message: INVALID_INBOUND_FILTER_MESSAGE,
  });

  const save = () => {
    if (!inboundGrid.gridList.length) {
      toastify('입고데이터가 없습니다.', { type: 'error' });
      return;
    }

    if (!inboundGrid.updatedRow.length && !productGrid.updatedRow.length) {
      toastify('수정된 내역이 없습니다.', { type: 'error' });
      return false;
    }

    let params: PutInboundsParams = {
      inboundUpdateDtos:
        inboundGrid.updatedRow.map(({ inboundId, centerId, availableDay }) => ({
          inboundId,
          centerId,
          availableDay,
        })) ?? [],
    };

    if (productGrid.updatedRow.length) {
      params = {
        ...params,
        inboundItemUpdateListDto: {
          inboundId: inboundItemId,
          inboundItemUpdateDtos:
            productGrid.updatedRow.map(({ inboundItemId, availableQuantity, itemRemark }) => ({
              inboundItemId,
              availableQuantity,
              inboundItemRemark: itemRemark,
            })) ?? [],
        },
      };
    }

    if (window.confirm('모든 변경사항을 저장하시겠습니까?')) rest.save(params);
  };

  const transmission = () => {
    if (!targetRows.length) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return false;
    }

    const isPass = targetRows.some((row) => {
      if (
        row.inboundCategoryValue &&
        EXCEPT_INBOUND_CATEGORY_VALUE.includes(row.inboundCategoryValue)
      ) {
        return false;
      }
      return true;
    });

    if (!isPass) {
      toastify('전송할 수 없는 상태입니다.\n해당 입고의 상태를 확인해 주세요.', {
        type: 'error',
      });
      return false;
    }

    performIfConfirmed('선택한 입고를 전송하시겠습니까?', () => {
      const params = {
        ids: targetRows.map((it) => it.inboundId),
      };
      rest.transmission(params);
    });
  };

  const cancelTransmission = () => {
    if (targetRows.length !== 1) {
      toastify('전송취소는 1건만 처리할 수 있습니다.', { type: 'error' });
      return;
    }

    const params = {
      ids: targetRows[0].inboundId,
    };

    if (window.confirm('선택한 입고를 전송취소 하시겠습니까?')) rest.cancelTransmission(params);
  };

  const openNewModal = () => {
    openNewDialog({
      title: '신규',
      content: <InboundHistoryNewModal search={search} />,
      size: 'large',
    });
  };

  const openCompletion = () => {
    if (targetRows.length !== 1) {
      toastify('완료처리는 1건만 처리할 수 있습니다.', { type: 'error' });
      return;
    }
    const [item] = targetRows;

    if (item.inboundStatusCodeValue !== '전송완료') {
      toastify('전송완료 상태에서만 완료처리할 수 있습니다.', { type: 'error' });
      return;
    }

    openCompletionDialog({
      title: '완료처리',
      content: (
        <CompletionModal
          inboundItemId={item.inboundId}
          wikey={item.wikey}
          close={resetDialog}
          search={search}
        />
      ),
      size: 'large',
    });
  };

  const cancellation = () => {
    const target = inboundGrid.updatedRow.filter((it) => it.active);
    for (const row of target) {
      if (row.inboundStatusCodeValue !== '입고예정') {
        toastify('취소할 수 없는 상태입니다.\n해당 입고의 상태를 확인해 주세요.', {
          type: 'error',
        });
        return;
      }

      if (row.productSupplyRequestId) {
        toastify('발주 어드민에서 생성된 입고는 취소할 수 없습니다.', { type: 'error' });
        return;
      }

      if (
        row.inboundCategoryValue &&
        [...EXCEPT_INBOUND_CATEGORY_VALUE, '재고조정', '유통가공'].includes(
          row.inboundCategoryValue
        )
      ) {
        toastify('취소할 수 없는 상태입니다.\n해당 입고의 상태를 확인해 주세요.', {
          type: 'error',
        });
        return;
      }
    }
    rest.cancellation({ ids: target.map((it) => it.inboundId) });
  };

  const excelDownload = () => {
    rest.excelDownload({ ...form, centerId, pageNumber: 0 });
  };

  const onClickDownloadExcelDownload = () => {
    openExcelDownLoadDetail();
  };

  return (
    <FilterHeader>
      <Button onClick={search} isPermissionRequired={false}>
        조회
      </Button>
      <Button onClick={openNewModal}>신규</Button>
      <Button onClick={save}>저장</Button>
      <Button onClick={performIfNotEmpty(targetRows, transmission)}>전송</Button>
      <Button onClick={performIfNotEmpty(targetRows, cancelTransmission)}>전송취소</Button>
      <Button onClick={performIfNotEmpty(targetRows, openCompletion)}>완료처리</Button>
      <Button onClick={performIfNotEmpty(targetRows, cancellation)}>취소처리</Button>
      <Button
        onClick={performIfNotEmpty(inboundGrid.gridList, excelDownload, '조회된 데이터가 없습니다')}
        isPermissionRequired={false}
      >
        엑셀
      </Button>
      <Button onClick={onClickDownloadExcelDownload} isPermissionRequired={false}>
        엑셀 다운로드 요청 내역
      </Button>
    </FilterHeader>
  );
}

export default Filter;
