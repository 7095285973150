import { keyColumn, textColumn } from 'react-datasheet-grid';

export const STOCK_STATUS_OPTIONS = [
  { label: '판매유지', value: 'KEEP' },
  { label: '일시품절', value: 'SOLD_OUT' },
  { label: '신상품대기', value: 'WAITING' },
  { label: '판매종료예정', value: 'EXPECTED_SALE_END' },
  { label: '판매종료', value: 'SALE_END' },
  { label: '등록오류', value: 'ERROR' },
  { label: '기타', value: 'ETC' },
];

export const PRODUCT_CODE = {
  columns: [
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemType', textColumn),
      title: '품목유형',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('stockStatusValue', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('vendorId', textColumn),
      title: '거래처코드',
      disabled: true,
    },
    {
      ...keyColumn('vendorName', textColumn),
      title: '거래처명',
      disabled: true,
      minWidth: 150,
    },
  ],
};
