import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { InboundRequestRow } from './apis/useInboundRequestListQuery';
import { INBOUND_REQUEST_STATUS_CODE_COLOR } from './constant';

export function InboundRequestStatusCell({ rowData }: { rowData: InboundRequestRow }) {
  const { inboundAdjustStatusCodeValue } = rowData;

  const textColor = INBOUND_REQUEST_STATUS_CODE_COLOR[
    inboundAdjustStatusCodeValue as keyof typeof INBOUND_REQUEST_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={inboundAdjustStatusCodeValue} textColor={textColor} />;
}
