import { useState } from 'react';

import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import { toastify } from '@Components/Toast';
import { useDialog } from '@Hooks';
import ModalFooter from '@src/Layout/components/ModalFooter';

import { FormType, ModalFormProps, SetFormEventType } from '../../CenterManage.type';
import { FULFILLMENT_OPTIONS } from '../../constant';

import { ModalFormWrapper } from './CenterManageModal.style';

function ModalForm({ save }: ModalFormProps) {
  const { resetDialog } = useDialog('CenterManageModal');
  const [form, setForm] = useState<FormType>({
    name: '',
    type: 'VIRTUAL',
    fulfillment: '',
    latitude: '',
    longitude: '',
    zipcode: '',
    mainAddress: '',
    subAddress: '',
    contact: '',
  });

  const setOnChangeHandler = (e: SetFormEventType) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const nameValidation = (value: string) => value.length <= 50;
  const mainAddressValidation = (value: string) => value.length <= 100;
  const subAddressValidation = (value: string) => value.length <= 60;
  const contactValidation = (value: string) => /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/.test(value);
  const latAndLongValidation = (value: string) => /^[0-9]+\.?[0-9]+$/.test(value);
  const zipCodeValidation = (value: string) => !!/^\d{5}$/.test(value);

  const validate = () => {
    const { name, latitude, longitude, contact, mainAddress, subAddress, zipcode } = form;
    if (!name) {
      toastify('[센터명]을 입력해주세요.', { type: 'error' });
      return false;
    }

    if (
      (latitude && !latAndLongValidation(latitude)) ||
      (longitude && !latAndLongValidation(longitude)) ||
      (contact && !contactValidation(contact)) ||
      (mainAddress && !mainAddressValidation(mainAddress)) ||
      (subAddress && !subAddressValidation(subAddress)) ||
      (zipcode && !zipCodeValidation(zipcode))
    ) {
      toastify('유효하지 않은 형식이 존재합니다.', { type: 'error' });
      return false;
    }

    if (nameValidation(name)) {
      return true;
    }

    return false;
  };

  const onSave = () => {
    if (validate()) {
      save(form);
      onClose();
    }
  };

  const onClose = () => {
    setForm({
      name: '',
      type: 'VIRTUAL',
      fulfillment: '',
      latitude: '',
      longitude: '',
      zipcode: '',
      mainAddress: '',
      subAddress: '',
      contact: '',
    });
    resetDialog();
  };

  return (
    <>
      <ModalFormWrapper direction='column' rowGap={20}>
        <Flex align='flex-start' colGap={50} rowGap={20} wrap='wrap'>
          <InputLabel
            label='센터명*'
            name='name'
            value={form.name}
            onChange={setOnChangeHandler}
            validation={nameValidation}
            span='[센터명]은 50자를 초과할 수 없습니다.'
          />
          <SelectLabel
            label='센터유형'
            name='type'
            value={form.type}
            disabled
            onChange={setOnChangeHandler}
            options={[
              { value: 'REAL', label: '물류센터' },
              { value: 'VIRTUAL', label: '가상센터' },
            ]}
          />
        </Flex>
        <SelectLabel
          label='운영사'
          name='fulfillment'
          value={form.fulfillment}
          onChange={setOnChangeHandler}
          options={FULFILLMENT_OPTIONS}
        />

        <Flex align='flex-start' colGap={50} rowGap={20} wrap='wrap'>
          <InputLabel
            label='위도'
            name='latitude'
            value={form.latitude}
            onChange={setOnChangeHandler}
            validation={latAndLongValidation}
            span='숫자만 입력해주세요'
          />
          <InputLabel
            label='경도'
            name='longitude'
            value={form.longitude}
            onChange={setOnChangeHandler}
            validation={latAndLongValidation}
            span='숫자만 입력해주세요'
          />
        </Flex>
        <InputLabel
          label='우편번호'
          name='zipcode'
          value={form.zipcode}
          onChange={setOnChangeHandler}
          validation={zipCodeValidation}
          span='[우편번호]는 숫자 5자 형식으로 입력해주세요.'
        />
        <InputLabel
          label='주소'
          name='mainAddress'
          value={form.mainAddress}
          onChange={setOnChangeHandler}
          validation={mainAddressValidation}
          span='[주소]는 100자를 초과할 수 없습니다.'
        />
        <InputLabel
          label='상세주소'
          name='subAddress'
          value={form.subAddress}
          onChange={setOnChangeHandler}
          validation={subAddressValidation}
          span='[상세주소]는 60자를 초과할 수 없습니다.'
        />
        <InputLabel
          label='대표연락처'
          name='contact'
          value={form.contact}
          onChange={setOnChangeHandler}
          validation={contactValidation}
          span='올바른 전화번호 번호 형식이 아닙니다. 02-1234-1234와 같은 형식으로 입력해주세요.'
        />
      </ModalFormWrapper>
      <ModalFooter>
        <Button onClick={onSave}>저장</Button>
        <Button onClick={onClose}>취소</Button>
      </ModalFooter>
    </>
  );
}

export default ModalForm;
