import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useMutation } from '@tanstack/react-query';

const cancelOutbound = async ({
  activeOutboundIds,
  availableQuantityRestocked,
}: {
  activeOutboundIds: number[];
  availableQuantityRestocked: boolean;
}) => {
  const { data } = await client.put('/outbounds/cancellation', {
    ids: activeOutboundIds,
    availableQuantityRestocked,
  });

  return data;
};

export const useCancelOutboundMutation = () => {
  return useMutation(cancelOutbound, {
    onSuccess() {
      toastify('취소처리되었습니다.');
    },
  });
};
