import { ReactNode } from 'react';

import styled from 'styled-components';

import Flex from '@src/Components/Flex';

function ModalFooter({ children }: { children: ReactNode }) {
  return (
    <StyledFlex align='center' justify='flex-end' colGap={16}>
      {children}
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)`
  margin-top: 3rem;
  padding: ${({ theme }) => theme.spaces['0.8']};
  background: ${({ theme }) => theme.colors.gray[200]};
`;

export default ModalFooter;
