import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@src/Constants/grid';

export const USER_GROUP_COLUMNS = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('groupCode', textColumn),
    title: '사용자그룹코드',
    disabled: true,
  },
  {
    ...keyColumn('activated', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('groupName', textColumn),
    title: '사용자그룹명',
  },
  {
    ...keyColumn('representativePhoneNo', textColumn),
    title: '대표연락처',
    minWidth: 120,
  },
  {
    ...keyColumn('createdMemberName', textColumn),
    title: '등록자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedMemberName', textColumn),
    title: '수정자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
    minWidth: 120,
  },
];

export const INITIAL_FILTER_FORM = {
  status: 'ALL',
  groupCode: '',
  userGroupRoleCode: '',
  userGroupName: '',
};

export const USER_GROUP_AND_ROLE_STATUS_CODE_COLOR = {
  활성화: 'green.500',
  비활성화: 'red.500',
};
