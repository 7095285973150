import { useRecoilValue } from 'recoil';

import CenterManage from '@Features/CenterManage';
import ClosedStockInquire from '@Features/ClosedStockInquire';
import DailyReceiptsAndPayments from '@Features/DailyReceiptsAndPayments';
import InboundDetail from '@Features/InboundDetail';
import InboundHistory from '@Features/InboundHistory';
import InboundResultAdjust from '@Features/InboundResultAdjust';
import InboundResultAdjustConfirm from '@Features/InboundResultAdjustConfirm';
import MonthlyReceiptsAndPayments from '@Features/MonthlyReceiptsAndPayments';
import OutboundDetail from '@Features/OutboundDetail';
import OutboundDivisionManage from '@Features/OutboundDivisionManage';
import OutboundHistory from '@Features/OutboundHistory';
import OutboundResultInquire from '@Features/OutboundResultInquire';
import ProcessedCirculateHistory from '@Features/ProcessedCirculateManage';
import ProductManage from '@Features/ProductManage';
import StockInquire from '@Features/StockInquire';
import StockAdjust from '@Features/StockInquireAdjust';
import UserGroupAndRoleManage from '@Features/UserGroupAndRoleManage';
import UserManage from '@Features/UserManage';
import VendorInquire from '@Features/VendorInquire';
import ClaimsInquire from '@src/Features/ClaimsInquire';
import ShelfLifeStock from '@src/Features/ShelfLifeStock/ShelfLifeStock';
import { currentTabAtom, TabItemAtom } from '@Stores/Tab/tab.atom';
import { tabState } from '@Stores/Tab/tab.selector';

import { ContentSection, Section, TabContainer } from './Layout.style';

const components = {
  CenterManage,
  OutboundResultInquire,
  StockInquire,
  VendorInquire,
  ProductManage,
  UserManage,
  InboundHistory,
  UserGroupAndRoleManage,
  InboundResultAdjust,
  InboundResultAdjustConfirm,
  ProcessedCirculateHistory,
  StockAdjust,
  ClosedStockInquire,
  OutboundHistory,
  DailyReceiptsAndPayments,
  MonthlyReceiptsAndPayments,
  InboundDetail,
  OutboundDetail,
  OutboundDivisionManage,
  ClaimsInquire,
  ShelfLifeStock,
};

function Layout() {
  const tabs = useRecoilValue(tabState) as TabItemAtom[];
  const currentTab = useRecoilValue(currentTabAtom);

  return (
    <ContentSection>
      <Section>
        {tabs.map((tab) => {
          const SpecificComponent = components[tab.frontFileName as keyof typeof components];
          return (
            <TabContainer key={tab.id} tabId={tab.id} currentTabId={currentTab.id}>
              <SpecificComponent />
            </TabContainer>
          );
        })}
      </Section>
    </ContentSection>
  );
}

export default Layout;
