import { toastify } from '@Components/Toast';
import { useDialog } from '@Hooks';
import { client } from '@src/client';
import { Nullable } from '@src/Utils/type';
import { useMutation } from '@tanstack/react-query';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';

import { User, useUserListQuery } from './useUserListQuery';

interface RegisterUserParams {
  userEmail: User['email'];
  name: User['userName'];
  mobileNo: User['mobileNo'];
  telNo: User['userName'];
  position: User['position'];
  roleId: Nullable<User['roleId']>;
  password: string;
  passwordCheck: string;
}

const registerUser = async ({
  userEmail,
  name,
  mobileNo,
  telNo,
  position,
  roleId,
  password,
  passwordCheck,
}: RegisterUserParams) => {
  if (password !== passwordCheck) {
    toastify('비밀번호와 확인 내용이 일치하지 않습니다. 다시 입력해 주세요.', { type: 'error' });
    return Promise.reject();
  }

  const { data } = await client.post<number>(
    '/users',
    mapObject(
      {
        userEmail,
        name,
        mobileNo,
        telNo,
        position,
        roleId,
        password,
        passwordCheck,
      },
      nullIfEmptyOrALL
    )
  );

  return data;
};

export const useRegisterUserMutation = () => {
  const userListQuery = useUserListQuery();
  const userRegisterModal = useDialog('userRegister');

  return useMutation(registerUser, {
    onSuccess() {
      toastify('등록되었습니다.');
      userListQuery.refetch();
      userRegisterModal.resetDialog();
    },
  });
};
