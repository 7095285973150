import dayjs from 'dayjs';
import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

import { centerCodeColumn } from '@Components/DataSheetGrid/CenterCodeCell';
import { ACTIVE_COLUMN } from '@Constants/grid';

import { OutboundItem } from './apis/useOutboundItemListQuery';
import { Outbound } from './apis/useOutboundListQuery';

export type OutboundRow = { active: boolean } & Outbound;

export const INITIAL_OUTBOUND_LIST_FILTER = {
  startAvailableDay: dayjs().subtract(15, 'd').format('YYYY-MM-DD'),
  endAvailableDay: dayjs().add(15, 'd').format('YYYY-MM-DD'),
  startCompleteDay: '',
  endCompleteDay: '',
  outboundCategory: '',
  outboundStatusCode: '',
  deliveryType: '',
  centerCode: '',
  productItemId: '',
  wokey: '',
  orderId: '',
  referenceCode: '',
};

export const EDIT_DISABLED_TARGET_STATUS = ['재고이동', '재고조정', '유통가공'];

export const OUTBOUND_STATUS_CODE_COLOR = {
  출고예정: 'orange.500',
  출고완료: 'green.500',
  전송완료: 'blue.500',
  출고취소: 'red.500',
};

const isOutboundCellDisabled = ({
  rowData: { outboundCategoryValue, outboundStatusCodeValue },
}: {
  rowData: OutboundRow;
}) => {
  return (
    EDIT_DISABLED_TARGET_STATUS.includes(outboundCategoryValue) ||
    outboundStatusCodeValue !== '출고예정'
  );
};

export const OUTBOUND_LIST_COLUMNS = [
  ACTIVE_COLUMN,
  { ...keyColumn('wokey', textColumn), title: '출고번호', disabled: true, minWidth: 120 },
  {
    ...keyColumn('outboundStatusCodeValue', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('outboundCategoryValue', textColumn),
    title: '출고유형',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('deliveryTypeValue', textColumn),
    title: '배송유형',
    disabled: true,
  },
  { ...keyColumn('orderId', textColumn), title: '주문번호', disabled: true, minWidth: 120 },
  {
    ...keyColumn('referenceCode', textColumn),
    title: '참조번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('availableDay', textColumn),
    title: '출고예정일',
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('completeDay', textColumn),
    title: '출고실적일',
    disabled: true,
  },
  {
    ...centerCodeColumn(),
    title: '센터코드',
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('centerName', textColumn),
    title: '센터명',
    disabled: true,
    minWidth: 150,
  },
  {
    ...keyColumn('productItemCount', intColumn),
    title: '품목수',
    disabled: true,
  },
  { ...keyColumn('allCount', intColumn), title: '총수량', disabled: true },
  {
    ...keyColumn('mainInvoiceNumber', textColumn),
    title: '대표 운송장 번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('arriavalCenterCode', textColumn),
    title: '도착센터코드',
    disabled: true,
  },
  {
    ...keyColumn('arrivalCenterName', textColumn),
    title: '도착센터명',
    disabled: true,
    minWidth: 150,
  },
  {
    ...keyColumn('vendorId', textColumn),
    title: '거래처코드',
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('vendorDisplayName', textColumn),
    title: '거래처명',
    disabled: true,
    minWidth: 150,
  },
  {
    ...keyColumn('recipient', textColumn),
    title: '수령인',
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('zipcode', textColumn),
    title: '우편번호',
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('address', textColumn),
    title: '주소',
    minWidth: 200,
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('detailAddress', textColumn),
    title: '상세주소',
    minWidth: 200,
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('mainMobileNo', textColumn),
    title: '연락처 1',
    minWidth: 120,
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('subMobileNo', textColumn),
    title: '연락처 2',
    minWidth: 120,
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('message', textColumn),
    title: '배송메시지',
    minWidth: 200,
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('enterMethod', textColumn),
    title: '배송출입정보',
    minWidth: 200,
    disabled: isOutboundCellDisabled,
  },
  {
    ...keyColumn('createdUserName', textColumn),
    title: '등록자',
    minWidth: 120,
    disabled: true,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    minWidth: 120,
    disabled: true,
  },
  {
    ...keyColumn('updatedUserName', textColumn),
    title: '수정자',
    minWidth: 120,
    disabled: true,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    minWidth: 120,
    disabled: true,
  },
];

export type OutboundItemRow = { active: boolean } & OutboundItem;

export const OUTBOUND_PRODUCT_LIST_COLUMNS = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    minWidth: 300,
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '출고예정수량',
    disabled: true,
  },
  {
    ...keyColumn('receivedQuantity', intColumn),
    title: '출고실적수량',
    disabled: true,
  },
  {
    ...keyColumn('parcelValue', textColumn),
    title: '운송사',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('invoiceNumber', textColumn),
    title: '송장번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('itemRemark', textColumn),
    title: '비고',
    minWidth: 200,
    disabled: true,
  },
  {
    ...keyColumn('createdUserName', textColumn),
    title: '등록자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedUserName', textColumn),
    title: '수정자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
    minWidth: 120,
  },
];

export const UPLOADED_OUTBOUND_LIST_COLUMNS = [
  {
    ...keyColumn('errorMessage', textColumn),
    title: '체크메시지',
    disabled: true,
    minWidth: 400,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '센터코드',
    disabled: true,
  },
  {
    ...keyColumn('outboundCategoryValue', textColumn),
    title: '출고유형',
    disabled: true,
  },
  {
    ...keyColumn('deliveryTypeValue', textColumn),
    title: '배송유형',
    disabled: true,
  },
  {
    ...keyColumn('orderId', textColumn),
    title: '주문번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('availableDay', textColumn),
    title: '출고예정일',
    disabled: true,
  },
  {
    ...keyColumn('arrivalCenterCode', textColumn),
    title: '도착센터코드',
    disabled: true,
  },
  {
    ...keyColumn('vendorId', textColumn),
    title: '거래처코드',
    disabled: true,
  },
  {
    ...keyColumn('recipient', textColumn),
    title: '수령인',
    disabled: true,
  },
  {
    ...keyColumn('zipcode', textColumn),
    title: '우편번호',
    disabled: true,
  },
  {
    ...keyColumn('address', textColumn),
    title: '주소',
    disabled: true,
    minWidth: 200,
  },
  {
    ...keyColumn('detailAddress', textColumn),
    title: '상세주소',
    disabled: true,
    minWidth: 200,
  },
  {
    ...keyColumn('mainMobileNo', textColumn),
    title: '연락처 1',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('subMobileNo', textColumn),
    title: '연락처 2',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('message', textColumn),
    title: '배송메시지',
    disabled: true,
    minWidth: 200,
  },
  {
    ...keyColumn('enterMethod', textColumn),
    title: '배송출입정보',
    disabled: true,
    minWidth: 200,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
    minWidth: 300,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '출고예정수량',
    disabled: true,
  },
  {
    ...keyColumn('outboundItemRemark', textColumn),
    title: '비고',
    disabled: true,
    minWidth: 200,
  },
];
