import styled, { css } from 'styled-components';

export const ExcelDownLoadLink = styled.a`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.radiuses[0.4]};
      padding: 0 ${theme.spaces[1.6]};
      color: #fff;
      font-weight: ${theme.fontWeights[500]};
      transition: background-color, color 0.15s ease;
      word-break: keep-all;
      height: 2.6rem;
      font-size: 1.4rem;
      border: 0.1rem solid ${theme.colors.blue[300]};
      color: ${theme.colors.blue[500]};
      line-height: 2.6rem;
      text-decoration: none;
      background-color: #fff;

      &:hover {
        background-color: ${theme.colors.blue[50]};
      }

      &:disabled {
        background-color: #fff;
        border: 0.1rem solid ${theme.colors.blue[100]};
        color: ${theme.colors.blue[200]};
      }
    `;
  }}
`;
