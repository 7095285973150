import { toastify } from '@Components/Toast';
import { client } from '@src/client';
import { useMutation } from '@tanstack/react-query';

import { useUserListQuery } from './useUserListQuery';

const updateUserRole = async ({ roleId, userId }: { roleId: number; userId: number }) => {
  const { data } = await client.put<number>('/users/role', {
    roleId,
    userId,
  });

  return data;
};

export const useUpdateUserRoleMutation = () => {
  const { refetch } = useUserListQuery();
  return useMutation(updateUserRole, {
    onSuccess() {
      toastify('변경되었습니다.');
      refetch();
    },
  });
};
