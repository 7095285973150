import CautionBox from '@Components/CautionBox';
import DataSheetGrid from '@Components/DataSheetGrid';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useDialog } from '@Hooks';
import GridLayout from '@Layout/components/GridLayout';
import ModalFooter from '@Layout/components/ModalFooter';
import ModalWrapper from '@Layout/components/ModalWrapper';
import ResultHeader from '@Layout/components/ResultHeader';
import { cropDecimalPoint, performIfConfirmed } from '@src/Utils/helper';

import ExcelFileUpload from '../InboundHistory/components/InboundHistoryNewModal/ExcelFileUpload';
import { ErrorCell } from '../InboundHistory/components/InboundHistoryNewModal/InboundHistoryNewModal.style';

import {
  UploadedOutbound,
  useOutboundRegisterListQuery,
  useValidatedOutboundRegisterListQuery,
} from './apis/useOutboundRegisterListQuery';
import { useRegisterOutboundListMutation } from './apis/useRegisterOutboundListMutation';
import { UPLOADED_OUTBOUND_LIST_COLUMNS } from './constant';

function OutboundRegisterDialog({ refetchOutboundList }: { refetchOutboundList: VoidFunction }) {
  const outboundRegisterListQuery = useOutboundRegisterListQuery();
  const outboundRegisterList =
    outboundRegisterListQuery.query.data?.map((it) => ({
      ...it,
      orderId: cropDecimalPoint(it.orderId),
      zipcode: cropDecimalPoint(it.zipcode),
    })) ?? [];
  const validatedOutboundRegisterListQuery =
    useValidatedOutboundRegisterListQuery(outboundRegisterList);
  const validatedOutboundRegisterList = validatedOutboundRegisterListQuery.query.data?.map(
    (it) => ({
      ...it,
      orderId: cropDecimalPoint(it.orderId),
      zipcode: cropDecimalPoint(it.zipcode),
    })
  );
  const registerOutboundListMutation = useRegisterOutboundListMutation();

  const outboundRegisterModal = useDialog('outboundRegister');

  const check = () => {
    if (!outboundRegisterList.length) {
      toastify('데이터가 존재하지 않습니다.', { type: 'error' });
      return;
    }

    validatedOutboundRegisterListQuery.query.refetch();
  };

  const submit = () => {
    if (validatedOutboundRegisterList)
      for (const row of validatedOutboundRegisterList) {
        if (row.errorMessage !== '성공!') {
          toastify(row.errorMessage, { type: 'error' });
          return false;
        }
      }

    performIfConfirmed('저장하시겠습니까?', async () => {
      const registerList = validatedOutboundRegisterList?.map((it) => ({
        ...it,
        orderId: cropDecimalPoint(it.orderId),
        zipcode: cropDecimalPoint(it.zipcode),
      }));
      await registerOutboundListMutation.mutateAsync(registerList);
      refetchOutboundList();
    });
  };

  const onFileUpload = (file: FileList) => {
    outboundRegisterListQuery.appendFile(file[0]);
    validatedOutboundRegisterListQuery.setIsPass(false);
  };

  const renderCheckMessage = ({ rowData }: { rowData: UploadedOutbound }) => {
    return (
      <ErrorCell bg={rowData.errorMessage}>
        <Text color='gray.900' fontWeight={400} size={1.2}>
          {rowData.errorMessage}
        </Text>
      </ErrorCell>
    );
  };

  return (
    <ModalWrapper>
      <div>
        <CautionBox
          title='참고사항'
          messages={[
            '센터코드 + 출고예정일 + 수령인 + 주소 + 주소상세가 모두 같은 품목의 경우, 1개 출고 전표로 구분되어 등록됩니다.',
            '1개 출고 전표에는 같은 품목은 포함될 수 없습니다.',
          ]}
        />

        <GridLayout
          header={
            <ResultHeader
              title='출고 등록 [품목 기준]'
              total={outboundRegisterList.length}
              right={<ExcelFileUpload onFileUpload={onFileUpload} fileName='업로드_출고등록' />}
            />
          }
          grid={
            <DataSheetGrid
              value={validatedOutboundRegisterList || outboundRegisterList}
              columns={UPLOADED_OUTBOUND_LIST_COLUMNS}
              customRendererList={[{ id: 'errorMessage', component: renderCheckMessage }]}
            />
          }
        />
      </div>
      <ModalFooter>
        <Button onClick={check}>체크</Button>
        <Button onClick={submit} disabled={!validatedOutboundRegisterListQuery.isPass}>
          저장
        </Button>
        <Button onClick={() => outboundRegisterModal.resetDialog()}>취소</Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default OutboundRegisterDialog;
