import dayjs, { ManipulateType } from 'dayjs';

import { toastify } from '@Components/Toast';
import { INVALID_INBOUND_FILTER_MESSAGE } from '@src/Constants/inbound';
import { INVALID_OUTBOUND_FILTER_MESSAGE } from '@src/Constants/outbound';

import { IndexingData } from './type';

export const cropDecimalPoint = <T>(item: T) => {
  return `${item}`.includes('.') ? (`${item}`.split('.')[0] as unknown as T) : item;
};

export const addIndexingData = <T>(
  data: T[],
  pageNumber: number,
  pageSize: number
): IndexingData<T>[] => {
  return data.map((it, idx) => ({
    no: !pageNumber ? idx + 1 : idx + 1 + 1 * (pageSize * pageNumber),
    ...it,
  }));
};

export const performIfConfirmed = (message: string, callback: VoidFunction) => {
  const isConfirmed = window.confirm(message);
  if (isConfirmed) {
    callback();
  }
};

export const performIfNotEmpty =
  (
    rows: unknown[],
    callback: VoidFunction,
    message: '조회된 데이터가 없습니다' | '선택된 항목이 없습니다' = '선택된 항목이 없습니다'
  ) =>
  () => {
    if (rows.length) {
      callback();
    } else {
      toastify(message, { type: 'error' });
    }
  };

export const performIfValidFilter =
  ({
    filter,
    callback,
    message,
  }: {
    filter: {
      startAvailableDay: string;
      endAvailableDay: string;
      startCompleteDay: string;
      endCompleteDay: string;
    };
    callback: VoidFunction;
    message: typeof INVALID_INBOUND_FILTER_MESSAGE | typeof INVALID_OUTBOUND_FILTER_MESSAGE;
  }) =>
  () => {
    if (
      (!filter.startAvailableDay || !filter.endAvailableDay) &&
      (!filter.startCompleteDay || !filter.endCompleteDay)
    ) {
      toastify(message, { type: 'error' });
    } else {
      callback();
    }
  };

export const generateMinDate = (
  standardDate: string,
  value: number,
  unit: ManipulateType = 'M'
) => {
  return new Date(dayjs(standardDate).subtract(value, unit).add(1, 'd').format('YYYY-MM-DD'));
};

export const generateMaxDate = (
  standardDate: string,
  value: number,
  unit: ManipulateType = 'M'
) => {
  return new Date(dayjs(standardDate).add(value, unit).subtract(1, 'd').format('YYYY-MM-DD'));
};
