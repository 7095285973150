import { useState } from 'react';

import { client } from '@src/client';
import { useDialog } from '@src/Hooks';
import useRequest from '@src/Hooks/useRequest';
import { Nullable } from '@src/Utils/type';
import { useMutation } from '@tanstack/react-query';

export interface InboundRequest {
  inboundItemId: number;
  wikey: string;
  productSupplyRequestId: Nullable<number>;
  availableDay: string;
  completeDay: string;
  productItemId: number;
  productItemName: string;
  availableQuantity: number;
  firstInboundQuantity: number;
  inboundAdjustmentId: Nullable<number>;
  result: Nullable<string>;
}

export interface InboundRequestRow extends InboundRequest {
  adjustQuantity: number;
  adjustReason: Nullable<string>;
}

export type InboundAdjustCreateDto = {
  inboundItemId: number;
  adjustQuantity: Nullable<number>;
  adjustReason: Nullable<string>;
};

const validateInboundAdjust = async (inboundAdjustCreateDtos: InboundAdjustCreateDto[]) => {
  const { data } = await client.post<InboundRequest[]>('/inbounds-adjustment/validation', {
    inboundAdjustCreateDtos,
  });

  return data;
};

export const useInboundAdjustRequestFormQuery = (ids: number[]) => {
  const inboundAdjustRequestModal = useDialog('InboundAdjustRequestModal');
  const [list, setList] = useState<InboundRequestRow[]>([]);
  const [isPass, setIsPass] = useState(false);

  const useQueryResult = useRequest<InboundRequestRow[]>(
    {
      url: '/inbounds-adjustment/request-form',
      method: 'POST',
      data: {
        ids,
      },
    },
    {
      enabled: ids.length !== 0,
      keepPreviousData: true,
      onSuccess(inboundRequestList) {
        setList(
          inboundRequestList.map((inboundRequest) => ({
            ...inboundRequest,
            adjustQuantity: 0,
            adjustReason: '',
            firstInboundQuantity: inboundRequest.firstInboundQuantity || 0,
            adjustResultQuantity: inboundRequest.firstInboundQuantity || 0,
          }))
        );
      },
      onError() {
        inboundAdjustRequestModal.resetDialog();
      },
    }
  );

  const useMutationResult = useMutation(validateInboundAdjust, {
    onSuccess(inboundRequestList) {
      setIsPass(() => !inboundRequestList.some((it) => it.result !== '성공!'));
      setList((prevInboundRequestList) =>
        prevInboundRequestList.map((inboundRequest) => ({
          ...inboundRequest,
          result:
            inboundRequestList.find(
              ({ inboundItemId }) => inboundItemId === inboundRequest.inboundItemId
            )?.result || '',
        }))
      );
    },
  });

  return { list, setList, useQueryResult, useMutationResult, isPass, setIsPass };
};
