import { useState } from 'react';

import styled from 'styled-components';

import DataSheetGrid from '@Components/DataSheetGrid';
import { DatePickerLabel } from '@Components/Date/DatePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { SelectLabel } from '@Components/Forms/Select';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import { CenterCodeRow } from '@Components/Modals/CenterCodeModal/CenterCodeModal.type';
import Pagination from '@Components/Pagination';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';

import { useClosedStockExcelQuery, useClosedStockListQuery } from './apis';
import { CLOSED_STOCK_GRID_OPTIONS, INITIAL_STATE, MATCH_OPTIONS } from './constant';

const PAGE_SIZE = 100;

function ClosedStockInquire() {
  const { columns } = CLOSED_STOCK_GRID_OPTIONS;
  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');
  const { showActivityIndicator } = useFullScreenActivityIndicator();

  const { form, attributes, setForm } = useFormField(INITIAL_STATE);

  const [pageNumber, setPageNumber] = useState(0);

  const {
    closedStockList,
    refetch: getClosedStockListQuery,
    total,
  } = useClosedStockListQuery({
    ...form,
    page: pageNumber,
    isExcel: false,
  });
  const { refetch: excelDownload } = useClosedStockExcelQuery({
    ...form,
    page: pageNumber,
    isExcel: true,
  });

  const selectCenterCode = (data: CenterCodeRow) => {
    setForm((prev) => ({
      ...prev,
      centerCode: data.centerCode || '',
    }));
  };

  const openCenterCode = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: <CenterCodeModal selectCenterCode={selectCenterCode} />,
      size: 'medium',
    });
  };

  const search = () => {
    if (!form.inquireDate) return toastify('조회기준일은 필수값입니다,', { type: 'error' });
    if (!form.centerCode) return toastify('센터코드는 필수값입니다.', { type: 'error' });
    showActivityIndicator();
    getClosedStockListQuery();
  };

  const handleExcelDownload = () => {
    if (!form.centerCode) return toastify('마감재고 조회를 먼저해 주세요.', { type: 'error' });
    showActivityIndicator();
    excelDownload();
  };

  const setPagination = (page: number) => {
    showActivityIndicator();
    setPageNumber(page);
  };

  return (
    <div>
      <FilterHeader>
        <Button isPermissionRequired={false} onClick={search}>
          조회
        </Button>
        <Button onClick={handleExcelDownload} isPermissionRequired={false}>
          엑셀
        </Button>
      </FilterHeader>
      <FormField>
        <DatePickerLabel
          {...attributes.inquireDate}
          label='조회 기준일'
          selected={form.inquireDate}
        />
        <InputButtonLabel
          {...attributes.centerCode}
          label='센터코드'
          buttonText='검색'
          readOnly={false}
          buttonClick={openCenterCode}
        />
        <SelectLabel {...attributes.match} label='일치여부' options={MATCH_OPTIONS} />
      </FormField>
      <Flex direction='column' rowGap={10}>
        <ResultHeader
          title='마감재고 조회'
          total={total}
          right={
            <Flex gap={20}>
              <Flex align='center' gap={3}>
                <PfBox />
                <Text as='p' size={1.4} color='gray.900' fontWeight={500}>
                  : 펫프렌즈
                </Text>
              </Flex>
              <Flex align='center' gap={3}>
                <CjBox />
                <Text as='p' size={1.4} color='gray.900' fontWeight={500}>
                  : 운영사
                </Text>
              </Flex>
            </Flex>
          }
        />
        <DataSheetGrid
          value={closedStockList}
          columns={columns}
          gutterColumn={CUSTOM_GUTTER_COLUMN}
        />
        <Flex justify='center'>
          <Pagination
            total={total}
            pageSize={PAGE_SIZE}
            currentPageIndex={pageNumber}
            onPageIndexChange={setPagination}
          />
        </Flex>
      </Flex>
    </div>
  );
}

const Square = styled.div`
  width: 4rem;
  height: 2rem;
`;

const PfBox = styled(Square)`
  background-color: #ddf9ff;
`;

const CjBox = styled(Square)`
  background-color: #ecffdd;
`;

export default ClosedStockInquire;
