import { useMemo } from 'react';

import styled from 'styled-components';

import Box from '@Components/Box';
import StatusCell from '@Components/Cell/StatusCell';
import { DatePicker, DatePickerOnChangeEvent } from '@Components/Date/DatePicker';
import Flex from '@Components/Flex';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import { CenterCodeRow } from '@Components/Modals/CenterCodeModal/CenterCodeModal.type';
import { Color } from '@Components/Typography/Typography.type';
import { useDialog } from '@Hooks';
import { INBOUND_STATUS_CODE_COLOR, NOT_EDITABLE_INBOUND_CATEGORY } from '@src/Constants/inbound';

import { InboundRow } from './InboundHistory.type';

interface CustomCellProps {
  rowData: InboundRow;
  setRowData: (rowData: InboundRow) => void;
}

export function InboundStatusCell({ rowData: data }: { rowData: InboundRow }) {
  const { inboundStatusCodeValue } = data;

  const textColor = INBOUND_STATUS_CODE_COLOR[
    inboundStatusCodeValue as keyof typeof INBOUND_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={inboundStatusCodeValue} textColor={textColor} />;
}

export function CenterCodeCell({
  rowData: data,
  setRowData,
}: {
  rowData: InboundRow;
  setRowData: (rowData: InboundRow) => void;
}) {
  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');

  const setCenterCode = (rowData: CenterCodeRow) => {
    const newCell = { ...data };
    newCell.centerCode = rowData.centerCode;
    newCell.centerId = rowData.id;
    newCell.active = true;
    setRowData(newCell);
  };

  const openCenterCodeModal = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: <CenterCodeModal selectCenterCode={setCenterCode} />,
      size: 'medium',
    });
  };

  const isButtonDisplay = useMemo(() => {
    const 입고예정인가 = data.inboundStatusCodeValue === '입고예정';
    const 수정가능한카테고리인가 =
      data.inboundCategoryValue &&
      !NOT_EDITABLE_INBOUND_CATEGORY.includes(data.inboundCategoryValue);

    return 입고예정인가 && 수정가능한카테고리인가 && !data.productSupplyRequestId;
  }, [data.inboundStatusCodeValue, data.inboundCategoryValue, data.productSupplyRequestId]);

  return (
    <Box pl-10 pr-10 $width='100%'>
      <Flex align='center' colGap={10} justify='space-between'>
        {data.centerCode ?? <span>-</span>}
        {isButtonDisplay && (
          <button type='button' onClick={openCenterCodeModal}>
            🔎
          </button>
        )}
      </Flex>
    </Box>
  );
}

const GridDatePicker = styled(DatePicker)`
  width: 100% !important;
  min-width: unset !important;
  border: unset !important;
  border-radius: 0 !important;
  background-color: transparent !important;

  &:disabled {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.gray[700]} !important;
  }
`;

export function DateCell({ rowData: data, setRowData }: CustomCellProps) {
  const onChangeHandler = (e: DatePickerOnChangeEvent) => {
    const { value } = e.target;
    const newCell = { ...data };
    newCell.availableDay = value ?? null;
    setRowData(newCell);
  };

  const disabled = useMemo(() => {
    const 입고예정인가 = data.inboundStatusCodeValue === '입고예정';
    const 수정가능한카테고리인가 =
      data.inboundCategoryValue &&
      !NOT_EDITABLE_INBOUND_CATEGORY.includes(data.inboundCategoryValue);

    return 입고예정인가 && 수정가능한카테고리인가 && !data.productSupplyRequestId;
  }, [data.inboundStatusCodeValue, data.inboundCategoryValue, data.productSupplyRequestId]);

  return (
    <Box pl-10 pr-10 $width='100%'>
      <GridDatePicker
        name='availableDay'
        selected={data.availableDay ?? ''}
        onChange={onChangeHandler}
        isClearable={false}
        disabled={!disabled}
        portalId='datepicker-root'
        withPortal
      />
    </Box>
  );
}
