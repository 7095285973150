import { client } from '@src/client';

import { VendorCodeRow } from '../VendorCodeModal.type';

export const postVendorCodeCommon = async (params: { vendorId: string; vendorName: string }) => {
  const body = {
    ...params,
    vendorId: params.vendorId === '' ? null : params.vendorId,
  };

  const { data } = await client.post<VendorCodeRow[]>('/vendors/list/common', body);
  return data;
};
