import CautionBox from '@Components/CautionBox';
import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { useDialog } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';
import ModalFooter from '@src/Layout/components/ModalFooter';
import { performIfConfirmed } from '@src/Utils/helper';

import {
  InboundRequestRow,
  useInboundAdjustRequestFormQuery as useInboundAdjustRequestListQuery,
} from '../../apis/useInboundAdjustRequestListQuery';
import { useSaveInboundAdjustMutation } from '../../apis/useSaveInboundAdjustMutation';

import { INBOUND_ADJUST_REQUEST, messages } from './constant';
import { CheckMessage, SelectTestCell } from './InboundAdjustRequestModalCell';

function InboundAdjustRequestModal({
  activeIds = [],
  refetch,
}: {
  activeIds: number[];
  refetch: VoidFunction;
}) {
  const { columns } = INBOUND_ADJUST_REQUEST;
  const inboundAdjustRequestList = useInboundAdjustRequestListQuery(activeIds);
  const saveInboundAdjustMutation = useSaveInboundAdjustMutation();
  const inboundAdjustRequestModal = useDialog('InboundAdjustRequestModal');

  const inboundAdjustCreateDtos = inboundAdjustRequestList.list.map(
    ({ inboundItemId, adjustReason, adjustQuantity }) => ({
      inboundItemId: Number(inboundItemId),
      adjustQuantity: adjustQuantity === null ? null : Number(adjustQuantity),
      adjustReason: adjustReason === '' ? null : adjustReason,
    })
  );

  const handleDataChange = (newList: InboundRequestRow[]) => {
    const calculatedList = newList.map((item) => ({
      ...item,
      adjustResultQuantity: Number(item.firstInboundQuantity) + Number(item.adjustQuantity) || 0,
    }));

    inboundAdjustRequestList.setList(calculatedList);
  };

  const saveInboundAdjust = () => {
    performIfConfirmed(
      `입고실적 [${inboundAdjustCreateDtos.length}]건에 대해 실적조정을 요청하시겠습니까?`,
      async () => {
        await saveInboundAdjustMutation.mutateAsync(inboundAdjustCreateDtos);
        refetch();
      }
    );
  };

  const checkHandler = () => {
    inboundAdjustRequestList.setIsPass(false);
    inboundAdjustRequestList.useMutationResult.mutate(inboundAdjustCreateDtos);
  };

  return (
    <div>
      <CautionBox title='참고사항' messages={messages} />
      <Flex direction='column' rowGap={10}>
        <ResultHeader title='입고 실적조정 요청' total={inboundAdjustRequestList.list.length} />
        <DataSheetGrid
          value={inboundAdjustRequestList.list}
          columns={columns}
          onChange={handleDataChange}
          customRendererList={[
            { id: 'adjustReason', component: SelectTestCell },
            { id: 'result', component: CheckMessage },
          ]}
        />
      </Flex>
      <ModalFooter>
        <Button onClick={checkHandler}>체크</Button>
        <Button disabled={!inboundAdjustRequestList.isPass} onClick={saveInboundAdjust}>
          저장
        </Button>
        <Button onClick={inboundAdjustRequestModal.resetDialog}>취소</Button>
      </ModalFooter>
    </div>
  );
}

export default InboundAdjustRequestModal;
