import { keyColumn, textColumn } from 'react-datasheet-grid';

import { STOCK_STATUS_OPTIONS } from '@Components/Modals/ProductCodeModal/constant';
import { ACTIVE_COLUMN } from '@Constants/grid';

export const OUTBOUND_DIVISION_INITIAL_STATE = {
  centerCode: '',
  stockStatus: '',
  productItemId: '',
  productItemName: '',
  activated: '',
};

export const OUTBOUND_DIVISION_ACTIVATED_OPTIONS = [
  { label: '전체', value: '' },
  { label: '활성화', value: 'Y' },
  { label: '비활성화', value: 'N' },
];

export const OUTBOUND_DIVISION_ACTIVATED_COLOR = {
  활성화: 'green.500',
  비활성화: 'red.500',
};

export const OUTBOUND_DIVISION_STOCK_STATUS_OPTIONS = [
  { label: '전체', value: '' },
  ...STOCK_STATUS_OPTIONS,
];

export const OUTBOUND_DIVISION_LIST_COLUMNS = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('activated', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    minWidth: 300,
    disabled: true,
  },
  {
    ...keyColumn('stockStatusValue', textColumn),
    title: '재고상태',
    disabled: true,
  },
  {
    ...keyColumn('unitQuantity', textColumn),
    title: '출고단위수량',
    disabled: true,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '출고센터코드',
    disabled: true,
  },
  {
    ...keyColumn('centerName', textColumn),
    title: '출고센터명',
    disabled: true,
    minWidth: 160,
  },
  {
    ...keyColumn('createdUserName', textColumn),
    title: '등록자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 130,
  },
  {
    ...keyColumn('updatedUserName', textColumn),
    title: '수정자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
    minWidth: 130,
  },
];
