import { useState } from 'react';

import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import Pagination from '@Components/Pagination';
import ProductCategorySelect from '@Components/ProductCategorySelect';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import { useDependentSelect, useFormField, useUpdateEffect } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';
import { performIfNotEmpty } from '@src/Utils/helper';

import { useExcelProductList } from './apis/useExcelProductList';
import { useProductListQuery } from './apis/useProductListQuery';
import {
  INITIAL_PRODUCT_LIST_DEPENDENT_SELECT,
  INITIAL_PRODUCT_LIST_FORM_FIELD,
  PRODUCT_ITEM_TYPE_OPTIONS,
  PRODUCT_LIST_COLUMNS,
  STOCK_STATUS_OPTIONS,
} from './constant';

function ProductManage() {
  const formField = useFormField(INITIAL_PRODUCT_LIST_FORM_FIELD);
  const dependentSelect = useDependentSelect(INITIAL_PRODUCT_LIST_DEPENDENT_SELECT);
  const [pageNumber, setPageNumber] = useState(0);
  const productListQuery = useProductListQuery();
  const { downloadExcel } = useExcelProductList();

  const { openDialog } = useExcelDownLoadHistory({
    targetUrl: 'product_item',
  });

  const productList = productListQuery.data?.results || [];
  const productListTotal = productListQuery.data?.total;

  const handleSearchClick = () => {
    productListQuery.setSearchFilter({
      ...formField.form,
      ...dependentSelect.form,
      pageNumber: 0,
    });
    setPageNumber(0);
  };

  const downloadProductList = () => {
    downloadExcel({
      ...formField.form,
      ...dependentSelect.form,
      pageNumber: 0,
    });
  };

  const onClickDownloadExcelDownload = () => {
    openDialog();
  };

  useUpdateEffect(() => {
    productListQuery.setSearchFilter((prevFilter) => ({
      ...prevFilter,
      pageNumber,
    }));
  }, [pageNumber]);

  return (
    <div>
      <div>
        <FilterHeader>
          <Button onClick={handleSearchClick} isPermissionRequired={false}>
            조회
          </Button>
          <Button
            onClick={performIfNotEmpty(
              productList,
              downloadProductList,
              '조회된 데이터가 없습니다'
            )}
            isPermissionRequired={false}
          >
            엑셀
          </Button>
          <Button isPermissionRequired={false} onClick={onClickDownloadExcelDownload}>
            엑셀 다운로드 요청 내역
          </Button>
        </FilterHeader>

        <FormField>
          <Flex direction='column' gap={10}>
            <SelectLabel
              {...formField.attributes.productItemType}
              label='품목유형'
              options={PRODUCT_ITEM_TYPE_OPTIONS}
            />
            <SelectLabel
              {...formField.attributes.stockStatus}
              label='재고상태'
              options={STOCK_STATUS_OPTIONS}
            />
          </Flex>
          <Flex direction='column' gap={10}>
            <ProductCategorySelect dependentSelect={dependentSelect} />
          </Flex>
          <Flex direction='column' gap={10}>
            <InputLabel {...formField.attributes.vendorId} label='거래처코드' />
            <InputLabel {...formField.attributes.productItemName} label='품목명' />
          </Flex>
          <TextAreaLabel label='품목코드' {...formField.attributes.productItemId} />
        </FormField>
      </div>

      <div>
        <Flex direction='column' rowGap={10}>
          <ResultHeader title='품목 내역' total={productListTotal} />
          <DataSheetGrid
            value={productList}
            columns={PRODUCT_LIST_COLUMNS}
            gutterColumn={CUSTOM_GUTTER_COLUMN}
          />
          <Flex justify='center'>
            <Pagination
              total={productListTotal}
              pageSize={500}
              currentPageIndex={pageNumber}
              onPageIndexChange={setPageNumber}
            />
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default ProductManage;
