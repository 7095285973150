import { useState } from 'react';

import styled from 'styled-components';

import DataSheetGrid from '@src/Components/DataSheetGrid';
import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import Form from '@src/Components/Forms/Form';
import { InputLabel } from '@src/Components/Forms/Input';
import { toastify } from '@src/Components/Toast';
import { useDialog, useFormField } from '@src/Hooks';
import FilterHeader from '@src/Layout/components/FilterHeader';
import FormField from '@src/Layout/components/FormField';
import ModalFooter from '@src/Layout/components/ModalFooter';
import ResultHeader from '@src/Layout/components/ResultHeader';

import { UserRole, useUserRoleListQuery } from './apis/useUserRoleListQuery';
import { INITIAL_USER_ROLE_LIST_FILTER, USER_ROLE_LIST_COLUMNS } from './constant';

function UserRoleModal({
  modalId = 'userRole',
  onSelect,
}: {
  modalId?: string;
  onSelect: (selectedUserRole: UserRole) => void;
}) {
  const formField = useFormField(INITIAL_USER_ROLE_LIST_FILTER);
  const userRoleListQuery = useUserRoleListQuery();
  const userRoleModal = useDialog(modalId);
  const [selectedUserRoleId, setSelectedUserRoleId] = useState<number | null>(null);

  const userRoleList = userRoleListQuery.data;

  const selectUserRoleId = () => {
    if (!userRoleList?.find((userRole) => userRole.roleId === selectedUserRoleId)) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return;
    }
    onSelect(userRoleList?.find((userRole) => userRole.roleId === selectedUserRoleId) as UserRole);
  };

  return (
    <Wrapper direction='column' justify='space-between'>
      <Flex direction='column' gap={10}>
        <Form onSubmit={() => userRoleListQuery.setSearchFilter(formField.form)}>
          <FilterHeader left='역할코드'>
            <Button type='submit' isPermissionRequired={false}>
              검색
            </Button>
          </FilterHeader>

          <FormField>
            <Flex direction='column' gap={10}>
              <Flex gap={100} justify='space-between'>
                <InputLabel {...formField.attributes.groupCode} label='사용자 그룹코드' />
                <InputLabel {...formField.attributes.roleCode} label='역할코드' />
              </Flex>
              <Flex gap={100} justify='space-between'>
                <InputLabel {...formField.attributes.groupName} label='사용자 그룹명' />
                <InputLabel {...formField.attributes.roleName} label='사용자 역할명' />
              </Flex>
            </Flex>
          </FormField>
        </Form>

        <ResultHeader title='역할코드 내역' total={userRoleList?.length} />
        <DataSheetGrid
          value={userRoleList}
          columns={USER_ROLE_LIST_COLUMNS}
          onActiveChange={({ row: rowIndex }) =>
            setSelectedUserRoleId(userRoleList?.[rowIndex].roleId as number)
          }
          dynamic
        />
      </Flex>

      <ModalFooter>
        <Button onClick={selectUserRoleId} isPermissionRequired={false}>
          선택
        </Button>
        <Button onClick={userRoleModal.resetDialog} isPermissionRequired={false}>
          취소
        </Button>
      </ModalFooter>
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  height: 100%;
`;

export default UserRoleModal;
