import { toastify } from '@Components/Toast';
import { client } from '@src/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteOutboundItem = async ({
  checkedOutboundItemIds,
  availableQuantityRestocked,
}: {
  checkedOutboundItemIds: number[];
  availableQuantityRestocked: boolean;
}) => {
  const { data } = await client.put<number[]>('/outbounds/item/disabled', {
    ids: checkedOutboundItemIds,
    availableQuantityRestocked,
  });
  return data;
};

export const useDeleteOutboundItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOutboundItem, {
    onSuccess() {
      toastify('삭제되었습니다.');
      queryClient.invalidateQueries(['outbounds']);
    },
  });
};
