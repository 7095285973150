import { selector } from 'recoil';

import { currentTabAtom, TabItemAtom, tabListAtom } from './tab.atom';

type TabStateSelector = TabItemAtom | TabItemAtom[];

const findAroundTab = (arr: TabItemAtom[], value: TabItemAtom, idx: number) =>
  arr.findIndex(({ id }) => id === value?.id) + idx;

export const tabState = selector<TabStateSelector>({
  key: 'tabState',
  get: ({ get }) => get(tabListAtom),
  set: ({ set, get }, newValue) => {
    const value = newValue as TabItemAtom;
    const prevList = get(tabListAtom);

    if (prevList.some((item) => item.id === value.id)) {
      return undefined;
    }

    set(tabListAtom, (prev) => [...prev, value]);
    set(currentTabAtom, value);
  },
});

export const updateCurrentTab = selector<TabStateSelector>({
  key: 'updateCurrentTab',
  get: ({ get }) => get(currentTabAtom),
  set: ({ set, get }, newValue) => {
    const [list, currentTab] = [get(tabListAtom), get(currentTabAtom)];
    const value = newValue as TabItemAtom;

    set(tabListAtom, (prev) => prev.filter(({ id }) => id !== value.id));

    if (value.id === currentTab.id) {
      const prevTab = list[findAroundTab(list, value, -1)];
      const afterTab = list[findAroundTab(list, value, 1)];

      if (prevTab || afterTab) set(currentTabAtom, prevTab ?? afterTab);
    }
  },
});
