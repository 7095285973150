import { CellProps, Column } from 'react-datasheet-grid';
import styled from 'styled-components';

import Box from '../Box';
import { DatePicker, DatePickerOnChangeEvent } from '../Date/DatePicker';

interface DateOptions {
  key: string;
  portalId?: string;
}

export function DateCell({
  rowData,
  columnData,
  setRowData,
  disabled,
}: CellProps<any, DateOptions>) {
  const { key, portalId } = columnData;

  const onChangeHandler = (e: DatePickerOnChangeEvent) => {
    const { value } = e.target;
    setRowData({
      ...rowData,
      [key]: value ?? null,
    });
  };

  return (
    <Box pl-10 pr-10 $width='100%'>
      <GridDatePicker
        name={key}
        selected={rowData[key] ?? ''}
        onChange={onChangeHandler}
        isClearable={false}
        disabled={disabled}
        portalId={portalId || 'datepicker-root'}
        withPortal
      />
    </Box>
  );
}

const GridDatePicker = styled(DatePicker)`
  width: 100% !important;
  min-width: unset !important;
  border: unset !important;
  border-radius: 0 !important;
  background-color: transparent !important;

  &:disabled {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.gray[700]} !important;
  }
`;

export const dateColumn = (options: DateOptions): Column<any, DateOptions> => ({
  component: DateCell,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  deleteValue: () => null,
  copyValue: ({ rowData }) => rowData[options.key],
  pasteValue: ({ value, rowData }) => {
    return {
      ...rowData,
      [options.key]: value,
    };
  },
});
