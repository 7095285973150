import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import FormField from '@Layout/components/FormField';

import { SELECT_OPTIONS } from './constant';
import { PostUserGroupParams } from './UserGroupAndRoleManageFilter.type';

function UserGroupAndRoleManageFilter({ attributes }: PostUserGroupParams) {
  return (
    <FormField>
      <SelectLabel {...attributes.status} label='상태' options={SELECT_OPTIONS} />
      <InputLabel {...attributes.groupCode} label='사용자그룹코드' />
      <InputLabel {...attributes.userGroupRoleCode} label='역할코드' />
      <InputLabel {...attributes.userGroupName} label='사용자그룹명' />
    </FormField>
  );
}

export default UserGroupAndRoleManageFilter;
