import { keyColumn, textColumn } from 'react-datasheet-grid';

export const CAUTION_BOX_NEW_MESSAGES = [
  '센터코드 + 입고유형 + 운송유형 + 입고예정일자 + 거래처코드가 모두 같은 품목의 경우, 1개 입고 전표로 구분되어 등록됩니다.',
  '1개 입고 전표에는 같은 품목은 포함될 수 없습니다. 발주 어드민에서 증정품의 경우 같은 품목이 복수로 포함될 수 있지만, 입고 등록 시에는 해당 품목 수 합산해 등록됩니다.',
];

export const INBOUND_NEW_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('errorMessage', textColumn),
      title: '체크메시지',
      disabled: true,
      minWidth: 400,
    },
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('inboundCategoryValue', textColumn),
      title: '입고유형',
      disabled: true,
    },
    {
      ...keyColumn('transportTypeValue', textColumn),
      title: '운송유형',
      disabled: true,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      ...keyColumn('productSupplyRequestId', textColumn),
      title: '발주번호',
      disabled: true,
      minwidth: 120,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '입고예정일',
      disabled: true,
    },
    {
      ...keyColumn('vendorId', textColumn),
      title: '거래처코드',
      disabled: true,
    },
    {
      ...keyColumn('vendorDisplayName', textColumn),
      title: '거래처명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '입고예정수량',
      disabled: true,
    },
    {
      ...keyColumn('inboundItemRemark', textColumn),
      title: '비고',
      disabled: true,
      minWidth: 200,
    },
  ],
};
