import { ChangeEvent, useState } from 'react';

import { DatePickerOnChangeEvent } from '@Components/Date/DatePicker';

type OnChangeEvent = ChangeEvent<HTMLInputElement | HTMLSelectElement> | DatePickerOnChangeEvent;

const DAY = 'Day';
export type FormFieldAttributes<K> = {
  [key in K & string]: {
    name: K;
    onChange: (event: OnChangeEvent) => void;
    disabled?: boolean;
    label?: string;
  } & (key extends `${string}${typeof DAY}` ? { selected: string } : { value: string });
};

export function useFormField<T extends Record<string, string>, K extends keyof T>(initialForm: T) {
  const [form, setForm] = useState(initialForm);

  const onChange = (event: OnChangeEvent) => {
    const { name, value } = event.target;
    if (typeof value === 'undefined') return;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const resetForm = () => setForm(initialForm);

  const attributes = Object.keys(form).reduce((accumulatedAttributes, key) => {
    const selectedOrValue = key.endsWith(DAY) ? 'selected' : 'value';
    return {
      ...accumulatedAttributes,
      [key]: {
        name: key,
        [selectedOrValue]: form[key],
        onChange,
      },
    };
  }, {} as FormFieldAttributes<K>);

  return { form, setForm, resetForm, attributes };
}
