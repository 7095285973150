import { useState } from 'react';

import { useFullScreenActivityIndicator } from '@Hooks';
import { client } from '@src/client';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { useQuery } from '@tanstack/react-query';

import { mapObject } from '../../../Utils/object';
import { VendorRow } from '../VendorInquire.type';

import { PostVendorListParams } from './type';

export const postVendorList = async (params: PostVendorListParams) => {
  const body = mapObject({ ...params }, nullIfEmptyOrALL);

  const { data } = await client.post<VendorRow[]>('/vendors/list', body);
  return data;
};

export const useVendorListQuery = (params: PostVendorListParams) => {
  const [vendorList, setVendorList] = useState<VendorRow[]>([]);
  const { hideActivityIndicator } = useFullScreenActivityIndicator();

  const { refetch } = useQuery(['vendor-list'], () => postVendorList(params), {
    enabled: false,
    onSuccess: (data) => {
      data && setVendorList(data);
    },
    onSettled: () => {
      hideActivityIndicator();
    },
  });

  return {
    vendorList,
    refetch,
  };
};

const postDownloadVendorList = async (params: PostVendorListParams) => {
  const body = mapObject({ ...params }, nullIfEmptyOrALL);
  const { data } = await client.post<{ send: string }>('/vendors/excel-download', body);

  return data;
};

export const useExcelVendorList = () => {
  const { downloadExcel } = useMassiveExcelRequest(postDownloadVendorList);

  return {
    downloadExcel,
  };
};
