import CautionBox from '@Components/CautionBox';
import Flex from '@Components/Flex';
import { InputLabel } from '@Components/Forms/Input';
import { toastify } from '@Components/Toast';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import { useMutation } from '@tanstack/react-query';

import { postNewCenterData } from '../../apis';
import { FormType } from '../../CenterManage.type';
import { CAUTION_MESSAGES } from '../../constant';

import ModalForm from './ModalForm';

function CenterManageModal({ onSuccess }: { onSuccess: () => void }) {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();
  const { mutateAsync: setNewCenterData } = useMutation(postNewCenterData, {
    onSuccess,
    onSettled: () => {
      hideActivityIndicator();
    },
  });

  const save = async (params: FormType) => {
    const resultParams = {
      name: params.name,
      address: {
        latitude: params.latitude || null,
        longitude: params.longitude || null,
        mainAddress: params.mainAddress || null,
        subAddress: params.subAddress || null,
        zipcode: params.zipcode || null,
      },
      fulfillment: params.fulfillment || null,
      contact: params.contact || null,
    };
    showActivityIndicator();
    await setNewCenterData(resultParams);
    toastify('저장되었습니다.');
  };

  return (
    <div>
      <CautionBox title='참고사항' messages={CAUTION_MESSAGES} />
      <Flex direction='column' align='flex-start' rowGap={20}>
        <Flex colGap={50} rowGap={20} wrap='wrap'>
          <InputLabel label='센터코드 **' name='centerId' placeholder='자동채번' disabled />
          <InputLabel label='상태' name='status' placeholder='비활성화' disabled />
        </Flex>
      </Flex>
      <ModalForm save={save} />
    </div>
  );
}

export default CenterManageModal;
