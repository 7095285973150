export interface SuggestionProps {
  id: string;
  list: { value: string; label?: string }[];
}
function Suggestion({ id, list }: SuggestionProps) {
  return (
    <datalist id={id}>
      {list.map(({ value, label }) => (
        <option key={value} value={value} label={label}>
          {label || value}
        </option>
      ))}
    </datalist>
  );
}

export default Suggestion;
