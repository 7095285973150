import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { flexRow } from '@Styles/utils/flex';

export function CheckCell<T extends { active: boolean }>({
  onCheck,
  value,
}: {
  onCheck: () => void;
  value: T[];
}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (value.length) {
      const isAllChecked = value.every((it) => it.active);
      setChecked(isAllChecked);
    }
  }, [value]);

  return (
    <CheckWrapper>
      &nbsp; <input type='checkbox' onChange={onCheck} checked={checked} />
    </CheckWrapper>
  );
}

const CheckWrapper = styled.div`
  ${flexRow('flex-start', 'center')}
`;
