import DataSheetGrid from '@src/Components/DataSheetGrid';
import { CUSTOM_GUTTER_COLUMN } from '@src/Constants/grid';
import GridLayout from '@src/Layout/components/GridLayout';
import ResultHeader from '@src/Layout/components/ResultHeader';
import type { Nullable } from '@src/Utils/type';

import { useClaimOutboundItem } from '../apis/useClaimOutboundItem';
import { CLAIM_OUTBOUND_ITEM_COLUMNS } from '../constant';

function ClaimOutbound({ outboundId }: { outboundId: Nullable<number> }) {
  const { list: claimOutboundList } = useClaimOutboundItem(outboundId);

  return (
    <GridLayout
      header={<ResultHeader title='출고 품목내역' />}
      grid={
        <DataSheetGrid
          columns={CLAIM_OUTBOUND_ITEM_COLUMNS}
          value={claimOutboundList}
          gutterColumn={CUSTOM_GUTTER_COLUMN}
        />
      }
    />
  );
}

export default ClaimOutbound;
