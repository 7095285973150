import { atom } from 'recoil';

import { ActivityIndicatorProps } from '@Components/ActivityIndicator/ActivityIndicator.type';

export const fullScreenActivityIndicatorAtom = atom<ActivityIndicatorProps>({
  key: 'fullScreenActivityIndicatorAtom',
  default: {
    isVisible: false,
  },
});
