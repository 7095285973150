import styled, { css } from 'styled-components';

import { Content, Overlay, Title } from '@radix-ui/react-dialog';
import { DialogSizes } from '@Stores/Dialog/dialog.atom';
import { flexColumn } from '@Styles/utils/flex';

export const StyledOverlay = styled(Overlay)`
  background-color: hsla(0, 0%, 0%, 0.439);
  position: fixed;
  inset: 0;
  z-index: 100;
`;

export const StyledContent = styled(Content)<{ size: DialogSizes }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${flexColumn('normal')};
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  z-index: 100;
  border-radius: ${({ theme }) => theme.radiuses['0.6']};
  box-shadow: 0.1rem 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.5);

  ${({ size }) =>
    ({
      xsmall: css`
        max-width: 45rem;
        max-height: 30rem;
      `,
      small: css`
        max-width: 45rem;
        max-height: 40vh;
      `,
      medium: css`
        max-width: 55vw;
        max-height: 60vh;
      `,
      large: css`
        max-width: 85vw;
        max-height: 85vh;
      `,
    }[size])}

  &:focus {
    outline: none;
  }
`;

export const TitleWrapper = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[300]};
  padding: 1rem 1.6rem;
  background: ${({ theme }) => theme.colors.gray[800]};
`;

export const TitleText = styled(Title)`
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
`;

export const ContentWrapper = styled.div`
  ${flexColumn('space-between')};
  overflow: auto;
  padding: ${({ theme }) => theme.spaces['1.6']};
  height: 100%;
`;
