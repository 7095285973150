import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@src/Constants/grid';

export const INITIAL_USER_LIST_FORM_FIELD = {
  status: 'ALL',
  roleCode: '',
  email: '',
  userName: '',
  mobileNo: '',
};

export const STATUS_OPTIONS = [
  { value: 'ALL', label: '전체' },
  { value: 'Y', label: '활성화' },
  { value: 'N', label: '비활성화' },
] as const;

export const USER_MANAGE_STATUS_CODE_COLOR = {
  활성화: 'green.500',
  비활성화: 'red.500',
};

export const USER_LIST_COLUMNS = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('userCode', textColumn),
    title: '사용자코드',
    disabled: true,
  },
  {
    ...keyColumn('activated', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('userName', textColumn),
    title: '사용자명',
    minWidth: 150,
  },
  {
    ...keyColumn('roleCode', textColumn),
    title: '역할코드',
    disabled: true,
  },
  {
    ...keyColumn('groupName', textColumn),
    title: '사용자 그룹명',
    disabled: true,
    minWidth: 150,
  },
  {
    ...keyColumn('roleName', textColumn),
    title: '사용자역할명',
    disabled: true,
    minWidth: 150,
  },
  {
    ...keyColumn('email', textColumn),
    title: 'ID(이메일)',
    disabled: true,
    minWidth: 200,
  },
  {
    ...keyColumn('mobileNo', textColumn),
    title: '핸드폰 번호',
    minWidth: 120,
  },
  {
    ...keyColumn('phoneNo', textColumn),
    title: '전화번호',
    minWidth: 120,
  },
  {
    ...keyColumn('position', textColumn),
    title: '직위/직책',
  },
  {
    ...keyColumn('createdMemberName', textColumn),
    title: '등록자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedMemberName', textColumn),
    title: '수정자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
    minWidth: 120,
  },
];
