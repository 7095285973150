import dayjs from 'dayjs';
import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const INBOUND_ADJUST_LIST_PAGE_SIZE = 500;

export const INITIAL_INBOUND_RESULT_ADJUST_FILTER = {
  startAvailableDay: dayjs().subtract(30, 'd').format('YYYY-MM-DD'), // 입고 예정일자 start
  endAvailableDay: dayjs().format('YYYY-MM-DD'), // 입고예정일자 end
  startCompleteDay: '', // 입고실적일자 start
  endCompleteDay: '', // 입고실적일자 end
  inboundCategory: '', // 입고유형
  centerCode: '', // 센터코드
  vendorId: '', // 거래처코드
  wikey: '', // 입고번호
  productItemId: '', // 품목코드
  statusCode: '', // 실적조정 상태
  productSupplyRequestId: '',
  referenceCode: '',
};

export const INBOUND_RESULT_ADJUST_STATUS_CODE_COLOR = {
  '실적조정 요청': 'blue.500',
  '실적조정 완료': 'green.500',
  '실적조정 반려': 'red.500',
  미등록: 'orange.500',
};

export const INBOUND_ADJUST_REQUEST_STATUS_CODE_OPTIONS = [
  { value: '', label: '전체' },
  { value: 'REQUESTED', label: '실적조정 요청' },
  { value: 'APPROVED', label: '실적조정 완료' },
  { value: 'REJECTED', label: '실적조정 반려' },
  { value: 'UNREGISTERED', label: '미등록' },
];

export const INBOUND_ADJUST_GRID_OPTIONS = {
  columns: [
    ACTIVE_COLUMN,
    { ...keyColumn('wikey', textColumn), title: '입고번호', disabled: true, minWidth: 120 },
    {
      ...keyColumn('inboundStatusCodeValue', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('inboundCategoryValue', textColumn),
      title: '입고유형',
      disabled: true,
    },
    {
      ...keyColumn('transportTypeValue', textColumn),
      title: '운송유형',
      disabled: true,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      ...keyColumn('productSupplyRequestId', textColumn),
      title: '발주번호',
      disabled: true,
      minWidth: 110,
    },
    {
      ...keyColumn('referenceCode', textColumn),
      title: '참조번호',
      disabled: true,
      minWidth: 110,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '입고예정일',
      disabled: true,
    },
    {
      ...keyColumn('completeDay', textColumn),
      title: '입고실적일',
      disabled: true,
    },
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('centerName', textColumn),
      title: '센터명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('vendorId', textColumn),
      title: '거래처코드',
      disabled: true,
    },
    {
      ...keyColumn('vendorDisplayName', textColumn),
      title: '거래처명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('productItemCount', textColumn),
      title: '품목수',
      disabled: true,
    },
    {
      ...keyColumn('allCount', intColumn),
      title: '총수량',
      disabled: true,
    },

    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '입고예정수량',
      disabled: true,
    },
    {
      ...keyColumn('firstInboundQuantity', textColumn),
      title: '입고실적수량',
      disabled: true,
    },
    {
      ...keyColumn('adjustQuantity', textColumn),
      title: '실적조정수량',
      disabled: true,
    },
    {
      ...keyColumn('receivedQuantity', textColumn),
      title: '조정결과수량',
      disabled: true,
    },
    {
      ...keyColumn('inboundAdjustStatusCodeValue', textColumn),
      title: '실적조정상태',
      disabled: true,
    },
    {
      ...keyColumn('adjustReasonValue', textColumn),
      title: '조정사유',
      disabled: true,
    },
    {
      ...keyColumn('remark', textColumn),
      title: '비고',
      disabled: true,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 120,
    },
  ],
};

export const NOT_ALLOWED_REQUEST_STATUSES = ['B2C - 반품'];
