import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const STOCK_INQUIRE_INITIAL_STATE = {
  startRequestDay: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
  endRequestDay: dayjs().format('YYYY-MM-DD'),
  stockAdjustCategory: '',
  stockAdjustReason: '',
};

export const STOCK_ADJUST_CATEGORY_OPTIONS = [
  { label: '전체', value: '' },
  { label: '입고', value: 'INBOUND' },
  { label: '출고', value: 'OUTBOUND' },
];

export const STOCK_ADJUST_REASON_OPTIONS = [
  { label: '전체', value: '' },
  { label: '감모손실', value: 'LOSS' },
  { label: '재고실사', value: 'STOCKTAKING' },
  { label: '폐기', value: 'DISPOSAL' },
  { label: '바코드오류', value: 'BARCODE_ERROR' },
];

export const STOCK_ADJUST_STATUS_CODE_COLOR = {
  등록: 'orange.500',
  수정등록: 'orange.500',
  완료: 'green.500',
  취소: 'red.500',
};

export const STOCK_ADJUST_GRID_OPTIONS = {
  columns: [
    ACTIVE_COLUMN,
    {
      ...keyColumn('stockAdjustCategoryValue', textColumn),
      title: '구분',
      disabled: true,
    },

    {
      ...keyColumn('stockAdjustRequestCode', textColumn),
      title: '재고조정 번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('requestDay', textColumn),
      title: '오더생성일',
      disabled: true,
    },
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('centerName', textColumn),
      title: '센터명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('stockAdjustReasonValue', textColumn),
      title: '조정사유',
      disabled: true,
    },
    {
      ...keyColumn('statusCodeValue', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('productItemCount', textColumn),
      title: '품목수',
      disabled: true,
    },
    {
      ...keyColumn('allCount', textColumn),
      title: '총 수량',
      disabled: true,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 120,
    },
  ],
};

export const STOCK_ADJUST_DETAIL_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('referenceCode', textColumn),
      title: '입고번호/출고번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('category', textColumn),
      title: '입고/출고유형',
      disabled: true,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '입고/출고예정일',
      disabled: true,
    },
    {
      ...keyColumn('statusCode', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('adjustQuantity', textColumn),
      title: '조정수량',
      disabled: true,
    },
    {
      ...keyColumn('remark', textColumn),
      title: '비고',
      disabled: true,
      minWidth: 400,
    },
  ],
};
