export const INBOUND_CATEGORY_OPTIONS = [
  { label: '전체', value: 'ALL' },
  { label: '매입입고', value: 'PURCHASE' },
  { label: '매입입고 - 교환', value: 'EXCHANGE' },
  { label: '판매자 심쿵', value: 'CONSIGNMENT' },
  { label: 'B2C - 반품', value: 'B2C_RETURN' },
  { label: 'B2B - 반품', value: 'B2B_RETURN' },
  { label: '유통가공', value: 'PROCESSING' },
  { label: '유통가공 - 사료샘플', value: 'PROCESSING_FEED_SAMPLE' },
  { label: '재고이동', value: 'MOVE' },
  { label: '재고조정', value: 'ADJUST' },
  { label: '기타-업체배송', value: 'ETC_VENDOR_DELIVERY' },
  { label: '기타', value: 'ETC' },
];

export const INBOUND_STATUS_CODE_OPTIONS = [
  { value: 'ALL', label: '전체' },
  { value: 'DRAFT', label: '입고예정' },
  { value: 'SENT', label: '전송완료' },
  { value: 'COMPLETED', label: '입고완료' },
  { value: 'CANCELED', label: '입고취소' },
];

export const INBOUND_STATUS_CODE_COLOR = {
  입고예정: 'orange.500',
  입고완료: 'green.500',
  전송완료: 'blue.500',
  입고취소: 'red.500',
};

export const INVALID_INBOUND_FILTER_MESSAGE = '입고예정일 또는 입고실적일을 입력해주세요.';

// 재고이동, 재고조정, 유통가공, 'B2C - 반품' 일때 그리드 수정 불가
export const NOT_EDITABLE_INBOUND_CATEGORY = ['재고이동', '재고조정', '유통가공', 'B2C - 반품'];
