import Cookies from 'js-cookie';
import { useRecoilValue } from 'recoil';

import { useUserMenuAndRoleListQuery } from '@src/Apis/user';
import { currentTabAtom } from '@Stores/Tab/tab.atom';
import { getDecodeToken } from '@Utils/token';

import { BreadcrumbItem, BreadcrumbWrapper } from './Breadcrumb.style';

function Breadcrumb() {
  const accessToken = getDecodeToken(Cookies.get('wms_tk')); //
  const { title, id, parentId } = useRecoilValue(currentTabAtom);
  const { data } = useUserMenuAndRoleListQuery({ userId: accessToken ? accessToken.user_id : -1 });
  const rootDepth = data?.userMenuList.find((menu) => menu.id === parentId);

  if (!id) {
    return null;
  }

  return (
    <BreadcrumbWrapper>
      <BreadcrumbItem>{rootDepth?.title}</BreadcrumbItem>
      {title && <BreadcrumbItem className='active'>{title}</BreadcrumbItem>}
    </BreadcrumbWrapper>
  );
}

export default Breadcrumb;
