import styled, { css } from 'styled-components';

import Flex from '@src/Components/Flex';

export const FormFieldBox = styled(Flex)`
  ${({ theme: { colors, spaces, radiuses } }) => {
    const { gray } = colors;
    return css`
      border: 0.1rem solid ${gray[400]};
      background: ${gray[100]};
      padding: ${spaces[1.6]};
      border-radius: ${radiuses[0.3]};
      margin: ${spaces[1.4]} 0;
    `;
  }}
`;
