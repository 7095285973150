import { CellProps, Column } from 'react-datasheet-grid';

import { useDialog } from '@src/Hooks';

import Box from '../Box';
import Flex from '../Flex';
import CenterCodeModal from '../Modals/CenterCodeModal';
import { CenterCodeRow } from '../Modals/CenterCodeModal/CenterCodeModal.type';

export function CenterCodeCell({ rowData, setRowData, disabled }: CellProps<CenterCodeRow>) {
  const { openDialog } = useDialog('CenterCodeModal');

  const openCenterCodeModal = () => {
    openDialog({
      title: '코드 선택',
      content: (
        <CenterCodeModal
          selectCenterCode={({ centerCode }) => setRowData({ ...rowData, centerCode })}
        />
      ),
      size: 'medium',
    });
  };

  return (
    <Box pl-10 pr-10 $width='100%'>
      <Flex align='center' colGap={10} justify='space-between'>
        {rowData.centerCode ?? <span>-</span>}
        {!disabled && (
          <button disabled={disabled} type='button' onClick={openCenterCodeModal}>
            🔎
          </button>
        )}
      </Flex>
    </Box>
  );
}

export const centerCodeColumn = (): Column => ({
  component: CenterCodeCell,
  disableKeys: true,
  keepFocus: true,
  deleteValue: ({ rowData }) => {
    return {
      ...rowData,
      centerCode: null,
    };
  },
  copyValue: ({ rowData }) => rowData.centerCode,
  pasteValue: ({ value, rowData }) => {
    return {
      ...rowData,
      centerCode: value,
    };
  },
});
