import { CenterCodeRow } from '@Components/Modals/CenterCodeModal/CenterCodeModal.type';
import useRequest from '@src/Hooks/useRequest';

export const useCenterCodeList = (centerId: string | null = null) => {
  return useRequest<CenterCodeRow[]>(
    {
      url: '/centers/list/common',
      method: 'POST',
      data: { centerId },
    },
    {
      staleTime: Infinity,
    }
  );
};
