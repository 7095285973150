import { useState } from 'react';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { ReceiptsAndPayment } from '@src/Features/DailyReceiptsAndPayments/useDailyReceiptsAndPaymentsQuery';
import { useDialog } from '@src/Hooks';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { Nullable } from '@src/Utils/type';

import { INITIAL_MONTHLY_RECEIPTS_AND_PAYMENTS_FILTER } from '../MonthlyReceiptsAndPayments';

interface MonthlyReceiptsAndPayments extends ReceiptsAndPayment {
  endLastTotal: Nullable<number>;
  endCurrentTotal: Nullable<number>;
}

export const useMonthlyReceiptsAndPaymentsQuery = () => {
  const [list, setList] = useState<MonthlyReceiptsAndPayments[]>([]);
  const searchProgressingModal = useDialog('SearchProgressingModal');
  const { data: centerCodeList } = useCenterCodeList();

  const { filter, setSearchFilter, useQueryResult } = useSearchRequest<
    typeof INITIAL_MONTHLY_RECEIPTS_AND_PAYMENTS_FILTER,
    MonthlyReceiptsAndPayments[]
  >({
    initialFilter: INITIAL_MONTHLY_RECEIPTS_AND_PAYMENTS_FILTER,
    getRequest: ({ centerCode: _centerCode, standardDay }) => {
      const centerId = centerCodeList?.find(({ centerCode }) => centerCode === _centerCode)?.id;

      return {
        url: `/stats/monthly/centers/${centerId}?month-of-record=${standardDay}`,
      };
    },
    options: {
      onSuccess(data) {
        setList(data);
      },
      onSettled() {
        searchProgressingModal.resetDialog();
      },
    },
  });

  return {
    filter,
    useQueryResult,
    setSearchFilter,
    list,
    isCompleted: !useQueryResult.isFetching && useQueryResult.isSuccess,
  };
};
