import styled from 'styled-components';

import Flex from '@Components/Flex';

export const CautionBox = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding: 1rem;
  background: #ddf9ff;
  margin-bottom: 2rem;

  & li {
    list-style: inside;
    line-height: 2rem;
  }
`;

export const CautionListWrapper = styled.ul`
  margin: 1.6rem 0 0;
`;

export const ModalFormWrapper = styled(Flex)`
  margin: 2rem 0;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding: 2rem 0;
`;
