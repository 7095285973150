import { useRef, useState } from 'react';

import { client } from '@src/client';
import { addIndexingData } from '@src/Utils/helper';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { PageResponse } from '@src/Utils/type';
import { useQuery } from '@tanstack/react-query';

import { InboundDetailRow } from '../InboundDetail.type';

import { PostInboundDetailParams } from './type';

const postInboundDetailList = async (params: PostInboundDetailParams) => {
  const body = mapObject({ ...params }, nullIfEmptyOrALL);

  const { data } = await client.post<PageResponse<InboundDetailRow[]>>('/inbound-items/list', body);

  return data;
};

export const postInboundDetailExcelDownload = async (params: PostInboundDetailParams) => {
  const body = mapObject({ ...params }, nullIfEmptyOrALL);
  const { data } = await client.post<{ send: string }>('/inbound-items/excel-download', body);
  return data;
};

export const useInboundDetailListQuery = (params: PostInboundDetailParams) => {
  const totalCountRef = useRef(0);
  const [enabled, setEnabled] = useState(false);

  const [inboundDetailList, setInboundDetailList] = useState<InboundDetailRow[]>([]);

  useQuery(['inbound-detail', 'list', params], () => postInboundDetailList(params), {
    keepPreviousData: true,
    enabled,
    onSuccess: (data) => {
      const detailList = addIndexingData(data.results, params.pageNumber, params.pageSize);
      setInboundDetailList(detailList);
      totalCountRef.current = data.total;
    },
    onSettled: () => setEnabled(false),
  });

  const search = () => setEnabled(true);

  return { inboundDetailList, search, total: totalCountRef.current };
};
