import { ReactNode } from 'react';

import Flex from '@Components/Flex';
import { Text } from '@src/Components/Typography';

interface ResultHeaderProps {
  title: ReactNode;
  total?: number;
  right?: ReactNode;
}

function ResultHeader({ title, total = 0, right }: ResultHeaderProps) {
  return (
    <Flex justify='space-between' align='center'>
      <Flex align='center' gap={5}>
        {title}
        {total ? typeof total === 'number' && <span>- 총 {total}건</span> : null}
      </Flex>
      {typeof right === 'string' ? <Text>{right}</Text> : right}
    </Flex>
  );
}

export default ResultHeader;
