import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useMutation } from '@tanstack/react-query';

const cancelSendOutbound = async (outboundId: number) => {
  const { data } = await client.put(`/outbounds/transmission/${outboundId}/cancellation`);

  return data;
};

export const useCancelSendOutboundMutation = () => {
  return useMutation(cancelSendOutbound, {
    onSuccess() {
      toastify('전송취소되었습니다.');
    },
  });
};
