import { SelectOption } from '../Forms/Select';

export const getDependentChildOptions = ({
  parentOptions,
  childOptions,
  childValue,
}: {
  parentOptions: SelectOption[] | undefined;
  childOptions: (SelectOption & { parentId: number })[] | undefined;
  childValue: string;
}) => {
  return (
    childOptions?.filter(
      ({ parentId }) =>
        String(parentId) === parentOptions?.find(({ value }) => value === childValue)?.value
    ) || []
  );
};
