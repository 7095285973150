import { InputLabel, InputLabelProps } from '../Input';
import Suggestion, { SuggestionProps } from '../Suggestion';

export interface SuggestionInputLabelProps extends Omit<InputLabelProps, 'list'> {
  list: SuggestionProps['list'];
}

function SuggestionInputLabel({ id, list, ...props }: SuggestionInputLabelProps) {
  return (
    <>
      <InputLabel id={id} {...props} autoComplete='off' list={`${id}-suggestion`} />
      <Suggestion id={`${id}-suggestion`} list={list} />
    </>
  );
}

export default SuggestionInputLabel;
