import { InputHTMLAttributes } from 'react';

import Flex from '@Components/Flex';

import { Label, RadioButton } from './Radio.style';

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

function Radio({ label, id, disabled, ...rest }: RadioProps) {
  return (
    <Flex colGap={5} justify='center' align='center'>
      <RadioButton type='radio' id={id} disabled={disabled} {...rest} />
      {label && (
        <Label htmlFor={id} disabled={disabled}>
          {label}
        </Label>
      )}
    </Flex>
  );
}

export default Radio;
