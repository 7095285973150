import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

export type ExcelHistoryItem = {
  created_at: string;
  email: string;
  params: string;
  service: string;
  service_info: string;
  status_type: string;
  download_link: string;
  uuid: string;
  completed_at: string;
};

export const getExcelHistory = async (targetUrl: string) => {
  const { data } = await axios.get<ExcelHistoryItem[]>(
    `${process.env.REACT_APP_EXECUTE_API_URL}/wms/${targetUrl}/requests`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};

export const useExcelHistory = (targetUrl: string) => {
  const { data } = useQuery(['excel', 'history', targetUrl], () => getExcelHistory(targetUrl), {
    keepPreviousData: true,
  });

  return { data };
};
