import Box from '@Components/Box';
import { Text } from '@Components/Typography';
import { Color } from '@Components/Typography/Typography.type';

function StatusCell({ textColor, statusValue }: { textColor: Color; statusValue: string }) {
  return (
    <Box pl-10 pr-10 $width='100%'>
      <Text color={textColor} fontWeight={400} size={1.2}>
        {statusValue}
      </Text>
    </Box>
  );
}

export default StatusCell;
