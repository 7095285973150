import dayjs from 'dayjs';
import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

export const INITIAL_OUTBOUND_DETAIL_FILTER = {
  startAvailableDay: dayjs().subtract(3, 'd').format('YYYY-MM-DD'),
  endAvailableDay: dayjs().add(3, 'd').format('YYYY-MM-DD'),
  startCompleteDay: '',
  endCompleteDay: '',
  outboundCategory: 'ALL',
  outboundStatusCode: 'ALL',
  deliveryType: 'ALL',
  centerCode: '',
  productItemId: '',
  wokey: '',
  orderId: '',
  referenceCode: '',
};

export const OUTBOUND_DETAIL_LIST_PAGE_SIZE = 500;

export const OUTBOUND_DETAIL_LIST_COLUMNS = [
  { ...keyColumn('wokey', textColumn), title: '출고번호', disabled: true, minWidth: 140 },
  {
    ...keyColumn('outboundStatusCodeValue', textColumn),
    title: '상태',
    disabled: true,
    minWidth: 70,
  },
  {
    ...keyColumn('outboundCategoryValue', textColumn),
    title: '출고유형',
    disabled: true,
    minWidth: 100,
  },
  {
    ...keyColumn('deliveryTypeValue', textColumn),
    title: '배송유형',
    disabled: true,
    minWidth: 70,
  },
  { ...keyColumn('orderId', textColumn), title: '주문번호', disabled: true, minWidth: 80 },
  {
    ...keyColumn('referenceCode', textColumn),
    title: '참조번호',
    disabled: true,
    minWidth: 140,
  },
  {
    ...keyColumn('availableDay', textColumn),
    title: '출고예정일',
    disabled: true,
    minWidth: 90,
  },
  {
    ...keyColumn('completeDay', textColumn),
    title: '출고실적일',
    disabled: true,
    minWidth: 90,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '센터코드',
    disabled: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    ...keyColumn('centerName', textColumn),
    title: '센터명',
    disabled: true,
    minWidth: 160,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
    minWidth: 300,
  },

  { ...keyColumn('availableQuantity', intColumn), title: '출고예정수량', disabled: true },
  {
    ...keyColumn('receivedQuantity', intColumn),
    title: '출고실적수량',
    disabled: true,
  },
  {
    ...keyColumn('invoiceParcel', textColumn),
    title: '운송사',
    disabled: true,
  },
  {
    ...keyColumn('invoiceNumber', textColumn),
    title: '송장번호',
    disabled: true,
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    disabled: true,
    minWidth: 380,
  },
  {
    ...keyColumn('createdUserName', textColumn),
    title: '등록자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 130,
  },
  {
    ...keyColumn('updatedUserName', textColumn),
    title: '수정자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
    minWidth: 130,
  },
];

export const EXCEL_HISTORY_GRID_COLUMNS = [
  {
    ...keyColumn('status_type', textColumn),
    title: '요청 상태',
    disabled: true,
    minWidth: 80,
  },
  {
    ...keyColumn('name', textColumn),
    title: '이름',
    disabled: true,
    minWidth: 180,
  },
  {
    ...keyColumn('created_at', textColumn),
    title: '요청날짜',
    disabled: true,
    minWidth: 180,
  },
  {
    ...keyColumn('download_link', textColumn),
    title: '다운로드 링크',
    disabled: true,
    minWidth: 80,
  },
];
