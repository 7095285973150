import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { OUTBOUND_STATUS_CODE_COLOR, OutboundRow } from './constant';

export function OutboundStatusCell({ rowData }: { rowData: OutboundRow }) {
  const { outboundStatusCodeValue } = rowData;

  const textColor = OUTBOUND_STATUS_CODE_COLOR[
    outboundStatusCodeValue as keyof typeof OUTBOUND_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={outboundStatusCodeValue} textColor={textColor} />;
}
