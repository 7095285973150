import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@Constants/grid';

export const INBOUND_RESULT_LIST_COLUMNS = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('wikey', textColumn),
    title: '입고번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('inboundStatusCodeValue', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('inboundCategoryValue', textColumn),
    title: '입고유형',
    disabled: true,
  },
  {
    ...keyColumn('transportTypeValue', textColumn),
    title: '운송유형',
    disabled: true,
  },
  {
    ...keyColumn('productSupplyRequestId', textColumn),
    title: '발주번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('referenceCode', textColumn),
    title: '참조번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('availableDay', textColumn),
    title: '입고예정일',
    disabled: true,
  },
  { ...keyColumn('completeDay', textColumn), title: '입고실적일', disabled: true },
  { ...keyColumn('centerCode', textColumn), title: '센터코드', disabled: true },
  { ...keyColumn('centerName', textColumn), title: '센터명', disabled: true, minWidth: 150 },
  { ...keyColumn('vendorId', textColumn), title: '거래처코드', disabled: true },
  {
    ...keyColumn('vendorDisplayName', textColumn),
    title: '거래처명',
    disabled: true,
    minWidth: 150,
  },
  { ...keyColumn('productItemCount', textColumn), title: '품목수', disabled: true },
  { ...keyColumn('allCount', textColumn), title: '총수량', disabled: true },
  { ...keyColumn('productItemId', textColumn), title: '품목코드', disabled: true },
  { ...keyColumn('productItemName', textColumn), title: '품목명', disabled: true, minWidth: 300 },
  { ...keyColumn('availableQuantity', textColumn), title: '입고예정수량', disabled: true },
  { ...keyColumn('firstInboundQuantity', textColumn), title: '입고실적수량', disabled: true },
  { ...keyColumn('adjustQuantity', textColumn), title: '실적조정수량', disabled: true },
  { ...keyColumn('receivedQuantity', textColumn), title: '조정결과수량', disabled: true },
  {
    ...keyColumn('inboundAdjustStatusCodeValue', textColumn),
    title: '실적조정상태',
    disabled: true,
  },
  { ...keyColumn('adjustReasonValue', textColumn), title: '조정사유', disabled: true },
  { ...keyColumn('itemRemark', textColumn), title: '비고', disabled: true, minWidth: 200 },
  { ...keyColumn('createdUserName', textColumn), title: '등록자', disabled: true, minWidth: 120 },
  { ...keyColumn('createdAt', textColumn), title: '등록일시', disabled: true, minWidth: 120 },
  { ...keyColumn('updatedUserName', textColumn), title: '수정자', disabled: true, minWidth: 120 },
  { ...keyColumn('updatedAt', textColumn), title: '수정일시', disabled: true, minWidth: 120 },
];

export const INITIAL_INBOUND_REQUEST_LIST_FILTER = {
  startAvailableDay: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
  endAvailableDay: dayjs().format('YYYY-MM-DD'),
  startCompleteDay: '',
  endCompleteDay: '',
  inboundCategory: 'ALL',
  centerCode: '',
  vendorId: '',
  wikey: '',
  productItemId: '',
  productSupplyRequestId: '',
  referenceCode: '',
};

export const INBOUND_REQUEST_STATUS_CODE_COLOR = {
  '실적조정 요청': 'blue.500',
  '실적조정 완료': 'green.500',
  '실적조정 반려': 'red.500',
  미등록: 'orange.500',
};
