import { keyColumn, textColumn } from 'react-datasheet-grid';

export const CENTER_CODE_GRID_OPTIONS_COLUMNS = {
  columns: [
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('activated', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('name', textColumn),
      title: '센터명',
      disabled: true,
    },
    {
      ...keyColumn('type', textColumn),
      title: '센터유형',
      disabled: true,
    },
  ],
};
