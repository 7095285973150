import { useState } from 'react';

import { useFullScreenActivityIndicator } from '@Hooks';
import { client } from '@src/client';
import { useQuery } from '@tanstack/react-query';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';

import { StockInquireRow } from '../StockInquire.type';

import { PostStockListParams } from './type';

export const postStockList = async (params: PostStockListParams) => {
  const { data } = await client.post<StockInquireRow[]>(
    '/stocks/list',
    mapObject({ ...params }, nullIfEmptyOrALL)
  );
  return data;
};

export const useStockListQuery = (params: PostStockListParams) => {
  const { hideActivityIndicator } = useFullScreenActivityIndicator();
  const [stockList, setStockList] = useState<StockInquireRow[]>([]);

  const { refetch } = useQuery(['stock-list', params], () => postStockList(params), {
    keepPreviousData: true,
    enabled: false,
    onSuccess: (data) => {
      setStockList(data);
    },
    onSettled: () => {
      hideActivityIndicator();
    },
  });

  return { stockList, refetch };
};

export const postStocksExcelDownload = async (params: PostStockListParams) => {
  const { data } = await client.post<{ send: string }>(
    '/stocks/excel-download',
    mapObject({ ...params }, nullIfEmptyOrALL)
  );

  return data;
};
