export const OUTBOUND_CATEGORY_OPTIONS = [
  { label: '전체', value: '' },
  { label: 'APP', value: 'APP' },
  { label: '외부몰', value: 'OUTSIDE_MALL' },
  { label: '사전예약', value: 'ADVANCE_RESERVATION' },
  { label: 'B2B', value: 'B2B' },
  { label: '교환', value: 'EXCHANGE' },
  { label: 'CS - APP', value: 'APP_CUSTOMER_SERVICE' },
  { label: 'CS - 외부몰', value: 'OUTSIDE_MALL_CUSTOMER_SERVICE' },
  { label: 'CS - 사전예약', value: 'ADVANCE_RESERVATION_CUSTOMER_SERVICE' },
  { label: 'CS - B2B', value: 'B2B_CUSTOMER_SERVICE' },
  { label: '유통가공', value: 'PROCESSING' },
  { label: '유통가공 - 사료샘플', value: 'PROCESSING_FEED_SAMPLE' },
  { label: '재고이동', value: 'MOVE' },
  { label: '재고조정', value: 'ADJUST' },
  { label: '거래처 - 반품', value: 'VENDOR_RETURN' },
  { label: '기타 - OFFICE', value: 'ETC_OFFICE' },
  { label: '기타 - 타택배', value: 'ANOTHER_COURIER' },
  { label: '기타-업체배송', value: 'ETC_VENDOR_DELIVERY' },
  { label: '기타', value: 'ETC' },
];

export const DELIVERY_TYPE_OPTIONS = [
  { label: '전체', value: 'ALL' },
  { label: '당일배송', value: 'EXPRESS' },
  { label: '새벽배송', value: 'MORNING' },
  { label: '택배배송', value: 'PARCEL' },
  { label: '차량배송', value: 'TRUCK' },
  { label: '기타', value: 'ETC' },
];

export const OUTBOUND_STATUS_CODE = [
  { label: '전체', value: 'ALL' },
  { label: '출고예정', value: 'DRAFT' },
  { label: '전송완료', value: 'SENT' },
  { label: '출고완료', value: 'COMPLETED' },
  { label: '출고취소', value: 'CANCELED' },
];
