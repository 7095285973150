import styled, { css } from 'styled-components';

import { flexRow } from '@Styles/utils/flex';

export const HeaderWrapper = styled.header`
  ${flexRow('space-between', 'center')};
  color: #fff;

  ${({ theme }) => {
    const {
      spaces,
      colors: { gray },
      sizes,
    } = theme;
    return css`
      padding: 0 ${spaces[1.6]};
      background-color: ${gray[800]};
      border-bottom: 0.1rem solid ${gray[900]};

      h1 {
        margin-right: ${spaces[2.4]};
        font-size: ${sizes[2.4]};
      }

      > div:last-child {
        font-size: ${sizes[1.2]};
      }
    `;
  }}
`;

export const LogoutButton = styled.span`
  cursor: pointer;
`;
