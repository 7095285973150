import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

export const PRODUCT_ITEM_TYPE_OPTIONS = [
  { value: 'ALL', label: '전체' },
  { value: 'GENERAL', label: '일반' },
  { value: 'PRIVATE_BRAND', label: 'PB' },
  { value: 'INDEPENDENT', label: '단독' },
  { value: 'SUBSIDIARY', label: '부자재' },
] as const;

export const STOCK_STATUS_OPTIONS = [
  { value: 'ALL', label: '전체' },
  { value: 'KEEP', label: '판매유지' },
  { value: 'SOLD_OUT', label: '일시품절' },
  { value: 'WAITING', label: '신상품대기' },
  { value: 'EXPECTED_SALE_END', label: '판매종료예정' },
  { value: 'SALE_END', label: '판매종료' },
  { value: 'ERROR', label: '등록오류' },
  { value: 'ETC', label: '기타' },
] as const;

export const INITIAL_PRODUCT_LIST_FORM_FIELD = {
  productItemType: 'ALL',
  stockStatus: 'ALL',
  vendorId: '',
  productItemName: '',
  productItemId: '',
} as const;

export const INITIAL_PRODUCT_LIST_DEPENDENT_SELECT = {
  productGroup1Id: 'ALL',
  productGroup2Id: 'ALL',
  productGroup3Id: 'ALL',
} as const;

export const PRODUCT_LIST_COLUMNS = [
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemType', textColumn),
    title: '품목유형',
    disabled: true,
  },
  {
    ...keyColumn('productStockType', textColumn),
    title: '상품재고유형',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
    minWidth: 300,
  },
  {
    ...keyColumn('stockStatusValue', textColumn),
    title: '재고상태',
    disabled: true,
  },
  {
    ...keyColumn('productGroup1Name', textColumn),
    title: '대분류',
    disabled: true,
  },
  {
    ...keyColumn('productGroup2Name', textColumn),
    title: '중분류',
    disabled: true,
  },
  {
    ...keyColumn('productGroup3Name', textColumn),
    title: '소분류',
    disabled: true,
  },
  {
    ...keyColumn('productGroup4Name', textColumn),
    title: '세분류',
    disabled: true,
  },
  {
    ...keyColumn('vendorId', textColumn),
    title: '거래처코드',
    disabled: true,
  },
  {
    ...keyColumn('vendorName', textColumn),
    title: '거래처명',
    disabled: true,
    minWidth: 200,
  },
  {
    ...keyColumn('itemBarcode', textColumn),
    title: '품목 바코드',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('boxBarcode', textColumn),
    title: '박스 바코드',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('boxUnit', intColumn),
    title: '박스(EA)',
    minWidth: 150,
    disabled: true,
  },
  {
    ...keyColumn('palletUnit', intColumn),
    title: '팔레트(EA)',
    minWidth: 150,
    disabled: true,
  },
  {
    ...keyColumn('outboundDivisionUnit', intColumn),
    title: '단수/단포(EA)',
    minWidth: 150,
    disabled: true,
  },
  {
    ...keyColumn('width', intColumn),
    title: '가로 (cm)',
    disabled: true,
  },
  {
    ...keyColumn('length', intColumn),
    title: '세로 (cm)',
    disabled: true,
  },
  {
    ...keyColumn('height', intColumn),
    title: '높이 (cm)',
    disabled: true,
  },
  {
    ...keyColumn('weight', intColumn),
    title: '중량 (kg)',
    disabled: true,
  },
  {
    ...keyColumn('volume', intColumn),
    title: '용량 (ml)',
    disabled: true,
  },
  {
    ...keyColumn('storageTemperature', textColumn),
    title: '보관온도',
    disabled: true,
  },
  {
    ...keyColumn('imminentStockConversionDays', textColumn),
    title: '임박전환(일)',
    minWidth: 120,
    disabled: true,
  },
  {
    ...keyColumn('noShippingConversionDays', textColumn),
    title: '출고불가(일)',
    minWidth: 120,
    disabled: true,
  },
  {
    ...keyColumn('createdUserName', textColumn),
    title: '등록자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedUserName', textColumn),
    title: '수정자',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
    minWidth: 120,
  },
];
