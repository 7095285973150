import { checkboxColumn, keyColumn, textColumn } from 'react-datasheet-grid';

export const ACTIVE_COLUMN = {
  ...keyColumn('active', checkboxColumn),
  title: '✔️',
  maxWidth: 40,
  minWidth: 40,
};

export const CUSTOM_GUTTER_COLUMN = {
  ...keyColumn('no', textColumn),
  title: 'No.',
  minWidth: 60,
  maxWidth: 60,
};
