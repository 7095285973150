import style, { css } from 'styled-components';

import { convertRem } from '@Styles/utils/calc';

import { FlexProps } from './Flex.type';

export const FlexContainer = style.div<FlexProps>`
    display: flex;

    ${({ align, direction, wrap, justify, gap, rowGap, colGap, width }) => {
      const [gapRow, gapCol] = [gap ?? rowGap, gap ?? colGap];
      return css`
        width: ${width};
        align-items: ${align};
        flex-direction: ${direction};
        flex-wrap: ${wrap};
        justify-content: ${justify};
        row-gap: ${gapRow && convertRem(gapRow)};
        column-gap: ${gapCol && convertRem(gapCol)};
      `;
    }}
`;
