import { Resetter } from 'recoil';

import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import Separator from '@Components/Separator';
import { Heading } from '@Components/Typography';
import { useFormField } from '@Hooks';
import ModalFooter from '@src/Layout/components/ModalFooter';

function UserRoleModal({ close, add }: { close: Resetter; add: (name: string) => void }) {
  const { form, attributes } = useFormField({
    userGroupCode: '',
    userGroupName: '',
    userRoleName: '',
  });

  const userRoleNameValidation = (value: string) => value.length < 50;

  const save = () => {
    if (!userRoleNameValidation(form.userRoleName)) {
      return false;
    }
    add(form.userRoleName);
  };

  return (
    <>
      <Flex direction='column' align='flex-start' rowGap={20}>
        <Heading>역할 등록</Heading>
        <Flex gap={20} wrap='wrap'>
          <InputLabel
            label='역할코드**'
            {...attributes.userGroupCode}
            placeholder='자동채번'
            readOnly
          />
          <InputLabel
            label='사용자그룹명'
            {...attributes.userGroupName}
            placeholder='활성화'
            readOnly
          />
        </Flex>
        <Separator />
        <InputLabel
          label='역할명*'
          {...attributes.userRoleName}
          validation={userRoleNameValidation}
          span='50자를 초과할수없습니다.'
        />
      </Flex>
      <ModalFooter>
        <Button onClick={save}>저장</Button>
        <Button onClick={close}>취소</Button>
      </ModalFooter>
    </>
  );
}

export default UserRoleModal;
