import { useSetRecoilState } from 'recoil';

import { ActivityIndicatorProps } from '@Components/ActivityIndicator/ActivityIndicator.type';
import { fullScreenActivityIndicatorAtom } from '@src/Stores/ActivityIndicator/fullScreenActivityIndicator.atom';

export function useFullScreenActivityIndicator() {
  const setActivityIndicatorState = useSetRecoilState(fullScreenActivityIndicatorAtom);

  const showActivityIndicator = (options?: Omit<ActivityIndicatorProps, 'isVisible'>) => {
    setActivityIndicatorState({ isVisible: true, ...options });
  };

  const hideActivityIndicator = () => {
    setActivityIndicatorState({ isVisible: false });
  };

  return { showActivityIndicator, hideActivityIndicator };
}
