import { CenterCodeRow } from '@src/Components/Modals/CenterCodeModal/CenterCodeModal.type';

import { completeOutboundParams } from './apis/useCompleteOutboundMutation';
import { UpdateOutboundListParams } from './apis/useUpdateOutboundListMutation';
import { OutboundItemRow, OutboundRow } from './constant';

export const getUpdateOutboundListParams = ({
  outboundList,
  selectedOutboundListItem,
  outboundItemList,
  centerCodeList,
}: {
  outboundList: OutboundRow[];
  selectedOutboundListItem: OutboundRow | undefined;
  outboundItemList: OutboundItemRow[];
  centerCodeList: CenterCodeRow[] | undefined;
}): UpdateOutboundListParams => {
  const outboundUpdateDtos = outboundList.map(
    ({
      outboundId,
      availableDay,
      centerCode,
      vendorId,
      recipient,
      zipcode,
      address,
      detailAddress,
      mainMobileNo,
      subMobileNo,
      message,
      enterMethod,
    }) => ({
      outboundId,
      availableDay,
      centerId:
        (centerCodeList?.find((item) => item.centerCode === centerCode)?.id as number) ?? null,
      vendorId,
      recipient,
      zipcode,
      address,
      detailAddress,
      mainMobileNo,
      subMobileNo,
      message,
      enterMethod,
    })
  );

  const outboundItemUpdateListDto =
    selectedOutboundListItem && outboundItemList.length !== 0
      ? {
          outboundId: selectedOutboundListItem.outboundId,
          outboundItemUpdateDtos: outboundItemList.map(
            ({ outboundItemId, availableQuantity, itemRemark }) => ({
              outboundItemId,
              availableQuantity,
              outboundItemRemark: itemRemark,
            })
          ),
        }
      : null;

  return { outboundUpdateDtos, outboundItemUpdateListDto };
};

export const getCompleteOutboundParams = ({
  outbound,
  outboundItemList,
  mainInvoiceNumber,
  completeDay,
}: {
  outbound: OutboundRow;
  outboundItemList: OutboundItemRow[];
  mainInvoiceNumber: string;
  completeDay: string;
}): completeOutboundParams => {
  const outboundItemCompleteDtos = outboundItemList.map(
    ({ outboundItemId, receivedQuantity, parcelValue, invoiceNumber, itemRemark }) => ({
      outboundItemId,
      receivedQuantity,
      invoiceParcel: parcelValue,
      invoiceNumber,
      outboundItemRemark: itemRemark,
    })
  );

  return {
    outboundId: outbound?.outboundId,
    outboundDetail: {
      completeDay,
      mainInvoiceNumber: mainInvoiceNumber || null,
      outboundItemCompleteDtos,
    },
  };
};
