import dayjs from 'dayjs';
import styled from 'styled-components';

import type { ExcelHistoryItem } from './apis/type';

const LinkText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 1rem;

  & > a {
    text-decoration: underline;
    color: #1b64da;
  }
`;

export function DownLoadLinkCell({ rowData }: { rowData: ExcelHistoryItem }) {
  const completedAt = rowData.completed_at;

  // completedAt + 12시간 이 지나면 다운 못 받음.
  const isDisabled = completedAt
    ? new Date() > new Date(dayjs(rowData.completed_at).add(12, 'hour').toDate())
    : true;

  if (isDisabled && rowData.status_type === '완료') return <LinkText>기한 만료</LinkText>;

  return (
    <LinkText>
      {rowData.download_link && (
        <a href={rowData.download_link} download>
          다운로드
        </a>
      )}
    </LinkText>
  );
}
