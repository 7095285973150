import { InputHTMLAttributes } from 'react';

import Flex from '@Components/Flex';

import { CheckBox, Label } from './Check.style';

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

function Check({ label, id, disabled, checked, ...rest }: CheckBoxProps) {
  return (
    <Flex colGap={5} justify='center' align='center'>
      <CheckBox type='checkbox' id={id} disabled={disabled} checked={checked} {...rest} />
      {label && (
        <Label htmlFor={id} disabled={disabled} checked={checked}>
          {label}
        </Label>
      )}
    </Flex>
  );
}

export default Check;
