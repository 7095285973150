import { useState } from 'react';

import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import Pagination from '@Components/Pagination';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import { useDialog, useFormField } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';
import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { INVALID_OUTBOUND_FILTER_MESSAGE } from '@src/Constants/outbound';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import { performIfNotEmpty, performIfValidFilter } from '@src/Utils/helper';

import OutboundResultInquireFilter from './components/OutboundResultInquireFilter';
import { postOutboundsRecordsExcelDownload, useOutboundsRecordsListQuery } from './apis';
import { GRID_OPTIONS, INITIAL_OUTBOUND_RESULT_INQUIRE_FILTER_FORM } from './constant';
import { OutboundResultInquireStatusCell } from './OutboundResultInquireCell';

const PAGE_SIZE = 500;

function OutboundResultInquire() {
  const { columns } = GRID_OPTIONS;

  const { form, attributes, setForm } = useFormField(INITIAL_OUTBOUND_RESULT_INQUIRE_FILTER_FORM);
  const [pageNumber, setPageNumber] = useState(0);
  const { data: centerCodeList } = useCenterCodeList();
  const centerId = centerCodeList?.find((item) => item.centerCode === form.centerCode)?.id || null;

  const { openDialog } = useDialog('CenterCodeModal');

  const { refetch, outboundRecordList, total } = useOutboundsRecordsListQuery({
    ...form,
    centerId,
    pageSize: PAGE_SIZE,
    pageNumber,
  });
  const { downloadExcel } = useMassiveExcelRequest(postOutboundsRecordsExcelDownload);

  const openCenterCodeModal = () => {
    openDialog({
      title: '선택기',
      content: (
        <CenterCodeModal
          selectCenterCode={(row) => {
            setForm((prev) => ({
              ...prev,
              centerCode: row.centerCode ?? '',
            }));
          }}
        />
      ),
    });
  };

  const search = () => {
    refetch();
  };

  const setPagination = (page: number) => {
    setPageNumber(page);
    search();
  };

  return (
    <section>
      <Flex direction='column'>
        <OutboundResultInquireFilter
          attributes={attributes}
          openCenterModal={openCenterCodeModal}
          search={performIfValidFilter({
            filter: form,
            callback: search,
            message: INVALID_OUTBOUND_FILTER_MESSAGE,
          })}
          excel={performIfNotEmpty(
            outboundRecordList,
            () => downloadExcel({ ...form, centerId, pageSize: PAGE_SIZE, pageNumber: 0 }),
            '조회된 데이터가 없습니다'
          )}
          form={form}
        />
        <Flex direction='column' rowGap={10}>
          <ResultHeader title='출고 실적내역' total={total} />
          <DataSheetGrid
            columns={columns}
            value={outboundRecordList}
            gutterColumn={CUSTOM_GUTTER_COLUMN}
            customRendererList={[
              { id: 'outboundStatusCodeValue', component: OutboundResultInquireStatusCell },
            ]}
          />
          <Flex justify='center'>
            <Pagination
              total={total}
              pageSize={PAGE_SIZE}
              currentPageIndex={pageNumber}
              onPageIndexChange={setPagination}
            />
          </Flex>
        </Flex>
      </Flex>
    </section>
  );
}

export default OutboundResultInquire;
