import { atom, DefaultValue, selector } from 'recoil';

import {
  ProcessingGridColumn,
  ProductItem,
} from '@Features/ProcessedCirculateManage/ProcessedCirculateHistory.type';
import { IndexingData, PageResponse } from '@Utils/type';

type ProductListState = PageResponse<(ProductItem & { itemNo: number })[]>;

type Pageable<T> = T & { page: number };

export interface ProcessedCirculateDefaultState {
  processingState: Pageable<PageResponse<IndexingData<ProcessingGridColumn>[]>>;
  productListState: Pageable<ProductListState>;
}

export const processedCirculateAtom = atom<ProcessedCirculateDefaultState>({
  key: 'processedCirculateAtom',
  default: {
    processingState: {
      results: [],
      limit: 0,
      offset: 0,
      total: 0,
      empty: false,
      page: 0,
    },
    productListState: {
      results: [],
      limit: 0,
      offset: 0,
      total: 0,
      empty: false,
      page: 0,
    },
  },
});

export const processingStateSelector = selector<
  Pageable<PageResponse<IndexingData<ProcessingGridColumn>[]>>
>({
  key: 'processingStateSelector',
  get: ({ get }) => get(processedCirculateAtom).processingState,
  set: ({ get, set }, newValue) =>
    newValue instanceof DefaultValue
      ? set(processedCirculateAtom, newValue)
      : set(processedCirculateAtom, {
          ...get(processedCirculateAtom),
          processingState: newValue,
        }),
});

export const processingListSelector = selector<IndexingData<ProcessingGridColumn>[]>({
  key: 'processingListSelector',
  get: ({ get }) => get(processedCirculateAtom).processingState.results,
  set: ({ get, set }, newValue) =>
    newValue instanceof DefaultValue
      ? set(processedCirculateAtom, newValue)
      : set(processedCirculateAtom, {
          ...get(processedCirculateAtom),
          processingState: {
            ...get(processedCirculateAtom).processingState,
            results: newValue,
          },
        }),
});

export const selectedProcessingSelector = selector<ProcessingGridColumn[]>({
  key: 'selectedProcessingSelector',
  get: ({ get }) =>
    get(processedCirculateAtom).processingState.results.filter((item) => item.active),
});
