import { client } from '@src/client';
import { useQuery } from '@tanstack/react-query';
import { Nullable } from '@Utils/type';

interface UserMenu {
  id: string;
  parentId: Nullable<string>;
  title: string;
  frontFileName: Nullable<string>;
  level: number;
  sort: number;
  roleId: string;
  permission: number;
  children: Nullable<UserMenu[]>;
}

interface UserRole {
  menuId: string;
  title: string;
  permission: number;
  selectPermission: boolean;
  updatePermission: boolean;
}

interface UserMenuAndRoleListResult {
  userMenuList: UserMenu[];
  userRoleList: {
    [key: string]: UserRole;
  };
}

const fetchUserMenuAndRoleList = async ({ userId }: { userId: number }) => {
  const { data } = await client.get<UserMenuAndRoleListResult>(`/menus/users/${userId}`);
  return data;
};

export const useUserMenuAndRoleListQuery = ({ userId }: { userId: number }) => {
  return useQuery(['menus', 'users', userId], () => fetchUserMenuAndRoleList({ userId }), {
    notifyOnChangeProps: ['data'],
    staleTime: Infinity,
  });
};
