import { ReactNode } from 'react';

import Center from '@src/Components/Center';
import Flex from '@src/Components/Flex';

interface GridLayoutProps {
  header: ReactNode;
  grid: ReactNode;
  pagination?: ReactNode;
}

function GridLayout({ header, grid, pagination }: GridLayoutProps) {
  return (
    <Flex direction='column' gap={10}>
      {header}
      {grid}
      {pagination && <Center>{pagination}</Center>}
    </Flex>
  );
}

export default GridLayout;
