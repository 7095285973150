import dayjs from 'dayjs';
import { Resetter } from 'recoil';

import Box from '@Components/Box';
import DataSheetGrid from '@Components/DataSheetGrid';
import { DatePickerLabel } from '@Components/Date/DatePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { toastify } from '@Components/Toast';
import { Heading } from '@Components/Typography';
import { useFormField } from '@Hooks';
import FormField from '@Layout/components/FormField';
import ModalFooter from '@Layout/components/ModalFooter';
import ResultHeader from '@Layout/components/ResultHeader';
import ModalWrapper from '@src/Layout/components/ModalWrapper';
import { performIfConfirmed } from '@Utils/helper';
import { Nullable } from '@Utils/type';

import { useCompleteOutboundMutation } from '../../apis/useCompleteOutboundMutation';
import { useOutboundItemListQuery } from '../../apis/useOutboundItemListQuery';
import { OutboundRow } from '../../constant';
import { getCompleteOutboundParams } from '../../util';

import { INITIAL_STATE, OUTBOUND_COMPLETION_COLUMNS } from './constant';

function CompletionModal({
  selectedOutboundId,
  item,
  close,
  search,
}: {
  selectedOutboundId: Nullable<number>;
  item: OutboundRow;
  close: Resetter;
  search: () => void;
}) {
  const { form, attributes } = useFormField({ ...INITIAL_STATE, wokey: item.wokey ?? '' });

  const { list: outboundItemList, setList: setOutboundItemList } =
    useOutboundItemListQuery(selectedOutboundId);
  const completeOutboundMutation = useCompleteOutboundMutation();

  const save = () => {
    if (!form.completeDay) {
      toastify('출고실적일은 필수값입니다.', { type: 'error' });
      return;
    }

    for (const row of outboundItemList) {
      if (!row.receivedQuantity) {
        toastify('출고실적수량은 필수값입니다.', { type: 'error' });
        return;
      }
    }

    performIfConfirmed('입력한 정보대로 완료처리하시겠습니까?', async () => {
      await completeOutboundMutation.mutateAsync(
        getCompleteOutboundParams({
          outboundItemList,
          outbound: item,
          mainInvoiceNumber: form.mainInvoiceNumber,
          completeDay: dayjs(form.completeDay).format('YYYY-MM-DD'),
        })
      );
      close();
      search();
    });
  };

  return (
    <ModalWrapper>
      <div>
        <Heading>출고 완료 처리</Heading>
        <Box>
          <FormField>
            <InputLabel label='대상 출고번호' {...attributes.wokey} readOnly />
            <Flex align='flex-start' justify='flex-start' colGap={36} rowGap={10} wrap='wrap'>
              <DatePickerLabel label='출고실적일 *' {...attributes.completeDay} />
              <InputLabel label='대표 운송장 번호' {...attributes.mainInvoiceNumber} />
            </Flex>
          </FormField>
          <Flex direction='column' rowGap={16}>
            <ResultHeader title='품목코드 내역' total={outboundItemList.length} />
            <DataSheetGrid
              value={outboundItemList}
              columns={OUTBOUND_COMPLETION_COLUMNS}
              onChange={setOutboundItemList}
            />
          </Flex>
        </Box>
      </div>
      <ModalFooter>
        <Button onClick={save}>저장</Button>
        <Button onClick={close}>취소</Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default CompletionModal;
