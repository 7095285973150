import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { ProcessingGridColumn } from '../../ProcessedCirculateHistory.type';

import { SEARCH_PROCESSING_STATUS_CODE_COLOR } from './constant';

export function ProcessedCirculateManageResultCell({ rowData }: { rowData: ProcessingGridColumn }) {
  const { processingStatusCodeValue } = rowData;

  const textColor = processingStatusCodeValue
    ? (SEARCH_PROCESSING_STATUS_CODE_COLOR[
        processingStatusCodeValue as keyof typeof SEARCH_PROCESSING_STATUS_CODE_COLOR
      ] as Color)
    : 'gray.500';

  return <StatusCell statusValue={processingStatusCodeValue ?? ''} textColor={textColor} />;
}
