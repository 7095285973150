import { toastify } from '@src/Components/Toast';

import { type GetShelfLifeStocksListParams } from './apis/useShelfLifeList';

export const validateSearch =
  ({
    filter,
    callback,
    message,
  }: {
    filter: GetShelfLifeStocksListParams;
    callback: VoidFunction;
    message: string;
  }) =>
  () => {
    if (!filter.dateOfRecord) {
      toastify(message, { type: 'error' });
    } else {
      callback();
    }
  };
