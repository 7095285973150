import { ChangeEventHandler, useEffect, useState } from 'react';

import styled from 'styled-components';

import Flex from '@Components/Flex';
import { disabled, inputStyle } from '@Styles/constant';

import { WithLabel, WithLabelProps } from '../WithLabel';

import { InputProps } from './Input.type';

const NUMBER_ONLY_INPUTS = ['vendorId', 'zipcode', 'productItemId', 'itemBarcode'];
const CONTACT_INPUTS = ['mobileNo', 'telNo', 'contact'];

export function Input({
  id,
  type = 'text',
  name,
  value,
  onChange,
  readOnly,
  validation,
  span,
  withButton,
  ...props
}: InputProps) {
  const [error, setError] = useState('');

  useEffect(() => {
    if (!value || !validation) {
      setError('');
      return;
    }

    if (validation(value)) {
      setError('');
    } else {
      setError(span || '');
    }
  }, [value, span]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (name) {
      if (NUMBER_ONLY_INPUTS.includes(name) && /[^0-9]/.test(event.target.value)) {
        return;
      }

      if (CONTACT_INPUTS.includes(name) && /[^0-9-]/.test(event.target.value)) {
        return;
      }
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Flex direction='column' rowGap={8}>
      <InputContainer
        id={id}
        type={type}
        name={name}
        className={`${error ? 'error' : ''} ${withButton ? 'with' : ''}`}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
        {...props}
      />
      {error && <Error>{error}</Error>}
    </Flex>
  );
}

export type InputLabelProps = Omit<WithLabelProps, 'children'> & InputProps;

export function InputLabel({ label, ...props }: InputLabelProps) {
  return (
    <WithLabel label={label}>
      <Input {...props} />
    </WithLabel>
  );
}

const InputContainer = styled.input`
  ${inputStyle};
  &:read-only {
    ${disabled}
  }
`;

const Error = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.sizes[1.2]};
  color: ${({ theme }) => theme.colors.red[300]};
`;

export default Input;
