import { keyColumn, textColumn } from 'react-datasheet-grid';

export const VENDOR_CODE_GRID_OPTIONS_COLUMNS = {
  columns: [
    {
      ...keyColumn('vendorId', textColumn),
      title: '거래처코드',
      disabled: true,
    },
    {
      ...keyColumn('displayName', textColumn),
      title: '거래처명',
      disabled: true,
    },
    {
      ...keyColumn('petfriendsManagerName', textColumn),
      title: '펫프 담당자',
      disabled: true,
    },
    {
      ...keyColumn('vendorManagerName', textColumn),
      title: '거래처 담당자',
      disabled: true,
    },
  ],
};
