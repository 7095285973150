import { toastify } from '@Components/Toast';

import { OutboundItemRow, OutboundRow } from './constant';

export const removeOutBoundValidation = (target: OutboundRow, itemList: OutboundItemRow[]) => {
  if (target?.outboundStatusCodeValue !== '출고예정') {
    toastify('품목 추가/삭제는 <출고예정> 상태만 가능합니다.', { type: 'error' });
    return false;
  }
  if (!itemList.length) {
    toastify('선택한 품목이 존재하지 않습니다.', { type: 'error' });
    return false;
  }
  return true;
};

export const sendOutBoundValidation = (targetList: OutboundRow[]) => {
  for (const row of targetList) {
    if (row.outboundCategoryValue && ['재고조정', '유통가공'].includes(row.outboundCategoryValue)) {
      toastify('전송할 수 없는 출고유형 입니다.', { type: 'error' });
      return false;
    }

    if (row.outboundStatusCodeValue !== '출고예정') {
      toastify('전송할 수 없는 상태입니다. 해당 출고의 상태를 확인해 주세요.', { type: 'error' });
      return false;
    }
  }

  return true;
};

export const cancelOutBoundValidation = (targetList: OutboundRow[]) => {
  for (const row of targetList) {
    if (
      row.outboundCategoryValue &&
      ['재고조정', '유통가공', '거래처 - 반품'].includes(row.outboundCategoryValue)
    ) {
      toastify('취소처리 할 수 없는 출고유형 입니다.', { type: 'error' });
      return false;
    }

    if (row.outboundStatusCodeValue !== '출고예정') {
      toastify('취소처리 할 수 없는 상태입니다. 해당 출고의 상태를 확인해주세요.', {
        type: 'error',
      });
      return false;
    }
  }

  return true;
};

export const cancelSendOutBoundValidation = (targetList: OutboundRow[]) => {
  if (targetList.length !== 1) {
    toastify('전송취소는 1건만 처리할 수 있습니다.', { type: 'error' });
    return false;
  }

  for (const row of targetList) {
    if (
      row.outboundCategoryValue &&
      ['재고조정', '유통가공', '교환'].includes(row.outboundCategoryValue)
    ) {
      toastify('전송취소할 수 없는 출고유형 입니다.', { type: 'error' });
      return false;
    }

    if (row.outboundStatusCodeValue !== '전송완료') {
      toastify('전송취소할 수 없는 상태입니다. 해당 출고의 상태를 확인해주세요.', {
        type: 'error',
      });
      return false;
    }
  }

  return true;
};

export const completeOutBoundValidation = (targetList: OutboundRow[]) => {
  if (targetList.length !== 1) {
    toastify('완료처리는 1건만 처리할 수 있습니다.', { type: 'error' });
    return false;
  }

  const [item] = targetList;

  if (item.outboundCategoryValue && ['교환'].includes(item.outboundCategoryValue)) {
    toastify('완료할 수 없는 출고유형 입니다.', { type: 'error' });
    return;
  }

  if (item.outboundStatusCodeValue !== '전송완료') {
    toastify('전송완료 상태에서만 완료처리할 수 있습니다.', { type: 'error' });
    return false;
  }

  return true;
};
