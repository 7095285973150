import { Resetter } from 'recoil';

import Box from '@Components/Box';
import CautionBox from '@Components/CautionBox';
import DataSheetGrid from '@Components/DataSheetGrid';
import { DatePickerLabel } from '@Components/Date/DatePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import Radio from '@Components/Forms/Radio';
import { SelectLabel } from '@Components/Forms/Select';
import { WithLabel } from '@Components/Forms/WithLabel';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import { CenterCodeRow } from '@Components/Modals/CenterCodeModal/CenterCodeModal.type';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import ExcelFileUpload from '@Features/InboundHistory/components/InboundHistoryNewModal/ExcelFileUpload';
import { ErrorCell } from '@Features/InboundHistory/components/InboundHistoryNewModal/InboundHistoryNewModal.style';
import { useDialog, useFullScreenActivityIndicator } from '@Hooks';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import ModalFooter from '@src/Layout/components/ModalFooter';

import { AdjustItem } from '../../apis/type';

import {
  CAUTION_BOX_MESSAGES,
  STOCK_ADJUST_GRID_OPTIONS,
  STOCK_ADJUST_REASON_NEW_OPTIONS,
} from './constant';
import { useStockAdjustNewModalMutation, useStockAdjustNewModalProductList } from './queries';

function StockAdjustNewModal({
  reset,
  id,
  stockAdjustRefetch,
}: {
  reset: Resetter;
  id?: number;
  stockAdjustRefetch: VoidFunction;
}) {
  const { columns } = STOCK_ADJUST_GRID_OPTIONS;

  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');

  const { form, attributes, setForm, adjustItem, setAdjustItem } =
    useStockAdjustNewModalProductList(id);
  const {
    setIsPass,
    isPass,
    addProduct,
    excelUpload,
    checkValidation,
    saveProductItem,
    modifyProductItem,
  } = useStockAdjustNewModalMutation({ setAdjustItem, reset, setForm, stockAdjustRefetch });

  const { showActivityIndicator } = useFullScreenActivityIndicator();

  const activatedList = adjustItem.filter((it) => it.active);

  const addProductItem = () => {
    if (!form.centerId) {
      toastify('센터코드는 필수값입니다.', { type: 'error' });
      return;
    }

    if (!form.availableDay) {
      toastify('[예정일]은 필수값입니다.', { type: 'error' });
      return;
    }

    const targetItem = adjustItem.map((it) => ({
      productItemId: it.productItemId,
      adjustQuantity: it.adjustQuantity,
      remark: it.remark,
    }));
    const params = { ...form, items: !targetItem.length ? null : targetItem };
    addProduct({ ...params, id });
    setForm((prev) => ({ ...prev, newProductItemIds: '' }));
  };

  const openCenterCode = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: <CenterCodeModal selectCenterCode={setCenterCode} />,
      size: 'medium',
    });
  };

  const setCenterCode = (rowData: CenterCodeRow) => {
    const { centerCode } = rowData;
    setForm((prev) => ({
      ...prev,
      centerCode: centerCode ?? '',
    }));
  };

  const onFileUpload = (file: FileList) => {
    setIsPass(false);
    const formData = new FormData();
    formData.append('file', file[0]);
    excelUpload(formData);
  };

  const onRadioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setForm((prev) => ({
      ...prev,
      category: value,
    }));
    setAdjustItem([]);
  };

  const check = () => {
    if (!adjustItem.length) {
      toastify('데이터가 존재하지 않습니다.', { type: 'error' });
      return;
    }

    if (!form.availableDay) {
      toastify('예정일은 필수값입니다.', { type: 'error' });
      return;
    }

    if (!form.centerCode) {
      toastify('센터코드는 필수값입니다.', { type: 'error' });
      return;
    }

    for (const row of adjustItem) {
      if (!row.adjustQuantity) {
        toastify('[조정수량]은 필수값입니다.', { type: 'error' });
        return;
      }
    }

    const targetItem = adjustItem.map((it) => ({
      productItemId: it.productItemId,
      adjustQuantity: it.adjustQuantity === null ? null : Number(it.adjustQuantity),
      remark: it.remark,
    }));
    const params = {
      ...form,
      items: !targetItem.length ? null : targetItem,
      newProductItemIds: undefined,
      id,
    };
    checkValidation(params);
  };

  const remove = () => {
    if (!activatedList.length) {
      toastify('선택된 재고가 존재하지 않습니다.', { type: 'error' });
      return false;
    }
    const target = activatedList.map((it) => it.productItemId);
    const remainItems = adjustItem.filter((it) => !target.includes(it.productItemId));
    const items = remainItems.map(({ productItemId }) => productItemId);

    if (!items.length) {
      setAdjustItem([]);
    } else
      addProduct({
        ...form,
        newProductItemIds: '',
        items: remainItems,
        id,
      });
  };

  const save = () => {
    const params = {
      ...form,
      items: adjustItem.map((it) => ({
        ...it,
        adjustQuantity: it.adjustQuantity === null ? null : Number(it.adjustQuantity),
        remark: it.remark,
      })),
    };

    if (!params.items.length) {
      toastify('저장할 내용이 없습니다', { type: 'error' });
      return;
    }

    if (params.items.filter((it) => it.result === null).length) {
      toastify('체크 먼저 해주세요.', { type: 'error' });
      return;
    }

    if (params.items.filter((it) => it.result !== 'Success').length) {
      toastify('유효성 확인해주세요.', { type: 'error' });
      return;
    }

    if (window.confirm(!id ? '저장하시겠습니까?' : '모든 변경사항을 저장하시겠습니까?')) {
      showActivityIndicator();
      if (id) {
        const body = { ...params, id };
        modifyProductItem(body);
      } else {
        saveProductItem(params);
      }
    }
  };

  const renderCheckMessage = ({ rowData }: { rowData: AdjustItem }) => {
    return (
      <ErrorCell bg={rowData.result}>
        <Text color='gray.900' fontWeight={400} size={1.2}>
          {rowData.result}
        </Text>
      </ErrorCell>
    );
  };

  return (
    <Flex direction='column' css='height:100%'>
      <div css='flex:1'>
        <CautionBox title='참고사항' messages={CAUTION_BOX_MESSAGES} />
        <FormField>
          <Flex rowGap={10} direction='column'>
            <WithLabel label='구분'>
              <Flex align='flex-start' colGap={10}>
                <Radio
                  label='입고'
                  id='inbound'
                  name='category'
                  value='INBOUND'
                  onChange={onRadioHandler}
                  checked={form.category === 'INBOUND'}
                />
                <Radio
                  label='출고'
                  id='outbound'
                  name='category'
                  value='OUTBOUND'
                  onChange={onRadioHandler}
                  checked={form.category === 'OUTBOUND'}
                />
              </Flex>
            </WithLabel>
            <InputButtonLabel
              label='센터코드'
              buttonText='검색'
              readOnly={false}
              buttonClick={openCenterCode}
              {...attributes.centerCode}
            />
            <SelectLabel
              label='조정사유'
              options={STOCK_ADJUST_REASON_NEW_OPTIONS}
              {...attributes.adjustReason}
            />
          </Flex>

          <Flex rowGap={10} direction='column'>
            <DatePickerLabel label='예정일' {...attributes.availableDay} />

            <Flex colGap={5} css='height:100%'>
              <TextAreaLabel {...attributes.newProductItemIds} label='품목코드' placeholder='' />
              <Button size='x-small' onClick={addProductItem}>
                추가
              </Button>
            </Flex>
          </Flex>

          <WithLabel label='파일 업로드'>
            <ExcelFileUpload onFileUpload={onFileUpload} fileName='업로드_재고조정등록' />
          </WithLabel>
        </FormField>
        <Box mt-30 mb-30>
          <Flex direction='column' rowGap={16}>
            <ResultHeader
              title='조정내역 등록'
              right={
                <Button size='x-small' onClick={remove}>
                  삭제
                </Button>
              }
            />
            <DataSheetGrid
              columns={columns}
              value={adjustItem}
              onChange={(value) => {
                setIsPass(false);
                setAdjustItem(value.map((item) => ({ ...item, result: '' })));
              }}
              customRendererList={[
                {
                  id: 'result',
                  component: renderCheckMessage,
                },
              ]}
            />
          </Flex>
        </Box>
      </div>
      <ModalFooter>
        <Button onClick={check}>체크</Button>
        <Button onClick={save} disabled={!isPass}>
          저장
        </Button>
        <Button onClick={reset}>취소</Button>
      </ModalFooter>
    </Flex>
  );
}

export default StockAdjustNewModal;
