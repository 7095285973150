import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INITIAL_STATE = {
  completeDay: '',
  mainInvoiceNumber: '',
  wokey: '',
};

export const OUTBOUND_COMPLETION_COLUMNS = [
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
    minWidth: 300,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', textColumn),
    title: '출고예정수량',
    disabled: true,
  },
  {
    ...keyColumn('receivedQuantity', textColumn),
    title: '출고실적수량',
  },
  {
    ...keyColumn('parcelValue', textColumn),
    title: '운송사',
    minWidth: 120,
  },
  {
    ...keyColumn('invoiceNumber', textColumn),
    title: '송장번호',
    minWidth: 120,
  },
  {
    ...keyColumn('itemRemark', textColumn),
    title: '비고',
    minWidth: 200,
  },
];
