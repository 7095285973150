import { useState } from 'react';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { useDialog } from '@src/Hooks';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { Nullable } from '@src/Utils/type';

import { INITIAL_DAILY_RECEIPTS_AND_PAYMENTS_FILTER } from './DailyReceiptsAndPayments';

export interface ReceiptsAndPayment {
  endDifference: Nullable<number>;
  inoutDifference: Nullable<number>;
  productItemId: number;
  productItemName: string;
  outboundAppMorningQuantity: number;
  outboundAppExpressQuantity: number;
  outboundAppLogisticsQuantity: number;
  outboundOutsideMallQuantity: number;
  outboundAdvanceReservationQuantity: number;
  outboundB2bFreightQuantity: number;
  outboundB2bLogisticsQuantity: number;
  outboundAppCsMorningQuantity: number;
  outboundAppCsExpressQuantity: number;
  outboundAppCsLogisticsQuantity: number;
  outboundOutsideMallCsQuantity: number;
  outboundAdvanceReservationCsQuantity: number;
  outboundB2bCsFreightQuantity: number;
  outboundB2bCsLogisticsQuantity: number;
  outboundProcessingQuantity: number;
  outboundProcessingFeedSampleQuantity: number;
  outboundMoveQuantity: number;
  outboundVendorReturnQuantity: number;
  outboundAdjustQuantity: number;
  outboundEtcQuantity: number;
  outboundAnotherCourierQuantity: number;
  outboundEtcOfficeQuantity: number;
  outboundTotalQuantity: number;
  inboundPurchaseQuantity: number;
  inboundExchangeQuantity: number;
  inboundB2cReturnQuantity: number;
  inboundB2bReturnQuantity: number;
  inboundProcessingQuantity: number;
  inboundProcessingFeedSampleQuantity: number;
  inboundMovingQuantity: number;
  inboundAdjustQuantity: number;
  inboundEtcQuantity: number;
  inboundTotalQuantity: number;
  appCsClaimMorningQuantity: number;
  appCsClaimExpressQuantity: number;
  appCsClaimParcelQuantity: number;
}

interface DailyReceiptsAndPayments extends ReceiptsAndPayment {
  endFromTotal: Nullable<number>;
  endToTotal: Nullable<number>;
}

export const useDailyReceiptsAndPaymentsQuery = () => {
  const [list, setList] = useState<DailyReceiptsAndPayments[]>([]);
  const loadingModal = useDialog('SearchProgressingModal');
  const { data: centerCodeList } = useCenterCodeList();

  const { filter, setSearchFilter, useQueryResult } = useSearchRequest<
    typeof INITIAL_DAILY_RECEIPTS_AND_PAYMENTS_FILTER,
    DailyReceiptsAndPayments[]
  >({
    initialFilter: INITIAL_DAILY_RECEIPTS_AND_PAYMENTS_FILTER,
    getRequest: ({ centerCode: _centerCode, startDay, endDay }) => {
      const centerId = centerCodeList?.find(({ centerCode }) => centerCode === _centerCode)?.id;

      return {
        url: `/stats/daily/centers/${centerId}/dates`,
        params: {
          from: startDay,
          to: endDay,
        },
      };
    },
    options: {
      onSuccess(data) {
        setList(data);
      },
      onSettled() {
        loadingModal.resetDialog();
      },
    },
  });

  return {
    filter,
    useQueryResult,
    setSearchFilter,
    list,
    isCompleted: !useQueryResult.isFetching && useQueryResult.isSuccess,
  };
};
