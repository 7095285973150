import { useState } from 'react';

import styled from 'styled-components';

import { useUserRoleIdQuery } from '@Apis/useUserRoleIdQuery';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import UserRoleModal from '@Components/Modals/UserRoleModal';
import Separator from '@Components/Separator';
import { toastify } from '@Components/Toast';
import { useDialog, useFormField } from '@Hooks';
import ModalFooter from '@Layout/components/ModalFooter';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { flexColumn } from '@Styles/utils/flex';

import { useRegisterUserMutation } from './apis/useRegisterUserMutation';

const PASSWORD_REGEX =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[.)(`~=+,><\-/?;:'"_!@#$%^&*])[a-zA-Z0-9.)(`~=+,><\-/?;:'"_!@#$%^&*]{8,20}$/;

function UserRegisterModal({ refetch }: { refetch: VoidFunction }) {
  const [errors, setErrors] = useState({
    name: '',
    userEmail: '',
    password: '',
    passwordCheck: '',
    mobileNo: '',
    telNo: '',
  });
  const formField = useFormField({
    name: '',
    roleCode: '',
    userEmail: '',
    password: '',
    passwordCheck: '',
    mobileNo: '',
    telNo: '',
    position: '',
  });
  const userRegisterModal = useDialog('userRegister');
  const userRoleModal = useDialog('userRole');
  const registerUserMutation = useRegisterUserMutation();
  const userRoleId = useUserRoleIdQuery(formField.form.roleCode);

  const registerUser = async () => {
    const { name, userEmail, roleCode, password, passwordCheck, mobileNo, telNo } = formField.form;

    if (!name) {
      toastify('[사용자명]을 입력해주세요.', { type: 'error' });
      return;
    }
    if (!roleCode) {
      toastify('[역할코드]를 입력해주세요.', { type: 'error' });
      return;
    }
    if (!userEmail) {
      toastify('[ID(이메일)]를 입력해주세요.', { type: 'error' });
      return;
    }
    if (!password) {
      toastify('[비밀번호]를 입력해주세요.', { type: 'error' });
      return;
    }
    if (!passwordCheck) {
      toastify('[비밀번호 확인]을 입력해주세요.', { type: 'error' });
      return;
    }
    if (!mobileNo) {
      toastify('[핸드폰 번호]를 입력해주세요.', { type: 'error' });
      return;
    }

    if (
      !validators.name(name) ||
      !validators.userEmail(userEmail) ||
      !validators.password(password) ||
      !validators.passwordCheck(passwordCheck) ||
      !validators.mobileNo(mobileNo) ||
      (telNo && !validators.telNo(telNo))
    ) {
      return;
    }

    await registerUserMutation.mutateAsync({
      ...formField.form,
      roleId: userRoleId,
    });
    refetch();
  };

  const openUserRoleModal = () => {
    userRoleModal.openDialog({
      title: '선택기',
      content: (
        <UserRoleModal
          onSelect={({ roleCode }) => {
            formField.setForm((prevForm) => ({ ...prevForm, roleCode }));
            userRoleModal.resetDialog();
          }}
        />
      ),
    });
  };

  const validators: Record<keyof typeof errors, (value: string) => boolean> = {
    name(value) {
      if (value.length > 50) {
        setErrors((prevErrors) => ({ ...prevErrors, name: '50자를 초과할 수 없습니다.' }));
        return false;
      }

      return true;
    },
    userEmail(value) {
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, userEmail: '올바른 형식이 아닙니다.' }));
        return false;
      }

      return true;
    },
    password(value) {
      if (!PASSWORD_REGEX.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: '8~20자의 영문+숫자+특수문자 조합으로 입력해 주세요.',
        }));
        return false;
      }

      return true;
    },
    passwordCheck(value) {
      if (!PASSWORD_REGEX.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          passwordCheck: '8~20자의 영문+숫자+특수문자 조합으로 입력해 주세요.',
        }));
        return false;
      }
      if (formField.form.password !== value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          passwordCheck: '일치하지 않습니다. 다시 입력해 주세요.',
        }));
        return false;
      }

      return true;
    },
    mobileNo(value) {
      if (value.length > 13) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobileNo: '13자를 초과할 수 없습니다.',
        }));
        return false;
      }
      if (!/^010-[0-9]{4}-[0-9]{4}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobileNo: '010-1234-1234와 같은 형식으로 입력해 주세요.',
        }));
        return false;
      }

      return true;
    },
    telNo(value) {
      if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          telNo: '올바른 형식이 아닙니다.',
        }));
        return false;
      }
      if (value.length > 13) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          telNo: '13자를 초과할 수 없습니다.',
        }));
        return false;
      }

      return true;
    },
  };

  return (
    <Wrapper>
      <Flex direction='column' gap={10}>
        <InputLabel label='사용자코드 **' defaultValue='자동채번' disabled />
        <InputLabel label='상태' defaultValue='활성화' disabled />
        <Separator />

        <InputLabel
          {...formField.attributes.name}
          label='사용자명 *'
          validation={validators.name}
          span={errors.name}
        />
        <InputButtonLabel
          {...formField.attributes.roleCode}
          label='역할코드 *'
          buttonClick={openUserRoleModal}
        />
        <InputLabel
          {...formField.attributes.userEmail}
          label='ID(이메일) *'
          validation={validators.userEmail}
          span={errors.userEmail}
        />

        <Flex colGap={60} rowGap={10} align='flex-start' wrap='wrap'>
          <InputLabel
            {...formField.attributes.password}
            label='비밀번호 *'
            validation={validators.password}
            span={errors.password}
          />
          <InputLabel
            {...formField.attributes.passwordCheck}
            label='비밀번호 확인 *'
            validation={validators.passwordCheck}
            span={errors.passwordCheck}
          />
        </Flex>

        <InputLabel
          {...formField.attributes.mobileNo}
          label='핸드폰 번호 *'
          validation={validators.mobileNo}
          span={errors.mobileNo}
        />
        <InputLabel
          {...formField.attributes.telNo}
          label='전화번호'
          validation={validators.telNo}
          span={errors.telNo}
        />
        <InputLabel {...formField.attributes.position} label='직위/직책' />
      </Flex>

      <ModalFooter>
        <Button onClick={registerUser}>저장</Button>
        <Button onClick={userRegisterModal.resetDialog}>취소</Button>
      </ModalFooter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${flexColumn('space-between')};
  height: 100%;
`;

export default UserRegisterModal;
