import styled from 'styled-components';

import Center from '../Center';
import { Text } from '../Typography';

function SearchCompleteBox() {
  return (
    <Wrapper>
      <Text color='gray.500'>조회가 완료됐습니다.</Text>
    </Wrapper>
  );
}

const Wrapper = styled(Center)`
  border-radius: ${({ theme }) => theme.radiuses['0.2']};
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[300]};
  padding: ${({ theme }) => theme.spaces['2.4']} 0;
`;

export default SearchCompleteBox;
