import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import { FormFieldAttributes } from '@Hooks';
import FormField from '@Layout/components/FormField';

import { STATUS_CODE_OPTIONS } from '../../constant';

interface CenterManageFilterProps {
  attributes: FormFieldAttributes<'status' | 'centerId'>;
}

export function CenterManageFilter({ attributes }: CenterManageFilterProps) {
  return (
    <FormField>
      <SelectLabel label='상태' {...attributes.status} options={STATUS_CODE_OPTIONS} />
      <InputLabel label='센터코드' {...attributes.centerId} />
    </FormField>
  );
}
