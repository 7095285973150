import { SyntheticEvent } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Cross2Icon } from '@radix-ui/react-icons';
import { currentTabAtom, TabItemAtom } from '@Stores/Tab/tab.atom';
import { tabState, updateCurrentTab } from '@Stores/Tab/tab.selector';

import { TabContainer, TabItem } from './Tab.style';

function Tab() {
  const tabs = useRecoilValue(tabState) as TabItemAtom[];
  const deleteTab = useSetRecoilState(updateCurrentTab);
  const [currentTab, setCurrentTab] = useRecoilState(currentTabAtom);

  const close = (e: SyntheticEvent, tab: TabItemAtom) => {
    e.stopPropagation();
    deleteTab(tab);
  };

  return (
    <TabContainer>
      {tabs.map((tab) => (
        <TabItem
          key={tab.id}
          className={currentTab.id === tab.id ? 'current' : ''}
          onClick={() => setCurrentTab(tab)}
        >
          <span>{tab.title}</span>
          <button type='button' onClick={(e) => close(e, tab)}>
            <Cross2Icon />
          </button>
        </TabItem>
      ))}
    </TabContainer>
  );
}

export default Tab;
