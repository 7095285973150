import StatusCell from '@src/Components/Cell/StatusCell';
import { type Color } from '@src/Components/Typography/Typography.type';

import { type ClaimRow } from './apis/useClaimInquireList';
import { CLAIM_STATUS_CODE_COLOR } from './constant';

export function ClaimStatusCell({ rowData }: { rowData: ClaimRow }) {
  const { status } = rowData;

  const textColor = CLAIM_STATUS_CODE_COLOR[
    status as keyof typeof CLAIM_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={status} textColor={textColor} />;
}
