import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { Nullable } from '@src/Utils/type';
import { useMutation } from '@tanstack/react-query';

import { OutboundItem } from './useOutboundItemListQuery';
import { Outbound } from './useOutboundListQuery';

interface OutboundUpdateDto
  extends Pick<
    Outbound,
    | 'outboundId'
    | 'availableDay'
    | 'vendorId'
    | 'recipient'
    | 'zipcode'
    | 'address'
    | 'detailAddress'
    | 'mainMobileNo'
    | 'subMobileNo'
    | 'message'
    | 'enterMethod'
  > {
  centerId: Nullable<number>;
}

interface OutboundItemUpdateDto extends Pick<OutboundItem, 'outboundItemId' | 'availableQuantity'> {
  outboundItemRemark: OutboundItem['itemRemark'];
}

interface OutboundItemUpdateListDto {
  outboundId: number;
  outboundItemUpdateDtos: OutboundItemUpdateDto[];
}

export interface UpdateOutboundListParams {
  outboundUpdateDtos: OutboundUpdateDto[];
  outboundItemUpdateListDto: Nullable<OutboundItemUpdateListDto>;
}

const updateOutboundList = async (params: UpdateOutboundListParams) => {
  const { data } = await client.put<{ outboundIds: number[]; outboundItemIds: number[] }>(
    '/outbounds',
    params
  );

  return data;
};

export const useUpdateOutboundListMutation = () => {
  return useMutation(updateOutboundList, {
    onSuccess() {
      toastify('저장되었습니다.');
    },
  });
};
