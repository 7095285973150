import { keyColumn, textColumn } from 'react-datasheet-grid';

import { ACTIVE_COLUMN } from '@src/Constants/grid';

export const USER_ROLE_COLUMNS = [
  ACTIVE_COLUMN,
  {
    ...keyColumn('roleCode', textColumn),
    title: '역할코드',
    disabled: true,
  },
  {
    ...keyColumn('roleName', textColumn),
    title: '역할명',
  },
  {
    ...keyColumn('groupCode', textColumn),
    title: '사용자그룹코드',
    disabled: true,
  },
  {
    ...keyColumn('createdMemberName', textColumn),
    title: '등록자',
    disabled: true,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
  },
  {
    ...keyColumn('updatedMemberName', textColumn),
    title: '수정자',
    disabled: true,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '수정일시',
    disabled: true,
  },
];
