import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import { RowInfo } from '@Components/DataSheetGrid/DataSheetGrid.type';
import Flex from '@Components/Flex';
import Pagination from '@Components/Pagination';
import { CUSTOM_GUTTER_COLUMN } from '@Constants/grid';
import { GridValue } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';

import { InboundGridIndexingData } from '../../apis/mutations';
import { INBOUND_GRID_OPTIONS } from '../../constant';
import { CenterCodeCell, DateCell, InboundStatusCell } from '../../InboundHistoryCell';

const PAGE_SIZE = 100;

function InboundHistoryGrid({
  inboundList,
  setInboundList,
  inboundRowChangeHandler,
  selectProductId,
  inboundListTotal,
  pageNumber,
  setPageNumber,
}: {
  inboundList: InboundGridIndexingData[];
  setInboundList: GridValue<InboundGridIndexingData>['setGridList'];
  inboundRowChangeHandler: GridValue<InboundGridIndexingData>['rowChangeHandler'];
  selectProductId: (rowInfo: RowInfo) => void;
  inboundListTotal: number;
  pageNumber: number;
  setPageNumber: (num: number) => void;
}) {
  const { columns } = INBOUND_GRID_OPTIONS;

  return (
    <>
      <ResultHeader title='입고내역' total={inboundListTotal} />
      <DataSheetGrid
        {...mappingAllCheck(columns, inboundList, setInboundList)}
        value={inboundList}
        onActiveChange={selectProductId}
        onChange={inboundRowChangeHandler}
        gutterColumn={CUSTOM_GUTTER_COLUMN}
        customRendererList={[
          { id: 'inboundStatusCodeValue', component: InboundStatusCell },
          { id: 'centerCode', component: CenterCodeCell },
          { id: 'availableDay', component: DateCell },
        ]}
      />
      <Flex justify='center'>
        <Pagination
          total={inboundListTotal}
          pageSize={PAGE_SIZE}
          currentPageIndex={pageNumber}
          onPageIndexChange={setPageNumber}
        />
      </Flex>
    </>
  );
}

export default InboundHistoryGrid;
