import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@src/Components/Typography/Typography.type';

import { USER_GROUP_AND_ROLE_STATUS_CODE_COLOR } from './constant';
import { UserGroupRow } from './UserGroupAndRoleManage.type';

export function UserGroupAndRoleManageStatusCell({ rowData }: { rowData: UserGroupRow }) {
  const { activated } = rowData;

  const textColor = USER_GROUP_AND_ROLE_STATUS_CODE_COLOR[
    activated as keyof typeof USER_GROUP_AND_ROLE_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={activated} textColor={textColor} />;
}
