import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import { useFormField, useFullScreenActivityIndicator } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';
import { performIfNotEmpty } from '@src/Utils/helper';

import VendorInquireFilter from './components/VendorInquireFilter';
import { useExcelVendorList, useVendorListQuery } from './apis';
import { INITIAL_STATE, VENDOR_INQUIRE_GRID_OPTIONS } from './constant';

function VendorInquire() {
  const { columns } = VENDOR_INQUIRE_GRID_OPTIONS;
  const { showActivityIndicator } = useFullScreenActivityIndicator();
  const { form, attributes } = useFormField(INITIAL_STATE);

  const { vendorList, refetch } = useVendorListQuery(form);
  const { downloadExcel } = useExcelVendorList();

  const search = () => {
    showActivityIndicator();
    refetch();
  };

  const downloadVendorList = () => {
    downloadExcel(form);
  };

  return (
    <div>
      <VendorInquireFilter
        search={search}
        attributes={attributes}
        downloadExcel={performIfNotEmpty(
          vendorList,
          downloadVendorList,
          '조회된 데이터가 없습니다'
        )}
      />
      <Flex direction='column' rowGap={10}>
        <ResultHeader title='거래처 내역' total={vendorList.length} />
        <DataSheetGrid value={vendorList} columns={columns} />
      </Flex>
    </div>
  );
}

export default VendorInquire;
