import { keyColumn, textColumn } from 'react-datasheet-grid';

export const EXCEL_HISTORY_GRID_COLUMNS = [
  {
    ...keyColumn('status_type', textColumn),
    title: '요청 상태',
    disabled: true,
    minWidth: 80,
  },
  {
    ...keyColumn('name', textColumn),
    title: '이름',
    disabled: true,
    minWidth: 180,
  },
  {
    ...keyColumn('created_at', textColumn),
    title: '요청날짜',
    disabled: true,
    minWidth: 180,
  },
  {
    ...keyColumn('download_link', textColumn),
    title: '다운로드 링크',
    disabled: true,
    minWidth: 80,
  },
];
