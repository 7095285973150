export const FormsProperties = {
  STATUS_CODE_OPTIONS: [
    { label: '전체', value: 'ALL' },
    { label: '거래중', value: 'Y' },
    { label: '중단', value: 'N' },
  ],
  PURCHASE_TYPE_OPTIONS: [
    { label: '전체', value: 'ALL' },
    { label: '매입', value: 'PURCHASE' },
    { label: '업체배송', value: 'VENDOR' },
    { label: '판매자심쿵', value: 'CONSIGNMENT' },
  ],
};
