import styled, { css } from 'styled-components';

import type { ButtonColors, ButtonSizes, ButtonVariants } from './Button';

export const StyledButton = styled.button<{
  size: ButtonSizes;
  color: ButtonColors;
  variant: ButtonVariants;
  withInput?: boolean;
}>`
  border-radius: ${({ theme }) => theme.radiuses[0.4]};
  padding: 0 ${({ theme }) => theme.spaces[1.6]};
  color: white;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  transition: background-color, color 0.15s ease;
  word-break: keep-all;
  background: #fff;

  ${({ size }) =>
    ({
      'x-small': css`
        height: 2.6rem;
        font-size: 1.4rem;
      `,
      small: css`
        height: 3.2rem;
        font-size: 1.4rem;
      `,
      medium: css`
        height: 3.6rem;
        font-size: 1.4rem;
      `,
      large: css`
        height: 4.2rem;
        font-size: 1.6rem;
      `,
    }[size])}

  ${({ color, variant, theme: { colors } }) =>
    ({
      primary: css`
        border: 0.1rem solid ${colors[color][500]};
        background-color: ${colors[color][500]};

        &:hover {
          border: 0.1rem solid ${colors[color][700]};
          background-color: ${colors[color][700]};
        }

        &:disabled {
          border: 0.1rem solid ${colors[color][100]};
          background-color: ${colors[color][100]};
        }
      `,
      secondary: css`
        border: 0.1rem solid ${colors[color][50]};
        background-color: ${colors[color][50]};
        color: ${colors[color][500]};

        &:hover {
          border: 0.1rem solid ${colors[color][100]};
          background-color: ${colors[color][100]};
        }

        &:disabled {
          border: 0.1rem solid ${colors[color][50]};
          background-color: ${colors[color][50]};
          color: ${colors[color][100]};
        }
      `,
      outline: css`
        border: 0.1rem solid ${colors[color][300]};
        color: ${colors[color][500]};

        &:hover {
          background-color: ${colors[color][50]};
        }

        &:disabled {
          background-color: white;
          border: 0.1rem solid ${colors[color][100]};
          color: ${colors.gray[200]};
        }
      `,
    }[variant])}

  ${({
    withInput,
    theme: {
      colors: { gray },
    },
  }) =>
    withInput &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
      border-color: ${gray[400]};
      color: ${gray[400]};
    `}
`;
