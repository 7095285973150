import {
  BoundType,
  PostUpdateValidateProcessingParams,
  ProcessingBothBoundsType,
  ProcessingGridItem,
  ProcessingListParams,
  UpdateProcessingGridItem,
} from '@Features/ProcessedCirculateManage/ProcessedCirculateHistory.type';
import { client } from '@src/client';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { PageResponse } from '@Utils/type';

export const getProcessingList = async ({
  startRequestDay,
  endRequestDay,
  processingCode,
  wkey,
  productItemId,
  pageNumber,
  pageSize,
}: ProcessingListParams) => {
  const { data } = await client.post<PageResponse<ProcessingGridItem[]>>(
    '/processing/list',
    mapObject(
      {
        startRequestDay,
        endRequestDay,
        processingCode,
        wkey,
        productItemId,
        pageNumber,
        pageSize,
      },
      nullIfEmptyOrALL
    )
  );

  return data;
};

type ProcessingValidatedBothBounds<I, O> = ProcessingBothBoundsType<I, O> & {
  validationResult: 'Success' | 'Fail';
};

export const postProcessingExcel = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await client.post<
    ProcessingValidatedBothBounds<
      UpdateProcessingGridItem<BoundType.INBOUND>,
      UpdateProcessingGridItem<BoundType.OUTBOUND>
    >
  >('/processing/excel-upload', formData);
  return data;
};

const PROCESSING_CATEGORY = {
  유통가공: 'PROCESSING',
} as const;

const addProcessingCategoryProperty = (
  list:
    | UpdateProcessingGridItem<BoundType.INBOUND>[]
    | UpdateProcessingGridItem<BoundType.OUTBOUND>[]
    | Partial<UpdateProcessingGridItem<BoundType.INBOUND>>[]
    | Partial<UpdateProcessingGridItem<BoundType.OUTBOUND>>[]
) => {
  return list.map((item) => {
    return {
      ...item,
      processingCategory:
        PROCESSING_CATEGORY[item.processingCategoryValue as keyof typeof PROCESSING_CATEGORY],
    };
  });
};

export const postAddValidateProcessing = async ({
  inbounds,
  outbounds,
}: ProcessingBothBoundsType<
  UpdateProcessingGridItem<BoundType.INBOUND>,
  UpdateProcessingGridItem<BoundType.OUTBOUND>
>) => {
  const { data } = await client.post<
    ProcessingValidatedBothBounds<
      UpdateProcessingGridItem<BoundType.INBOUND>,
      UpdateProcessingGridItem<BoundType.OUTBOUND>
    >
  >('/processing/validation', {
    inbounds: addProcessingCategoryProperty(inbounds),
    outbounds: addProcessingCategoryProperty(outbounds),
  });

  return data;
};

export const postUpdateValidateProcessing = async ({
  inbounds,
  outbounds,
  processingId,
}: PostUpdateValidateProcessingParams) => {
  const { data } = await client.post<
    ProcessingValidatedBothBounds<
      UpdateProcessingGridItem<BoundType.INBOUND>,
      UpdateProcessingGridItem<BoundType.OUTBOUND>
    >
  >(`/processing/validation/${processingId}`, {
    inbounds: addProcessingCategoryProperty(inbounds),
    outbounds: addProcessingCategoryProperty(outbounds),
  });

  return data;
};

export const postProcessingCreate = async (
  params: ProcessingBothBoundsType<
    Partial<UpdateProcessingGridItem<BoundType.INBOUND>>,
    Partial<UpdateProcessingGridItem<BoundType.OUTBOUND>>
  >
) => {
  const { data } = await client.post<number>('/processing', params);

  return data;
};

export const putProcessingUpdate = async (
  processingId: number,
  {
    inbounds,
    outbounds,
  }: ProcessingBothBoundsType<
    Partial<UpdateProcessingGridItem<BoundType.INBOUND>>,
    Partial<UpdateProcessingGridItem<BoundType.OUTBOUND>>
  >
) => {
  const { data } = await client.put(`/processing/${processingId}`, {
    inbounds: addProcessingCategoryProperty(inbounds),
    outbounds: addProcessingCategoryProperty(outbounds),
  });

  return data;
};

export const putProcessingCancel = async (ids: number[]) => {
  const { data } = await client.put(`/processing/cancellation`, { ids });

  return data;
};

export const putProcessingTransmit = async (ids: number[]) => {
  const { data } = await client.put(`/processing/transmission`, { ids });

  return data;
};
