import styled from 'styled-components';

export const BreadcrumbWrapper = styled.ul`
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  border-radius: ${({ theme }) => theme.radiuses[0.3]};
  display: inline-flex;
  overflow: hidden;
`;

export const BreadcrumbItem = styled.li`
  background: #fff;
  color: ${({ theme }) => theme.colors.gray[800]};
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  outline: none;
  font-size: ${({ theme }) => theme.sizes[1.4]};
  height: 3.8rem;
  line-height: 3.8rem;
  padding-left: 2.6rem;
  padding-right: 1.4rem;
  position: relative;
  text-decoration: none;
  transition: background 0.2s linear;

  &::after,
  &::before {
    background: white;
    bottom: 0;
    clip-path: polygon(50% 50%, -50% -50%, 0 100%);
    content: '';
    left: 100%;
    position: absolute;
    top: 0;
    transition: background 0.2s linear;
    width: 2rem;
    z-index: 1;
  }

  &::before {
    background: ${({ theme }) => theme.colors.gray[400]};
    margin-left: 0.1rem;
  }

  &.active {
    border-right: none;
    background: ${({ theme }) => theme.colors.gray[100]};
  }
`;
