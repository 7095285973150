import { useState } from 'react';

import dayjs from 'dayjs';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import DataSheetGrid from '@src/Components/DataSheetGrid';
import { DateRangePickerLabel } from '@src/Components/Date/DateRangePicker';
import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import { InputLabel } from '@src/Components/Forms/Input';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { SelectLabel } from '@src/Components/Forms/Select';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import CenterCodeModal from '@src/Components/Modals/CenterCodeModal';
import VendorCodeModal from '@src/Components/Modals/VendorCodeModal';
import Pagination from '@src/Components/Pagination';
import { CUSTOM_GUTTER_COLUMN } from '@src/Constants/grid';
import {
  INBOUND_CATEGORY_OPTIONS,
  INBOUND_STATUS_CODE_OPTIONS,
  INVALID_INBOUND_FILTER_MESSAGE,
} from '@src/Constants/inbound';
import { useDialog, useFormField } from '@src/Hooks';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import FilterHeader from '@src/Layout/components/FilterHeader';
import FormField from '@src/Layout/components/FormField';
import ResultHeader from '@src/Layout/components/ResultHeader';
import { performIfValidFilter } from '@Utils/helper';

import { postInboundDetailExcelDownload, useInboundDetailListQuery } from './apis';
import {
  INBOUND_DETAIL_GRID_OPTIONS,
  INBOUND_DETAIL_INITIAL_STATE,
  INBOUND_DETAIL_PAGE_SIZE,
} from './constant';
import { InboundDetailStatusCell } from './InboundDetailCell';

function InboundDetail() {
  const { columns } = INBOUND_DETAIL_GRID_OPTIONS;
  const [pageNumber, setPageNumber] = useState(0);
  const { form, attributes, setForm } = useFormField(INBOUND_DETAIL_INITIAL_STATE);

  const { data: centerCodeList } = useCenterCodeList();
  const centerId = centerCodeList?.find((item) => item.centerCode === form.centerCode)?.id || null;

  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');
  const { openDialog: openVendorCodeDialog } = useDialog('VendorCodeModal');
  const { openDialog: openExcelHistoryDialog } = useExcelDownLoadHistory({
    targetUrl: 'inbound_item',
  });

  const { downloadExcel: excelDownloadMutation } = useMassiveExcelRequest(
    postInboundDetailExcelDownload
  );

  const { search, inboundDetailList, total } = useInboundDetailListQuery({
    ...form,
    centerId,
    pageNumber,
    pageSize: INBOUND_DETAIL_PAGE_SIZE,
  });

  const openCenterCode = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: (
        <CenterCodeModal
          selectCenterCode={({ centerCode }) => {
            if (!centerCode) return;
            setForm((prev) => ({
              ...prev,
              centerCode,
            }));
          }}
        />
      ),
      size: 'medium',
    });
  };

  const openVendorCode = () => {
    openVendorCodeDialog({
      title: '코드 선택',
      content: (
        <VendorCodeModal
          selectVendorCode={(rowData) =>
            setForm((prev) => ({ ...prev, vendorId: String(rowData.vendorId) }))
          }
        />
      ),
      size: 'medium',
    });
  };

  const openExcelHistory = () => {
    openExcelHistoryDialog();
  };

  const excelDownload = () => {
    excelDownloadMutation({ ...form, centerId, pageNumber: 0, pageSize: INBOUND_DETAIL_PAGE_SIZE });
  };

  const onSearchHandler = () => {
    setPageNumber(0);
    search();
  };

  const setPagination = (page: number) => {
    setPageNumber(page);
    search();
  };

  return (
    <div>
      <FilterHeader>
        <Button
          isPermissionRequired={false}
          onClick={performIfValidFilter({
            filter: form,
            callback: onSearchHandler,
            message: INVALID_INBOUND_FILTER_MESSAGE,
          })}
        >
          조회
        </Button>
        <Button
          isPermissionRequired={false}
          onClick={performIfValidFilter({
            filter: form,
            callback: excelDownload,
            message: INVALID_INBOUND_FILTER_MESSAGE,
          })}
        >
          엑셀
        </Button>
        <Button isPermissionRequired={false} onClick={openExcelHistory}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>
      <FormField>
        <Flex direction='column' rowGap={10}>
          <DateRangePickerLabel
            label='입고예정일'
            start={{
              ...attributes.startAvailableDay,
              minDate: new Date(
                dayjs(form.endAvailableDay).subtract(3, 'M').add(1, 'd').format('YYYY-MM-DD')
              ),
            }}
            end={{
              ...attributes.endAvailableDay,
              maxDate: new Date(
                dayjs(form.startAvailableDay).add(3, 'M').subtract(1, 'd').format('YYYY-MM-DD')
              ),
            }}
          />
          <DateRangePickerLabel
            label='입고실적일'
            start={{
              ...attributes.startCompleteDay,
              minDate: new Date(
                dayjs(form.endCompleteDay).subtract(3, 'M').add(1, 'd').format('YYYY-MM-DD')
              ),
            }}
            end={{
              ...attributes.endCompleteDay,
              maxDate: new Date(
                dayjs(form.startCompleteDay).add(3, 'M').subtract(1, 'd').format('YYYY-MM-DD')
              ),
            }}
          />
          <InputButtonLabel
            label='센터코드'
            buttonText='검색'
            readOnly={false}
            buttonClick={openCenterCode}
            {...attributes.centerCode}
          />
        </Flex>
        <Flex direction='column' rowGap={10}>
          <SelectLabel
            label='입고유형'
            options={INBOUND_CATEGORY_OPTIONS}
            {...attributes.inboundCategory}
          />
          <SelectLabel
            label='상태'
            options={INBOUND_STATUS_CODE_OPTIONS}
            {...attributes.statusCode}
          />
          <InputButtonLabel
            label='거래처코드'
            readOnly={false}
            buttonText='검색'
            buttonClick={openVendorCode}
            {...attributes.vendorId}
          />
        </Flex>
        <TextAreaLabel label='입고번호' {...attributes.wikey} />
        <TextAreaLabel label='발주번호' {...attributes.productSupplyRequestId} />
        <Flex direction='column' rowGap={10}>
          <TextAreaLabel label='품목코드' css='height:6.2rem' {...attributes.productItemId} />
          <InputLabel label='참조번호' {...attributes.referenceCode} />
        </Flex>
      </FormField>
      <Flex direction='column' rowGap={10}>
        <ResultHeader title='입고 상세내역' total={total} />
        <DataSheetGrid
          columns={columns}
          value={inboundDetailList}
          customRendererList={[
            { id: 'inboundStatusCodeValue', component: InboundDetailStatusCell },
          ]}
          gutterColumn={CUSTOM_GUTTER_COLUMN}
        />
        <Flex justify='center'>
          <Pagination
            total={total}
            pageSize={INBOUND_DETAIL_PAGE_SIZE}
            currentPageIndex={pageNumber}
            onPageIndexChange={setPagination}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default InboundDetail;
