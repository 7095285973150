import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INITIAL_OUTBOUND_RESULT_INQUIRE_FILTER_FORM = {
  startAvailableDay: dayjs().subtract(3, 'day').format('YYYY-MM-DD'), // 출고예정일 start
  endAvailableDay: dayjs().add(3, 'day').format('YYYY-MM-DD'), // 출고예정일 end
  startCompleteDay: '', // 출고실적일 start
  endCompleteDay: '', // 출고실적일 end
  outboundCategory: '', // 출고유형
  deliveryType: '', // 배송유형
  centerCode: '', // 센터코드
  wokey: '', // 출고번호
  productItemId: '', // 품목코드
  orderId: '',
  referenceCode: '',
};

export const OUTBOUND_RESULT_INQUIRE_STATUS_CODE_COLOR = {
  출고예정: 'orange.500',
  출고완료: 'green.500',
  전송완료: 'blue.500',
  출고취소: 'red.500',
};

export const GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('wokey', textColumn),
      title: '출고번호',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('outboundStatusCodeValue', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('outboundCategoryValue', textColumn),
      title: '출고유형',
      disabled: true,
    },
    {
      ...keyColumn('deliveryTypeValue', textColumn),
      title: '배송유형',
      disabled: true,
    },
    {
      ...keyColumn('orderId', textColumn),
      title: '주문번호',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('referenceCode', textColumn),
      title: '참조번호',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '출고예정일',
      disabled: true,
    },
    {
      ...keyColumn('completeDay', textColumn),
      title: '출고실적일',
      disabled: true,
    },
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('centerName', textColumn),
      title: '센터명',
      minWidth: 150,
      disabled: true,
    },
    {
      ...keyColumn('mainInvoiceNumber', textColumn),
      title: '대표 운송장 번호',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      minWidth: 300,
      disabled: true,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '출고예정수량',
      disabled: true,
    },
    {
      ...keyColumn('receivedQuantity', textColumn),
      title: '출고실적수량',
      disabled: true,
    },
    {
      ...keyColumn('parcelValue', textColumn),
      title: '운송사',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('invoiceNumber', textColumn),
      title: '송장번호',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('itemRemark', textColumn),
      title: '비고',
      minWidth: 200,
      disabled: true,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      minWidth: 120,
      disabled: true,
    },
  ],
};
