import { cloneElement, ReactElement, useId } from 'react';

import styled, { CSSProperties } from 'styled-components';

import Flex from '@Components/Flex';

export interface WithLabelProps {
  className?: string;
  direction?: CSSProperties['flexDirection'];
  label: string;
  children: ReactElement;
}

export function WithLabel({ label, direction = 'row', className, children }: WithLabelProps) {
  const id = useId();
  return (
    <Flex colGap={10} align='flex-start' direction={direction} className={className}>
      <Label htmlFor={id}>{label}</Label>
      {cloneElement(children, { id })}
    </Flex>
  );
}

const Label = styled.label`
  min-width: 8.3rem;
  font-size: ${({ theme }) => theme.sizes[1.2]};
  line-height: 2.6rem;
`;
