import { useState } from 'react';

import { client } from '@src/client';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import useRequest from '@src/Hooks/useRequest';
import { addIndexingData } from '@Utils/helper';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { IndexingData, PageResponse } from '@Utils/type';

import {
  InboundAdjustRow,
  InboundAdjustRowAndActive,
  PostInboundsAdjustListParams,
} from '../InboundResultAdjust.type';

export const useInboundsAdjustListQuery = (params: PostInboundsAdjustListParams) => {
  const [list, setList] = useState<IndexingData<InboundAdjustRowAndActive>[]>([]);
  const useQueryResult = useRequest<PageResponse<InboundAdjustRow[]>>(
    {
      url: '/inbounds-adjustment/list',
      method: 'POST',
      data: mapObject(
        {
          ...params,
        },
        nullIfEmptyOrALL
      ),
    },
    {
      enabled: false,
      keepPreviousData: true,
      onSuccess(data) {
        const inboundAdjustResultList = addIndexingData(
          data.results.map((result) => ({ ...result, active: false })),
          params.pageNumber,
          params.pageSize
        );
        setList(inboundAdjustResultList);
      },
    }
  );

  return { useQueryResult, list, setList };
};

export const postInboundAdjustExcelDownload = async (params: PostInboundsAdjustListParams) => {
  const body = mapObject({ ...params }, nullIfEmptyOrALL);

  const { data } = await client.post<{ send: string }>('/inbounds-adjustment/excel-download', body);

  return data;
};

export const useInboundAdjustExcelDownloadMutation = () => {
  const { downloadExcel: excelDownload } = useMassiveExcelRequest(postInboundAdjustExcelDownload);

  return { excelDownload };
};
