import styled from 'styled-components';

export const CautionBoxContainer = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding: 1rem;
  background: #ddf9ff;
  margin-bottom: 3.2rem;

  & li {
    list-style: inside;
    line-height: 2rem;
  }
`;

export const CautionListWrapper = styled.ul`
  margin: 1.6rem 0 0;
`;
