import { useState } from 'react';

import Box from '@Components/Box';
import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import Check from '@Components/Forms/Check';
import { SelectLabel } from '@Components/Forms/Select';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import { CenterCodeRow } from '@Components/Modals/CenterCodeModal/CenterCodeModal.type';
import ProductCategorySelect from '@Components/ProductCategorySelect';
import { toastify } from '@Components/Toast';
import {
  useDependentSelect,
  useDialog,
  useFormField,
  useFullScreenActivityIndicator,
} from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import { performIfNotEmpty } from '@src/Utils/helper';

import { postStocksExcelDownload, useStockListQuery } from './apis';
import {
  INITIAL_PRODUCT_LIST_DEPENDENT_SELECT,
  PRODUCT_ITEM_TYPE_OPTIONS,
  STOCK_INQUIRE_GRID_OPTIONS,
  STOCK_INQUIRE_INITIAL_STATE,
  STOCK_STATUS_OPTIONS,
} from './constant';

function StockInquire() {
  const { columns } = STOCK_INQUIRE_GRID_OPTIONS;
  const { showActivityIndicator } = useFullScreenActivityIndicator();

  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');

  const { form, setForm, attributes } = useFormField(STOCK_INQUIRE_INITIAL_STATE);
  const [zeroExcluded, setZeroExcluded] = useState(false);
  const dependentSelect = useDependentSelect(INITIAL_PRODUCT_LIST_DEPENDENT_SELECT);
  const { data: centerCodeList } = useCenterCodeList();

  const { downloadExcel } = useMassiveExcelRequest(postStocksExcelDownload);
  const { openDialog } = useExcelDownLoadHistory({ targetUrl: 'stock' });

  const centerId =
    centerCodeList?.find(({ centerCode }) => centerCode === form.centerCode)?.id ?? null;

  const { stockList, refetch } = useStockListQuery({
    ...form,
    ...dependentSelect.form,
    centerId,
    zeroIncluded: !zeroExcluded,
  });

  const setCenterCode = ({ centerCode }: CenterCodeRow) => {
    if (!centerCode) return;
    setForm((prev) => ({
      ...prev,
      centerCode,
    }));
  };

  const openCenterCode = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: <CenterCodeModal selectCenterCode={setCenterCode} />,
      size: 'medium',
    });
  };

  const search = () => {
    if (!form.centerCode) {
      toastify('센터코드는 필수값입니다.', { type: 'error' });
      return;
    }
    showActivityIndicator();
    refetch();
  };

  const excelDownload = () => {
    downloadExcel({
      ...form,
      ...dependentSelect.form,
      centerId,
      zeroIncluded: !zeroExcluded,
    });
  };

  const onClickExcelHistory = () => {
    openDialog();
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={search} isPermissionRequired={false}>
          조회
        </Button>
        <Button
          onClick={performIfNotEmpty(stockList, excelDownload, '조회된 데이터가 없습니다')}
          isPermissionRequired={false}
        >
          엑셀
        </Button>
        <Button onClick={onClickExcelHistory} isPermissionRequired={false}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>

      <FormField>
        <Flex direction='column' rowGap={10}>
          <InputButtonLabel
            {...attributes.centerCode}
            label='센터코드'
            readOnly={false}
            buttonText='검색'
            buttonClick={openCenterCode}
          />
          <SelectLabel
            label='품목유형'
            {...attributes.productItemType}
            options={PRODUCT_ITEM_TYPE_OPTIONS}
          />
          <SelectLabel
            label='재고상태'
            {...attributes.stockStatus}
            options={STOCK_STATUS_OPTIONS}
          />
        </Flex>

        <Flex direction='column' rowGap={10}>
          <ProductCategorySelect dependentSelect={dependentSelect} />
        </Flex>
        <TextAreaLabel label='품목코드' {...attributes.productItemIds} />

        <Flex direction='column'>
          <Check
            id='zero-included'
            label='재고 ‘0’ 제외'
            checked={zeroExcluded}
            onChange={(event) => setZeroExcluded(event.target.checked)}
          />
        </Flex>
      </FormField>

      <Box mt-20>
        <Flex direction='column' rowGap={10}>
          <ResultHeader title='재고조회' total={stockList.length} />
          <DataSheetGrid columns={columns} value={stockList} />
        </Flex>
      </Box>
    </div>
  );
}

export default StockInquire;
