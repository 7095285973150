import { useCallback, useState } from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import Box from '@Components/Box';
import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { Text } from '@Components/Typography';
import {
  postUpdateValidateProcessing,
  putProcessingUpdate,
} from '@Features/ProcessedCirculateManage/apis';
import {
  UpdateProcessingInGridColumn,
  UpdateProcessingOutGridColumn,
} from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageModal/ProcessedCirculateUpdateModal/constant';
import { useUpdateProcessingDetailQuery } from '@Features/ProcessedCirculateManage/hooks';
import { selectedProcessingSelector } from '@Features/ProcessedCirculateManage/stores/atoms';
import { useDialog } from '@Hooks';
import ProductCodeModal from '@src/Components/Modals/ProductCodeModal';
import { ProductItemRow } from '@src/Components/Modals/ProductCodeModal/ProductCodeModal.type';
import {
  BoundType,
  UpdateProcessingGridItem,
} from '@src/Features/ProcessedCirculateManage/ProcessedCirculateHistory.type';
import { getCheckMessage } from '@src/Features/ProcessedCirculateManage/utils';
import ResultHeader from '@src/Layout/components/ResultHeader';
import { performIfNotEmpty } from '@src/Utils/helper';
import { addActiveColumn } from '@src/Utils/object';
import { Nullable } from '@src/Utils/type';

import { CheckMessage } from '../CheckMessage';

type RowDataType =
  | (UpdateProcessingGridItem<BoundType.INBOUND> & { active: boolean })
  | (UpdateProcessingGridItem<BoundType.OUTBOUND> & { active: boolean });

function ProcessingCategoryValueCell({
  rowData,
  setRowData,
}: {
  rowData: RowDataType;
  setRowData: (rowData: RowDataType) => void;
}) {
  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCell = { ...rowData };
    newCell.processingCategoryValue = e.target.value;
    setRowData(newCell);
  };

  return (
    <Box pr-10 pl-10 $width='100%'>
      <select
        className='width-100'
        value={rowData.processingCategoryValue ?? '유통가공'}
        onChange={onChangeHandler}
      >
        <option value='유통가공'>유통가공</option>
      </select>
    </Box>
  );
}

const DatePickerRootModal = styled.div`
  & .react-datepicker__portal {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

function ProcessedCirculateUpdateModal({
  refetchProcessingList,
  refetchProcessingDetail,
  rootProcessingId,
}: {
  rootProcessingId: Nullable<number>;
  refetchProcessingList: VoidFunction;
  refetchProcessingDetail: VoidFunction;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { resetDialog } = useDialog('ProcessedCirculateUpdateModal');
  const productCodeModal = useDialog('ProductCodeModal');
  const selectedProcessingList = useRecoilValue(selectedProcessingSelector);
  const processedCirculateUpdateModal = useDialog('ProcessedCirculateUpdateModal');

  const processingId = selectedProcessingList[0]?.processingId || null;

  const {
    processingDetail,
    setInboundList: setInBoundList,
    setOutboundList: setOutBoundList,
    setProcessingDetail,
  } = useUpdateProcessingDetailQuery(processingId);

  const inBoundList = processingDetail.inbounds;
  const outBoundList = processingDetail.outbounds;

  const activeOutboundList = outBoundList.filter(({ active }) => active);
  const activeInboundList = inBoundList.filter(({ active }) => active);

  const check = async () => {
    const { inbounds, outbounds, validationResult } = await postUpdateValidateProcessing({
      outbounds: outBoundList.map((it) => ({ ...it, errorMessage: '' })),
      inbounds: inBoundList.map((it) => ({ ...it, errorMessage: '' })),
      processingId,
    });

    setProcessingDetail({
      inbounds: addActiveColumn(
        inbounds.map((inbound) => ({
          ...inbound,
          errorMessage: getCheckMessage(inbound.errorMessage),
        }))
      ),
      outbounds: addActiveColumn(
        outbounds.map((outbound) => ({
          ...outbound,
          errorMessage: getCheckMessage(outbound.errorMessage),
        }))
      ),
      validationResult,
    });
    setIsChecked(validationResult === 'Success');
  };

  const save = async () => {
    await putProcessingUpdate(processingId as number, {
      inbounds: inBoundList,
      outbounds: outBoundList,
    });
    processedCirculateUpdateModal.resetDialog();
    refetchProcessingList();
    if (rootProcessingId) refetchProcessingDetail();
  };

  const addOutGridChangeHandler = useCallback(
    (
      list: (UpdateProcessingGridItem<BoundType.OUTBOUND> & {
        active: boolean;
      })[]
    ) => {
      setOutBoundList(list);
      if (isChecked) {
        setIsChecked(false);
      }
    },
    [isChecked]
  );

  const addInGridChangeHandler = useCallback(
    (
      list: (UpdateProcessingGridItem<BoundType.INBOUND> & {
        active: boolean;
      })[]
    ) => {
      setInBoundList(list);
      if (isChecked) {
        setIsChecked(false);
      }
    },
    [isChecked]
  );

  const selectOutboundProduct = ({ productItemId, productItemName }: ProductItemRow) => {
    setOutBoundList([
      ...outBoundList,
      {
        errorMessage: '',
        centerCode: '',
        processingCategoryValue: '유통가공',
        availableDay: '',
        processingInOutCategory: 'OUTBOUND',
        processingInOutCategoryValue: '출고',
        productItemId,
        productItemName,
        remark: '',
        result: null,
        processingCategory: '',
        availableQuantity: 0,
        active: false,
      },
    ]);
  };

  const selectInboundProduct = ({ productItemId, productItemName }: ProductItemRow) => {
    setInBoundList([
      ...inBoundList,
      {
        errorMessage: '',
        centerCode: '',
        processingCategoryValue: '유통가공',
        availableDay: '',
        processingInOutCategory: 'INBOUND',
        processingInOutCategoryValue: '입고',
        productItemId,
        productItemName,
        remark: '',
        result: null,
        processingCategory: '',
        availableQuantity: 0,
        active: false,
      },
    ]);
  };

  const addProduct = (selectProductCode: (rowData: ProductItemRow) => void) => () => {
    productCodeModal.openDialog({
      title: '선택기',
      content: <ProductCodeModal type='etc' selectProductCode={selectProductCode} />,
    });
  };

  return (
    <Flex direction='column' gap={20}>
      <Flex direction='column' rowGap={16}>
        <ResultHeader
          title='출고 내역 등록'
          right={
            <Flex align='center' gap={8}>
              <Text color='gray.800' fontWeight={500} size={1.4}>
                품목 추가/삭제
              </Text>
              <Button onClick={addProduct(selectOutboundProduct)} size='x-small'>
                추가
              </Button>
              <Button
                onClick={performIfNotEmpty(activeOutboundList, () =>
                  setOutBoundList(outBoundList.filter(({ active }) => !active))
                )}
                size='x-small'
              >
                삭제
              </Button>
            </Flex>
          }
        />
        <DataSheetGrid
          value={outBoundList}
          onChange={addOutGridChangeHandler}
          columns={UpdateProcessingOutGridColumn}
          customRendererList={[
            { id: 'processingCategoryValue', component: ProcessingCategoryValueCell },
            { id: 'errorMessage', component: CheckMessage },
          ]}
        />
      </Flex>
      <Flex direction='column' rowGap={16}>
        <ResultHeader
          title='입고 내역 등록'
          right={
            <Flex gap={5} align='center'>
              <Text color='gray.800' fontWeight={500} size={1.4}>
                품목 추가/삭제
              </Text>
              <Button onClick={addProduct(selectInboundProduct)} size='x-small'>
                추가
              </Button>
              <Button
                onClick={performIfNotEmpty(activeInboundList, () =>
                  setInBoundList(inBoundList.filter(({ active }) => !active))
                )}
                size='x-small'
              >
                삭제
              </Button>
            </Flex>
          }
        />
        <DataSheetGrid
          value={inBoundList}
          onChange={addInGridChangeHandler}
          columns={UpdateProcessingInGridColumn}
          customRendererList={[
            { id: 'processingCategoryValue', component: ProcessingCategoryValueCell },
            { id: 'errorMessage', component: CheckMessage },
          ]}
        />
      </Flex>
      <Flex direction='row' justify='right' gap={10}>
        <Button disabled={!outBoundList.length && !inBoundList.length} onClick={check} size='small'>
          체크
        </Button>
        <Button disabled={!isChecked} onClick={save} size='small'>
          저장
        </Button>
        <Button onClick={resetDialog} size='small'>
          취소
        </Button>
      </Flex>
      <DatePickerRootModal id='datepicker-modal-root' />
    </Flex>
  );
}

export default ProcessedCirculateUpdateModal;
