import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { OutboundsRecordRow } from './apis/type';
import { OUTBOUND_RESULT_INQUIRE_STATUS_CODE_COLOR } from './constant';

export function OutboundResultInquireStatusCell({ rowData }: { rowData: OutboundsRecordRow }) {
  const { outboundStatusCodeValue } = rowData;

  const textColor = OUTBOUND_RESULT_INQUIRE_STATUS_CODE_COLOR[
    outboundStatusCodeValue as keyof typeof OUTBOUND_RESULT_INQUIRE_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={outboundStatusCodeValue} textColor={textColor} />;
}
