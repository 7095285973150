import { useRecoilValue } from 'recoil';

import { useDialog } from '@Hooks';
import { Portal, Root } from '@radix-ui/react-dialog';
import { DialogAtom, dialogIdsAtom, dialogSelectorFamily } from '@Stores/Dialog/dialog.atom';

import {
  ContentWrapper,
  StyledContent,
  StyledOverlay,
  TitleText,
  TitleWrapper,
} from './Dialog.style';

// https://www.radix-ui.com/docs/primitives/components/dialog
function Dialog({ id }: { id: DialogAtom['id'] }) {
  const {
    size = 'medium',
    isOpen,
    title,
    content,
    onOpenChange,
    noCloseEsc,
    noCloseClickOutSide,
  } = useRecoilValue(dialogSelectorFamily(id));
  const { openDialog, resetDialog } = useDialog(id);

  const handleOpenChange = (nextIsOpen: DialogAtom['isOpen']) => {
    if (nextIsOpen) {
      openDialog();
    } else {
      resetDialog();
    }
  };

  const handleEscKeyDown = (e: Event) => {
    if (noCloseEsc) e.preventDefault();
  };

  const handleOutSideClick = (e: Event) => {
    if (noCloseClickOutSide) e.preventDefault();
  };

  return (
    <Root open={isOpen} onOpenChange={onOpenChange ?? handleOpenChange}>
      <Portal>
        <StyledOverlay />
        <StyledContent
          size={size}
          onEscapeKeyDown={handleEscKeyDown}
          onPointerDownOutside={handleOutSideClick}
        >
          {title && (
            <TitleWrapper>
              {typeof title === 'string' ? <TitleText>{title}</TitleText> : title}
            </TitleWrapper>
          )}
          <ContentWrapper>{content}</ContentWrapper>
        </StyledContent>
      </Portal>
    </Root>
  );
}

function DialogContainer() {
  const dialogIds = useRecoilValue(dialogIdsAtom);

  return (
    <div>
      {dialogIds.map((id) => (
        <Dialog key={id} id={id} />
      ))}
    </div>
  );
}

export default DialogContainer;
