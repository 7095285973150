import { useState } from 'react';

import CautionBox from '@Components/CautionBox';
import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useDialog, useFullScreenActivityIndicator } from '@Hooks';
import ModalFooter from '@Layout/components/ModalFooter';
import ResultHeader from '@Layout/components/ResultHeader';
import { useMutation } from '@tanstack/react-query';
import { performIfConfirmed } from '@Utils/helper';

import {
  postInBoundHistoryExcelUpload,
  postInBoundHistoryExcelValidation,
  postInbounds,
} from '../../apis';
import { ExcelRow } from '../../InboundHistory.type';

import { CAUTION_BOX_NEW_MESSAGES, INBOUND_NEW_GRID_OPTIONS } from './constant';
import ExcelFileUpload from './ExcelFileUpload';
import { ErrorCell } from './InboundHistoryNewModal.style';

function InboundHistoryNewModal({ search }: { search: VoidFunction }) {
  const { columns } = INBOUND_NEW_GRID_OPTIONS;
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const [isPass, setIsPass] = useState(false);
  const [inboundHistoryList, setInboundHistoryList] = useState<ExcelRow[]>([]);

  const { resetDialog } = useDialog('InboundHistoryNewDialog');

  const successHandler = (data: ExcelRow[] | undefined) => data && setInboundHistoryList(data);

  const { mutate: setExcelUploadMutation } = useMutation(postInBoundHistoryExcelUpload, {
    onSuccess: successHandler,
    onSettled: () => {
      hideActivityIndicator();
    },
  });
  const { mutate: setExcelValidation } = useMutation(postInBoundHistoryExcelValidation, {
    onSuccess: (data) => {
      successHandler(data);
      setIsPass(() => {
        return !data.some((it) => it.errorMessage !== '성공!');
      });
    },
    onSettled: () => {
      hideActivityIndicator();
    },
  });
  const { mutate: saveMutation } = useMutation(postInbounds, {
    onSuccess: (data) => {
      if (data) {
        toastify('저장되었습니다.');
        resetDialog();
        search();
      }
    },
  });

  const onFileUpload = (file: FileList) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    setExcelUploadMutation(formData);
    setIsPass(false);
    // 매입입고, 유통가공, 재고이동, 재고조정 업로드 불가
    showActivityIndicator();
  };

  const check = () => {
    if (!inboundHistoryList.length) {
      toastify('데이터가 존재하지 않습니다.', { type: 'error' });
      return;
    }
    showActivityIndicator();
    setExcelValidation(inboundHistoryList);
  };

  const renderCheckMessage = ({ rowData }: { rowData: ExcelRow }) => {
    return (
      <ErrorCell bg={rowData.errorMessage}>
        <Text color='gray.900' fontWeight={400} size={1.2}>
          {rowData.errorMessage}
        </Text>
      </ErrorCell>
    );
  };

  const submit = () => {
    for (const row of inboundHistoryList) {
      if (row.errorMessage !== '성공!') {
        toastify(row.errorMessage, { type: 'error' });
        return false;
      }
    }
    performIfConfirmed('저장하시겠습니까?', () => {
      saveMutation(inboundHistoryList);
    });
  };

  return (
    <Flex direction='column' css='height:100%'>
      <div css='flex:1'>
        <CautionBox title='참고사항' messages={CAUTION_BOX_NEW_MESSAGES} />
        <Flex direction='column' rowGap={16}>
          <ResultHeader
            title='입고 등록 [품목기준]'
            total={inboundHistoryList.length}
            right={<ExcelFileUpload onFileUpload={onFileUpload} fileName='업로드_입고등록' />}
          />
          <DataSheetGrid
            columns={columns}
            value={inboundHistoryList}
            customRendererList={[{ id: 'errorMessage', component: renderCheckMessage }]}
          />
        </Flex>
      </div>
      <ModalFooter>
        <Button onClick={check}>체크</Button>
        <Button onClick={submit} disabled={!isPass}>
          저장
        </Button>
        <Button onClick={resetDialog}>취소</Button>
      </ModalFooter>
    </Flex>
  );
}

export default InboundHistoryNewModal;
