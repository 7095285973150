import { Dispatch, SetStateAction, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import {
  putProcessingCancel,
  putProcessingTransmit,
} from '@Features/ProcessedCirculateManage/apis';
import ProcessedCirculateAddModal from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageModal/ProcessedCirculateAddModal';
import ProcessedCirculateUpdateModal from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageModal/ProcessedCirculateUpdateModal';
import { INITIAL_CIRCULATE_MANAGE_FILTER_FORM } from '@Features/ProcessedCirculateManage/constant';
import { useProcessingListQuery } from '@Features/ProcessedCirculateManage/hooks';
import { UpdatableCheckFunctionType } from '@Features/ProcessedCirculateManage/ProcessedCirculateHistory.type';
import {
  processingStateSelector,
  selectedProcessingSelector,
} from '@Features/ProcessedCirculateManage/stores/atoms';
import { useDialog, useFormField, useUpdateEffect } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { performIfNotEmpty } from '@src/Utils/helper';
import { Nullable } from '@src/Utils/type';

function ProcessedCirculateManageFilter({
  setProcessingId,
  refetchProcessingDetail,
  processingId,
}: {
  processingId: Nullable<number>;
  setProcessingId: Dispatch<SetStateAction<Nullable<number>>>;
  refetchProcessingDetail: VoidFunction;
}) {
  const { form, attributes } = useFormField(INITIAL_CIRCULATE_MANAGE_FILTER_FORM);
  const { openDialog: openAddDialog } = useDialog('ProcessedCirculateAddModal');
  const { openDialog: openUpdateDialog } = useDialog('ProcessedCirculateUpdateModal');
  const selectedProcessingList = useRecoilValue(selectedProcessingSelector);
  const { page } = useRecoilValue(processingStateSelector);
  const { setSearchFilter, filter, useQueryResult } = useProcessingListQuery();

  const updatableCheckFunction = useCallback(
    (callback: () => unknown, type: UpdatableCheckFunctionType) => {
      const isUpdatable = selectedProcessingList.every(
        ({ processingStatusCodeValue }) =>
          processingStatusCodeValue === '등록' || processingStatusCodeValue === '수정등록'
      );

      if (isUpdatable) {
        callback();
      } else {
        toastify(`${type}할 수 없는 상태입니다.`, { type: 'error' });
      }
    },
    [selectedProcessingList]
  );

  useUpdateEffect(() => {
    setSearchFilter({
      ...filter,
      pageNumber: page,
    });
  }, [page]);

  const search = () => {
    setSearchFilter({ ...form, pageNumber: page, pageSize: 100 });
    setProcessingId(null);
  };

  const add = useCallback(() => {
    openAddDialog({
      title: '유통가공 신규 등록',
      content: <ProcessedCirculateAddModal refetchProcessingList={useQueryResult.refetch} />,
      size: 'large',
    });
  }, []);

  const update = useCallback(() => {
    if (selectedProcessingList.length > 1) {
      return toastify('1건만 처리할 수 있습니다.', { type: 'error' });
    }

    updatableCheckFunction(
      () =>
        openUpdateDialog({
          title: '수정',
          content: (
            <ProcessedCirculateUpdateModal
              rootProcessingId={processingId}
              refetchProcessingList={useQueryResult.refetch}
              refetchProcessingDetail={refetchProcessingDetail}
            />
          ),
          size: 'large',
        }),
      '수정'
    );
  }, [selectedProcessingList, updatableCheckFunction]);

  const cancel = useCallback(() => {
    updatableCheckFunction(async () => {
      await putProcessingCancel(
        selectedProcessingList.map(({ processingId }) => processingId as number)
      );
      useQueryResult.refetch();
    }, '취소');
  }, [updatableCheckFunction, selectedProcessingList]);

  const send = useCallback(() => {
    updatableCheckFunction(async () => {
      await putProcessingTransmit(
        selectedProcessingList.map(({ processingId }) => processingId as number)
      );
      useQueryResult.refetch();
    }, '전송');
  }, [updatableCheckFunction, selectedProcessingList]);

  return (
    <div>
      <FilterHeader>
        <Button onClick={search} isPermissionRequired={false}>
          조회
        </Button>
        <Button onClick={add}>신규</Button>
        <Button onClick={performIfNotEmpty(selectedProcessingList, update)}>수정</Button>
        <Button onClick={performIfNotEmpty(selectedProcessingList, cancel)}>취소</Button>
        <Button onClick={performIfNotEmpty(selectedProcessingList, send)}>전송</Button>
      </FilterHeader>
      <FormField>
        <DateRangePickerLabel
          label='오더 생성일'
          start={attributes.startRequestDay}
          end={attributes.endRequestDay}
        />
        <TextAreaLabel label='유통가공번호' {...attributes.processingCode} />
        <TextAreaLabel label='입/출고번호' {...attributes.wkey} />
        <TextAreaLabel label='품목코드' {...attributes.productItemId} />
      </FormField>
    </div>
  );
}

export default ProcessedCirculateManageFilter;
