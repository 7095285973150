import { useMemo, useState } from 'react';

import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import { RowInfo } from '@Components/DataSheetGrid/DataSheetGrid.type';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { useDialog, useFormField } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import ResultHeader from '@Layout/components/ResultHeader';
import { useMutation } from '@tanstack/react-query';
import { performIfConfirmed } from '@Utils/helper';
import { Nullable } from '@Utils/type';

import AuthorityManageModal from './components/modals/AuthorityManageModal';
import UserGroupModal from './components/modals/UserGroupModal';
import UserGroupAndRoleManageFilter from './components/UserGroupAndRoleManageFilter';
import UserRoleResult from './components/UserRoleResult';
import {
  putUserGroupRoles,
  putUserGroupStatus,
  useUserGroupListQuery,
  useUserRoleListQuery,
} from './apis';
import { INITIAL_FILTER_FORM, USER_GROUP_COLUMNS } from './constant';
import { UserGroupAndRoleManageStatusCell } from './UserGroupAndRoleManageCell';

function UserGroupAndRoleManage() {
  const { openDialog, resetDialog } = useDialog('UserGroupModal');

  const { form, attributes } = useFormField(INITIAL_FILTER_FORM);

  const [userIndex, setUserIndex] = useState<Nullable<number>>(null);

  const {
    userGroupList,
    setUserGroupList,
    userGroupRowChange,
    refetch,
    userGroupUpdatedList,
    setUserGroupUpdatedList,
  } = useUserGroupListQuery(form);

  const userGroupId = useMemo(
    () => (userIndex === null ? null : userGroupList[userIndex].id),
    [userIndex, userGroupList]
  );

  const { roleList, setRoleList, roleRowChange, roleUpdatedList, setRoleUpdatedList, refresh } =
    useUserRoleListQuery(userGroupId);

  const { mutate: saveMutation } = useMutation(putUserGroupRoles, {
    onSuccess: (data) => {
      if (data) {
        toastify('저장되었습니다.');
        setUserGroupUpdatedList([]);
        setRoleUpdatedList([]);
        refetch();
      }
    },
  });
  const { mutate: statusMutation } = useMutation(putUserGroupStatus, {
    onSuccess: (data) => {
      const { isAbled } = data;
      if (data) {
        toastify(isAbled ? '활성화되었습니다.' : '비활성화되었습니다.');
        refetch();
      }
    },
  });

  const activatedList = userGroupList.filter((it) => it.active);

  const setStatus = (isAbled: boolean) => {
    if (!activatedList.length) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return;
    }

    for (const row of activatedList) {
      if (isAbled && row.activated === '활성화') {
        toastify('허용되지 않은 동작입니다.', { type: 'error' });
        return;
      }

      if (!isAbled && row.activated === '비활성화') {
        toastify('허용되지 않은 동작입니다.', { type: 'error' });
        return;
      }
    }

    const params = activatedList.map((it) => it.id);
    performIfConfirmed(
      isAbled ? '활성화 상태로 변경하시겠습니까?' : '비활성화 상태로 변경하시겠습니까?',
      () => {
        statusMutation({ ids: params, isAbled });
      }
    );
  };

  const selectUserId = (rowInfo: RowInfo) => {
    if (rowInfo.colId !== 'active') return;
    setUserIndex(rowInfo.row);
  };

  const search = () => {
    setUserIndex(null);
    setUserGroupUpdatedList([]);
    setRoleUpdatedList([]);
    refetch();
  };

  const add = () => {
    openDialog({
      title: '신규',
      content: <UserGroupModal close={resetDialog} search={search} />,
      size: 'medium',
    });
  };

  const save = () => {
    if (!userGroupUpdatedList.length && !roleUpdatedList.length) {
      toastify('수정된 내역이 없습니다.', { type: 'error' });
      return;
    }

    performIfConfirmed('모든 변경사항을 저장하시겠습니까?', () => {
      const params = {
        userGroupUpdateDtos: userGroupUpdatedList.map(
          ({ id, representativePhoneNo, groupName }) => ({ id, representativePhoneNo, groupName })
        ),
        empty: !userGroupUpdatedList.length,
        userGroupRoleUpdateListDto: {
          groupId: userGroupId,
          roleUpdateDtos: roleUpdatedList.map(({ roleId, roleName }) => ({ roleId, roleName })),
          empty: !userGroupId,
        },
      };
      saveMutation(params);
    });
  };

  const authority = () => {
    if (!activatedList.length) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return;
    }

    if (activatedList.length !== 1) {
      toastify('권한관리는 1건만 처리할 수 있습니다.', { type: 'error' });
      return;
    }
    const [target] = activatedList;
    openDialog({
      title: '권한관리',
      content: <AuthorityManageModal close={resetDialog} userInfo={target} />,
    });
  };

  return (
    <section>
      <Flex direction='column'>
        <FilterHeader>
          <Button onClick={search} isPermissionRequired={false}>
            조회
          </Button>
          <Button onClick={add}>신규</Button>
          <Button onClick={save}>저장</Button>
          <Button onClick={authority}>권한관리</Button>
          <Button onClick={() => setStatus(true)}>활성화</Button>
          <Button onClick={() => setStatus(false)}>비활성화</Button>
        </FilterHeader>
        <UserGroupAndRoleManageFilter attributes={attributes} />
        <Flex direction='column' rowGap={14}>
          <ResultHeader title='사용자 그룹 내역' total={userGroupList?.length} />
          <DataSheetGrid
            {...mappingAllCheck(USER_GROUP_COLUMNS, userGroupList, setUserGroupList)}
            value={userGroupList}
            onChange={userGroupRowChange}
            onActiveChange={selectUserId}
            customRendererList={[{ id: 'activated', component: UserGroupAndRoleManageStatusCell }]}
          />
          {userIndex !== null && (
            <UserRoleResult
              userGroupId={userGroupId}
              roleList={roleList}
              setRoleList={setRoleList}
              roleRowChange={roleRowChange}
              refresh={refresh}
            />
          )}
        </Flex>
      </Flex>
    </section>
  );
}

export default UserGroupAndRoleManage;
