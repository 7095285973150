import { useMemo, useState } from 'react';

import { RowInfo } from '@Components/DataSheetGrid/DataSheetGrid.type';
import Flex from '@Components/Flex';
import { useFormField } from '@Hooks';
import { useCenterCodeList } from '@src/Apis/useCenterCodeList';

import { useInboundList, useProductList } from './apis/mutations';
import Filter from './components/Filter';
import Form from './components/Form';
import InboundHistoryGrid from './components/InboundHistoryGrid';
import ProductGrid from './components/ProductGrid';
import { INITIAL_INBOUND_HISTORY_FILTER_FORM } from './constant';

function InboundHistory() {
  const [inboundIndex, setInboundIndex] = useState<number | undefined>(undefined);
  const { form, setForm, attributes } = useFormField(INITIAL_INBOUND_HISTORY_FILTER_FORM);
  const { data: centerCodeList } = useCenterCodeList();
  const centerId = centerCodeList?.find((item) => item.centerCode === form.centerCode)?.id || null;

  const [pageNumber, setPageNumber] = useState(0);

  const { inboundGrid, inboundListTotal, ...inboundRest } = useInboundList(
    { ...form, centerId, pageNumber },
    inboundIndex
  );
  const { productGrid, ...productRest } = useProductList(inboundGrid.gridList, inboundIndex);

  const inboundItemId = useMemo(
    () => (inboundIndex === undefined ? null : inboundGrid.gridList[inboundIndex].inboundId),
    [inboundIndex]
  );

  const selectProductId = (rowInfo: RowInfo) => {
    setInboundIndex(rowInfo.row);
  };

  const search = () => {
    inboundRest.search();
    setInboundIndex(undefined);
    inboundGrid.setUpdatedRow([]);
    productGrid.setUpdatedRow([]);
  };

  const setPagination = (page: number) => {
    setPageNumber(page);
    search();
  };

  return (
    <div>
      <Filter
        {...inboundRest}
        form={form}
        inboundItemId={inboundItemId}
        inboundGrid={inboundGrid}
        productGrid={productGrid}
        centerId={centerId}
        search={search}
      />
      <Form attributes={attributes} form={form} setForm={setForm} />
      <Flex direction='column' rowGap={10}>
        <InboundHistoryGrid
          inboundList={inboundGrid.gridList}
          setInboundList={inboundGrid.setGridList}
          inboundRowChangeHandler={inboundGrid.rowChangeHandler}
          inboundListTotal={inboundListTotal}
          selectProductId={selectProductId}
          pageNumber={pageNumber}
          setPageNumber={setPagination}
        />
        {inboundIndex !== undefined && (
          <ProductGrid
            inboundList={inboundGrid.gridList}
            productList={productGrid.gridList}
            setProductList={productGrid.setGridList}
            inboundIndex={inboundIndex}
            productRowChangeHandler={productGrid.rowChangeHandler}
            {...productRest}
          />
        )}
      </Flex>
    </div>
  );
}

export default InboundHistory;
