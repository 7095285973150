import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useMutation } from '@tanstack/react-query';

const sendOutbound = async ({ outboundIds }: { outboundIds: number[] }) => {
  const { data } = await client.post('/outbounds/transmission', { ids: outboundIds });

  return data;
};

export const useSendOutboundMutation = () => {
  return useMutation(sendOutbound, {
    onSuccess() {
      toastify('전송되었습니다.');
    },
  });
};
