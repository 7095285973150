import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import Flex from '@src/Components/Flex';
import { FormFieldAttributes } from '@src/Hooks';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';

import { PostVendorListParams } from '../../apis/type';

import { FormsProperties } from './constant';

function VendorInquireFilter({
  search,
  attributes,
  downloadExcel,
}: {
  search: VoidFunction;
  attributes: FormFieldAttributes<keyof PostVendorListParams>;
  downloadExcel: VoidFunction;
}) {
  const { STATUS_CODE_OPTIONS, PURCHASE_TYPE_OPTIONS } = FormsProperties;

  const { openDialog } = useExcelDownLoadHistory({
    targetUrl: 'vendor',
  });

  const onClickDownloadExcelDownload = () => {
    openDialog();
  };

  return (
    <div>
      <FilterHeader>
        <Button isPermissionRequired={false} onClick={search}>
          조회
        </Button>
        <Button isPermissionRequired={false} onClick={downloadExcel}>
          엑셀
        </Button>
        <Button isPermissionRequired={false} onClick={onClickDownloadExcelDownload}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>

      <FormField>
        <Flex direction='column' gap={10}>
          <SelectLabel {...attributes.activated} label='거래상태' options={STATUS_CODE_OPTIONS} />
          <SelectLabel
            {...attributes.vendorType}
            label='거래방법'
            options={PURCHASE_TYPE_OPTIONS}
          />
        </Flex>
        <Flex direction='column' gap={10}>
          <InputLabel {...attributes.vendorId} label='거래처코드' />
          <InputLabel {...attributes.vendorName} label='거래처명' />
        </Flex>
      </FormField>
    </div>
  );
}

export default VendorInquireFilter;
