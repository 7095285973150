import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import { toastify } from '@Components/Toast';
import { EXCEL_START_POINT, PointType } from '@Constants/mapping';

/**
 *
 * @param data : T
 * @param fileName :
 * 1. 입고내역 : 다운로드_입고내역
 * 2. 입고등록 : 업로드_입고등록
 * 3. 출고등록 : 업로드_출고등록
 * 4. 출고내역 : 다운로드_출고내역
 * 5. 재고조회 : 다운로드_실시간재고조회
 * 6. 입고상세내역 : 다운로드_입고상세내역
 * 7. 출고상세내역 : 다운로드_출고상세내역
 * 8. 거래처조회 : 다운로드_거래처조회
 * 9. 품목관리 : 다운로드_품목관리
 * 10. 출고분할정보 : 다운로드_출고분할정보
 * s3(cdn)에 반드시 파일이 있어야함.
 */

export const updateAsExcel = <T>(
  data: T[],
  fileName: PointType,
  options?: {
    isIndex?: boolean;
    cb?: () => void;
  }
) => {
  if (!(fileName in EXCEL_START_POINT)) {
    toastify('파일 명을 확인해주세요.');
    return false;
  }

  const url = `${process.env.REACT_APP_EXCEL_URL}${fileName}.xlsx`;

  const convertJsonToArrValue = data.reduce<any[]>((acc, cur, index) => {
    const targetArr = Object.entries(cur).map((item) => item[1]);
    acc.push(options?.isIndex ? [index + 1, ...targetArr] : targetArr);
    return acc;
  }, []);

  fetch(url)
    .then((res) => res.arrayBuffer())
    .then((excelData) => {
      const workbook = XLSX.read(excelData, { type: 'array' });

      const [firstSheetName] = workbook.SheetNames;
      const sheet = workbook.Sheets[firstSheetName];

      XLSX.utils.sheet_add_aoa(sheet, convertJsonToArrValue, {
        origin: `A${EXCEL_START_POINT[fileName]}`,
      });

      XLSX.writeFile(workbook, `${fileName}_${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
    })
    .finally(() => {
      options?.cb?.();
    })
    .catch((err) => {
      toastify('엑셀 다운로드에 실패하였습니다.', { type: 'error' });
      console.error(err);
    });
};
