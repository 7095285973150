import { useState } from 'react';

import { useGridValue } from '@Hooks';
import { useUserRoleIdQuery } from '@src/Apis/useUserRoleIdQuery';
import { client } from '@src/client';
import { useQuery } from '@tanstack/react-query';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { Nullable } from '@Utils/type';

import { INITIAL_USER_LIST_FORM_FIELD } from '../constant';

type Status = 'ALL' | 'Y' | 'N';

export interface User {
  userId: number;
  userCode: string;
  activated: string;
  userName: string;
  roleId: number;
  roleCode: Nullable<string>;
  groupName: Nullable<string>;
  roleName: Nullable<string>;
  email: Nullable<string>;
  mobileNo: string;
  phoneNo: Nullable<string>;
  position: Nullable<string>;
  createdMemberName: Nullable<string>;
  createdAt: Nullable<string>;
  updatedMemberName: Nullable<string>;
  updatedAt: Nullable<string>;
}

interface UserListParams {
  status: Status;
  roleId: number;
  email: string;
  userName: string;
  mobileNo: string;
}

const fetchUserList = async ({ status, roleId, email, userName, mobileNo }: UserListParams) => {
  const { data } = await client.post<User[]>(
    '/users/list',
    mapObject(
      {
        status,
        roleId,
        email,
        userName,
        mobileNo,
      },
      nullIfEmptyOrALL
    )
  );

  return data;
};

export interface UserRow extends User {
  active: boolean;
}

export const useUserListQuery = () => {
  const [filter, setFilter] = useState(INITIAL_USER_LIST_FORM_FIELD);
  const [enabled, setEnabled] = useState(false);
  const {
    gridList: userList,
    setGridList: setUserList,
    updatedRow: updatedUserList,
    rowChangeHandler: handleGridChange,
  } = useGridValue<UserRow>([], 'userId');
  const userRoleId = useUserRoleIdQuery(filter.roleCode);

  const { refetch } = useQuery<User[] | undefined, unknown, UserRow[] | undefined>(
    ['users', 'list', filter],
    () =>
      fetchUserList({
        ...filter,
        roleId: userRoleId,
      } as UserListParams),
    {
      enabled,
      keepPreviousData: true,
      select(users) {
        return users?.map((user) => ({ ...user, active: false }));
      },
      onSuccess(users) {
        if (users) {
          setUserList(users);
        }
      },
      onSettled() {
        setEnabled(false);
      },
    }
  );

  const setSearchFilter: typeof setFilter = (filter) => {
    setEnabled(true);
    setFilter(filter);
  };

  return { userList, setUserList, updatedUserList, handleGridChange, setSearchFilter, refetch };
};
