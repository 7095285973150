import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useMutation } from '@tanstack/react-query';

import { useInboundRequestListQuery } from './useInboundRequestListQuery';

export type InboundRequestStatus = 'approval' | 'reject';

interface updateInboundRequestStatusParams {
  status: InboundRequestStatus;
  ids: number[];
}

const updateInboundRequestStatus = async ({ status, ids }: updateInboundRequestStatusParams) => {
  const { data } = await client.put<number>(`/inbounds-adjustment/${status}`, { ids });

  return data;
};

const MESSAGES: Record<InboundRequestStatus, string> = {
  approval: '승인 처리되었습니다.',
  reject: '반려 처리되었습니다.',
};

export const useUpdateInboundRequestStatusMutation = () => {
  const inboundRequestListQuery = useInboundRequestListQuery();

  return useMutation(updateInboundRequestStatus, {
    onSuccess(_, { status }) {
      const message = MESSAGES[status];
      toastify(message);
      inboundRequestListQuery.refetch();
    },
  });
};
