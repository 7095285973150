import { useState } from 'react';

import Flex from '@Components/Flex';
import * as HoverCard from '@radix-ui/react-hover-card';

import { HoverMenuContent, HoverMenuWrapper, MenuItem } from './HoverMenu.style';
import { HoverMenuItemProps, HoverMenuProps } from './HoverMenu.type';

function HoverMenu({ title, children }: HoverMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HoverMenuWrapper open={isOpen} onOpenChange={setIsOpen}>
      <HoverCard.Trigger onMouseDown={() => setIsOpen(true)}>{title}</HoverCard.Trigger>
      <HoverMenuContent>
        <Flex direction='column'>{children}</Flex>
      </HoverMenuContent>
    </HoverMenuWrapper>
  );
}

function HoverMenuItem({ onClick, children }: HoverMenuItemProps) {
  return (
    <MenuItem type='button' onClick={onClick}>
      {children}
    </MenuItem>
  );
}

HoverMenu.Item = HoverMenuItem;
export default HoverMenu;
