import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INITIAL_STATE = {
  vendorId: '',
  vendorName: '',
  vendorType: 'ALL',
  activated: '',
};

export const VENDOR_INQUIRE_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('vendorId', textColumn),
      title: '거래처코드',
      disabled: true,
    },
    {
      ...keyColumn('activated', textColumn),
      title: '거래상태',
      disabled: true,
    },
    {
      ...keyColumn('displayName', textColumn),
      title: '거래처명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('companyName', textColumn),
      title: '법인명',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('ceoName', textColumn),
      title: '대표자명',
      disabled: true,
    },
    {
      ...keyColumn('businessNumber', textColumn),
      title: '사업자등록번호',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('businessType', textColumn),
      title: '업태',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('businessCategory', textColumn),
      title: '종목',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('companyAddress', textColumn),
      title: '주소',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('vendorTypes', textColumn),
      title: '거래방법',
      disabled: true,
      minWidth: 140,
    },
    {
      ...keyColumn('purchaseMethod', textColumn),
      title: '주문방법',
      disabled: true,
    },
    {
      ...keyColumn('paymentMethod', textColumn),
      title: '결제방법',
      disabled: true,
    },

    {
      ...keyColumn('paymentDate', textColumn),
      title: '결제일',
      disabled: true,
    },

    {
      ...keyColumn('bank', textColumn),
      title: '은행',
      disabled: true,
    },

    {
      ...keyColumn('accountNumber', textColumn),
      title: '계좌번호',
      disabled: true,
      minWidth: 150,
    },

    {
      ...keyColumn('depositor', textColumn),
      title: '예금주',
      disabled: true,
      minWidth: 150,
    },
    {
      ...keyColumn('salesManager', textColumn),
      title: '영업제휴담당자명',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('salesMangerPosition', textColumn),
      title: '영업제휴담당자 직책',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('salesMangerTelNo', textColumn),
      title: '영업제휴담당자 연락처',
      disabled: true,
      minWidth: 130,
    },
    {
      ...keyColumn('salesMangerEmail', textColumn),
      title: '영업제휴담당자 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('purchaseManager', textColumn),
      title: '발주담당자명',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('purchaseManagerPosition', textColumn),
      title: '발주담당자 직책',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('purchaseManagerTelNo', textColumn),
      title: '발주담당자 연락처',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('purchaseManagerEmail', textColumn),
      title: '발주담당자 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('moneyManager', textColumn),
      title: '정산담당자명',
      disabled: true,
    },
    {
      ...keyColumn('moneyManagerPosition', textColumn),
      title: '정산담당자 직책',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('moneyManagerTelNo', textColumn),
      title: '정산담당자 연락처',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('moneyManagerEmail', textColumn),
      title: '정산담당자 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('csManager', textColumn),
      title: 'CS담당자명',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('csManagerPosition', textColumn),
      title: 'CS담당자 직책',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('csManagerTelNo', textColumn),
      title: 'CS담당자 연락처',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('csManagerEmail', textColumn),
      title: 'CS담당자 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('representativeType', textColumn),
      title: 'PF 대표담당자',
      disabled: true,
    },
    {
      ...keyColumn('md1ManagerName', textColumn),
      title: 'PF MD1',
      disabled: true,
    },
    {
      ...keyColumn('md1ManagerTelNo', textColumn),
      title: 'PF MD1 연락처',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('md1ManagerEmail', textColumn),
      title: 'PF MD1 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('md2ManagerName', textColumn),
      title: 'PF MD2',
      disabled: true,
    },
    {
      ...keyColumn('md2ManagerTelNo', textColumn),
      title: 'PF MD2 연락처',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('md2ManagerEmail', textColumn),
      title: 'PF MD2 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('supplyManagerName', textColumn),
      title: 'PF 발주담당자명',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('supplyManagerTelNo', textColumn),
      title: 'PF 발주담당자 연락처',
      disabled: true,
      minWidth: 130,
    },
    {
      ...keyColumn('supplyManagerEmail', textColumn),
      title: 'PF 발주담당자 E-mail',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('pfMoneyManagerName', textColumn),
      title: 'PF 정산담당자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('pfMoneyManagerTelNo', textColumn),
      title: 'PF 정산담당자 연락처',
      disabled: true,
      minWidth: 130,
    },
    {
      ...keyColumn('pfMoneyManagerEmail', textColumn),
      title: 'PF 정산담당자 E-mail',
      disabled: true,
      minWidth: 180,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 140,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 140,
    },
  ],
};
