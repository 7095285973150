import { useGridValue } from '@src/Hooks';
import useRequest from '@src/Hooks/useRequest';
import { addIndexingData } from '@src/Utils/helper';
import type { IndexingData, Nullable } from '@src/Utils/type';

import { CLAIM_LIST_PAGE_SIZE } from './useClaimInquireList';

export interface ClaimInboundItem {
  reverseItemId: number;
  productItemId: number;
  productItemName: string;
  availableQuantity: number;
  acceptedQuantity: number;
  defectiveQuantity: number;
  parcel: string;
  invoiceNumber: string;
  updatedAt: string;
}

export const useClaimInboundItem = (reverseId: Nullable<number>) => {
  const { gridList, setGridList, setUpdatedRow } = useGridValue<IndexingData<ClaimInboundItem>>(
    [],
    'reverseItemId'
  );

  const query = useRequest<ClaimInboundItem[]>(
    {
      url: `/reverse/${reverseId}/items`,
    },
    {
      enabled: reverseId !== null,
      onSuccess(data) {
        setGridList(addIndexingData(data, 0, CLAIM_LIST_PAGE_SIZE));
        setUpdatedRow([]);
      },
    }
  );

  return {
    list: gridList,
    setList: setGridList,
    query,
  };
};
