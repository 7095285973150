import axios from 'axios';
import Cookies from 'js-cookie';
import Decode from 'jwt-decode';

import { PostRefreshLoginParams } from '@Features/Login/apis/type';

import { Nullable } from './type';

export interface TokenType {
  exp: number;
  iat: number;
  iss: string;
  login_id: string;
  user_id: number;
}

export const getDecodeToken = (token: Nullable<string> | undefined) =>
  token ? Decode<TokenType>(token) : '';

export const redirectToLoginPage = () => {
  window.location.href = '/login';
  return false;
};

export function validateToken() {
  const accessToken = getDecodeToken(Cookies.get('wms_tk')); // 인증 토큰
  const refreshToken = getDecodeToken(localStorage.getItem('wms_rftk')); // 리프레쉬 토큰
  if (!refreshToken) redirectToLoginPage(); // 리프레쉬 토큰 없을 경우 로그인화면으로 갱신

  let accessTokenExpTime = 0;

  try {
    accessToken ? (accessTokenExpTime = accessToken.exp) : redirectToLoginPage();
  } catch (err) {
    return redirectToLoginPage();
  }

  if (accessTokenExpTime < Date.now() / 1000) {
    // 토큰 갱신 시
    let refreshTokenExpTime = 0;

    try {
      refreshToken ? (refreshTokenExpTime = refreshToken.exp) : redirectToLoginPage();
    } catch (err) {
      return redirectToLoginPage();
    }

    if (refreshTokenExpTime > Date.now() / 1000) {
      const params: PostRefreshLoginParams = {
        loginId: accessToken ? accessToken.login_id : '',
        refreshToken: localStorage.getItem('wms_rftk'),
      };
      return new Promise((resolve) => {
        // 리토큰 갱신
        axios
          .post<string>(`${process.env.REACT_APP_BASE_URL}/auth/server/refresh`, params)
          .then((res) => {
            const { data } = res;
            if (data) {
              Cookies.set('wms_tk', data);
              resolve(data);
            }
          });
      });
    }
    // 리프레쉬 토큰 exp 만료 시
    Cookies.remove('wms_tk');
    localStorage.removeItem('wms_rftk');
    redirectToLoginPage();
  }
}
