import { useGridValue } from '@src/Hooks';
import useRequest from '@src/Hooks/useRequest';
import { addIndexingData } from '@src/Utils/helper';
import type { IndexingData, Nullable } from '@src/Utils/type';

import { CLAIM_LIST_PAGE_SIZE } from './useClaimInquireList';

export interface ClaimOutboundItem {
  outboundItemId: number;
  productItemId: number;
  productItemName: string;
  availableQuantity: number;
  receivedQuantity: number;
  parcel: string;
  invoiceNumber: string;
  updatedAt: string;
}

export const useClaimOutboundItem = (outboundId: Nullable<number>) => {
  const { gridList, setGridList, setUpdatedRow } = useGridValue<IndexingData<ClaimOutboundItem>>(
    [],
    'outboundItemId'
  );

  const query = useRequest<ClaimOutboundItem[]>(
    {
      url: `/app-outbounds/exchange/${outboundId}/items`,
    },
    {
      enabled: outboundId !== null,
      onSuccess(data) {
        setGridList(addIndexingData(data, 0, CLAIM_LIST_PAGE_SIZE));
        setUpdatedRow([]);
      },
    }
  );

  return {
    list: gridList,
    setList: setGridList,
    query,
  };
};
