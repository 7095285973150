import { useRef } from 'react';

import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

import Flex from '@Components/Flex';
import { WithLabel, WithLabelProps } from '@Components/Forms/WithLabel';

import { DatePicker, DatePickerProps, isAllowedDate } from '../DatePicker';

interface DateRangePickerProps extends Record<'start' | 'end', DatePickerProps> {
  id?: string;
}

function DateRangePicker({ id, start, end }: DateRangePickerProps) {
  const startDate = start.selected ? dayjs(start.selected).toDate() : undefined;
  const endDate = end.selected ? dayjs(end.selected).toDate() : undefined;
  const startDatePickerRef = useRef<ReactDatePicker>(null);
  const endDatePickerRef = useRef<ReactDatePicker>(null);

  const handleStartDateCalendarClose = () => {
    if (isAllowedDate(start.selected) && !end.selected) {
      endDatePickerRef.current?.setOpen(true);
    }
  };

  const handleEndDateCalendarClose = () => {
    if (!start.selected && isAllowedDate(end.selected)) {
      startDatePickerRef.current?.setOpen(true);
    }
  };

  return (
    <RangeWrapper gap={10} align='center'>
      <DatePicker
        id={id}
        ref={startDatePickerRef}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        onCalendarClose={handleStartDateCalendarClose}
        maxDate={endDate}
        {...start}
      />
      <span>~</span>
      <DatePicker
        ref={endDatePickerRef}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        onCalendarClose={handleEndDateCalendarClose}
        minDate={startDate}
        disabled={!startDate}
        {...end}
      />
    </RangeWrapper>
  );
}

export function DateRangePickerLabel({
  label,
  ...props
}: Omit<WithLabelProps, 'children'> & DateRangePickerProps) {
  return (
    <WithLabel label={label}>
      <DateRangePicker {...props} />
    </WithLabel>
  );
}

const RangeWrapper = styled(Flex)`
  & > span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`;
