import { useState } from 'react';

import { client } from '@src/client';
import { useQuery } from '@tanstack/react-query';
import { addIndexingData } from '@Utils/helper';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { Nullable, PageResponse } from '@Utils/type';

import {
  INITIAL_PRODUCT_LIST_DEPENDENT_SELECT,
  INITIAL_PRODUCT_LIST_FORM_FIELD,
  PRODUCT_ITEM_TYPE_OPTIONS,
  STOCK_STATUS_OPTIONS,
} from '../constant';

export interface Product {
  productItemName: Nullable<string>;
  productItemType: Nullable<string>;
  productStockType: Nullable<string>;
  stockStatusValue: Nullable<string>;
  productGroup1Name: Nullable<string>;
  productGroup2Name: Nullable<string>;
  productGroup3Name: Nullable<string>;
  productGroup4Name: Nullable<string>;
  vendorId: Nullable<number>;
  vendorName: Nullable<string>;
  itemBarcode: Nullable<string>;
  boxBarcode: Nullable<string>;
  boxUnit: Nullable<number>;
  width: Nullable<number>;
  length: Nullable<number>;
  height: Nullable<number>;
  weight: Nullable<number>;
  volume: Nullable<number>;
  storageTemperature: Nullable<string>;
  shelfLifeDays: Nullable<string>;
  createdUserName: Nullable<string>;
  createdAt: Nullable<string>;
  updatedUserName: Nullable<string>;
  updatedAt: Nullable<string>;
}

type ProductItemType = typeof PRODUCT_ITEM_TYPE_OPTIONS[number]['value'];
type StockStatus = typeof STOCK_STATUS_OPTIONS[number]['value'];
export interface FetchProductListParams {
  productItemType: ProductItemType;
  stockStatus: StockStatus;

  productGroup1Id: 'ALL' | number;
  productGroup2Id: 'ALL' | number;
  productGroup3Id: 'ALL' | number;

  vendorId: string;
  productItemName: string;
  productItemId: string;

  pageNumber: number;
}

export const PAGE_SIZE = 500;

const fetchProductList = async ({
  productItemType,
  stockStatus,
  vendorId,
  productItemName,
  productItemId,
  productGroup1Id,
  productGroup3Id,
  productGroup2Id,
  pageNumber,
}: FetchProductListParams) => {
  const { data } = await client.post<PageResponse<Product[]>>(
    '/product-items/list',
    mapObject(
      {
        productItemType,
        stockStatus,
        vendorId: Number(vendorId) || null,
        productItemName,
        productItemId,
        productGroup1Id,
        productGroup3Id,
        productGroup2Id,
        pageNumber,
        pageSize: PAGE_SIZE,
      },
      nullIfEmptyOrALL
    )
  );

  return data;
};

export const useProductListQuery = () => {
  const [filter, setFilter] = useState<FetchProductListParams>({
    ...INITIAL_PRODUCT_LIST_FORM_FIELD,
    ...INITIAL_PRODUCT_LIST_DEPENDENT_SELECT,
    pageNumber: 0,
  });
  const [enabled, setEnabled] = useState(false);

  const { data } = useQuery(['product-items', 'list', filter], () => fetchProductList(filter), {
    enabled,
    onSettled() {
      setEnabled(false);
    },
    select(data) {
      return {
        total: data?.total,
        results: addIndexingData(data?.results, filter.pageNumber, PAGE_SIZE),
      };
    },
    keepPreviousData: true,
  });

  const setSearchFilter: typeof setFilter = (filter) => {
    setEnabled(true);
    setFilter(filter);
  };

  return { data, setSearchFilter };
};
