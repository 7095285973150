import { toastify } from '@Components/Toast';
import { client } from '@src/client';
import { useMutation } from '@tanstack/react-query';

interface updateUserActiveParams {
  userIds: number[];
  active: 'able' | 'disabled';
}

const updateUserActive = async ({ userIds, active }: updateUserActiveParams) => {
  const { data } = await client.put<number[]>(`/users/${active}`, {
    ids: userIds,
  });

  return data;
};

export const useUpdateUserActiveMutation = () => {
  return useMutation(updateUserActive, {
    onSuccess(_, { active }) {
      toastify({ able: '활성화되었습니다.', disabled: '비활성화되었습니다.' }[active]);
    },
  });
};
