import { useRecoilValue } from 'recoil';

import { fullScreenActivityIndicatorAtom } from '@Stores/ActivityIndicator/fullScreenActivityIndicator.atom';

import ActivityIndicator from './ActivityIndicator';

function FullScreenActivityIndicatorContainer() {
  const activityIndicatorState = useRecoilValue(fullScreenActivityIndicatorAtom);

  return <ActivityIndicator {...activityIndicatorState} fullScreen zIndex={101} />;
}

export default FullScreenActivityIndicatorContainer;
