import { client } from '@src/client';
import { replaceSpaceToNull } from '@Utils/object';
import { PageResponse } from '@Utils/type';

import { ProductItemRow } from '../ProductCodeModal.type';

import { PostProductListItemCommonParams } from './type';

export const postProductItemListCommon = async (params: PostProductListItemCommonParams) => {
  const body = {
    ...replaceSpaceToNull({ ...params }),
    pageNumber: params.pageNumber,
    pageSize: 200,
  };

  const { data } = await client.post<PageResponse<ProductItemRow[]>>(
    '/product-items/list/common',
    body
  );

  return data;
};
