import FileUploader from '@Components/FileUploader';
import Flex from '@Components/Flex';

import { ExcelDownLoadLink } from '../../InboundHistory.style';

const excelUrl = process.env.REACT_APP_EXCEL_URL;

function ExcelFileUpload({
  onFileUpload,
  fileName,
}: {
  onFileUpload: (file: FileList) => void;
  fileName: string;
}) {
  return (
    <Flex align='center' colGap={12}>
      <FileUploader onChange={onFileUpload} />
      <ExcelDownLoadLink download href={`${excelUrl}${fileName}.xlsx`}>
        엑셀양식
      </ExcelDownLoadLink>
    </Flex>
  );
}

export default ExcelFileUpload;
