import { fontWeights, sizes } from '@src/Styles/ThemeStyle';

import { StyledTypography } from './Typography.style';
import { TypographyProps } from './Typography.type';

function Typography({
  as,
  fontWeight = 400,
  size = 1.6,
  color = 'gray.900',
  align = 'left',
  isTruncated = false,
  children,
}: TypographyProps) {
  return (
    <StyledTypography
      as={as}
      fontWeight={fontWeight}
      size={size}
      color={color}
      align={align}
      isTruncated={isTruncated}
    >
      {children}
    </StyledTypography>
  );
}

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const HEADING_FONT_SIZES: Record<HeadingTag, keyof typeof sizes> = {
  h1: 2.4,
  h2: 2.2,
  h3: 2,
  h4: 2,
  h5: 1.8,
  h6: 1.8,
} as const;

const HEADING_FONT_WEIGHTS: Record<HeadingTag, keyof typeof fontWeights> = {
  h1: 700,
  h2: 600,
  h3: 600,
  h4: 500,
  h5: 500,
  h6: 500,
};

export function Heading({
  as = 'h2',
  ...props
}: {
  as?: HeadingTag;
} & TypographyProps) {
  return (
    <Typography
      as={as}
      size={HEADING_FONT_SIZES[as as HeadingTag]}
      fontWeight={HEADING_FONT_WEIGHTS[as as HeadingTag]}
      {...props}
    />
  );
}

export function Text({
  as = 'p',
  ...props
}: {
  as?: 'p' | 'i' | 'u' | 'abbr' | 'del' | 'em' | 'ins' | 'kbd' | 'mark' | 's' | 'span' | 'strong';
} & TypographyProps) {
  return <Typography as={as} {...props} />;
}
