import dayjs from 'dayjs';

import { DatePickerLabel } from '@Components/Date/DatePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import SearchProgressingModal from '@Components/Modals/SearchProgressingModal';
import SearchCompleteBox from '@Components/SearchCompleteBox';
import { Heading } from '@Components/Typography';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { toastify } from '@src/Components/Toast';
import { updateAsExcel } from '@Utils/excel';
import { performIfConfirmed } from '@Utils/helper';

import { useCloseStockMutation } from './apis/useCloseStockMutation';
import { useMonthlyReceiptsAndPaymentsQuery } from './apis/useMonthlyReceiptsAndPaymentsQuery';

export const INITIAL_MONTHLY_RECEIPTS_AND_PAYMENTS_FILTER = {
  standardDay: dayjs().subtract(1, 'month').format('YYYY-MM'),
  centerCode: 'C0001',
};

function MonthlyReceiptsAndPayments() {
  const formField = useFormField(INITIAL_MONTHLY_RECEIPTS_AND_PAYMENTS_FILTER);
  const monthlyReceiptsAndPaymentsQuery = useMonthlyReceiptsAndPaymentsQuery();
  const centerCodeModal = useDialog('CenterCodeModal');
  const closeStockMutation = useCloseStockMutation();
  const searchProgressingModal = useDialog('SearchProgressingModal');

  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '선택기',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            formField.setForm((prevForm) => ({
              ...prevForm,
              centerCode: rowData.centerCode as string,
            }));
          }}
        />
      ),
    });
  };

  const searchMonthlyReceiptsAndPayments = () => {
    if (!formField.form.standardDay) {
      toastify('조회기준월은 필수값입니다.', { type: 'error' });
      return;
    }

    if (!formField.form.centerCode) {
      toastify('센터코드는 필수값입니다.', { type: 'error' });
      return;
    }

    searchProgressingModal.openDialog({
      size: 'small',
      content: <SearchProgressingModal />,
      onOpenChange: () => null,
    });

    monthlyReceiptsAndPaymentsQuery.setSearchFilter(formField.form);
  };

  const downloadExcel = () => {
    showActivityIndicator();

    updateAsExcel(
      monthlyReceiptsAndPaymentsQuery.list.map((item) => ({
        centerCode: monthlyReceiptsAndPaymentsQuery.filter.centerCode,
        ...item,
      })),
      '다운로드_월별수불대사',
      {
        cb: () => {
          hideActivityIndicator();
        },
      }
    );
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={searchMonthlyReceiptsAndPayments} isPermissionRequired={false}>
          조회
        </Button>
        <Button
          disabled={
            monthlyReceiptsAndPaymentsQuery.useQueryResult.isFetching ||
            monthlyReceiptsAndPaymentsQuery.list.length === 0
          }
          onClick={downloadExcel}
          isPermissionRequired={false}
        >
          엑셀
        </Button>

        <Button
          onClick={() =>
            performIfConfirmed('재고마감 후에는 수정이 불가능합니다.\n진행하시겠습니까?', () => {
              closeStockMutation.mutate(formField.form.standardDay, {
                onSuccess() {
                  toastify(`${formField.form.standardDay}월 재고마감이 완료됐습니다`);
                },
              });
            })
          }
        >
          재고마감
        </Button>
      </FilterHeader>

      <FormField>
        <DatePickerLabel
          {...formField.attributes.standardDay}
          required
          label='조회 기준월'
          maxDate={new Date(INITIAL_MONTHLY_RECEIPTS_AND_PAYMENTS_FILTER.standardDay)}
          dateFormat='yyyy-MM'
          dayjsFormat='YYYY-MM'
          showMonthYearPicker
        />

        <InputButtonLabel
          {...formField.attributes.centerCode}
          label='센터코드'
          readOnly={false}
          buttonClick={openCenterCodeModal}
          required
        />
      </FormField>

      <Flex direction='column' gap={10}>
        <Heading>수불 대사 (월별)</Heading>
        {monthlyReceiptsAndPaymentsQuery.isCompleted && <SearchCompleteBox />}
      </Flex>
    </div>
  );
}

export default MonthlyReceiptsAndPayments;
