import { FormFieldAttributes } from '@src/Hooks';
import { prependAllOption } from '@src/Utils/options';

import { SelectLabel } from '../Forms/Select';

import { useProductCategoryQuery } from './useProductCategoryQuery';
import { getDependentChildOptions } from './utils';

interface ProductCategorySelectProps {
  dependentSelect: {
    form: {
      readonly productGroup1Id: 'ALL';
      readonly productGroup2Id: 'ALL';
      readonly productGroup3Id: 'ALL';
    };
    attributes: FormFieldAttributes<'productGroup1Id' | 'productGroup2Id' | 'productGroup3Id'>;
    resetForm: () => void;
  };
}

function ProductCategorySelect({ dependentSelect }: ProductCategorySelectProps) {
  const productCategoryQuery = useProductCategoryQuery();

  return (
    <>
      <SelectLabel
        {...dependentSelect.attributes.productGroup1Id}
        label='대분류'
        options={prependAllOption(productCategoryQuery.data?.productGroup1Id)}
      />
      <SelectLabel
        {...dependentSelect.attributes.productGroup2Id}
        label='중분류'
        options={prependAllOption(
          getDependentChildOptions({
            parentOptions: productCategoryQuery.data?.productGroup1Id,
            childOptions: productCategoryQuery.data?.productGroup2Id,
            childValue: dependentSelect.form.productGroup1Id,
          })
        )}
      />
      <SelectLabel
        {...dependentSelect.attributes.productGroup3Id}
        label='소분류'
        options={prependAllOption(
          getDependentChildOptions({
            parentOptions: productCategoryQuery.data?.productGroup2Id,
            childOptions: productCategoryQuery.data?.productGroup3Id,
            childValue: dependentSelect.form.productGroup2Id,
          })
        )}
      />
    </>
  );
}

export default ProductCategorySelect;
