import { useState } from 'react';

import ProcessedCirculateManageFilter from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageFilter';
import ProcessedCirculateManageResult from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageResult';
import Flex from '@src/Components/Flex';
import { Nullable } from '@src/Utils/type';

import { useProcessingDetailQuery } from './hooks';

function ProcessedCirculateHistory() {
  const [processingId, setProcessingId] = useState<Nullable<number>>(null);
  const processingDetailQuery = useProcessingDetailQuery(processingId);

  return (
    <Flex as='section' direction='column'>
      <ProcessedCirculateManageFilter
        processingId={processingId}
        setProcessingId={setProcessingId}
        refetchProcessingDetail={processingDetailQuery.refetch}
      />
      <ProcessedCirculateManageResult
        processingId={processingId}
        setProcessingId={setProcessingId}
        processingDetail={processingDetailQuery.data}
      />
    </Flex>
  );
}

export default ProcessedCirculateHistory;
