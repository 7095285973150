import { useState } from 'react';

import { useFullScreenActivityIndicator, useGridValue } from '@src/Hooks';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { addIndexingData } from '@src/Utils/helper';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import type { IndexingData } from '@src/Utils/type';

import { INITIAL_SHELF_LIFE_LIST_FILTER } from '../constant';

export interface ShelfLifeStocksRow {
  centerId: number;
  centerCode: string;
  dateOfRecord: string;
  productStockType: string;
  productItemId: number;
  productItemName: string;
  storageType: string;
  location: string;
  quantity: number;
  itemBarcode: string;
  shelfLifeDays: string;
  temperature: string;
  imminentStockConversionDays: number;
  noShippingConversionDays: number;
}

export interface GetShelfLifeStocksListParams {
  centerId: string;
  dateOfRecord: string;
  productStockType: string;
  storageType: string;
  temperature: string;
  locationType: string;
  productItemIds: string;
  productItemBarcodes: string;
  imminentStockTarget: string;
  noShippingTarget: string;
}

const initialFilter = { ...INITIAL_SHELF_LIFE_LIST_FILTER };

const getShelfLifeStocksListRequest = ({
  imminentStockTarget,
  noShippingTarget,
  ...rest
}: GetShelfLifeStocksListParams) => {
  const [isImminentStockTarget, isNoShippingTarget] = [
    imminentStockTarget === 'checked',
    noShippingTarget === 'checked',
  ];

  return {
    url: '/shelf-life/stocks/list',
    method: 'POST',
    data: mapObject(
      {
        imminentStockTarget: isImminentStockTarget,
        noShippingTarget: isNoShippingTarget,
        ...rest,
      },
      nullIfEmptyOrALL
    ),
  };
};

export const useShelfLifeStocksList = () => {
  const { hideActivityIndicator } = useFullScreenActivityIndicator();
  const [shelfLifeStocksListCount, setShelfLifeStocksListCount] = useState(0);
  const { gridList, setGridList, setUpdatedRow } = useGridValue<IndexingData<ShelfLifeStocksRow>>(
    [],
    'no'
  );

  const { setSearchFilter, useQueryResult } = useSearchRequest<
    typeof initialFilter,
    { results: ShelfLifeStocksRow[]; totalCount: number }
  >({
    initialFilter,
    getRequest: getShelfLifeStocksListRequest,
    options: {
      onSuccess({ results, totalCount }) {
        setShelfLifeStocksListCount(totalCount ?? 0);
        setGridList(addIndexingData(results, 0, 100));
        setUpdatedRow([]);
      },
      onSettled() {
        hideActivityIndicator();
      },
    },
  });

  return {
    setSearchFilter,
    shelfLifeStocksList: gridList,
    shelfLifeStocksListCount,
    refetch: useQueryResult.refetch,
  };
};
