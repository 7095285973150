import Box from '@src/Components/Box';
import DataSheetGrid from '@src/Components/DataSheetGrid';
import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import { useDialog } from '@src/Hooks';
import ModalFooter from '@src/Layout/components/ModalFooter';

import { useExcelHistory } from './apis';
import { DownLoadLinkCell } from './Cell';
import { EXCEL_HISTORY_GRID_COLUMNS } from './constant';

function ExcelHistoryModal({ targetUrl }: { targetUrl: string }) {
  const { data } = useExcelHistory(targetUrl);
  const { resetDialog } = useDialog('ExcelHistory');

  return (
    <Box mt-20>
      <Flex direction='column' rowGap={10}>
        <DataSheetGrid
          columns={EXCEL_HISTORY_GRID_COLUMNS}
          value={data ?? []}
          customRendererList={[{ id: 'download_link', component: DownLoadLinkCell }]}
        />
      </Flex>
      <ModalFooter>
        <Button onClick={resetDialog}>확인</Button>
      </ModalFooter>
    </Box>
  );
}

export default ExcelHistoryModal;
