import { useMemo, useRef, useState } from 'react';

import { toastify } from '@Components/Toast';
import { useDialog, useGridValue } from '@Hooks';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import { useMutation } from '@tanstack/react-query';
import { addIndexingData } from '@Utils/helper';
import { IndexingData } from '@Utils/type';

import { InboundRow, ProductRow } from '../InboundHistory.type';

import {
  postAddProductItem,
  postInboundsExcelDownload,
  postTransmission,
  putCancellation,
  putCancelTransmission,
  putInbounds,
  putRemoveProductItem,
  useInboundListQuery,
  useInboundsItemsQuery,
} from './index';
import { PostAddProductItemParams, PostInboundsListParams, PutInboundsParams } from './type';

export type InboundGridIndexingData = IndexingData<InboundRow>;

export const useInboundList = (
  params: PostInboundsListParams,
  inboundIndex: number | undefined
) => {
  const totalCount = useRef(0);
  const [enabled, setEnabled] = useState(false);
  const inboundGrid = useGridValue<InboundGridIndexingData>([], 'inboundId');
  const { productGrid } = useProductList(inboundGrid.gridList, inboundIndex);

  const search = () => setEnabled(true);

  useInboundListQuery(
    enabled,
    params,
    (data) => {
      if (data) {
        const inboundList = addIndexingData(
          data.results,
          params.pageNumber,
          params.pageSize ?? 100
        );

        inboundGrid.setGridList(inboundList);
        totalCount.current = data.total;
      }
    },
    () => setEnabled(false)
  );

  const { mutate: setSave } = useMutation(putInbounds, {
    onSuccess: () => {
      toastify('저장되었습니다.');
      inboundGrid.setUpdatedRow([]);
      productGrid.setUpdatedRow([]);
    },
    onSettled: () => {
      search();
    },
  });

  const { mutate: setTransmission } = useMutation(postTransmission, {
    onSuccess: () => {
      toastify('전송되었습니다.');
      search();
      inboundGrid.setUpdatedRow([]);
    },
  });

  const { mutate: setCancelTransmission } = useMutation(putCancelTransmission, {
    onSuccess: () => {
      toastify('전송취소되었습니다.');
      search();
      inboundGrid.setUpdatedRow([]);
    },
  });

  const { mutate: setCancellation } = useMutation(putCancellation, {
    onSuccess: () => {
      toastify('취소 처리 되었습니다.');
      search();
      inboundGrid.setUpdatedRow([]);
    },
  });

  const { downloadExcel: setExcelDownload } = useMassiveExcelRequest(postInboundsExcelDownload);

  const save = (params: PutInboundsParams) => setSave(params);
  const transmission = (params: { ids: number[] }) => setTransmission(params);
  const cancelTransmission = (params: { ids: number }) => setCancelTransmission(params);
  const cancellation = (params: { ids: number[] }) => setCancellation(params);
  const excelDownload = (params: PostInboundsListParams) => setExcelDownload(params);

  return {
    inboundGrid,
    inboundListTotal: totalCount.current,
    search,
    save,
    transmission,
    cancelTransmission,
    cancellation,
    excelDownload,
  };
};

export const useProductList = (inboundGrid: InboundRow[], inboundIndex: number | undefined) => {
  const productGrid = useGridValue<ProductRow>([], 'inboundItemId');
  const { resetDialog } = useDialog('ProductCodeModal');

  const inboundItemId = useMemo(
    () => (inboundIndex === undefined ? null : inboundGrid[inboundIndex].inboundId),
    [inboundIndex]
  );

  const { refetch: productListRefetch } = useInboundsItemsQuery({
    inboundItemId,
    onSuccess: (data) => {
      if (data) {
        productGrid.setGridList(data);
      }
    },
  });

  const { mutate: addProductItem } = useMutation(postAddProductItem, {
    onSuccess: () => {
      toastify('추가되었습니다.');
      resetDialog();
      productListRefetch();
    },
  });

  const { mutate: removeProductItem } = useMutation(putRemoveProductItem, {
    onSuccess: () => {
      toastify('삭제되었습니다.');
      productListRefetch();
    },
  });

  const addItem = (params: PostAddProductItemParams) => addProductItem(params);
  const removeItem = (params: { ids: number[] }) => removeProductItem(params);

  return {
    productGrid,
    addItem,
    removeItem,
  };
};
