import styled from 'styled-components';

import Flex from '@Components/Flex';
import FormField from '@Layout/components/FormField';

export const ModalForm = styled(FormField)`
  margin-bottom: 3.2rem;
`;

export const MiniPagination = styled(Flex)`
  transform: scale(0.8);
`;
