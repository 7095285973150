import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { useMutation } from '@tanstack/react-query';

import { CLAIM_LIST_PAGE_SIZE, GetClaimInquireListParams } from './useClaimInquireList';

export interface ClaimListExcelRow {
  reverseCode: string;
  externalId: number;
  category: string;
  status: string;
  reasonType: string;
  requestDay: string;
  completeDay: string;
  wikey: string;
  wokey: string;
  outboundStatus: string;
  orderId: string;
  deliveryType: string;
  productItemId: number;
  productItemName: string;
  returnAvailableQuantity: number;
  returnAcceptedQuantity: number;
  returnDefectiveQuantity: number;
  returnParcel: string;
  returnInvoiceNumber: string;
  outboundAvailableQuantity: number;
  outboundReceivedQuantity: number;
  outboundParcel: string;
  outboundInvoiceNumber: string;
  createdAt: string;
  updatedAt: string;
}

export const postClaimInquireExcel = async (params: GetClaimInquireListParams) => {
  const body = mapObject(
    { ...params, pageSize: params.pageSize || CLAIM_LIST_PAGE_SIZE },
    nullIfEmptyOrALL
  );
  const { data } = await client.post<ClaimListExcelRow[]>('/reverse/excel-download', body);
  return data;
};

export const useClaimInquireExcel = () => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const { mutate } = useMutation(postClaimInquireExcel, {
    onMutate: () => showActivityIndicator(),
    onSuccess: () => {
      hideActivityIndicator();
      toastify('완료되었습니다.');
    },
    onError: () => hideActivityIndicator(),
  });

  return { excelDownloadMutate: mutate };
};
