import StatusCell from '@src/Components/Cell/StatusCell';
import { Color } from '@src/Components/Typography/Typography.type';
import { INBOUND_STATUS_CODE_COLOR } from '@src/Constants/inbound';

import { InboundDetailRow } from './InboundDetail.type';

export function InboundDetailStatusCell({ rowData }: { rowData: InboundDetailRow }) {
  const { inboundStatusCodeValue } = rowData;
  const textColor = INBOUND_STATUS_CODE_COLOR[
    inboundStatusCodeValue as keyof typeof INBOUND_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={inboundStatusCodeValue} textColor={textColor} />;
}
