import { ButtonHTMLAttributes, MouseEvent, ReactNode } from 'react';

import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';

import { useUserMenuAndRoleListQuery } from '@src/Apis/user';
import { currentTabAtom } from '@Stores/Tab/tab.atom';
import { colors } from '@Styles/ThemeStyle';
import { getDecodeToken } from '@Utils/token';

import { StyledButton } from './Button.style';

export type ButtonSizes = 'x-small' | 'small' | 'medium' | 'large';
export type ButtonColors = keyof typeof colors;
export type ButtonVariants = 'primary' | 'secondary' | 'outline';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSizes;
  color?: ButtonColors;
  variant?: ButtonVariants;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  disabled?: boolean;
  children: ReactNode;
  withInput?: boolean;
  isPermissionRequired?: boolean;
}

function Button({
  size = 'medium',
  color = 'blue',
  variant = 'outline',
  type = 'button',
  onClick,
  disabled,
  children,
  withInput,
  isPermissionRequired = true,
  className,
}: ButtonProps) {
  const accessToken = getDecodeToken(Cookies.get('wms_tk'));
  const { data } = useUserMenuAndRoleListQuery({ userId: accessToken ? accessToken.user_id : -1 });
  const { id } = useRecoilValue(currentTabAtom);

  const onClickHandler = debounce((e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
  }, 300);

  if (isPermissionRequired && data?.userRoleList[id as string]?.permission !== 3) {
    return null;
  }

  return (
    <StyledButton
      className={className}
      size={size}
      color={color}
      variant={variant}
      type={type}
      onClick={onClickHandler}
      disabled={disabled}
      withInput={withInput}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
