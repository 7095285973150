import { keyColumn, textColumn } from 'react-datasheet-grid';

export const STOCK_INQUIRE_INITIAL_STATE = {
  centerCode: '',
  productItemType: '',
  stockStatus: '',
  productItemIds: '',
};

export const INITIAL_PRODUCT_LIST_DEPENDENT_SELECT = {
  productGroup1Id: 'ALL',
  productGroup2Id: 'ALL',
  productGroup3Id: 'ALL',
} as const;

export const PRODUCT_ITEM_TYPE_OPTIONS = [
  { label: '전체', value: '' },
  { label: '일반', value: 'GENERAL' },
  { label: 'PB', value: 'PRIVATE_BRAND' },
  { label: '단독', value: 'INDEPENDENT' },
  { label: '부자재', value: 'SUBSIDIARY' },
];

export const STOCK_STATUS_OPTIONS = [
  { label: '전체', value: '' },
  { label: '판매유지', value: 'KEEP' },
  { label: '일시품절', value: 'SOLD_OUT' },
  { label: '신상품대기', value: 'WAITING' },
  { label: '판매종료예정', value: 'EXPECTED_SALE_END' },
  { label: '판매종료', value: 'SALE_END' },
  { label: '등록오류', value: 'ERROR' },
  { label: '기타', value: 'ETC' },
];

export const STOCK_INQUIRE_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemType', textColumn),
      title: '품목유형',
      disabled: true,
    },
    {
      ...keyColumn('stockStatusValue', textColumn),
      title: '재고상태',
      disabled: true,
    },
    {
      ...keyColumn('productGroup1Name', textColumn),
      title: '대분류',
      disabled: true,
    },
    {
      ...keyColumn('productGroup2Name', textColumn),
      title: '중분류',
      disabled: true,
    },
    {
      ...keyColumn('productGroup3Name', textColumn),
      title: '소분류',
      disabled: true,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 500,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '가용재고',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('allocationQuantity', textColumn),
      title: '출고예정재고',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('stopQuantity', textColumn),
      title: '보류재고',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('damageQuantity', textColumn),
      title: '파손재고',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('standByQuantity', textColumn),
      title: '입고예정재고',
      disabled: true,
      minWidth: 200,
    },
    {
      ...keyColumn('totalQuantity', textColumn),
      title: '총 재고',
      disabled: true,
      minWidth: 200,
    },
  ],
};
