import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

export const CLOSED_STOCK_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('sameStockStatus', textColumn),
      title: '일치여부',
      disabled: true,
    },
    {
      ...keyColumn('wmsAvailable', textColumn),
      title: '가용재고',
      disabled: true,
      headerClassName: 'blue',
      minWidth: 130,
    },
    {
      ...keyColumn('wmsAllocation', textColumn),
      title: '출고예정재고',
      disabled: true,
      headerClassName: 'blue',
      minWidth: 130,
    },
    {
      ...keyColumn('wmsStop', textColumn),
      title: '보류재고',
      disabled: true,
      headerClassName: 'blue',
    },
    {
      ...keyColumn('wmsDamage', textColumn),
      title: '파손재고',
      disabled: true,
      headerClassName: 'blue',
      minWidth: 130,
    },
    {
      ...keyColumn('wmsStandby', textColumn),
      title: '입고예정재고',
      disabled: true,
      headerClassName: 'blue',
      minWidth: 130,
    },
    {
      ...keyColumn('wmsTotal', textColumn),
      title: '총 재고',
      disabled: true,
      headerClassName: 'blue',
    },
    {
      ...keyColumn('externalAvailable', textColumn),
      title: '가용재고',
      disabled: true,
      headerClassName: 'green',
    },
    {
      ...keyColumn('externalAllocation', textColumn),
      title: '출고예정재고',
      disabled: true,
      headerClassName: 'green',
    },
    {
      ...keyColumn('externalStop', textColumn),
      title: '보류재고',
      disabled: true,
      headerClassName: 'green',
    },
    {
      ...keyColumn('externalDamage', textColumn),
      title: '파손재고',
      disabled: true,
      headerClassName: 'green',
    },
    {
      ...keyColumn('externalStandby', textColumn),
      title: '입고예정재고',
      disabled: true,
      headerClassName: 'green',
    },
    {
      ...keyColumn('externalTotal', textColumn),
      title: '총 재고',
      disabled: true,
      headerClassName: 'green',
    },
  ],
};

export const MATCH_OPTIONS = [
  { label: '전체', value: '' },
  { label: '일치', value: 'Y' },
  { label: '불일치', value: 'N' },
];

export const INITIAL_STATE = {
  inquireDate: dayjs().format('YYYY-MM-DD'),
  centerCode: '',
  match: '',
};
