import { useEffect, useRef, useState } from 'react';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { client } from '@src/client';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import { useQuery } from '@tanstack/react-query';
import { updateAsExcel } from '@Utils/excel';
import { addIndexingData } from '@Utils/helper';
import { IndexingData, PageResponse } from '@Utils/type';

import { ClosedStockRow } from '../ClosedStockInquire.type';

import { ClosedStockListParams, ClosedStockRowsResponse } from './type';

const PAGE_SIZE = 50;

export const getClosedStockList = async ({
  isExcel,
  match,
  centerId,
  inquireDate,
  page,
}: ClosedStockListParams) => {
  const matchResult = match === '' ? undefined : !!(match === 'Y');

  const { data } = await client.get<PageResponse<ClosedStockRowsResponse[]>>(
    `/stats/close/centers/${centerId}/dates/${inquireDate}`,
    {
      params: {
        excel: isExcel,
        match: matchResult,
        size: PAGE_SIZE,
        page,
      },
    }
  );
  return data;
};

export const useClosedStockExcelQuery = (params: ClosedStockListParams) => {
  const { hideActivityIndicator } = useFullScreenActivityIndicator();
  const { data: centerCodeList } = useCenterCodeList();
  const centerId =
    params.centerId ??
    centerCodeList?.find(({ centerCode }) => centerCode === params.centerCode)?.id;

  return useQuery(['closed-stock-excel'], () => getClosedStockList({ ...params, centerId }), {
    enabled: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      const rows = data.results.map(
        ({
          productItemName,
          productItemId,
          sameStockStatus,
          externalStockCountByStatusDaily,
          wmsStockCountByStatusDaily,
        }) => ({
          센터코드: params.centerCode,
          품목코드: productItemId,
          품목명: productItemName,
          일치여부: sameStockStatus ? '일치' : '불일치',
          '펫프렌즈 Avaliable': wmsStockCountByStatusDaily.available,
          '펫프렌즈 Allocated': wmsStockCountByStatusDaily.allocation,
          '펫프렌즈 Stop': wmsStockCountByStatusDaily.stop,
          '펫프렌즈 Damage': wmsStockCountByStatusDaily.damage,
          '펫프렌즈 Stand_by': wmsStockCountByStatusDaily.standby,
          '펫프렌즈 TOTAL': wmsStockCountByStatusDaily.total,
          'CJ Avaliable': externalStockCountByStatusDaily.available,
          'CJ Allocated': externalStockCountByStatusDaily.allocation,
          'CJ Stop': externalStockCountByStatusDaily.stop,
          'CJ Damage': externalStockCountByStatusDaily.damage,
          'CJ Stand_by': externalStockCountByStatusDaily.standby,
          'CJ TOTAL': externalStockCountByStatusDaily.total,
        })
      );
      updateAsExcel(rows, '다운로드_마감재고조회', { cb: () => hideActivityIndicator() });
    },
    onError: () => hideActivityIndicator(),
  });
};

export const useClosedStockListQuery = (params: ClosedStockListParams) => {
  const totalCountRef = useRef<number>(0);
  const [closedStockList, setClosedStockList] = useState<IndexingData<ClosedStockRow>[]>([]);
  const { hideActivityIndicator } = useFullScreenActivityIndicator();
  const { data: centerCodeList } = useCenterCodeList();

  const existCenterId =
    centerCodeList?.find((item) => item.centerCode === params.centerCode)?.id || null;

  const { refetch } = useQuery(
    ['closed-stock-list', params],
    () => getClosedStockList({ ...params, centerId: existCenterId }),
    {
      keepPreviousData: true,
      enabled: false,
      notifyOnChangeProps: ['data'],
      onSuccess: (data) => {
        const { results, total } = data;
        const rows = results?.map(
          ({
            productItemId,
            productItemName,
            sameStockStatus,
            stockDailyStatExternalId,
            stockDailyStatWMSId,
            externalStockCountByStatusDaily,
            wmsStockCountByStatusDaily,
          }) => ({
            productItemId,
            productItemName,
            stockDailyStatExternalId,
            sameStockStatus: sameStockStatus ? '일치' : '불일치',
            stockDailyStatWMSId,
            wmsAllocation: wmsStockCountByStatusDaily.allocation,
            wmsAvailable: wmsStockCountByStatusDaily.available,
            wmsDamage: wmsStockCountByStatusDaily.damage,
            wmsProductItemId: wmsStockCountByStatusDaily.productItemId,
            wmsSaveDateTime: wmsStockCountByStatusDaily.saveDateTime,
            wmsStandby: wmsStockCountByStatusDaily.standby,
            wmsStop: wmsStockCountByStatusDaily.stop,
            wmsTotal: wmsStockCountByStatusDaily.total,
            externalAllocation: externalStockCountByStatusDaily.allocation,
            externalAvailable: externalStockCountByStatusDaily.available,
            externalDamage: externalStockCountByStatusDaily.damage,
            externalProductItemId: externalStockCountByStatusDaily.productItemId,
            externalSaveDateTime: externalStockCountByStatusDaily.saveDateTime,
            externalStandby: externalStockCountByStatusDaily.standby,
            externalStop: externalStockCountByStatusDaily.stop,
            externalTotal: externalStockCountByStatusDaily.total,
          })
        );

        totalCountRef.current = total;
        setClosedStockList(addIndexingData(rows, params.page ?? 0, PAGE_SIZE));
      },
      onSettled: () => {
        hideActivityIndicator();
      },
    }
  );

  useEffect(() => {
    if (params.centerCode) refetch();
  }, [params.page]);

  return { closedStockList, setClosedStockList, refetch, total: totalCountRef.current };
};
