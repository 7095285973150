import { intColumn, keyColumn, textColumn } from 'react-datasheet-grid';

export const AddOutGridColumnOption = [
  {
    ...keyColumn('errorMessage', textColumn),
    title: '체크메시지',
    minWidth: 400,
    disabled: true,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '센터코드',
    disabled: true,
  },
  {
    ...keyColumn('processingCategoryValue', textColumn),
    title: '출고유형',
    disabled: true,
  },
  {
    ...keyColumn('availableDay', textColumn),
    title: '출고예정일자',
    disabled: true,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    minWidth: 300,
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '출고예정수량',
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    disabled: true,
    minWidth: 200,
  },
];

export const AddInGridColumnOption = [
  {
    ...keyColumn('errorMessage', textColumn),
    title: '체크메시지',
    minWidth: 400,
    disabled: true,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '센터코드',
    disabled: true,
  },
  {
    ...keyColumn('processingCategoryValue', textColumn),
    title: '입고유형',
    disabled: true,
  },
  {
    ...keyColumn('availableDay', textColumn),
    title: '입고예정일자',
    disabled: true,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
    minWidth: 300,
  },
  {
    ...keyColumn('availableQuantity', intColumn),
    title: '입고예정수량',
  },
  {
    ...keyColumn('remark', textColumn),
    title: '비고',
    disabled: true,
    minWidth: 200,
  },
];
