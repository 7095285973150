import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INITIAL_USER_ROLE_LIST_FILTER = {
  groupCode: '',
  roleCode: '',
  groupName: '',
  roleName: '',
};

export const USER_ROLE_LIST_COLUMNS = [
  {
    ...keyColumn('roleCode', textColumn),
    title: '역할코드',
    disabled: true,
  },
  {
    ...keyColumn('activated', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('groupName', textColumn),
    title: '사용자 그룹명',
    disabled: true,
  },
  {
    ...keyColumn('roleName', textColumn),
    title: '사용자 역할명',
    disabled: true,
  },
];
