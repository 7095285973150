import styled, { css } from 'styled-components';

import Flex from '@Components/Flex';

export const LoginFormField = styled(Flex)`
  background: #fff;
  border-radius: ${({ theme }) => theme.radiuses['0.6']};
  width: 50rem;
  height: 52rem;
  padding: 4.6rem 5rem;
  box-shadow: 0.1rem 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.12);
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.gray['900']};
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeights['700']};
  position: relative;
`;

export const Badge = styled.img`
  position: absolute;
  width: 4rem;
  height: auto;
  top: -2.8rem;
  left: 5.6rem;
  transform: rotate(12deg);
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray['800']};
  margin: 0 0 3.2rem;
  line-height: 2.4rem;
  text-align: center;
`;

export const LoginInput = styled.input`
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
  margin-bottom: 1.6rem;
  ${({ theme: { colors, radiuses, sizes } }) => {
    const { gray, blue, red } = colors;
    return css`
      border: 0.1rem solid ${gray['400']};
      border-radius: ${radiuses['0.6']};
      color: ${gray['800']};
      font-size: ${sizes['1.6']};

      &:focus {
        border: 0.1rem solid ${blue[500]};
        box-shadow: 0 0 0.5rem rgba(0, 112, 243, 0.5);
      }

      &.error {
        border: 0.1rem solid ${red[500]};
        box-shadow: 0 0 0.5rem rgba(238, 0, 0, 0.5);
      }
    `;
  }}
`;

export const ErrorText = styled.h4`
  color: ${({ theme }) => theme.colors.red['400']};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights['400']};
  height: 2.4rem;
`;

export const PetFButton = styled.button`
  background: #ff4081;
  width: 100%;
  height: 6rem;
  border-radius: ${({ theme }) => theme.radiuses['0.6']};
  color: #fff;
  font-size: 1.8rem;
  margin: 1.6rem 0 2rem;
  font-weight: ${({ theme }) => theme.fontWeights['700']};
`;
