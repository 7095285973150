import dayjs from 'dayjs';

import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import { DELIVERY_TYPE_OPTIONS, OUTBOUND_CATEGORY_OPTIONS } from '@Constants/options';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { useExcelDownLoadHistory } from '@src/Hooks/useExcelDownLoadHistory';

import { OutboundResultInquireFilterTypes } from '../../OutboundResultInquire.type';

function OutboundResultInquireFilter({
  attributes,
  openCenterModal,
  search,
  excel,
  form,
}: OutboundResultInquireFilterTypes) {
  const outboundResultExcelHistoryModal = useExcelDownLoadHistory({ targetUrl: 'outbound_record' });

  const onClickExcelHistory = () => {
    outboundResultExcelHistoryModal.openDialog();
  };

  return (
    <>
      <FilterHeader>
        <Button isPermissionRequired={false} onClick={search}>
          조회
        </Button>
        <Button variant='outline' onClick={excel} isPermissionRequired={false}>
          엑셀
        </Button>
        <Button variant='outline' onClick={onClickExcelHistory} isPermissionRequired={false}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>
      <FormField>
        <Flex direction='column' gap={10}>
          <DateRangePickerLabel
            label='출고예정일'
            start={{
              ...attributes.startAvailableDay,
              minDate: new Date(
                dayjs(form.endAvailableDay).subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD')
              ),
            }}
            end={{
              ...attributes.endAvailableDay,
              maxDate: new Date(
                dayjs(form.startAvailableDay).add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD')
              ),
            }}
          />
          <DateRangePickerLabel
            label='출고실적일'
            start={{
              ...attributes.startCompleteDay,
              minDate: new Date(
                dayjs(form.endCompleteDay).subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD')
              ),
            }}
            end={{
              ...attributes.endCompleteDay,
              maxDate: new Date(
                dayjs(form.startCompleteDay).add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD')
              ),
            }}
          />
          <InputButtonLabel
            {...attributes.centerCode}
            label='센터코드'
            buttonText='검색'
            readOnly={false}
            buttonClick={openCenterModal}
          />
        </Flex>
        <Flex direction='column' gap={10}>
          <SelectLabel
            {...attributes.outboundCategory}
            label='출고유형'
            options={OUTBOUND_CATEGORY_OPTIONS}
          />
          <SelectLabel
            {...attributes.deliveryType}
            label='배송유형'
            options={DELIVERY_TYPE_OPTIONS}
          />
        </Flex>
        <TextAreaLabel label='출고번호' {...attributes.wokey} />
        <TextAreaLabel label='주문번호' {...attributes.orderId} />
        <Flex direction='column' rowGap={10}>
          <TextAreaLabel label='품목코드' {...attributes.productItemId} css='height:6.2rem' />
          <InputLabel label='참조번호' {...attributes.referenceCode} />
        </Flex>
      </FormField>
    </>
  );
}

export default OutboundResultInquireFilter;
