import React, { useState } from 'react';

import { Resetter } from 'recoil';

import { toastify } from '@Components/Toast';
import { useFormField, useFullScreenActivityIndicator } from '@Hooks';
import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { useMutation } from '@tanstack/react-query';

import {
  postAddStockAdjustProductItem,
  postCheckStockAdjustValidation,
  postStockAdjustment,
  postStockAdjustmentExcelUpload,
  putStockAdjustment,
  useStockAdjustmentDataQuery,
} from '../../apis';
import { AdjustItems } from '../../apis/type';

import { STOCK_ADJUST_NEW_MODAL_INITIAL_STATE } from './constant';

export const useStockAdjustNewModalProductList = (id?: number) => {
  const [adjustItem, setAdjustItem] = useState<AdjustItems>([]);
  const { form, attributes, setForm } = useFormField(STOCK_ADJUST_NEW_MODAL_INITIAL_STATE);
  const { data: centerCodeList } = useCenterCodeList();
  const centerId =
    centerCodeList?.find(({ centerCode }) => centerCode === form.centerCode)?.id ?? null;

  useStockAdjustmentDataQuery(id, (data) => {
    if (!data) return;
    const { items, ...rest } = data;
    setForm((prev) => ({
      ...prev,
      ...rest,
    }));
    setAdjustItem(items.map((it) => ({ ...it, active: false })));
  });

  return { form: { ...form, centerId }, attributes, setForm, adjustItem, setAdjustItem };
};

export const useStockAdjustNewModalMutation = ({
  setAdjustItem,
  reset,
  setForm,
  stockAdjustRefetch,
}: {
  setAdjustItem: React.Dispatch<React.SetStateAction<AdjustItems>>;
  reset: Resetter;
  setForm: React.Dispatch<React.SetStateAction<typeof STOCK_ADJUST_NEW_MODAL_INITIAL_STATE>>;
  stockAdjustRefetch: VoidFunction;
}) => {
  const [isPass, setIsPass] = useState(false);

  const { hideActivityIndicator } = useFullScreenActivityIndicator();

  const { mutate: addProduct } = useMutation(postAddStockAdjustProductItem, {
    onSuccess: (data) => {
      if (!data || !data.items) return;
      setAdjustItem(data.items);
    },
  });

  const { mutate: excelUpload } = useMutation(postStockAdjustmentExcelUpload, {
    onSuccess: (data) => {
      if (data) {
        const { items, ...forms } = data;
        setForm((prev) => ({ ...prev, ...forms }));
        setAdjustItem(items);
      }
    },
  });

  const { mutate: checkValidation } = useMutation(postCheckStockAdjustValidation, {
    onSuccess: (data) => {
      setIsPass(data.every((item) => item.result === 'Success'));
      setAdjustItem(data);
    },
  });

  const { mutate: saveProductItem } = useMutation(postStockAdjustment, {
    onSuccess: () => {
      hideActivityIndicator();
      toastify('저장되었습니다.');
      reset();
    },
    onSettled() {
      hideActivityIndicator();
      stockAdjustRefetch();
    },
  });

  const { mutate: modifyProductItem } = useMutation(putStockAdjustment, {
    onSuccess: (data) => {
      setAdjustItem(data.items);
      hideActivityIndicator();
      toastify('저장되었습니다.');
      reset();
    },
    onSettled() {
      hideActivityIndicator();
      stockAdjustRefetch();
    },
  });

  return {
    addProduct,
    checkValidation,
    saveProductItem,
    modifyProductItem,
    excelUpload,
    isPass,
    setIsPass,
  };
};
