import { atom } from 'recoil';

export interface TabItemAtom {
  parentId?: string | null;
  id?: string;
  title?: string;
  frontFileName?: string | null;
}

export const tabListAtom = atom<TabItemAtom[]>({
  key: 'tabListAtom',
  default: [],
});

export const currentTabAtom = atom<TabItemAtom>({
  key: 'currentTabAtom',
  default: {},
});
