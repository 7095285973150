import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import { DateRangePickerLabel } from '@Components/Date/DateRangePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import Pagination from '@Components/Pagination';
import { useDialog, useFormField } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import GridLayout from '@Layout/components/GridLayout';
import ResultHeader from '@Layout/components/ResultHeader';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { INBOUND_CATEGORY_OPTIONS, INVALID_INBOUND_FILTER_MESSAGE } from '@src/Constants/inbound';
import {
  generateMaxDate,
  generateMinDate,
  performIfConfirmed,
  performIfNotEmpty,
  performIfValidFilter,
} from '@Utils/helper';

import {
  INBOUND_REQUEST_LIST_PAGE_SIZE,
  useInboundRequestListQuery,
} from './apis/useInboundRequestListQuery';
import {
  InboundRequestStatus,
  useUpdateInboundRequestStatusMutation,
} from './apis/useUpdateInboundRequestStatusMutation';
import { INBOUND_RESULT_LIST_COLUMNS, INITIAL_INBOUND_REQUEST_LIST_FILTER } from './constant';
import { InboundRequestStatusCell } from './InboundResultAdjstConfirmCell';

function InboundResultAdjustConfirm() {
  const formField = useFormField(INITIAL_INBOUND_REQUEST_LIST_FILTER);
  const centerCodeModal = useDialog('CenterCodeModal');
  const inboundRequestListQuery = useInboundRequestListQuery();
  const updateInboundRequestStatusMutation = useUpdateInboundRequestStatusMutation();

  const inboundRequestList = inboundRequestListQuery.data?.results || [];
  const activeInboundRequestList = inboundRequestList.filter(({ active }) => active);
  const inboundRequestListTotal = inboundRequestListQuery.data?.total;

  const setInboundRequestStatus = (status: InboundRequestStatus) => () => {
    performIfConfirmed(
      {
        approval: '입고 실적조정 요청을 승인하시겠습니까?',
        reject: '입고 실적조정 요청을 반려하시겠습니까?',
      }[status],
      () => {
        updateInboundRequestStatusMutation.mutate({
          status,
          ids: activeInboundRequestList.map((row) => row.adjustId),
        });
      }
    );
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '선택기',
      content: (
        <CenterCodeModal
          selectCenterCode={({ centerCode }) =>
            formField.setForm((prevForm) => ({ ...prevForm, centerCode: centerCode || '' }))
          }
        />
      ),
      size: 'large',
    });
  };

  const search = () => {
    inboundRequestListQuery.setSearchFilter({ ...formField.form, pageNumber: 0 });
  };

  return (
    <div>
      <FilterHeader>
        <Button
          onClick={performIfValidFilter({
            filter: formField.form,
            callback: search,
            message: INVALID_INBOUND_FILTER_MESSAGE,
          })}
          isPermissionRequired={false}
        >
          조회
        </Button>
        <Button
          onClick={performIfNotEmpty(activeInboundRequestList, setInboundRequestStatus('approval'))}
        >
          실적조정 승인
        </Button>
        <Button
          onClick={performIfNotEmpty(activeInboundRequestList, setInboundRequestStatus('reject'))}
        >
          실적조정 반려
        </Button>
      </FilterHeader>

      <FormField>
        <Flex direction='column' gap={10}>
          <DateRangePickerLabel
            label='입고예정일'
            start={{
              ...formField.attributes.startAvailableDay,
              minDate: generateMinDate(formField.form.endAvailableDay, 3),
            }}
            end={{
              ...formField.attributes.endAvailableDay,
              maxDate: generateMaxDate(formField.form.startAvailableDay, 3),
            }}
          />
          <DateRangePickerLabel
            label='입고실적일'
            start={{
              ...formField.attributes.startCompleteDay,
              minDate: generateMinDate(formField.form.endCompleteDay, 3),
            }}
            end={{
              ...formField.attributes.endCompleteDay,
              maxDate: generateMaxDate(formField.form.startCompleteDay, 3),
            }}
          />
          <InputButtonLabel
            {...formField.attributes.centerCode}
            label='센터코드'
            readOnly={false}
            buttonClick={openCenterCodeModal}
          />
        </Flex>
        <Flex direction='column' gap={10}>
          <SelectLabel
            {...formField.attributes.inboundCategory}
            label='입고유형'
            options={INBOUND_CATEGORY_OPTIONS}
          />
          <InputLabel {...formField.attributes.vendorId} label='거래처코드' />
        </Flex>
        <TextAreaLabel {...formField.attributes.wikey} label='입고번호' />
        <TextAreaLabel label='발주번호' {...formField.attributes.productSupplyRequestId} />
        <Flex direction='column' rowGap={10}>
          <TextAreaLabel
            {...formField.attributes.productItemId}
            label='품목코드'
            css='height:6.2rem'
          />
          <InputLabel label='참조번호' {...formField.attributes.referenceCode} />
        </Flex>
      </FormField>

      <GridLayout
        header={<ResultHeader title='입고 실적조정 내역' total={inboundRequestListTotal} />}
        grid={
          <DataSheetGrid
            {...mappingAllCheck(
              INBOUND_RESULT_LIST_COLUMNS,
              inboundRequestList,
              inboundRequestListQuery.setInboundRequestList
            )}
            value={inboundRequestList}
            onChange={inboundRequestListQuery.setInboundRequestList}
            customRendererList={[
              { id: 'inboundAdjustStatusCodeValue', component: InboundRequestStatusCell },
            ]}
          />
        }
        pagination={
          <Pagination
            total={inboundRequestListTotal}
            pageSize={INBOUND_REQUEST_LIST_PAGE_SIZE}
            currentPageIndex={inboundRequestListQuery.pageNumber}
            onPageIndexChange={inboundRequestListQuery.setPageNumber}
          />
        }
      />
    </div>
  );
}

export default InboundResultAdjustConfirm;
