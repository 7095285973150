import { ForwardedRef, forwardRef, useLayoutEffect, useMemo, useRef } from 'react';

import {
  Column,
  DataSheetGrid as DataGrid,
  DataSheetGridRef,
  DynamicDataSheetGrid as DynamicDataGrid,
} from 'react-datasheet-grid';
import { SelectionWithId } from 'react-datasheet-grid/dist/types';

import Flex from '@Components/Flex';
import { Text } from '@Components/Typography';
import { Nullable } from '@Utils/type';

import Box from '../Box';

import { CheckCell } from './CheckCell';
import { GridGlobalStyle } from './DataSheetGrid.style';
import { DataGridProps, RowInfo } from './DataSheetGrid.type';

function EmptyBox() {
  return (
    <Box mt-14>
      <Flex align='center' justify='center'>
        <Text as='span' size={1.2} color='gray.600'>
          No Data
        </Text>
      </Flex>
    </Box>
  );
}

export const mappingAllCheck = <U extends { active: boolean }>(
  cols: Partial<Column<any, any, any>>[],
  val: U[],
  onCheck: (val: U[]) => void
) => {
  const [firstColumn, ...arrs] = [...cols];

  const setAllCheck = () => {
    const active = val.every((it) => it.active);
    const results = [...val.map((it) => ({ ...it, active: !active }))];

    onCheck(results);
  };

  return {
    columns: [{ ...firstColumn, title: <CheckCell onCheck={setAllCheck} value={val} /> }, ...arrs],
    dynamic: true,
  };
};

function DataSheetGrid(
  {
    columns,
    value = [],
    onActiveChange,
    customRendererList,
    lockRows = true,
    height = window.screen.availHeight / 2,
    dynamic = false,
    ...rest
  }: DataGridProps,
  ref: ForwardedRef<DataSheetGridRef> | undefined
) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (wrapperRef.current === null) return;

    const numberHeader = wrapperRef.current.querySelector(
      '.dsg-cell-header-container'
    ) as HTMLDivElement;
    numberHeader.innerHTML = `<div class="dsg-cell-header-container">No.</div>`;
  }, []);

  const config = useMemo(() => {
    const reColumns = columns?.map((header) => {
      const target = customRendererList && customRendererList.find((it) => it.id === header.id);
      if (target) {
        const item = { ...header };
        item.component = target.component;
        return item;
      }
      return header;
    });
    return {
      columns: reColumns,
    };
  }, [columns, customRendererList]);

  const onSelectionChange = ({ selection }: { selection: Nullable<SelectionWithId> }) => {
    if (!selection) return;
    const { min, max } = selection;
    if (min.col === 0 && max.col === 0 && min.colId === 'active') return;

    const rowInfo: RowInfo = {
      colId: min.colId,
      row: min.row,
    };
    onActiveChange && onActiveChange(rowInfo);
  };

  // https://react-datasheet-grid.netlify.app/docs/performance/static-vs-dynamic
  const DataSheetGrid = dynamic ? DynamicDataGrid : DataGrid;

  return (
    <div ref={wrapperRef}>
      <DataSheetGrid
        ref={ref}
        columns={config.columns}
        value={value}
        onSelectionChange={onSelectionChange}
        lockRows={lockRows}
        height={height}
        rowHeight={25}
        disableExpandSelection
        {...rest}
      />
      {!value.length && <EmptyBox />}
      <GridGlobalStyle />
    </div>
  );
}

export default forwardRef<DataSheetGridRef, DataGridProps>(DataSheetGrid);
