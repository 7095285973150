import { client } from '@src/client';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { Nullable } from '@Utils/type';

import { OutboundDivisionRow } from '../OutboundDivisionManage.type';

import { OutboundDivisionEditRow, PostOutboundDivisionValidationParams } from './type';

export interface PostOutboundDivisionListParams {
  stockStatus: string;
  productItemId: string;
  productItemName: string;
  activated: string;
  centerId: Nullable<number>;
}

export interface putOutboundDivisionActivatedParams {
  activated: 'Y' | 'N';
  ids: number[];
}

export const postOutboundDivisionList = async (params: PostOutboundDivisionListParams) => {
  const body = mapObject({ ...params }, nullIfEmptyOrALL);
  const { data } = await client.post<OutboundDivisionRow[]>('/outbound-divisions/list', body);
  return data;
};

export const postOutboundDivisionUpdateForm = async (params: { ids: number[] }) => {
  const { data } = await client.post<OutboundDivisionEditRow[]>(
    '/outbound-divisions/update-form',
    params
  );

  return data;
};

export const postOutboundDivisionsValidation = async (
  params: PostOutboundDivisionValidationParams
) => {
  const { data } = await client.post<OutboundDivisionEditRow[]>(
    '/outbound-divisions/validation/update',
    params
  );

  return data;
};

export const postOutboundDivisionExcelUpload = async (params: FormData) => {
  const { data } = await client.post<OutboundDivisionEditRow[]>(
    '/outbound-divisions/excel-upload',
    params,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

export const putOutboundDivisions = async (params: PostOutboundDivisionValidationParams) => {
  const { data } = await client.put<number[]>('/outbound-divisions', params);
  return data;
};

export const postOutboundDivisions = async (params: PostOutboundDivisionValidationParams) => {
  const { data } = await client.post<number[]>('/outbound-divisions', params);
  return data;
};

export const putOutboundDivisionActivated = async (params: putOutboundDivisionActivatedParams) => {
  const { data } = await client.put<number[]>('/outbound-divisions/activate', params);
  return data;
};

export const postDivisionRegisterValidation = async (
  params: PostOutboundDivisionValidationParams
) => {
  const { data } = await client.post<OutboundDivisionEditRow[]>(
    '/outbound-divisions/validation/register',
    params
  );
  return data;
};
