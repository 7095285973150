import style from 'styled-components';

import * as LabelPrimitive from '@radix-ui/react-label';

export const CheckBox = style.input`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
`;

export const Label = style(LabelPrimitive.Root)<{ disabled?: boolean; checked?: boolean }>`
color: ${({
  theme: {
    colors: { gray },
  },
  disabled,
}) => (disabled ? gray[400] : gray[800])};
    line-height: 3.4rem;
    min-width: 8rem;
    cursor: pointer;
    position:relative;
    font-size: 1.2rem;
`;
