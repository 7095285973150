import { Dispatch, SetStateAction } from 'react';

import { DateRangePickerLabel } from '@src/Components/Date/DateRangePicker';
import Flex from '@src/Components/Flex';
import { SelectLabel } from '@src/Components/Forms/Select';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import { FormFieldAttributes } from '@src/Hooks';
import FormField from '@src/Layout/components/FormField';

import {
  CLAIMS_CATEGORY_OPTIONS,
  CLAIMS_REASON_TYPE_OPTIONS,
  CLAIMS_STATUS_OPTIONS,
  INITIAL_CLAIMS_LIST_FILTER,
} from '../constant';

type ClaimFilterParams = typeof INITIAL_CLAIMS_LIST_FILTER;

interface ClaimFilterProps {
  formField: {
    form: ClaimFilterParams;
    setForm: Dispatch<SetStateAction<ClaimFilterParams>>;
    resetForm: VoidFunction;
    attributes: FormFieldAttributes<keyof ClaimFilterParams>;
  };
}

function ClaimFilter({ formField }: ClaimFilterProps) {
  return (
    <FormField>
      <Flex direction='column' gap={10}>
        <DateRangePickerLabel
          id='requestDay'
          start={formField.attributes.startRequestDay}
          end={formField.attributes.endRequestDay}
          label='접수일'
        />
        <DateRangePickerLabel
          id='completeDay'
          start={formField.attributes.startCompleteDay}
          end={formField.attributes.endCompleteDay}
          label='완료일'
        />
      </Flex>
      <Flex direction='column' gap={10}>
        <SelectLabel
          label='접수유형'
          options={CLAIMS_CATEGORY_OPTIONS}
          {...formField.attributes.category}
        />
        <SelectLabel
          label='상태'
          options={CLAIMS_STATUS_OPTIONS}
          {...formField.attributes.status}
        />
        <SelectLabel
          label='사유'
          options={CLAIMS_REASON_TYPE_OPTIONS}
          {...formField.attributes.reasonType}
        />
      </Flex>
      <TextAreaLabel label='반품번호' {...formField.attributes.reverseCodes} />
      <TextAreaLabel label='접수번호' {...formField.attributes.externalIds} />
      <TextAreaLabel label='입고번호' {...formField.attributes.wikeys} />
      <TextAreaLabel label='출고번호' {...formField.attributes.wokeys} />
    </FormField>
  );
}

export default ClaimFilter;
