import { useState } from 'react';

export interface Operation {
  type: 'CREATE' | 'UPDATE' | 'DELETE';
  fromRowIndex: number;
  toRowIndex: number;
}

export interface GridValue<T> {
  gridList: T[];
  updatedRow: T[];
  setGridList: React.Dispatch<React.SetStateAction<T[]>>;
  setUpdatedRow: React.Dispatch<React.SetStateAction<T[]>>;
  rowChangeHandler: (value: T[], operations: Operation[]) => void;
}

export function useGridValue<T>(initialState: T[], key: keyof T): GridValue<T> {
  const [gridList, setGridList] = useState(initialState);
  const [updatedRow, setUpdatedRow] = useState<T[]>([]);

  const rowChangeHandler = (rows: T[], operations: Operation[]) => {
    const [{ type, fromRowIndex, toRowIndex }] = operations;
    if (type === 'UPDATE') {
      const updatedRows = rows.filter((_, index) => index >= fromRowIndex && index < toRowIndex);
      const arr = [...updatedRow, ...updatedRows].reduce((acc: T[], cur: T): T[] => {
        const prev = acc.find((it) => it[key] === cur[key]);
        if (prev) {
          const idx = acc.findIndex((it) => it[key] === cur[key]);
          acc[idx] = cur;
        } else {
          acc.push(cur);
        }
        return acc;
      }, []);

      setUpdatedRow(arr);
    }
    setGridList(rows);
  };

  return { gridList, updatedRow, setGridList, setUpdatedRow, rowChangeHandler };
}
