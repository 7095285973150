import { toastify } from '@src/Components/Toast';

import { type GetClaimInquireListParams } from './apis/useClaimInquireList';

export const validateSearch =
  ({
    filter,
    callback,
    message,
  }: {
    filter: GetClaimInquireListParams;
    callback: VoidFunction;
    message: string;
  }) =>
  () => {
    if (
      (!filter.startCompleteDay || !filter.endCompleteDay) &&
      (!filter.startRequestDay || !filter.endRequestDay)
    ) {
      toastify(message, { type: 'error' });
    } else {
      callback();
    }
  };
