import { client } from '@src/client';
import { PageResponse } from '@src/Utils/type';
import { useQuery } from '@tanstack/react-query';
import { replaceSpaceToNull } from '@Utils/object';

import { StockAdjustItemRow, StockAdjustRow } from '../StockInquireAdjust.type';

import {
  AdjustItems,
  PageResponseDataType,
  PostStockAdjustItemsParams,
  PostStockAdjustListParams,
  PostStockAdjustmentExcelResult,
  PostStockAdjustmentParams,
  StockAdjustProductItemParams,
  StockAdjustProductItemResults,
} from './type';

export const postStockAdjustList = async (params: PostStockAdjustListParams) => {
  const body = replaceSpaceToNull({ ...params, pageSize: params.pageSize ?? 100 });

  const { data } = await client.post<PageResponse<StockAdjustRow[]>>(
    '/stocks-adjustment/list',
    body
  );

  return data;
};

export const useStockAdjustListQuery = (
  params: PostStockAdjustListParams,
  onSuccess: (data: PageResponse<StockAdjustRow[]> | undefined) => void
) => {
  return useQuery(['stock-adjust-list', params], () => postStockAdjustList(params), {
    keepPreviousData: true,
    enabled: false,
    onSuccess,
  });
};

export const postStockAdjustItems = async (params: PostStockAdjustItemsParams) => {
  const { stockId, ...body } = params;
  const { data } = await client.post<PageResponse<StockAdjustItemRow[]>>(
    `/stocks-adjustment/${stockId}/items`,
    { ...body }
  );

  return data;
};

export const useStockAdjustItems = (
  params: PostStockAdjustItemsParams & {
    onSuccess: (data: PageResponseDataType | undefined) => void;
  }
) => {
  const { stockId, onSuccess } = params;
  return useQuery(['stock-adjust', 'item', stockId], () => postStockAdjustItems(params), {
    keepPreviousData: true,
    enabled: !!stockId,
    onSuccess,
  });
};

export const putStockAdjustCompletion = async (params: { ids: number[] }) => {
  const { data } = await client.put<number[]>('/stocks-adjustment/completion', params);
  return data;
};

export const putStockAdjustCancellation = async (params: { ids: number[] }) => {
  const { data } = await client.put<number[]>('/stocks-adjustment/cancellation', params);
  return data;
};

export const postAddStockAdjustProductItem = async ({
  id,
  newProductItemIds,
  ...params
}: StockAdjustProductItemParams & { id?: number }) => {
  const method = id ? 'put' : 'post';

  const { data } = await client[method]<StockAdjustProductItemResults>(
    id ? `/stocks-adjustment/${id}/items` : '/stocks-adjustment/items',
    { ...params, newProductItemIds: newProductItemIds || null }
  );
  return data;
};

export const postCheckStockAdjustValidation = async (
  params: Omit<StockAdjustProductItemParams, 'newProductItemIds'> & { id?: number }
) => {
  const endPoint = params.id
    ? `/stocks-adjustment/${params.id}/validation`
    : '/stocks-adjustment/validation';
  const { data } = await client.post<AdjustItems>(endPoint, params);
  return data;
};

export const postStockAdjustment = async (params: PostStockAdjustmentParams) => {
  const { data } = await client.post<number>('/stocks-adjustment', params);
  return data;
};

export const getStockAdjustmentData = async (id: number | undefined) => {
  const { data } = await client.get<StockAdjustProductItemResults>(
    `/stocks-adjustment/${id}/request-form`
  );
  return data;
};

export const useStockAdjustmentDataQuery = (
  id: number | undefined,
  onSuccess: (data: StockAdjustProductItemResults | undefined) => void
) => {
  return useQuery(['stock-adjustment-data', id], () => getStockAdjustmentData(id), {
    keepPreviousData: true,
    enabled: !!id,
    onSuccess,
  });
};

export const putStockAdjustment = async (
  params: StockAdjustProductItemParams & { id: number | undefined }
) => {
  const { id, ...rest } = params;
  const { data } = await client.put<StockAdjustProductItemResults>(`/stocks-adjustment/${id}`, {
    ...rest,
  });
  return data;
};

export const postStockAdjustmentExcelUpload = async (params: FormData) => {
  const { data } = await client.post<PostStockAdjustmentExcelResult>(
    '/stocks-adjustment/excel-upload',
    params,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return data;
};
