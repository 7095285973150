import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import EmailSuggestionInputLabel from '@Components/Forms/EmailSuggestionInputLabel';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import UserRoleIdModal from '@Components/Modals/UserRoleModal';
import { toastify } from '@Components/Toast';
import { useDialog, useFormField } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { performIfConfirmed, performIfNotEmpty } from '@Utils/helper';

import { useUpdateUserActiveMutation } from './apis/useUpdateUserActiveMutation';
import { useUpdateUserListMutation } from './apis/useUpdateUserListMutation';
import { useUpdateUserRoleMutation } from './apis/useUpdateUserRoleMutation';
import { useUserListQuery } from './apis/useUserListQuery';
import { INITIAL_USER_LIST_FORM_FIELD, STATUS_OPTIONS, USER_LIST_COLUMNS } from './constant';
import { UserManageStatusCell } from './UserManageCell';
import UserRegisterModal from './UserRegisterModal';

function UserManage() {
  const formField = useFormField(INITIAL_USER_LIST_FORM_FIELD);
  const { userList, setUserList, updatedUserList, handleGridChange, setSearchFilter, refetch } =
    useUserListQuery();
  const { openDialog } = useDialog('userRegister');
  const userRoleModal = useDialog('userRole');
  const updateUserListMutation = useUpdateUserListMutation();
  const updateUserRoleMutation = useUpdateUserRoleMutation();
  const updateUserActiveMutation = useUpdateUserActiveMutation();

  const checkedUserList = userList.filter((user) => user.active);

  const trySaveUserList = () => {
    if (!updatedUserList.length) {
      toastify('수정된 내역이 없습니다.', { type: 'error' });
      return false;
    }

    performIfConfirmed('모든 변경사항을 저장하시겠습니까?', () =>
      updateUserListMutation.mutate(updatedUserList)
    );
  };

  const tryUpdateUserRole = () => {
    if (checkedUserList.length !== 1) {
      toastify('권한 변경은 1건만 처리할 수 있습니다.', { type: 'error' });
      return;
    }

    const user = checkedUserList[0];
    userRoleModal.openDialog({
      title: '선택기',
      content: (
        <UserRoleIdModal
          onSelect={({ roleId }) => {
            performIfConfirmed('역할을 변경하시겠습니까?', async () => {
              await updateUserRoleMutation.mutateAsync({
                userId: user.userId,
                roleId,
              });
              userRoleModal.resetDialog();
              refetch();
            });
          }}
        />
      ),
    });
  };

  const updateUserActive = (active: 'able' | 'disabled') => () => {
    const activeMsg = active === 'able' ? '활성화' : '비활성화';

    if (active === 'able' && checkedUserList.some((it) => it.activated === activeMsg)) {
      toastify('허용되지 않는 동작입니다.', { type: 'error' });
      return;
    }
    if (active === 'disabled' && checkedUserList.some((it) => it.activated === activeMsg)) {
      toastify('허용되지 않는 동작입니다.', { type: 'error' });
      return;
    }

    performIfConfirmed(
      `${{ able: '활성화', disabled: '비활성화' }[active]} 상태로 변경하시겠습니까?`,
      async () => {
        await updateUserActiveMutation.mutateAsync({
          active,
          userIds: checkedUserList.map((user) => user.userId),
        });
        refetch();
      }
    );
  };

  const openUserRoleModal = () => {
    userRoleModal.openDialog({
      title: '선택기',
      content: (
        <UserRoleIdModal
          onSelect={({ roleCode }) => {
            formField.setForm((prevForm) => ({ ...prevForm, roleCode }));
            userRoleModal.resetDialog();
          }}
        />
      ),
    });
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={() => setSearchFilter(formField.form)} isPermissionRequired={false}>
          조회
        </Button>
        <Button
          onClick={() =>
            openDialog({
              title: '신규',
              content: <UserRegisterModal refetch={refetch} />,
            })
          }
        >
          신규
        </Button>
        <Button onClick={trySaveUserList}>저장</Button>
        <Button onClick={performIfNotEmpty(checkedUserList, tryUpdateUserRole)}>권한변경</Button>
        <Button onClick={performIfNotEmpty(checkedUserList, updateUserActive('able'))}>
          활성화
        </Button>
        <Button onClick={performIfNotEmpty(checkedUserList, updateUserActive('disabled'))}>
          비활성화
        </Button>
      </FilterHeader>

      <FormField>
        <div>
          <SelectLabel {...formField.attributes.status} label='상태' options={STATUS_OPTIONS} />
        </div>
        <Flex direction='column' gap={10}>
          <InputButtonLabel
            {...formField.attributes.roleCode}
            label='역할코드'
            buttonClick={openUserRoleModal}
          />
          <EmailSuggestionInputLabel {...formField.attributes.email} label='ID(이메일)' />
        </Flex>
        <Flex direction='column' gap={10}>
          <InputLabel {...formField.attributes.userName} label='사용자명' maxLength={50} />
          <InputLabel {...formField.attributes.mobileNo} label='핸드폰 번호' />
        </Flex>
      </FormField>

      <Flex rowGap={10} direction='column'>
        <ResultHeader title='사용자 내역' total={userList?.length} />
        <DataSheetGrid
          {...mappingAllCheck(USER_LIST_COLUMNS, userList, setUserList)}
          value={userList}
          onChange={handleGridChange}
          customRendererList={[{ id: 'activated', component: UserManageStatusCell }]}
        />
      </Flex>
    </div>
  );
}

export default UserManage;
