import { toastify } from '@Components/Toast';
import { useDialog } from '@Hooks';
import { client } from '@src/client';
import { useMutation } from '@tanstack/react-query';
import { Nullable } from '@Utils/type';

export type InboundAdjustCreateDto = {
  inboundItemId: number;
  adjustQuantity: Nullable<number>;
  adjustReason: Nullable<string>;
};

const saveInboundAdjust = async (inboundAdjustCreateDtos: InboundAdjustCreateDto[]) => {
  const { data } = await client.post('/inbounds-adjustment', {
    inboundAdjustCreateDtos,
  });

  return data;
};

export const useSaveInboundAdjustMutation = () => {
  const inboundAdjustRequestModal = useDialog('InboundAdjustRequestModal');

  return useMutation(saveInboundAdjust, {
    onSuccess() {
      inboundAdjustRequestModal.resetDialog();
      toastify('저장되었습니다.');
    },
  });
};
