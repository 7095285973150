import styled from 'styled-components';

export const Container = styled.main`
  nav {
    font-weight: ${({ theme }) => theme.fontWeights[600]};
    height: 6rem;
    font-size: ${({ theme }) => theme.sizes[1.4]};

    a {
      line-height: 6rem;
    }
  }
`;

export const ContentSection = styled.div`
  padding: 2rem;
`;

export const Section = styled.section`
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  border-radius: ${({ theme }) => theme.radiuses[0.3]};
  padding: 2rem;
  background: #fff;
  box-shadow: 0.1rem 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.12);
`;

export const TabContainer = styled.div<{ tabId?: string; currentTabId?: string }>`
  display: ${({ tabId, currentTabId }) => (tabId === currentTabId ? 'block' : 'none')};
`;
