import { ReactNode } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { OptionalToastify, ToastifyOption } from './Toast.type';

export function toastify(
  message: ReactNode,
  {
    position = 'top-center',
    autoClose = 2000,
    hideProgressBar = true,
    newestOnTop = false,
    closeOnClick = true,
    rtl = false,
    pauseOnFocusLoss = true,
    pauseOnHover = true,
    draggable = true,
    type = 'success',
    closeButton = false,
    limit = 1,
    className = 'wms-toast',
    theme = 'dark',
  }: OptionalToastify = {}
) {
  const config: ToastifyOption = {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    pauseOnFocusLoss,
    newestOnTop,
    rtl,
    type,
    closeButton,
    limit,
    className,
    theme,
  };

  return toast(<ToastSpan>{message}</ToastSpan>, config);
}

const ToastSpan = styled.span`
  text-align: center;
  font-size: 1.2rem;
`;
