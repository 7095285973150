import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { Nullable } from '@src/Utils/type';
import { useMutation } from '@tanstack/react-query';

interface OutboundDetail {
  completeDay: string;
  mainInvoiceNumber: Nullable<string>;
  outboundItemCompleteDtos: {
    outboundItemId: number;
    receivedQuantity: Nullable<number>;
    invoiceParcel: Nullable<string>;
    invoiceNumber: Nullable<string>;
    outboundItemRemark: Nullable<string>;
  }[];
}

export interface completeOutboundParams {
  outboundId: number;
  outboundDetail: OutboundDetail;
}
const completeOutbound = async ({ outboundId, outboundDetail }: completeOutboundParams) => {
  const { data } = await client.put(`/outbounds/${outboundId}/completion`, outboundDetail);

  return data;
};

export const useCompleteOutboundMutation = () => {
  return useMutation(completeOutbound, {
    onSuccess() {
      toastify('완료처리되었습니다.');
    },
  });
};
