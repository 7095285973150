import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import ProductCodeModal from '@Components/Modals/ProductCodeModal';
import { ProductItemRow } from '@Components/Modals/ProductCodeModal/ProductCodeModal.type';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useDialog } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';
import { performIfNotEmpty } from '@src/Utils/helper';

import { EXCEPT_INBOUND_CATEGORY_VALUE, PRODUCT_GRID_OPTIONS } from '../../constant';
import { ProductGridProps } from '../../InboundHistory.type';

function ProductGrid({
  inboundList,
  productList,
  setProductList,
  inboundIndex,
  productRowChangeHandler,
  ...rest
}: ProductGridProps) {
  const { columns } = PRODUCT_GRID_OPTIONS;
  const { openDialog: openProductCodeDialog } = useDialog('ProductCodeModal');

  const targetRows = productList.filter(({ active }) => active);

  const addItem = (rowData: ProductItemRow & { availableQuantity?: number }) => {
    const { productItemId, productItemName, availableQuantity } = rowData;
    const target = inboundIndex !== undefined && inboundList[inboundIndex];
    const id = target && target.inboundId;

    if (!id || target.inboundStatusCodeValue !== '입고예정') {
      toastify('품목 추가/삭제는 <입고예정> 상태만 가능합니다.', { type: 'error' });
      return;
    }

    const params = {
      productItemId: String(productItemId),
      productItemName,
      inboundId: String(id),
      ...(availableQuantity !== undefined && availableQuantity !== null
        ? { availableQuantity }
        : {}),
    };
    rest.addItem(params);
  };

  const openProductModal = () => {
    const target = typeof inboundIndex === 'number' ? inboundList[inboundIndex] : undefined;

    if (target && target.inboundStatusCodeValue !== '입고예정') {
      toastify('품목 추가/삭제는 <입고예정> 상태만 가능합니다.', { type: 'error' });
      return;
    }

    if (
      target &&
      target?.inboundCategoryValue &&
      EXCEPT_INBOUND_CATEGORY_VALUE.includes(target?.inboundCategoryValue)
    ) {
      toastify('품목 추가/삭제할 수 없는 상태입니다.\n해당 입고의 상태를 확인해 주세요.', {
        type: 'error',
      });
      return;
    }

    openProductCodeDialog({
      title: '품목 코드 선택',
      content: <ProductCodeModal type='inbound' selectProductCode={addItem} />,
      size: 'medium',
    });
  };

  const removeItems = () => {
    const target = typeof inboundIndex === 'number' ? inboundList[inboundIndex] : undefined;

    if (!targetRows.length) {
      toastify('선택한 품목이 존재하지 않습니다.', { type: 'error' });
      return;
    }
    if (target && target.inboundStatusCodeValue !== '입고예정') {
      toastify('품목 추가/삭제는 <입고예정> 상태만 가능합니다.', { type: 'error' });
      return;
    }

    if (
      target &&
      target?.inboundCategoryValue &&
      EXCEPT_INBOUND_CATEGORY_VALUE.includes(target?.inboundCategoryValue)
    ) {
      toastify('품목 추가/삭제할 수 없는 상태입니다.\n해당 입고의 상태를 확인해 주세요.', {
        type: 'error',
      });
      return;
    }

    const params = {
      ids: targetRows.map((it) => it.inboundItemId),
    };

    if (window.confirm('선택한 품목을 입고에서 삭제합니다. \n 진행하시겠습니까?'))
      rest.removeItem(params);
  };

  const renderRight = () => {
    return (
      <Flex align='center' colGap={16}>
        <Text color='gray.800' fontWeight={500} size={1.4}>
          품목 추가/삭제
        </Text>
        <Flex align='center' colGap={8}>
          <Button size='x-small' onClick={openProductModal}>
            추가
          </Button>
          <Button size='x-small' onClick={performIfNotEmpty(targetRows, removeItems)}>
            삭제
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction='column' rowGap={10}>
      <ResultHeader title='품목내역' total={productList.length} right={renderRight()} />
      <DataSheetGrid
        {...mappingAllCheck(columns, productList, setProductList)}
        value={productList}
        onChange={productRowChangeHandler}
      />
    </Flex>
  );
}

export default ProductGrid;
