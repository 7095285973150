import { client } from '@src/client';

import { CenterCodeRow } from '../CenterCodeModal.type';

import { PostCenterCodeParams } from './type';

export const postCenterCodeCommon = async (params: PostCenterCodeParams) => {
  const { data } = await client.post<CenterCodeRow[]>('/centers/list/common', params);

  return data;
};
