import { flatten, mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';

import { client } from '../../../client';
import { CenterManageRow } from '../CenterManage.type';

import {
  CenterManageData,
  PostCenterManageDataParams,
  PostNewCenterDataParams,
  PutCenterDataParams,
  putCenterStatusParams,
} from './type';

export const postCenterManageData = async (params: PostCenterManageDataParams = {}) => {
  const { data } = await client.post<CenterManageData[]>('/centers/list', params);

  const result = data.reduce<CenterManageRow[]>((acc, cur) => {
    let temp = { ...cur };

    if (temp.activated === 'Y') {
      temp = {
        ...cur,
        activated: '활성화',
        active: false,
      };
    } else {
      temp = {
        ...cur,
        activated: '비활성화',
        active: false,
      };
    }

    return acc.concat(flatten(temp) as CenterManageRow);
  }, []);

  return result;
};

export const putCenterStatus = async (
  params: putCenterStatusParams = { ids: [], isDisabled: false }
) => {
  const { isDisabled, ids } = params;
  const { data } = await client.put<number[]>(isDisabled ? '/centers/disabled' : '/centers/able', {
    ids,
  });

  return data;
};

export const postNewCenterData = async (params: PostNewCenterDataParams) => {
  const { data } = await client.post<number>(
    '/centers',
    mapObject({ ...params }, nullIfEmptyOrALL)
  );
  return data;
};

export const putCenterData = async (params: PutCenterDataParams) => {
  const { data } = await client.put<string[]>('/centers', params);
  return data;
};
