import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useDialog } from '@src/Hooks';
import { useMutation } from '@tanstack/react-query';

import { UploadedOutbound } from './useOutboundRegisterListQuery';

const registerOutboundList = async (uploadedOutboundList: UploadedOutbound[] | undefined) => {
  const { data } = await client.post<number[]>('/outbounds', uploadedOutboundList);

  return data;
};

export const useRegisterOutboundListMutation = () => {
  const outboundRegisterModal = useDialog('outboundRegister');

  return useMutation(registerOutboundList, {
    onSuccess: () => {
      toastify('저장되었습니다.');
      outboundRegisterModal.resetDialog();
    },
  });
};
