import { css } from 'styled-components';

export const ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const inputStyle = css`
  height: 2.6rem;
  width: 12.5rem;
  line-height: 2.6rem;
  padding: 0 0.4rem;
  ${({ theme: { colors, radiuses, sizes } }) => {
    const { gray, blue, red } = colors;
    return css`
      border: 0.1rem solid ${gray[400]};
      border-radius: ${radiuses[0.4]};
      color: ${gray[700]};
      font-size: ${sizes[1.2]};

      &:focus,
      &:focus-visible {
        border: 0.1rem solid ${blue[500]};
        box-shadow: 0 0 0.5rem rgba(0, 112, 243, 0.5);
      }

      &.error {
        border: 0.1rem solid ${red[500]};
        box-shadow: 0 0 0.5rem rgba(238, 0, 0, 0.5);
      }

      &.with {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    `;
  }}
`;

export const disabled = css`
  ${({
    theme: {
      colors: { gray },
    },
  }) => {
    return css`
      background: ${gray[100]};
      border: 0.1rem solid ${gray[300]};
      color: ${gray[400]};
      box-shadow: none;
    `;
  }}
`;
