import { TextareaHTMLAttributes } from 'react';

import styled from 'styled-components';

import { FormFieldBox } from '@src/Layout/components/FormField/FormField.style';
import { inputStyle } from '@src/Styles/constant';

import { WithLabel, WithLabelProps } from './WithLabel';

function TextArea({
  id,
  name,
  placeholder = '복수검색\n(Enter 또는 “,” 로 구분)',
  value,
  onChange,
}: TextareaHTMLAttributes<HTMLTextAreaElement>) {
  return (
    <StyledTextarea
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}

type TextAreaLabelProps = Omit<WithLabelProps, 'children'> &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

export function TextAreaLabel({ label, className, ...props }: TextAreaLabelProps) {
  return (
    <WithLabel
      label={label}
      className={className}
      css={`
        ${FormFieldBox} > & {
          height: 9.8rem;
        }
      `}
    >
      <TextArea {...props} />
    </WithLabel>
  );
}

const StyledTextarea = styled.textarea`
  ${inputStyle};
  width: 12.5rem;
  height: 100%;
  line-height: 1.5;
  padding: 0.4rem;
  resize: none;
`;
