import styled from 'styled-components';

import Flex from '@src/Components/Flex';
import { RadioProps } from '@src/Components/Forms/Radio/Radio';
import { Label, RadioButton } from '@src/Components/Forms/Radio/Radio.style';

const CustomLabel = styled(Label)`
  font-size: 1.4rem;
`;

const CustomRadioButton = styled(RadioButton)`
  width: 2.4rem;
  height: 2.4rem;
`;

export function CustomRadio({ label, id, disabled, ...rest }: RadioProps) {
  return (
    <Flex colGap={5} justify='center' align='center'>
      <CustomRadioButton type='radio' id={id} disabled={disabled} {...rest} />
      {label && (
        <CustomLabel htmlFor={id} disabled={disabled}>
          {label}
        </CustomLabel>
      )}
    </Flex>
  );
}
