import { ChangeEvent, useState } from 'react';

import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import { Text } from '@src/Components/Typography';
import { useDialog } from '@src/Hooks';
import ModalFooter from '@src/Layout/components/ModalFooter';
import ModalWrapper from '@src/Layout/components/ModalWrapper';

import { CustomRadio } from '../CancelConfirmModal/styled';

function RemoveConfirmModal({
  onRemoveConfirm,
}: {
  onRemoveConfirm: (isRestocked: boolean) => void;
}) {
  const removeConfirmModal = useDialog('OutboundProductRemoveConfirm');

  const [availableQuantityRestocked, setAvailableQuantityRestocked] = useState('true');

  const onRadioChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setAvailableQuantityRestocked(e.target.value);
  };

  const onCancelConfirmSubmit = () => {
    onRemoveConfirm(availableQuantityRestocked === 'true');
  };

  const onClose = () => {
    removeConfirmModal.resetDialog();
  };

  return (
    <ModalWrapper>
      <Text
        as='p'
        css={{
          marginTop: '.8rem',
          fontSize: '1.6rem',
          fontWeight: 400,
          lineHeight: '2.4rem',
        }}
      >
        선택한 품목을 삭제합니다. <br />
        삭제된 상품들의 가용재고 원복여부를 선택해주세요.
      </Text>
      <Flex align='center' justify='center' colGap='4rem'>
        <CustomRadio
          label='가용재고 원복'
          name='availableQuantityRestocked'
          id='true'
          value='true'
          checked={availableQuantityRestocked === 'true'}
          onChange={onRadioChangeHandler}
        />
        <CustomRadio
          label='가용재고 미처리'
          name='availableQuantityRestocked'
          id='false'
          value='false'
          checked={availableQuantityRestocked === 'false'}
          onChange={onRadioChangeHandler}
        />
      </Flex>
      <ModalFooter>
        <Button onClick={onClose} variant='outline'>
          취소
        </Button>
        <Button onClick={onCancelConfirmSubmit}>확인</Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default RemoveConfirmModal;
