import style from 'styled-components';

import { convertRem } from '@Styles/utils/calc';

import { FormContainerProps } from './Form.type';

export const FormContainer = style.form<FormContainerProps>`
    width: ${({ width }) => width && convertRem(width)};
    position: relative;

    > input[type="submit"] {
        display: none;
    }   
`;

FormContainer.defaultProps = {
  width: '100%',
};
