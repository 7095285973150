import { useGridValue } from '@src/Hooks';
import useRequest from '@src/Hooks/useRequest';
import { Nullable } from '@src/Utils/type';

import { OutboundItemRow } from '../constant';

import { OutboundId } from './useOutboundListQuery';

export interface OutboundItem {
  outboundItemId: number;
  productItemId: Nullable<string>;
  productItemName: Nullable<string>;
  availableQuantity: Nullable<number>;
  receivedQuantity: Nullable<number>;
  parcelValue: Nullable<string>;
  invoiceNumber: Nullable<string>;
  itemRemark: Nullable<string>;
  createdUserName: Nullable<string>;
  createdAt: Nullable<string>;
  updatedUserName: Nullable<string>;
  updatedAt: Nullable<string>;
}

export const useOutboundItemListQuery = (outboundId: Nullable<OutboundId>) => {
  const {
    gridList: list,
    setGridList: setList,
    rowChangeHandler,
    updatedRow: updatedList,
    setUpdatedRow: setUpdatedList,
  } = useGridValue<OutboundItemRow>([], 'outboundItemId');

  const query = useRequest<OutboundItem[]>(
    { url: `/outbounds/${outboundId}/items` },
    {
      enabled: outboundId !== null,
      onSuccess(data) {
        setList(
          data.map((item) => ({
            ...item,
            productItemId: String(item.productItemId),
            active: false,
          }))
        );
        setUpdatedList([]);
      },
    }
  );

  return { list, setList, query, onOutboundItemListChange: rowChangeHandler, updatedList };
};
