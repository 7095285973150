import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INBOUND_DETAIL_INITIAL_STATE = {
  startAvailableDay: dayjs().subtract(15, 'd').format('YYYY-MM-DD'),
  endAvailableDay: dayjs().add(15, 'd').format('YYYY-MM-DD'),
  startCompleteDay: '',
  endCompleteDay: '',
  inboundCategory: 'ALL',
  centerCode: '',
  vendorId: '',
  statusCode: 'ALL',
  wikey: '',
  productItemId: '',
  referenceCode: '',
  productSupplyRequestId: '',
};

export const INBOUND_DETAIL_PAGE_SIZE = 500;

export const INBOUND_DETAIL_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('wikey', textColumn),
      title: '입고번호',
      disabled: true,
      minWidth: 140,
    },
    {
      ...keyColumn('inboundStatusCodeValue', textColumn),
      title: '상태',
      disabled: true,
    },
    {
      ...keyColumn('inboundCategoryValue', textColumn),
      title: '입고유형',
      disabled: true,
    },
    {
      ...keyColumn('transportTypeValue', textColumn),
      title: '운송유형',
      disabled: true,
    },
    {
      ...keyColumn('productSupplyRequestId', textColumn),
      title: '발주번호',
      disabled: true,
      minWidth: 140,
    },
    {
      ...keyColumn('referenceCode', textColumn),
      title: '참조번호',
      disabled: true,
      minWidth: 140,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '입고예정일',
      disabled: true,
    },
    {
      ...keyColumn('completeDay', textColumn),
      title: '입고실적일',
      disabled: true,
    },
    {
      ...keyColumn('centerCode', textColumn),
      title: '센터코드',
      disabled: true,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      ...keyColumn('centerName', textColumn),
      title: '센터명',
      disabled: true,
      minWidth: 160,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '입고예정수량',
      disabled: true,
    },
    {
      ...keyColumn('receivedQuantity', textColumn),
      title: '입고실적수량',
      disabled: true,
    },
    {
      ...keyColumn('remark', textColumn),
      title: '비고',
      disabled: true,
      minWidth: 380,
    },
    {
      ...keyColumn('createdUserName', textColumn),
      title: '등록자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('createdAt', textColumn),
      title: '등록일시',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedUserName', textColumn),
      title: '수정자',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('updatedAt', textColumn),
      title: '수정일시',
      disabled: true,
      minWidth: 120,
    },
  ],
};
