import { client } from '@src/client';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { useQuery } from '@tanstack/react-query';
import { mapObject } from '@Utils/object';
import { Nullable, PageResponse } from '@Utils/type';

import { ExcelRow, InboundRow, ProductRow } from '../InboundHistory.type';

import {
  PostAddProductItemParams,
  PostInboundsListParams,
  PutCompletionParams,
  PutInboundsParams,
} from './type';

export const postInboundsList = async (params: PostInboundsListParams) => {
  const body = mapObject({ ...params, pageSize: params?.pageSize ?? 100 }, nullIfEmptyOrALL);
  const { data } = await client.post<PageResponse<InboundRow[]>>('/inbounds/list', body);
  return data;
};

export const useInboundListQuery = (
  enabled: boolean,
  body: PostInboundsListParams,
  onSuccess: (data: PageResponse<InboundRow[]> | undefined) => void,
  onSettled: () => void
) => {
  return useQuery(['inbound-list', body], () => postInboundsList(body), {
    keepPreviousData: true,
    enabled,
    onSuccess,
    onSettled,
  });
};

export const getInboundsItems = async ({ inboundItemId }: { inboundItemId: Nullable<number> }) => {
  if (!inboundItemId) return undefined;
  const { data } = await client.get<ProductRow[]>(`/inbounds/${inboundItemId}/items`);

  return data;
};

export const useInboundsItemsQuery = (params: {
  inboundItemId: Nullable<number>;
  onSuccess: (data: ProductRow[] | undefined) => void;
}) => {
  const { inboundItemId, onSuccess } = params;
  return useQuery(['inbounds-items', inboundItemId], () => getInboundsItems({ inboundItemId }), {
    keepPreviousData: true,
    enabled: !!params.inboundItemId,
    onSuccess,
  });
};

export const postInBoundHistoryExcelUpload = async (params: FormData) => {
  const { data } = await client.post<ExcelRow[]>('/inbounds/excel-upload', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export const postInBoundHistoryExcelValidation = async (params: ExcelRow[]) => {
  const { data } = await client.post<ExcelRow[]>('/inbounds/validation', params);

  return data;
};

export const postAddProductItem = async (params: PostAddProductItemParams) => {
  const { data } = await client.post<number>('/inbounds/item', params);

  return data;
};

export const putRemoveProductItem = async (params: { ids: number[] }) => {
  const { data } = await client.put<number[]>('/inbounds/item/disabled', params);

  return data;
};

export const putInbounds = async (params: PutInboundsParams) => {
  const { data } = await client.put<{ inboundIds: number[]; inboundItemIds: number[] }>(
    '/inbounds',
    params
  );
  return data;
};

export const postInbounds = async (params: ExcelRow[]) => {
  const { data } = await client.post('/inbounds', params);
  return data;
};

export const postTransmission = async (params: { ids: number[] }) => {
  const { data } = await client.post<number[]>('/inbounds/transmission', params);
  return data;
};

export const putCancelTransmission = async (params: { ids: number }) => {
  const { data } = await client.put<number[]>(`/inbounds/transmission/${params.ids}/cancellation`);
  return data;
};

export const putCompletion = async (params: PutCompletionParams) => {
  const { inboundItemId, ...rest } = params;
  const { data } = await client.put<Nullable<number>>(`/inbounds/${inboundItemId}/completion`, {
    ...rest,
  });
  return data;
};

export const putCancellation = async (params: { ids: number[] }) => {
  const { data } = await client.put<number[]>('/inbounds/cancellation', params);
  return data;
};

export const postInboundsExcelDownload = async (params: PostInboundsListParams) => {
  const body = mapObject({ ...params, pageSize: params?.pageSize ?? 100 }, nullIfEmptyOrALL);
  const { data } = await client.post<{ send: string }>('/inbounds/excel-download', body);
  return data;
};
