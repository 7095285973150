import { toastify } from '@Components/Toast';
import { client } from '@src/client';
import { useMutation } from '@tanstack/react-query';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';

import { User, useUserListQuery } from './useUserListQuery';

const updateUserList = async (userList: User[]) => {
  const userInfoUpdateDtos = userList.map((user) => ({
    id: user.userId,
    name: user.userName,
    mobileNo: user.mobileNo,
    telNo: user.phoneNo,
    position: user.position,
  }));

  const { data } = await client.put<number[]>('/users', {
    userInfoUpdateDtos: userInfoUpdateDtos.map((userInfoUpdateDto) =>
      mapObject(userInfoUpdateDto, nullIfEmptyOrALL)
    ),
  });

  return data;
};

export const useUpdateUserListMutation = () => {
  const userListQuery = useUserListQuery();

  return useMutation(updateUserList, {
    onSuccess() {
      toastify('저장되었습니다.');
    },
    onError() {
      userListQuery.refetch();
    },
  });
};
