import { toastify } from '@Components/Toast';
import { client } from '@src/client';
import { useMutation } from '@tanstack/react-query';

const cancelInboundAdjust = async (adjustIds: number[]) => {
  const { data } = await client.put<number[]>('/inbounds-adjustment/cancellation', {
    ids: adjustIds,
  });

  return data;
};

export const useCancelInboundAdjustMutation = () => {
  return useMutation(cancelInboundAdjust, {
    onSuccess() {
      toastify('실적조정 요청이 취소되었습니다.');
    },
  });
};
