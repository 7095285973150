import { Heading, Text } from '@Components/Typography';

import { CautionBoxContainer, CautionListWrapper } from './CautionBox.style';

interface CautionBoxProps {
  title: string;
  messages: string[];
}

function CautionBox({ title, messages }: CautionBoxProps) {
  return (
    <CautionBoxContainer>
      <Heading as='h2' color='gray.900' fontWeight={600}>
        {title}
      </Heading>
      <CautionListWrapper>
        {messages.map((message: string) => (
          <li key={message}>
            <Text as='span' size={1.2} color='gray.800'>
              {message}
            </Text>
          </li>
        ))}
      </CautionListWrapper>
    </CautionBoxContainer>
  );
}

export default CautionBox;
