import dayjs from 'dayjs';

import { DateRangePickerLabel } from '@src/Components/Date/DateRangePicker';
import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import CenterCodeModal from '@src/Components/Modals/CenterCodeModal';
import SearchProgressingModal from '@src/Components/Modals/SearchProgressingModal';
import SearchCompleteBox from '@src/Components/SearchCompleteBox';
import { toastify } from '@src/Components/Toast';
import { Heading } from '@src/Components/Typography';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@src/Hooks';
import FilterHeader from '@src/Layout/components/FilterHeader';
import FormField from '@src/Layout/components/FormField';
import { updateAsExcel } from '@src/Utils/excel';

import { useDailyReceiptsAndPaymentsQuery } from './useDailyReceiptsAndPaymentsQuery';

export const INITIAL_DAILY_RECEIPTS_AND_PAYMENTS_FILTER = {
  startDay: dayjs().format('YYYY-MM-DD'),
  endDay: dayjs().format('YYYY-MM-DD'),
  centerCode: '',
};

function DailyReceiptsAndPayments() {
  const formField = useFormField(INITIAL_DAILY_RECEIPTS_AND_PAYMENTS_FILTER);
  const dailyReceiptsAndPaymentsQuery = useDailyReceiptsAndPaymentsQuery();
  const centerCodeModal = useDialog('CenterCodeModal');
  const searchProgressingModal = useDialog('SearchProgressingModal');

  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const searchDeailyReceiptsAndPayments = () => {
    if (!formField.form.centerCode) {
      toastify('센터코드는 필수값입니다.', { type: 'error' });
      return;
    }

    if (!formField.form.startDay || !formField.form.endDay) {
      toastify('조회기준일은 필수값입니다.', { type: 'error' });
      return;
    }

    searchProgressingModal.openDialog({
      size: 'small',
      content: <SearchProgressingModal />,
      onOpenChange: () => null,
    });

    dailyReceiptsAndPaymentsQuery.setSearchFilter(formField.form);
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '선택기',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            formField.setForm((prevForm) => ({
              ...prevForm,
              centerCode: rowData.centerCode as string,
            }));
          }}
        />
      ),
    });
  };

  const downloadExcel = () => {
    showActivityIndicator();

    updateAsExcel(
      dailyReceiptsAndPaymentsQuery.list.map((item) => ({
        centerCode: dailyReceiptsAndPaymentsQuery.filter.centerCode,
        ...item,
      })),
      '다운로드_일별수불대사',
      {
        cb: () => {
          hideActivityIndicator();
        },
      }
    );
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={searchDeailyReceiptsAndPayments} isPermissionRequired={false}>
          조회
        </Button>
        <Button
          disabled={
            dailyReceiptsAndPaymentsQuery.useQueryResult.isFetching ||
            dailyReceiptsAndPaymentsQuery.list.length === 0
          }
          onClick={downloadExcel}
          isPermissionRequired={false}
        >
          엑셀
        </Button>
      </FilterHeader>

      <FormField>
        <DateRangePickerLabel
          label='조회 기준일'
          start={{
            ...formField.attributes.startDay,
            minDate: new Date(dayjs().subtract(30, 'day').format('YYYY-MM-DD')),
            maxDate: new Date(dayjs().format('YYYY-MM-DD')),
          }}
          end={{
            ...formField.attributes.endDay,
            maxDate:
              formField.form.startDay === dayjs().format('YYYY-MM-DD')
                ? new Date(dayjs().format('YYYY-MM-DD'))
                : new Date(dayjs().subtract(1, 'day').format('YYYY-MM-DD')),
          }}
        />

        <InputButtonLabel
          {...formField.attributes.centerCode}
          label='센터코드'
          readOnly={false}
          buttonClick={openCenterCodeModal}
        />
      </FormField>

      <Flex direction='column' gap={10}>
        <Heading>수불 대사 (일별)</Heading>
        {dailyReceiptsAndPaymentsQuery.isCompleted && <SearchCompleteBox />}
      </Flex>
    </div>
  );
}

export default DailyReceiptsAndPayments;
