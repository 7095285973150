import style, { css } from 'styled-components';

import { ellipsis } from '@Styles/constant';
import { calcRem } from '@Styles/utils/calc';
import { flexRow } from '@Styles/utils/flex';

export const TabContainer = style.ul`
    display: flex;
    align-items: flex-end;
    min-height: 5rem;
    column-gap: .4rem;    
    width: 100%;

    ${({
      theme: {
        colors: { gray },
        spaces,
      },
    }) => {
      return css`
        border-bottom: 0.1rem solid ${gray[400]};
        padding: 0 ${spaces[2.0]} 0;
        background: ${gray[200]};
      `;
    }}
    
`;

export const TabItem = style.li`
    ${flexRow('space-between', 'center')};
    height: 3.2rem;
    padding: 0 1.4rem;
    position: relative;
    cursor: pointer;
    width: 18rem;
    overflow: hidden;
    border-radius: .5rem .5rem 0 0;


    ${({ theme: { colors } }) => {
      const { gray, blue } = colors;

      return css`
        background-color: ${gray[50]};
        border: 0.1rem solid ${gray[400]};
        border-bottom-width: 0;

        &:not(.current):hover {
          background: ${gray[100]};
        }

        &.current {
          text-shadow: black 0 0 ${calcRem(0.4)};
          color: ${gray[900]};

          &::after {
            content: '';
            width: 100%;
            height: 0.3rem;
            background: ${blue[600]};
            position: absolute;
            bottom: -0.1rem;
            left: 0;
            z-index: 1;
          }
        }

        & span {
          display: block;
          line-height: 3.2rem;
          font-size: 1.3rem;
          color: ${gray[800]};
          ${ellipsis}
        }

        & button {
          height: 100%;
          ${flexRow('center', 'center')}

          & svg {
            margin-bottom: 0.1rem;
          }
        }
      `;
    }}
`;
