import { useState } from 'react';

import { useRecoilState } from 'recoil';

import { getProcessingList } from '@Features/ProcessedCirculateManage/apis';
import {
  BoundType,
  ProcessingBothBoundsType,
  ProcessingGridItem,
  ProcessingInGridItem,
  ProcessingOutGridItem,
  UpdateProcessingGridItem,
} from '@Features/ProcessedCirculateManage/ProcessedCirculateHistory.type';
import { processingStateSelector } from '@Features/ProcessedCirculateManage/stores/atoms';
import useRequest from '@src/Hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import { addIndexingData } from '@Utils/helper';
import { addActiveColumn } from '@Utils/object';
import { Nullable, PageResponse } from '@Utils/type';

import { INITIAL_CIRCULATE_MANAGE_FILTER_FORM } from '../constant';

export const useProcessingListQuery = () => {
  const [filter, setFilter] = useState({
    ...INITIAL_CIRCULATE_MANAGE_FILTER_FORM,
    pageNumber: 0,
    pageSize: 100,
  });
  const [{ results }, setProcessingState] = useRecoilState(processingStateSelector);
  const [enabled, setEnabled] = useState(false);

  const useQueryResult = useQuery<PageResponse<ProcessingGridItem[]>>(
    ['processingList', filter],
    () => getProcessingList(filter),
    {
      enabled,
      onSettled() {
        setEnabled(false);
      },
      onSuccess(page) {
        setProcessingState({
          ...page,
          results: addIndexingData(
            page.results.map((item) => ({ ...item, active: false })),
            filter.pageNumber,
            filter.pageSize
          ),
          page: filter.pageNumber,
        });
      },
      keepPreviousData: true,
    }
  );

  const setSearchFilter: typeof setFilter = (filter) => {
    setEnabled(true);
    setFilter(filter);
  };

  return { results, filter, setSearchFilter, useQueryResult };
};

export const useProcessingDetailQuery = (processingId: Nullable<number>) => {
  return useRequest<ProcessingBothBoundsType<ProcessingInGridItem, ProcessingOutGridItem>>(
    {
      url: `/processing/${processingId}`,
    },
    {
      enabled: processingId !== null,
    }
  );
};

type ProcessingDetail = ProcessingBothBoundsType<
  UpdateProcessingGridItem<BoundType.INBOUND> & { active: boolean },
  UpdateProcessingGridItem<BoundType.OUTBOUND> & { active: boolean }
> & {
  validationResult: 'Success' | 'Fail';
};

type ProcessingDetailResponse = ProcessingBothBoundsType<
  UpdateProcessingGridItem<BoundType.INBOUND>,
  UpdateProcessingGridItem<BoundType.OUTBOUND>
> & {
  validationResult: 'Success' | 'Fail';
};

export const useUpdateProcessingDetailQuery = (processingId: Nullable<number>) => {
  const [processingDetail, setProcessingDetail] = useState<ProcessingDetail>({
    inbounds: [],
    outbounds: [],
    validationResult: 'Fail',
  });

  const useQueryResult = useRequest<ProcessingDetailResponse>(
    {
      url: `/processing/${processingId}/update-form`,
    },
    {
      enabled: processingId !== null,
      onSuccess(data) {
        setProcessingDetail({
          ...data,
          inbounds: addActiveColumn(
            data.inbounds.map((it) => ({
              ...it,
              processingCategoryValue: it.processingCategoryValue ?? '유통가공',
            }))
          ),
          outbounds: addActiveColumn(
            data.outbounds.map((it) => ({
              ...it,
              processingCategoryValue: it.processingCategoryValue ?? '유통가공',
            }))
          ),
        });
      },
    }
  );

  const setInboundList = (
    inbounds: (UpdateProcessingGridItem<BoundType.INBOUND> & { active: boolean })[]
  ) =>
    setProcessingDetail((prev) => ({
      ...prev,
      inbounds,
    }));

  const setOutboundList = (
    outbounds: (UpdateProcessingGridItem<BoundType.OUTBOUND> & { active: boolean })[]
  ) =>
    setProcessingDetail((prev) => ({
      ...prev,
      outbounds,
    }));

  return { processingDetail, setProcessingDetail, useQueryResult, setInboundList, setOutboundList };
};
