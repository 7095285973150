import { toastify } from '@src/Components/Toast';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useFullScreenActivityIndicator } from './useFullScreenActivityIndicator';

export const useMassiveExcelRequest = <T>(
  request: (params: T) => Promise<{ send: string }>,
  options?: UseMutationOptions<{ send: string }, unknown, T, unknown>
) => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const { mutate: downloadExcel } = useMutation(
    request,
    options ?? {
      onMutate: () => showActivityIndicator(),
      onSuccess: () => {
        hideActivityIndicator();
        toastify('완료되었습니다.');
      },
      onError: () => hideActivityIndicator(),
    }
  );

  return { downloadExcel };
};
