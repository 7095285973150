import dayjs from 'dayjs';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import DataSheetGrid from '@src/Components/DataSheetGrid';
import { DateRangePickerLabel } from '@src/Components/Date/DateRangePicker';
import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import { InputLabel } from '@src/Components/Forms/Input';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { SelectLabel } from '@src/Components/Forms/Select';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import CenterCodeModal from '@src/Components/Modals/CenterCodeModal';
import ExcelHistoryModal from '@src/Components/Modals/ExcelHistoryModal/ExcelHistoryModal';
import Pagination from '@src/Components/Pagination';
import { toastify } from '@src/Components/Toast';
import { CUSTOM_GUTTER_COLUMN } from '@src/Constants/grid';
import {
  DELIVERY_TYPE_OPTIONS,
  OUTBOUND_CATEGORY_OPTIONS,
  OUTBOUND_STATUS_CODE,
} from '@src/Constants/options';
import { INVALID_OUTBOUND_FILTER_MESSAGE } from '@src/Constants/outbound';
import { useDialog, useFormField } from '@src/Hooks';
import FilterHeader from '@src/Layout/components/FilterHeader';
import FormField from '@src/Layout/components/FormField';
import GridLayout from '@src/Layout/components/GridLayout';
import ResultHeader from '@src/Layout/components/ResultHeader';
import { performIfValidFilter } from '@src/Utils/helper';

import {
  INITIAL_OUTBOUND_DETAIL_FILTER,
  OUTBOUND_DETAIL_LIST_COLUMNS,
  OUTBOUND_DETAIL_LIST_PAGE_SIZE,
} from './constant';
import {
  useDownloadOutboundDetailMutation,
  useOutboundDetailQuery,
} from './useOutboundDetailQuery';

function OutboundDetail() {
  const outboundDetailformField = useFormField(INITIAL_OUTBOUND_DETAIL_FILTER);
  const { useSearchRequestResult, outboundDetail, setOutboundDetail } = useOutboundDetailQuery();
  const centerCodeModal = useDialog('CenterCodeModal');
  const downloadOutboundDetailMutation = useDownloadOutboundDetailMutation();
  const { data: centerCodeList } = useCenterCodeList();
  const outboundDetailExcelDownLoadHistoryModal = useDialog('ExcelHistory');

  const minimumDate = (dateToString: string) =>
    new Date(dayjs(dateToString).subtract(1, 'month').add(1, 'd').format('YYYY-MM-DD'));

  const maximumDate = (dateToString: string) =>
    new Date(dayjs(dateToString).add(1, 'month').subtract(1, 'd').format('YYYY-MM-DD'));

  const searchOutboundDetail = () => {
    useSearchRequestResult.setSearchFilter({
      ...outboundDetailformField.form,
      pageNumber: 0,
    });
  };

  const downloadOutboundDetailExcel = () => {
    const { centerCode, ...params } = outboundDetailformField.form;
    const centerId = centerCodeList?.find((item) => item.centerCode === centerCode)?.id || null;

    if (!outboundDetail.results?.length) {
      toastify('출고 상세 데이터가 없습니다.', { type: 'error' });
      return false;
    }

    downloadOutboundDetailMutation.excelDownload({
      ...params,
      pageNumber: 0,
      centerId,
    });
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '센터코드 조회',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            outboundDetailformField.setForm((prevForm) => ({
              ...prevForm,
              centerCode: rowData.centerCode as string,
            }));
          }}
        />
      ),
    });
  };
  const openDownLoadHistoryModalHandler = () => {
    outboundDetailExcelDownLoadHistoryModal.openDialog({
      title: '엑셀 다운로드 내역',
      content: <ExcelHistoryModal targetUrl='outbound_item' />,
    });
  };

  return (
    <div>
      <FilterHeader>
        <Button
          onClick={performIfValidFilter({
            filter: outboundDetailformField.form,
            callback: searchOutboundDetail,
            message: INVALID_OUTBOUND_FILTER_MESSAGE,
          })}
          isPermissionRequired={false}
        >
          조회
        </Button>
        <Button
          onClick={performIfValidFilter({
            filter: outboundDetailformField.form,
            callback: downloadOutboundDetailExcel,
            message: INVALID_OUTBOUND_FILTER_MESSAGE,
          })}
          isPermissionRequired={false}
        >
          엑셀
        </Button>
        <Button onClick={openDownLoadHistoryModalHandler}>엑셀 다운로드 요청 내역</Button>
      </FilterHeader>

      <FormField>
        <Flex direction='column' gap={10}>
          <DateRangePickerLabel
            label='출고예정일'
            id='availableDay'
            start={{
              ...outboundDetailformField.attributes.startAvailableDay,
              minDate: minimumDate(outboundDetailformField.form.endAvailableDay),
            }}
            end={{
              ...outboundDetailformField.attributes.endAvailableDay,
              maxDate: maximumDate(outboundDetailformField.form.startAvailableDay),
            }}
          />
          <DateRangePickerLabel
            label='출고실적일'
            id='completeDay'
            start={{
              ...outboundDetailformField.attributes.startCompleteDay,
              minDate: minimumDate(outboundDetailformField.form.endCompleteDay),
            }}
            end={{
              ...outboundDetailformField.attributes.endCompleteDay,
              maxDate: maximumDate(outboundDetailformField.form.startCompleteDay),
            }}
          />
          <InputButtonLabel
            {...outboundDetailformField.attributes.centerCode}
            readOnly={false}
            label='센터코드'
            buttonClick={openCenterCodeModal}
          />
        </Flex>
        <Flex direction='column' gap={10}>
          <SelectLabel
            {...outboundDetailformField.attributes.outboundCategory}
            label='출고유형'
            options={OUTBOUND_CATEGORY_OPTIONS}
          />
          <SelectLabel
            {...outboundDetailformField.attributes.deliveryType}
            label='배송유형'
            options={DELIVERY_TYPE_OPTIONS}
          />
          <SelectLabel
            {...outboundDetailformField.attributes.outboundStatusCode}
            label='상태'
            options={OUTBOUND_STATUS_CODE}
          />
        </Flex>
        <TextAreaLabel {...outboundDetailformField.attributes.wokey} label='출고번호' />
        <TextAreaLabel {...outboundDetailformField.attributes.orderId} label='주문번호' />
        <Flex direction='column' rowGap={10}>
          <TextAreaLabel
            {...outboundDetailformField.attributes.productItemId}
            label='품목코드'
            css='height:6.2rem'
          />
          <InputLabel {...outboundDetailformField.attributes.referenceCode} label='참조번호' />
        </Flex>
      </FormField>

      <GridLayout
        header={<ResultHeader title='출고 상세내역' total={outboundDetail.total} />}
        grid={
          <DataSheetGrid
            columns={OUTBOUND_DETAIL_LIST_COLUMNS}
            value={outboundDetail.results}
            onChange={(outboundDetailResults) => {
              setOutboundDetail((prevOutboundDetail) => ({
                ...prevOutboundDetail,
                results: outboundDetailResults,
              }));
            }}
            gutterColumn={CUSTOM_GUTTER_COLUMN}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={useSearchRequestResult.filter.pageNumber}
            onPageIndexChange={(pageNumber) => {
              useSearchRequestResult.setSearchFilter((prevFilter) => ({
                ...prevFilter,
                pageNumber,
              }));
            }}
            total={outboundDetail.total}
            pageSize={OUTBOUND_DETAIL_LIST_PAGE_SIZE}
          />
        }
      />
    </div>
  );
}

export default OutboundDetail;
