import ExcelHistoryModal from '@src/Components/Modals/ExcelHistoryModal/ExcelHistoryModal';

import { OpenDialogParams, useDialog } from './useDialog';

export const useExcelDownLoadHistory = ({ targetUrl }: { targetUrl: string }) => {
  const excelDownLoadHistoryModal = useDialog('ExcelHistory');

  const openDialog = (params?: OpenDialogParams) => {
    excelDownLoadHistoryModal.openDialog(
      params ?? {
        title: '엑셀 다운로드 내역',
        content: <ExcelHistoryModal targetUrl={targetUrl} />,
      }
    );
  };

  const closeDialog = () => {
    excelDownLoadHistoryModal.resetDialog();
  };

  return { openDialog, closeDialog };
};
