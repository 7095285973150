import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INBOUND_ADJUST_REQUEST = {
  columns: [
    {
      ...keyColumn('result', textColumn),
      title: '체크메시지',
      disabled: true,
      minWidth: 400,
    },
    {
      ...keyColumn('wikey', textColumn),
      title: '입고번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('productSupplyRequestId', textColumn),
      title: '발주번호',
      disabled: true,
      minWidth: 120,
    },
    {
      ...keyColumn('availableDay', textColumn),
      title: '입고예정일',
      disabled: true,
    },
    {
      ...keyColumn('completeDay', textColumn),
      title: '입고실적일',
      disabled: true,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('productItemName', textColumn),
      title: '품목명',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('availableQuantity', textColumn),
      title: '입고예정수량',
      disabled: true,
    },
    {
      // ??
      ...keyColumn('firstInboundQuantity', textColumn),
      title: '입고실적수량',
      disabled: true,
    },
    {
      ...keyColumn('adjustQuantity', textColumn),
      title: '실적조정수량',
    },
    {
      ...keyColumn('adjustResultQuantity', textColumn),
      title: '조정결과수량',
      disabled: true,
    },
    {
      ...keyColumn('adjustReason', textColumn),
      title: '조정사유',
    },
  ],
};

export const messages = [
  '입고 실적조정은 센터에서 2가지 사유로 등록 가능합니다. (실적 오기입, 원천불량 반품), 비고란에 센터 반출번호 등을 기입해 주세요.',
  '실적조정수량은 양의 정수 / 음의 정수로 입력가능합니다. (1, -1)',
  '요청 후 승인 시, 입고수량 및 재고수량에 반영됩니다.',
  '기타 사유 선택 시, 기타 사유란에 사유를 상세히 입력해 주세요.',
];
