import { ReactNode } from 'react';

import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';

import { DialogProps } from '@radix-ui/react-dialog';

export type DialogSizes = 'xsmall' | 'small' | 'medium' | 'large';
export interface DialogAtom {
  id: string;
  size?: DialogSizes;
  isOpen: boolean;
  title?: ReactNode;
  content: ReactNode;
  onOpenChange?: DialogProps['onOpenChange'];
  noCloseEsc?: boolean;
  noCloseClickOutSide?: boolean;
}

const dialogAtomFamily = atomFamily<DialogAtom, DialogAtom['id']>({
  key: 'dialogAtomFamily',
  default: (id) => ({
    id,
    isOpen: false,
    content: undefined,
  }),
});

export const dialogIdsAtom = atom<DialogAtom['id'][]>({
  key: 'dialogIdsAtom',
  default: [],
});

export const dialogSelectorFamily = selectorFamily<DialogAtom, DialogAtom['id']>({
  key: 'dialogSelectorFamily',

  get:
    (id) =>
    ({ get }) =>
      get(dialogAtomFamily(id)),

  set:
    (id) =>
    ({ set, reset }, dialog) => {
      if (dialog instanceof DefaultValue) {
        reset(dialogAtomFamily(id));
        set(dialogIdsAtom, (prevDialogIds) => prevDialogIds.filter((dialogId) => dialogId !== id));
      } else {
        set(dialogAtomFamily(id), dialog);
        set(dialogIdsAtom, (prevDialogIds) => Array.from(new Set([...prevDialogIds, dialog.id])));
      }
    },
});
