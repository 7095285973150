import { useCallback } from 'react';

import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { DialogAtom, dialogSelectorFamily } from '@Stores/Dialog/dialog.atom';

export type OpenDialogParams = Pick<
  DialogAtom,
  'size' | 'title' | 'content' | 'onOpenChange' | 'noCloseClickOutSide' | 'noCloseEsc'
>;

type RecommendedDialogId =
  | 'outboundRegister'
  | 'ProductCodeModal'
  | 'CenterCodeModal'
  | 'VendorCodeModal'
  | 'SearchProgressingModal'
  | 'AvailableValueModal'
  | 'CenterManageModal'
  | 'OutboundDivisionRegister'
  | 'OutboundDivisionEdit'
  | 'OutboundCancelConfirm'
  | 'OutboundProductRemoveConfirm'
  | 'ExcelHistory';
type DialogId = RecommendedDialogId | Omit<DialogAtom['id'], RecommendedDialogId>;

export function useDialog(id: DialogId) {
  const setDialog = useSetRecoilState(dialogSelectorFamily(id as string));
  const resetDialog = useResetRecoilState(dialogSelectorFamily(id as string));

  const openDialog = useCallback(
    (
      { size, title, content, onOpenChange, noCloseClickOutSide, noCloseEsc }: OpenDialogParams = {
        size: 'medium',
        content: undefined,
      }
    ) =>
      setDialog((prevDialog) => ({
        ...prevDialog,
        size,
        isOpen: true,
        title,
        content,
        onOpenChange,
        noCloseClickOutSide,
        noCloseEsc,
      })),
    []
  );

  return { openDialog, resetDialog };
}
