import { Resetter } from 'recoil';

import Box from '@Components/Box';
import DataSheetGrid from '@Components/DataSheetGrid';
import { DatePickerLabel } from '@Components/Date/DatePicker';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { toastify } from '@Components/Toast';
import { Heading } from '@Components/Typography';
import { useFormField, useGridValue } from '@Hooks';
import FormField from '@Layout/components/FormField';
import ModalFooter from '@Layout/components/ModalFooter';
import ResultHeader from '@Layout/components/ResultHeader';
import { useMutation } from '@tanstack/react-query';
import { Nullable } from '@Utils/type';

import { putCompletion, useInboundsItemsQuery } from '../../apis';
import { ProductRow } from '../../InboundHistory.type';

import { COMPLETION_GRID_OPTIONS } from './constant';

function CompletionModal({
  inboundItemId,
  wikey,
  close,
  search,
}: {
  inboundItemId: Nullable<number>;
  wikey: string;
  close: Resetter;
  search: () => void;
}) {
  const { columns } = COMPLETION_GRID_OPTIONS;
  const { form, attributes } = useFormField({
    wikey,
    startAvailableDay: '',
  });
  const {
    gridList: productList,
    setGridList: setProductList,
    rowChangeHandler: productRowChange,
  } = useGridValue<ProductRow>([], 'productItemId');

  useInboundsItemsQuery({
    inboundItemId,
    onSuccess: (data) => data && setProductList(data),
  });

  const { mutate } = useMutation(putCompletion, {
    onSuccess: () => {
      toastify('완료되었습니다.');
      close();
      search();
    },
  });

  const save = () => {
    const targetRows = [...productList];

    if (!form.startAvailableDay) {
      toastify('입고실적일 필수값입니다.', { type: 'error' });
      return;
    }

    if (targetRows.filter(({ receivedQuantity }) => !receivedQuantity).length) {
      toastify('입고실적수량은 필수값입니다.', { type: 'error' });
      return;
    }

    if (window.confirm('입력한 정보대로 완료처리하시겠습니까?')) {
      const params = {
        inboundItemId,
        completeDay: form.startAvailableDay,
        inboundItemUpdateDtos: productList,
      };
      mutate(params);
    }
  };

  return (
    <div>
      <Heading>입고 완료 처리</Heading>
      <Box>
        <FormField>
          <Flex align='flex-start' justify='flex-start' colGap={36} rowGap={10} wrap='wrap'>
            <InputLabel label='대상 입고번호' {...attributes.wikey} readOnly />
            <DatePickerLabel label='입고실적일 *' {...attributes.startAvailableDay} />
          </Flex>
        </FormField>
        <Flex direction='column' rowGap={16}>
          <ResultHeader title='품목코드 내역' total={productList.length} />
          <DataSheetGrid columns={columns} value={productList} onChange={productRowChange} />
        </Flex>
      </Box>
      <ModalFooter>
        <Button onClick={save}>저장</Button>
        <Button onClick={close}>취소</Button>
      </ModalFooter>
    </div>
  );
}

export default CompletionModal;
