import { useCallback, useState } from 'react';

import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { Heading, Text } from '@Components/Typography';
import {
  CautionBox,
  CautionListWrapper,
} from '@Features/CenterManage/components/CenterManageModal/CenterManageModal.style';
import ExcelFileUpload from '@Features/InboundHistory/components/InboundHistoryNewModal/ExcelFileUpload';
import {
  postAddValidateProcessing,
  postProcessingCreate,
  postProcessingExcel,
} from '@Features/ProcessedCirculateManage/apis';
import {
  AddInGridColumnOption,
  AddOutGridColumnOption,
} from '@Features/ProcessedCirculateManage/components/ProcessedCirculateManageModal/ProcessedCirculateAddModal/constant';
import {
  BoundType,
  UpdateProcessingGridItem,
} from '@Features/ProcessedCirculateManage/ProcessedCirculateHistory.type';
import { useDialog } from '@Hooks';
import ModalFooter from '@Layout/components/ModalFooter';
import { getCheckMessage } from '@src/Features/ProcessedCirculateManage/utils';
import ResultHeader from '@src/Layout/components/ResultHeader';

import { CheckMessage } from '../CheckMessage';

function ProcessedCirculateAddModal({
  refetchProcessingList,
}: {
  refetchProcessingList: VoidFunction;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [outBoundList, setOutBoundList] = useState<UpdateProcessingGridItem<BoundType.OUTBOUND>[]>(
    []
  );
  const [inBoundList, setInBoundList] = useState<UpdateProcessingGridItem<BoundType.INBOUND>[]>([]);
  const { resetDialog } = useDialog('ProcessedCirculateAddModal');

  const fileHandler = async (files: FileList) => {
    const { inbounds, outbounds } = await postProcessingExcel(files[0]);
    setOutBoundList(outbounds);
    setInBoundList(inbounds);
  };

  const check = useCallback(async () => {
    const { inbounds, outbounds, validationResult } = await postAddValidateProcessing({
      outbounds: outBoundList.map((it) => ({ ...it, errorMessage: '' })),
      inbounds: inBoundList.map((it) => ({ ...it, errorMessage: '' })),
    });

    setOutBoundList(
      outbounds.map((item) => ({
        ...item,
        errorMessage: getCheckMessage(item.errorMessage),
      }))
    );
    setInBoundList(
      inbounds.map((item) => ({
        ...item,
        errorMessage: getCheckMessage(item.errorMessage),
      }))
    );

    setIsChecked(validationResult === 'Success');
  }, [outBoundList, inBoundList]);

  const save = useCallback(async () => {
    await postProcessingCreate({
      inbounds: inBoundList.map(
        ({
          processingInOutCategory,
          centerCode,
          processingCategory,
          availableDay,
          productItemId,
          productItemName,
          availableQuantity,
          remark,
        }) => ({
          processingInOutCategory,
          centerCode,
          processingCategory,
          availableDay,
          productItemId,
          productItemName,
          availableQuantity,
          remark,
        })
      ),
      outbounds: outBoundList.map(
        ({
          processingInOutCategory,
          centerCode,
          processingCategory,
          availableDay,
          productItemId,
          productItemName,
          availableQuantity,
          remark,
        }) => ({
          processingInOutCategory,
          centerCode,
          processingCategory,
          availableDay,
          productItemId,
          productItemName,
          availableQuantity,
          remark,
        })
      ),
    });
    refetchProcessingList();
    resetDialog();
  }, [outBoundList, inBoundList, refetchProcessingList]);

  const cancel = () => {
    resetDialog();
  };

  const addOutGridChangeHandler = useCallback(
    (list: UpdateProcessingGridItem<BoundType.OUTBOUND>[]) => {
      setOutBoundList(list);
      if (isChecked) {
        setIsChecked(false);
      }
    },
    [isChecked]
  );

  const addInGridChangeHandler = useCallback(
    (list: UpdateProcessingGridItem<BoundType.INBOUND>[]) => {
      setInBoundList(list);
      if (isChecked) {
        setIsChecked(false);
      }
    },
    [isChecked]
  );

  return (
    <Flex direction='column' gap={10} css='height:100%'>
      <div css='flex:1'>
        <CautionBox>
          <Heading as='h2' color='gray.900' fontWeight={600}>
            참고사항
          </Heading>
          <CautionListWrapper>
            <li>
              <Text as='span' size={1.2} color='gray.800'>
                출고-입고 순서로 진행됩니다. (입고가 출고보다 빠를 수 없습니다)
              </Text>
            </li>
            <li>
              <Text as='span' size={1.2} color='gray.800'>
                예정일자를 정확히 입력해주시기 바랍니다.
              </Text>
            </li>
          </CautionListWrapper>
        </CautionBox>

        <Flex direction='column' rowGap={16}>
          <ResultHeader
            title='출고 내역 등록'
            right={<ExcelFileUpload onFileUpload={fileHandler} fileName='업로드_유통가공등록' />}
          />
          <DataSheetGrid
            value={outBoundList}
            onChange={addOutGridChangeHandler}
            columns={AddOutGridColumnOption}
            customRendererList={[{ id: 'errorMessage', component: CheckMessage }]}
          />
        </Flex>
        <Flex direction='column' rowGap={16}>
          <ResultHeader title='입고 내역 등록' />
          <DataSheetGrid
            value={inBoundList}
            onChange={addInGridChangeHandler}
            columns={AddInGridColumnOption}
            customRendererList={[{ id: 'errorMessage', component: CheckMessage }]}
          />
        </Flex>
      </div>
      <ModalFooter>
        <Button disabled={!outBoundList.length && !inBoundList.length} onClick={check}>
          체크
        </Button>
        <Button disabled={!isChecked} onClick={save}>
          저장
        </Button>
        <Button onClick={cancel}>취소</Button>
      </ModalFooter>
    </Flex>
  );
}

export default ProcessedCirculateAddModal;
