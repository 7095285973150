import StatusCell from '@Components/Cell/StatusCell';
import { Text } from '@Components/Typography';
import { Color } from '@Components/Typography/Typography.type';
import { ErrorCell } from '@Features/InboundHistory/components/InboundHistoryNewModal/InboundHistoryNewModal.style';

import { OutboundDivisionEditRow, OutboundDivisionRowAndActive } from '../../apis/type';
import { OUTBOUND_DIVISION_ACTIVATED_COLOR } from '../../constant';

export function ActivatedCell({ rowData }: { rowData: OutboundDivisionRowAndActive }) {
  const { activated } = rowData;
  const textColor = OUTBOUND_DIVISION_ACTIVATED_COLOR[
    activated as keyof typeof OUTBOUND_DIVISION_ACTIVATED_COLOR
  ] as Color;

  return <StatusCell statusValue={activated} textColor={textColor} />;
}

export function ValidationResultMessage({ rowData }: { rowData: OutboundDivisionEditRow }) {
  return (
    <ErrorCell bg={rowData.validationMessage ?? ''}>
      <Text color='gray.900' fontWeight={400} size={1.2}>
        {rowData.validationMessage}
      </Text>
    </ErrorCell>
  );
}
