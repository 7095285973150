import { useRef } from 'react';

import { useCenterCodeList } from '@Apis/useCenterCodeList';
import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputButtonLabel } from '@Components/Forms/InputButton';
import { SelectLabel } from '@Components/Forms/Select';
import { TextAreaLabel } from '@Components/Forms/TextArea';
import CenterCodeModal from '@Components/Modals/CenterCodeModal';
import { toastify } from '@Components/Toast';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import FormField from '@Layout/components/FormField';
import GridLayout from '@Layout/components/GridLayout';
import ResultHeader from '@Layout/components/ResultHeader';
import { useMutation } from '@tanstack/react-query';
import { updateAsExcel } from '@Utils/excel';
import { performIfConfirmed, performIfNotEmpty } from '@Utils/helper';

import { useOutboundDivisionListQuery } from './apis/queries';
import { ActivatedCell } from './components/OutboundDivisionCell';
import OutboundDivisionEditModal from './components/OutboundDivisionEditModal';
import { putOutboundDivisionActivated } from './apis';
import {
  OUTBOUND_DIVISION_ACTIVATED_OPTIONS,
  OUTBOUND_DIVISION_INITIAL_STATE,
  OUTBOUND_DIVISION_LIST_COLUMNS,
  OUTBOUND_DIVISION_STOCK_STATUS_OPTIONS,
} from './constant';

function OutboundDivisionManage() {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();
  const { data: centerCodeList } = useCenterCodeList();
  const activatedStatus = useRef('');

  const registerModal = useDialog('OutboundDivisionRegister');
  const editModal = useDialog('OutboundDivisionEdit');
  const centerCodeModal = useDialog('CenterCodeModal');

  const { form, attributes, setForm } = useFormField(OUTBOUND_DIVISION_INITIAL_STATE);

  const centerId = centerCodeList?.find((item) => item.centerCode === form.centerCode)?.id ?? null;

  const { refetch, outboundDivisionList, setOutboundDivisionList } = useOutboundDivisionListQuery({
    ...form,
    centerId,
  });
  const { mutate: updateActivated } = useMutation(putOutboundDivisionActivated, {
    onSuccess: () => {
      toastify(activatedStatus.current === 'Y' ? '활성화 되었습니다.' : '비활성화 되었습니다.', {
        type: 'success',
      });
    },
    onSettled: () => search(),
  });

  const targetRows = outboundDivisionList.filter((it) => it.active);

  const search = () => {
    showActivityIndicator();
    refetch();
  };

  const openRegisterModal = () => {
    registerModal.openDialog({
      title: '신규 등록',
      content: <OutboundDivisionEditModal close={registerModal.resetDialog} />,
    });
  };

  const openEditModal = performIfNotEmpty(targetRows, () => {
    const invalidStatusValue = ['판매종료', '등록오류', '기타'];
    const validationList = targetRows.map(({ stockStatusValue }) =>
      invalidStatusValue.includes(stockStatusValue)
    );

    if (validationList.some((it) => it)) {
      toastify('수정할 수 없는 재고상태가 포함되어 있습니다.', { type: 'error' });
      return;
    }

    editModal.openDialog({
      title: '출고 분할 수정',
      content: (
        <OutboundDivisionEditModal
          rows={targetRows}
          close={editModal.resetDialog}
          search={search}
        />
      ),
    });
  });

  const setActivated = performIfNotEmpty(targetRows, () => {
    if (targetRows.some(({ activated }) => activated === '활성화')) {
      toastify('이미 활성화된 내역이 있습니다.', { type: 'error' });
      return;
    }

    performIfConfirmed('활성화 하시겠습니까?', () => {
      activatedStatus.current = 'Y';
      updateActivated({ activated: 'Y', ids: targetRows.map((it) => it.id) });
    });
  });

  const setInActivated = performIfNotEmpty(targetRows, () => {
    if (targetRows.some(({ activated }) => activated === '비활성화')) {
      toastify('이미 비활성화된 내역이 있습니다.', { type: 'error' });
      return;
    }

    performIfConfirmed('비활성화 하시겠습니까?', () => {
      activatedStatus.current = 'N';
      updateActivated({ activated: 'N', ids: targetRows.map((it) => it.id) });
    });
  });

  const excelDownload = () => {
    showActivityIndicator();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const divisionExcelList = outboundDivisionList.map(({ id, active, ...item }) => ({ ...item }));
    updateAsExcel(divisionExcelList, '다운로드_출고분할정보', {
      isIndex: true,
      cb: () => hideActivityIndicator(),
    });
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '선택기',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            const { centerCode } = rowData;
            setForm((prev) => ({
              ...prev,
              centerCode: centerCode ?? '',
            }));
          }}
        />
      ),
    });
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={search}>조회</Button>
        <Button onClick={openRegisterModal}>신규</Button>
        <Button onClick={openEditModal}>수정</Button>
        <Button onClick={setActivated}>활성화</Button>
        <Button onClick={setInActivated}>비활성화</Button>
        <Button onClick={excelDownload}>엑셀</Button>
      </FilterHeader>
      <FormField>
        <Flex direction='column' gap={10}>
          <InputButtonLabel
            {...attributes.centerCode}
            label='센터코드'
            readOnly={false}
            buttonClick={openCenterCodeModal}
          />
          <SelectLabel
            {...attributes.activated}
            label='상태'
            options={OUTBOUND_DIVISION_ACTIVATED_OPTIONS}
          />
          <SelectLabel
            {...attributes.stockStatus}
            label='재고상태'
            options={OUTBOUND_DIVISION_STOCK_STATUS_OPTIONS}
          />
        </Flex>
        <TextAreaLabel label='품목코드' css='height: 6.2rem' {...attributes.productItemId} />
        <TextAreaLabel
          label='품목명'
          css='height: 6.2rem'
          placeholder=''
          {...attributes.productItemName}
        />
      </FormField>
      <GridLayout
        header={<ResultHeader title='출고 분할 정보 ' total={outboundDivisionList.length} />}
        grid={
          <DataSheetGrid
            {...mappingAllCheck(
              OUTBOUND_DIVISION_LIST_COLUMNS,
              outboundDivisionList,
              setOutboundDivisionList
            )}
            value={outboundDivisionList}
            onChange={setOutboundDivisionList}
            customRendererList={[{ id: 'activated', component: ActivatedCell }]}
          />
        }
      />
    </div>
  );
}

export default OutboundDivisionManage;
