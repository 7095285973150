import DataSheetGrid from '@src/Components/DataSheetGrid';
import { CUSTOM_GUTTER_COLUMN } from '@src/Constants/grid';
import GridLayout from '@src/Layout/components/GridLayout';
import ResultHeader from '@src/Layout/components/ResultHeader';
import type { Nullable } from '@src/Utils/type';

import { useClaimInboundItem } from '../apis/useClaimInboundItem';
import { CLAIM_INBOUND_ITEM_COLUMNS } from '../constant';

function ClaimInbound({ reverseId }: { reverseId: Nullable<number> }) {
  const { list: claimInboundList } = useClaimInboundItem(reverseId);

  return (
    <GridLayout
      header={<ResultHeader title='입고 품목내역' />}
      grid={
        <DataSheetGrid
          columns={CLAIM_INBOUND_ITEM_COLUMNS}
          value={claimInboundList}
          gutterColumn={CUSTOM_GUTTER_COLUMN}
        />
      }
    />
  );
}

export default ClaimInbound;
