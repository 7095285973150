import { DOMAINS } from '@src/Constants/form';

import SuggestionInputLabel from '../SuggestionInputLabel';
import { SuggestionInputLabelProps } from '../SuggestionInputLabel/SuggestionInputLabel';

type EmailSuggestionInputLabelProps = Omit<SuggestionInputLabelProps, 'list'>;

function EmailSuggestionInputLabel({ value, ...props }: EmailSuggestionInputLabelProps) {
  return (
    <SuggestionInputLabel
      {...props}
      type='email'
      list={DOMAINS.map((domain) => ({
        value: `${value?.split('@')[0]}@${domain}`,
      }))}
    />
  );
}

export default EmailSuggestionInputLabel;
