import { ReactNode } from 'react';

import Breadcrumb from '@Components/Breadcrumb';
import Flex from '@Components/Flex';

function FilterHeader({
  left = <Breadcrumb />,
  children,
}: {
  left?: ReactNode;
  children: ReactNode | ReactNode[];
}) {
  return (
    <Flex align='center' justify='space-between'>
      {left}
      <Flex align='center' gap={10}>
        {children}
      </Flex>
    </Flex>
  );
}

export default FilterHeader;
