import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import { mapObject } from '@src/Utils/object';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { useMutation } from '@tanstack/react-query';

import type { GetShelfLifeStocksListParams } from './useShelfLifeList';

export const downloadShelfLifeListExcelDownload = async ({
  imminentStockTarget,
  noShippingTarget,
  ...rest
}: GetShelfLifeStocksListParams) => {
  const [isImminentStockTarget, isNoShippingTarget] = [
    imminentStockTarget === 'checked',
    noShippingTarget === 'checked',
  ];

  const body = mapObject(
    {
      imminentStockTarget: isImminentStockTarget,
      noShippingTarget: isNoShippingTarget,
      ...rest,
    },
    nullIfEmptyOrALL
  );

  const { data } = await client.post('/shelf-life/stocks/excel-download', body);

  return data;
};

export const useDownloadShelfLifeListMutation = () => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();
  const { mutate: excelDownload } = useMutation(downloadShelfLifeListExcelDownload, {
    onMutate: () => showActivityIndicator(),
    onSuccess: () => {
      hideActivityIndicator();
      toastify('완료되었습니다.');
    },
    onError: () => hideActivityIndicator(),
  });

  return { excelDownload };
};
