import styled from 'styled-components';

import Center from '@src/Components/Center';
import { Text } from '@src/Components/Typography';

function SearchProgressingModal() {
  return (
    <Wrapper>
      <Text color='gray.500' align='center'>
        조회중 입니다.
        <br />
        잠시 기다려주세요.
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled(Center)`
  height: 100%;
`;

export default SearchProgressingModal;
