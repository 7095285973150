import styled from 'styled-components';

import { ActivityIndicatorProps } from './ActivityIndicator.type';

export const ActivityIndicatorWrapper = styled.div<Omit<ActivityIndicatorProps, 'isVisible'>>`
  ${({ fullScreen, zIndex }) =>
    fullScreen &&
    `width: 100vw;
     height: 100vh;
     position: fixed;
     top: 0;
     left: 0;
     z-index: ${zIndex};`}
`;

export const ActivityIndicatorBackground = styled(ActivityIndicatorWrapper)`
  display: ${({ fullScreen }) => !fullScreen && 'none'};
  background-color: ${({ fullScreen, backgroundColor }) => fullScreen && backgroundColor};
  opacity: ${({ fullScreen, backgroundOpacity }) => fullScreen && backgroundOpacity};
`;

export const ActivityIndicatorImg = styled.img<
  Required<Pick<ActivityIndicatorProps, 'size' | 'fullScreen' | 'zIndex'>>
>`
  ${({ fullScreen, zIndex }) =>
    fullScreen &&
    `position: absolute;
     z-index: ${zIndex + 1};
     top: 50%;
     left: 50%;
     transform:
     translate(-50%, -50%);`}
  ${({ size }) => `
    width: ${size}rem;
    height:${size}rem;
    `}
  opacity: 1;
`;
