import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface addOutboundItemParams {
  outboundId: number;
  productItemId: number;
  productItemName: string;
  availableQuantity?: number;
}

const addOutboundItem = async (params: addOutboundItemParams) => {
  const { data } = await client.post<number>('/outbounds/item', params);

  return data;
};

export const useAddOutboundItemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addOutboundItem, {
    onSuccess() {
      toastify('추가되었습니다.');
    },
    onSettled() {
      queryClient.invalidateQueries(['outbounds']);
    },
  });
};
