import { useEffect, useRef, useState } from 'react';

import { SelectionWithId } from 'react-datasheet-grid/dist/types';

import CautionBox from '@Components/CautionBox';
import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { SelectLabel } from '@Components/Forms/Select';
import Pagination from '@Components/Pagination';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useDialog, useFormField } from '@Hooks';
import ResultHeader from '@Layout/components/ResultHeader';
import ModalFooter from '@src/Layout/components/ModalFooter';
import { useMutation } from '@tanstack/react-query';
import { Nullable } from '@Utils/type';

import { postProductItemListCommon } from './apis';
import AvailableCodeModal from './AvailableCodeModal';
import { PRODUCT_CODE, STOCK_STATUS_OPTIONS } from './constant';
import { MiniPagination, ModalForm } from './ProductCodeModal.style';
import { ProductItemRow } from './ProductCodeModal.type';

const PAGE_SIZE = 200;

export type ProductCodeType = 'inbound' | 'outbound' | 'etc';

function ProductCodeModal({
  type = 'etc',
  selectProductCode,
}: {
  type: ProductCodeType; // inbound / outbound: availableQuantity 입력가능 , disEdirot: 기존
  selectProductCode?: (rowData: ProductItemRow & { availableQuantity?: number }) => void;
}) {
  const { columns } = PRODUCT_CODE;
  const selectionIndex = useRef<number[]>([]);
  const totalCountRef = useRef<number>(0);

  const { form, attributes } = useFormField({
    vendorId: '',
    vendorName: '',
    productItemId: '',
    productItemName: '',
    stockStatus: 'KEEP',
  });

  const [productItemList, setProductItemList] = useState<ProductItemRow[]>([]);
  const [pageNumber, setPageNumber] = useState(0);

  const { resetDialog } = useDialog('ProductCodeModal');
  const { openDialog: availableCodeModalOpen, resetDialog: availableCodeModalClose } =
    useDialog('AvailableValueModal');

  const { mutate } = useMutation(postProductItemListCommon, {
    onSuccess: (data) => {
      totalCountRef.current = data.total;
      setProductItemList(data.results);
    },
  });

  const search = (page?: number) => {
    if (!page) setPageNumber(0);
    mutate({ ...form, pageNumber: page ?? 0 });
    selectionIndex.current = [];
  };

  useEffect(() => {
    if (pageNumber) search(pageNumber);
  }, [pageNumber]);

  const onSelectionChange = ({ selection }: { selection: Nullable<SelectionWithId> }) => {
    if (!selection) return;
    const { max, min } = selection;
    const { row: minRow } = min;
    const { row: maxRow } = max; // 복수 데이터 대비용 확인하고 지울예정
    selectionIndex.current = [minRow, maxRow];
  };

  const submit = () => {
    if (!selectionIndex.current.length) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return;
    }
    const [targetRow] = selectionIndex.current;
    const target = productItemList[targetRow];

    if (type !== 'etc') {
      availableCodeModalOpen({
        title: '예정수량 입력',
        size: 'small',
        content: (
          <AvailableCodeModal
            type={type}
            target={target}
            close={availableCodeModalClose}
            selectProductCode={selectProductCode}
          />
        ),
      });
    } else {
      selectProductCode && selectProductCode(target);
      resetDialog();
    }
  };

  return (
    <div>
      <CautionBox
        title='참고사항'
        messages={[
          '거래처를 잘 확인한 후에 선택해주세요.',
          '품목코드 내역은 최대 200개까지만 조회됩니다. 필터로 검색 후 사용하세요.',
        ]}
      />
      <Flex align='center' justify='space-between'>
        <Text as='p' color='gray.900'>
          거래처 별 품목코드 조회
        </Text>
        <Button onClick={() => search()}>검색</Button>
      </Flex>
      <ModalForm>
        <Flex direction='column' rowGap={10} colGap={25}>
          <InputLabel label='거래처코드' {...attributes.vendorId} />
          <InputLabel label='거래처명' {...attributes.vendorName} />
        </Flex>
        <Flex direction='column' rowGap={10} colGap={25}>
          <InputLabel label='품목코드' {...attributes.productItemId} />
          <InputLabel label='품목명' {...attributes.productItemName} />
          <SelectLabel {...attributes.stockStatus} label='상태' options={STOCK_STATUS_OPTIONS} />
        </Flex>
      </ModalForm>
      <Flex direction='column' rowGap={16}>
        <ResultHeader title='품목코드 내역' total={totalCountRef.current} />
        <DataSheetGrid
          columns={columns}
          value={productItemList}
          onSelectionChange={onSelectionChange}
        />
        <MiniPagination justify='center'>
          <Pagination
            total={totalCountRef.current}
            pageSize={PAGE_SIZE}
            currentPageIndex={pageNumber}
            onPageIndexChange={setPageNumber}
          />
        </MiniPagination>
      </Flex>
      <ModalFooter>
        <Button onClick={submit}>선택</Button>
        <Button onClick={resetDialog}>취소</Button>
      </ModalFooter>
    </div>
  );
}

export default ProductCodeModal;
