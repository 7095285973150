import { useState } from 'react';

import { AxiosRequestConfig } from 'axios';

import { useCenterCodeList } from '@Apis/useCenterCodeList';
import { DELIVERY_TYPE_OPTIONS, OUTBOUND_CATEGORY_OPTIONS } from '@Constants/options';
import { useFullScreenActivityIndicator, useGridValue } from '@Hooks';
import { client } from '@src/client';
import { useMassiveExcelRequest } from '@src/Hooks/useMassiveExcelRequest';
import useSearchRequest from '@src/Hooks/useSearchRequest';
import { addIndexingData } from '@Utils/helper';
import { mapObject } from '@Utils/object';
import { nullIfEmptyOrALL } from '@Utils/primitive';
import { IndexingData, Nullable, PageResponse } from '@Utils/type';

import { INITIAL_OUTBOUND_LIST_FILTER, OutboundRow } from '../constant';

export interface FetchOutboundListParams {
  startAvailableDay: string;
  endAvailableDay: string;
  startCompleteDay: string;
  endCompleteDay: string;

  outboundCategory: typeof OUTBOUND_CATEGORY_OPTIONS[number]['value'];
  deliveryType: typeof DELIVERY_TYPE_OPTIONS[number]['value'];
  centerCode: string;
  centerId?: Nullable<number>;

  outboundStatusCode: string;
  wokey: string;
  productItemId: string;

  pageNumber: number;
  pageSize?: number;
}

export interface Outbound {
  outboundId: number;
  wokey: Nullable<string>;
  outboundStatusCodeValue: string;
  outboundCategoryValue: string;
  deliveryTypeValue: Nullable<string>;
  orderId: Nullable<number>;
  referenceCode: Nullable<string>;
  availableDay: Nullable<string>;
  completeDay: Nullable<string>;
  centerCode: Nullable<string>;
  centerName: Nullable<string>;
  remark: Nullable<string>;
  productItemCount: Nullable<number>;
  allCount: Nullable<number>;
  mainInvoiceNumber: Nullable<string>;
  arriavalCenterCode: Nullable<string>;
  arriavalCenterId: Nullable<string>;
  arrivalCenterName: Nullable<string>;
  vendorId: Nullable<number>;
  vendorDisplayName: Nullable<number>;
  recipient: Nullable<string>;
  zipcode: Nullable<string>;
  address: Nullable<string>;
  detailAddress: Nullable<string>;
  mainMobileNo: Nullable<string>;
  subMobileNo: Nullable<string>;
  message: Nullable<string>;
  enterMethod: Nullable<string>;
  createdUserName: Nullable<string>;
  createdAt: Nullable<string>;
  updatedUserName: Nullable<string>;
  updatedAt: Nullable<string>;
}

export type OutboundId = Nullable<number>;

export const OUTBOUND_LIST_PAGE_SIZE = 100;

const getFetchOutboundListRequest = (params: FetchOutboundListParams): AxiosRequestConfig => {
  const { data: centerCodeList } = useCenterCodeList();

  return {
    url: '/outbounds/list',
    method: 'POST',
    data: mapObject(
      {
        ...params,
        centerId:
          centerCodeList?.find(({ centerCode }) => centerCode === params.centerCode)?.id || null,
        pageSize: params.pageSize || OUTBOUND_LIST_PAGE_SIZE,
      },
      nullIfEmptyOrALL
    ),
  };
};

const initialFilter = { ...INITIAL_OUTBOUND_LIST_FILTER, pageNumber: 0 };
export const useOutboundListQuery = () => {
  const [outboundListTotal, setOutboundListTotal] = useState(0);
  const {
    gridList: list,
    setGridList: setList,
    updatedRow: updatedList,
    setUpdatedRow: setUpdatedList,
    rowChangeHandler,
  } = useGridValue<IndexingData<OutboundRow>>([], 'outboundId');
  const { hideActivityIndicator } = useFullScreenActivityIndicator();

  const { filter, setSearchFilter, useQueryResult } = useSearchRequest<
    typeof initialFilter,
    PageResponse<Outbound[]>
  >({
    initialFilter,
    getRequest: getFetchOutboundListRequest,
    options: {
      onSuccess(data) {
        setOutboundListTotal(data.total);
        setList(
          addIndexingData(
            data.results.map((item) => ({ ...item, active: false })),
            filter.pageNumber,
            OUTBOUND_LIST_PAGE_SIZE
          )
        );
        setUpdatedList([]);
      },
      onSettled() {
        hideActivityIndicator();
      },
    },
  });

  const setPageNumber = (pageNumber: number) =>
    setSearchFilter((prevFilter) => ({ ...prevFilter, pageNumber }));

  return {
    pageNumber: filter.pageNumber,
    setPageNumber,
    setSearchFilter,
    list,
    setList,
    rowChangeHandler,
    updatedList,
    outboundListTotal,
    refetch: useQueryResult.refetch,
  };
};

const postExcelDownload = async (params: FetchOutboundListParams) => {
  const body = mapObject(
    {
      ...params,
      pageSize: params.pageSize || OUTBOUND_LIST_PAGE_SIZE,
    },
    nullIfEmptyOrALL
  );

  const { data } = await client.post<{ send: string }>('/outbounds/excel-download', body);
  return data;
};

export const useDownloadOutboundListQuery = () => {
  const { downloadExcel } = useMassiveExcelRequest(postExcelDownload);

  return { excelDownload: downloadExcel };
};
