import styled, { css, keyframes } from 'styled-components';

import * as HoverCard from '@radix-ui/react-hover-card';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const HoverMenuWrapper = styled(HoverCard.Root)``;

HoverMenuWrapper.defaultProps = {
  openDelay: 0,
  closeDelay: 100,
};

export const HoverMenuContent = styled(HoverCard.Content)`
  background: #fff;

  &::after {
    position: absolute;
    top: -0.4rem;
    left: calc(50% - 0.8rem);
    content: '';
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid #fff;
    z-index: 1;
  }

  ${({ theme }) => {
    const { colors, radiuses } = theme;
    return css`
      border: 0.1rem solid ${colors.gray[400]};
      border-radius: ${radiuses[0.4]};
    `;
  }}

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
    will-change: transform, opacity;
    &[data-state='open'] {
      &[data-side='top'] {
        animation-name: ${slideDownAndFade};
      }
      &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
      }
      &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
      }
      &[data-side='left'] {
        animation-name: ${slideRightAndFade};
      }
    }
  }
`;

HoverMenuContent.defaultProps = {
  sideOffset: -10,
};

export const MenuItem = styled.button`
  font-size: 1.2rem;
  z-index: 2;
  padding: 1rem 2rem;

  &:last-child {
    border-bottom: none;
  }

  ${({ theme }) => {
    const {
      colors: { gray },
      radiuses,
    } = theme;
    return css`
      color: ${gray[800]};
      border-bottom: 0.1rem solid ${gray[400]};

      &:first-child {
        border-top-left-radius: ${radiuses[0.4]};
        border-top-right-radius: ${radiuses[0.4]};
      }

      &:last-child {
        border-bottom-left-radius: ${radiuses[0.4]};
        border-bottom-right-radius: ${radiuses[0.4]};
      }

      &:hover {
        background: ${gray[200]};
      }
    `;
  }}
`;
