import Box from '@Components/Box';
import StatusCell from '@Components/Cell/StatusCell';
import { Text } from '@Components/Typography';
import { Color } from '@Components/Typography/Typography.type';

import { CenterManageRow } from './CenterManage.type';
import { CENTER_MANAGE_STATUS_CODE_COLOR } from './constant';

export function CenterManageStatusCell({ rowData }: { rowData: CenterManageRow }) {
  const { activated } = rowData;
  const textColor = CENTER_MANAGE_STATUS_CODE_COLOR[
    activated as keyof typeof CENTER_MANAGE_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={activated} textColor={textColor} />;
}

export function CenterManageTypeCell({ rowData }: { rowData: CenterManageRow }) {
  return (
    <Box pl-10 pr-10 $width='100%'>
      <Text fontWeight={400} size={1.2}>
        {rowData.type === 'VIRTUAL' ? '가상센터' : '물류센터'}
      </Text>
    </Box>
  );
}
