import styled from 'styled-components';

import { flexRow } from '@Styles/utils/flex';
import { Nullable } from '@Utils/type';

type ErrorCellProps = { bg: string | Nullable<string> };

export const ErrorCell = styled.div<ErrorCellProps>`
  background-color: ${({ bg }) => {
    if (!bg) return '#fff';
    if (bg === '성공!' || bg === 'Success') return `#DBFFE5`;
    return '#FFABAB';
  }};
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  ${flexRow('normal', 'center')}
  flex-wrap: nowrap;

  & > p {
    white-space: nowrap;
  }
`;
