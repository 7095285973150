import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

export const SHELF_LIFE_재고유형 = [
  { label: '전체', value: '' },
  { label: '매입', value: 'PURCHASE' },
  { label: '판매자 심쿵', value: 'CONSIGNMENT' },
];

export const SHELF_LIFE_보관유형 = [
  { label: '전체', value: '' },
  { label: '파렛트렉', value: 'PALLET' },
  { label: '선반렉', value: 'SHELF' },
  { label: '경량렉', value: 'LIGHTWEIGHT' },
];

export const SHELF_LIFE_보관온도 = [
  { label: '전체', value: '' },
  { label: '상온', value: 'AMBIENT' },
  { label: '냉장', value: 'FRIDGE' },
  { label: '냉동', value: 'FROZEN' },
];

export const SHELF_LIFE_로케이션타입 = [
  { label: '전체', value: '' },
  { label: '가용', value: 'USABLE' },
  { label: '비가용', value: 'UNUSABLE' },
];

export const INITIAL_SHELF_LIFE_LIST_FILTER = {
  centerId: '12',
  dateOfRecord: dayjs().format('YYYY-MM-DD'),
  productStockType: '',
  storageType: '',
  temperature: '',
  productItemIds: '',
  productItemBarcodes: '',
  imminentStockTarget: '',
  noShippingTarget: '',
  locationType: '',
};

export const SHELF_LIFE_LIST_COLUMNS = [
  {
    ...keyColumn('centerCode', textColumn),
    title: '센터코드',
    disabled: true,
    minWidth: 70,
  },
  {
    ...keyColumn('dateOfRecord', textColumn),
    title: '기준일자',
    disabled: true,
  },
  {
    ...keyColumn('productStockType', textColumn),
    title: '상품재고유형',
    disabled: true,
  },
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
    minWidth: 400,
  },
  {
    ...keyColumn('storageType', textColumn),
    title: '보관유형',
    disabled: true,
    minWidth: 80,
  },
  {
    ...keyColumn('location', textColumn),
    title: '로케이션',
    disabled: true,
    minWidth: 160,
  },
  {
    ...keyColumn('locationType', textColumn),
    title: '로케이션 타입',
    disabled: true,
    minWidth: 100,
  },
  {
    ...keyColumn('quantity', textColumn),
    title: '수량',
    disabled: true,
    minWidth: 50,
  },
  {
    ...keyColumn('itemBarcode', textColumn),
    title: '품목바코드',
    disabled: true,
    minWidth: 140,
  },
  {
    ...keyColumn('shelfLifeDay', textColumn),
    title: '유통기한',
    disabled: true,
  },
  {
    ...keyColumn('temperature', textColumn),
    title: '보관온도',
    disabled: true,
    minWidth: 80,
  },
  {
    ...keyColumn('imminentStockConversionDays', textColumn),
    title: '임박전환(일)',
    disabled: true,
    minWidth: 90,
  },
  {
    ...keyColumn('noShippingConversionDays', textColumn),
    title: '출고불가(일)',
    disabled: true,
    minWidth: 90,
  },
];
