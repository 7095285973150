import { useEffect, useRef, useState } from 'react';

import Box from '@Components/Box';
import DataSheetGrid from '@Components/DataSheetGrid';
import { RowInfo } from '@Components/DataSheetGrid/DataSheetGrid.type';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { InputLabel } from '@Components/Forms/Input';
import { Text } from '@Components/Typography';
import { useDialog, useFormField } from '@Hooks';
import FormField from '@Layout/components/FormField';
import ResultHeader from '@Layout/components/ResultHeader';
import Form from '@src/Components/Forms/Form';
import { toastify } from '@src/Components/Toast';
import ModalFooter from '@src/Layout/components/ModalFooter';
import { useMutation } from '@tanstack/react-query';

import { postCenterCodeCommon } from './apis';
import { CenterCodeRow } from './CenterCodeModal.type';
import { CENTER_CODE_GRID_OPTIONS_COLUMNS } from './constant';

function CenterCodeModal({
  selectCenterCode,
}: {
  selectCenterCode?: (rowData: CenterCodeRow) => void;
}) {
  const { columns } = CENTER_CODE_GRID_OPTIONS_COLUMNS;
  const rowIndex = useRef<number>(Number.MIN_SAFE_INTEGER);

  const [centerCodeList, setCenterCodeList] = useState<CenterCodeRow[]>([]);

  const { form, attributes } = useFormField({ centerId: '', centerName: '' });

  const { resetDialog } = useDialog('CenterCodeModal');

  const { mutate } = useMutation(postCenterCodeCommon, {
    onSuccess: (data) => data && setCenterCodeList(data),
  });

  useEffect(() => {
    search();
  }, []);

  const search = () => mutate(form);

  const close = () => resetDialog();

  const select = (rowInfo: RowInfo) => {
    rowIndex.current = rowInfo.row;
  };

  const submit = () => {
    if (rowIndex.current < 0) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return false;
    }
    const target = centerCodeList[rowIndex.current];
    selectCenterCode && selectCenterCode(target);
    resetDialog();
  };

  const renderActivated = ({ rowData }: { rowData: CenterCodeRow }) => {
    return (
      <Box pl-10 pr-10>
        {rowData?.activated === 'Y' ? '활성화' : '비활성화'}
      </Box>
    );
  };

  const renderType = ({ rowData }: { rowData: CenterCodeRow }) => {
    return (
      <Box pl-10 pr-10>
        {rowData?.type === 'REAL' ? '물류센터' : '가상센터'}
      </Box>
    );
  };

  return (
    <Form onSubmit={search}>
      <Flex align='center' justify='space-between'>
        <Text as='p' color='gray.900'>
          센터코드 조회
        </Text>
        <Button variant='outline' type='submit' isPermissionRequired={false}>
          검색
        </Button>
      </Flex>
      <Box mb-32>
        <FormField>
          <Flex align='flex-start' justify='flex-start' colGap={36} rowGap={10} wrap='wrap'>
            <InputLabel label='센터코드' {...attributes.centerId} />
            <InputLabel label='센터명' {...attributes.centerName} />
          </Flex>
        </FormField>
      </Box>
      <Flex direction='column' rowGap={16}>
        <ResultHeader title='센터코드 내역' total={centerCodeList.length} />
        <DataSheetGrid
          columns={columns}
          customRendererList={[
            { id: 'activated', component: renderActivated },
            { id: 'type', component: renderType },
          ]}
          value={centerCodeList}
          onActiveChange={select}
        />
      </Flex>
      <ModalFooter>
        <Button variant='outline' onClick={submit} isPermissionRequired={false}>
          선택
        </Button>
        <Button variant='outline' onClick={close} isPermissionRequired={false}>
          취소
        </Button>
      </ModalFooter>
    </Form>
  );
}

export default CenterCodeModal;
