import { useRef, useState } from 'react';

import { client } from '@src/client';
import { nullIfEmptyOrALL } from '@src/Utils/primitive';
import { useQuery } from '@tanstack/react-query';
import { addIndexingData } from '@Utils/helper';
import { flatten, mapObject } from '@Utils/object';
import { IndexingData, PageResponse } from '@Utils/type';

import {
  OutboundsRecordRow,
  PostOutboundsRecordsListParams,
  PostOutboundsRecordsListResponseDataResult,
} from './type';

type PostOutboundsRecordsListParamsAndPage = Partial<PostOutboundsRecordsListParams> & {
  pageSize: number;
  pageNumber: number;
};

export const postOutboundsRecordsList = async ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  centerCode,
  ...rest
}: PostOutboundsRecordsListParamsAndPage) => {
  const body = mapObject({ ...rest }, nullIfEmptyOrALL);

  const { data } = await client.post<PageResponse<PostOutboundsRecordsListResponseDataResult[]>>(
    '/outbounds/records/list',
    body
  );

  return data;
};

export const useOutboundsRecordsListQuery = (params: PostOutboundsRecordsListParamsAndPage) => {
  const totalRef = useRef(0);
  const [enabled, setEnabled] = useState(false);
  const [outboundRecordList, setOutboundRecordList] = useState<IndexingData<OutboundsRecordRow>[]>(
    []
  );

  const search = () => setEnabled(true);

  useQuery(['outbounds-record-list', params], () => postOutboundsRecordsList(params), {
    enabled,
    onSuccess: (data) => {
      if (data) {
        setOutboundRecordList(
          addIndexingData(
            data.results.map((it) => flatten(it)) as OutboundsRecordRow[],
            params.pageNumber,
            params.pageSize
          )
        );
        totalRef.current = data.total;
      }
    },
    onSettled: () => setEnabled(false),
  });

  return { refetch: search, outboundRecordList, total: totalRef.current };
};

export const postOutboundsRecordsExcelDownload = async ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  centerCode,
  ...rest
}: PostOutboundsRecordsListParamsAndPage) => {
  const body = mapObject({ ...rest }, nullIfEmptyOrALL);
  const { data } = await client.post<{ send: string }>('/outbounds/records/excel-download', body);
  return data;
};
