import { OutboundDivisionEditRowAndActive, OutboundDivisionEditValidationRow } from './type';

export const getMappingValidationRowParams = (
  rows: OutboundDivisionEditRowAndActive[]
): OutboundDivisionEditValidationRow[] => {
  return rows.map(({ id, centerCode, productItemId, productItemName, unitQuantity }) => ({
    id,
    centerCode,
    productItemId,
    productItemName,
    unitQuantity,
  }));
};
