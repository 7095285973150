import StatusCell from '@Components/Cell/StatusCell';
import { Color } from '@Components/Typography/Typography.type';

import { UserRow } from './apis/useUserListQuery';
import { USER_MANAGE_STATUS_CODE_COLOR } from './constant';

export function UserManageStatusCell({ rowData }: { rowData: UserRow }) {
  const { activated } = rowData;

  const textColor = USER_MANAGE_STATUS_CODE_COLOR[
    activated as keyof typeof USER_MANAGE_STATUS_CODE_COLOR
  ] as Color;

  return <StatusCell statusValue={activated} textColor={textColor} />;
}
