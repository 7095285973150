import { UserRole } from '@src/Components/Modals/UserRoleModal/apis/useUserRoleListQuery';
import useRequest from '@src/Hooks/useRequest';

export const useUserRoleIdQuery = (roleCode: string) => {
  const { data } = useRequest<UserRole[]>(
    {
      url: '/user-groups/roles/list/common',
      method: 'POST',
      data: {
        groupCode: null,
        groupName: null,
        roleCode: null,
        roleName: null,
      },
    },
    {
      staleTime: Infinity,
    }
  );

  return roleCode ? data?.find((user) => user.roleCode === roleCode)?.roleId || 0 : null;
};
