import { useState } from 'react';

import DataSheetGrid from '@src/Components/DataSheetGrid';
import { type RowInfo } from '@src/Components/DataSheetGrid/DataSheetGrid.type';
import Button from '@src/Components/Forms/Button';
import ExcelHistoryModal from '@src/Components/Modals/ExcelHistoryModal/ExcelHistoryModal';
import Pagination from '@src/Components/Pagination';
import { CUSTOM_GUTTER_COLUMN } from '@src/Constants/grid';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@src/Hooks';
import FilterHeader from '@src/Layout/components/FilterHeader';
import GridLayout from '@src/Layout/components/GridLayout';
import ResultHeader from '@src/Layout/components/ResultHeader';
import { performIfNotEmpty } from '@src/Utils/helper';
import type { Nullable } from '@src/Utils/type';

import { useClaimInquireExcel } from './apis/useClaimInquireExcel';
import { CLAIM_LIST_PAGE_SIZE, useClaimInquireList } from './apis/useClaimInquireList';
import ClaimFilter from './components/ClaimFilter';
import ClaimInbound from './components/ClaimInbound';
import ClaimOutbound from './components/ClaimOutbound';
import { ClaimStatusCell } from './ClaimInquireCell';
import { CLAIMS_LIST_COLUMNS, INITIAL_CLAIMS_LIST_FILTER } from './constant';
import { validateSearch } from './util';

function ClaimsInquire() {
  const formField = useFormField(INITIAL_CLAIMS_LIST_FILTER);
  const claimInquireList = useClaimInquireList();
  const { excelDownloadMutate } = useClaimInquireExcel();

  const claimInquireExcelDownloadHistoryModal = useDialog('ExcelHistory');

  const { showActivityIndicator } = useFullScreenActivityIndicator();

  const { claimListTotal, list: claimList } = claimInquireList;

  const [selectId, setSelectId] = useState<Nullable<number>>(null);

  const targetClaims = selectId !== null ? claimList[selectId] : null;

  const onClickSearch = validateSearch({
    filter: { ...formField.form, pageNumber: 0 },
    callback: () => {
      showActivityIndicator();
      claimInquireList.setSearchFilter({ ...formField.form, pageNumber: 0 });
      setSelectId(null);
    },
    message: '검색요청일(접수일 or 완료일)을 입력해주세요.',
  });

  const onChangePageIndex = (pageNumber: number) => {
    validateSearch({
      filter: { ...formField.form, pageNumber },
      callback: () => {
        showActivityIndicator();
        claimInquireList.setPageNumber(pageNumber);
        setSelectId(null);
      },
      message: '검색요청일(접수일 or 완료일)을 입력해주세요.',
    })();
  };

  const onClickSelectIds = (item: RowInfo) => {
    setSelectId(item.row);
  };

  const onClickExcelDownload = () => {
    excelDownloadMutate({ ...formField.form, pageNumber: 0 });
  };

  const openClaimInquireExcelDownloadHistoryModal = () => {
    claimInquireExcelDownloadHistoryModal.openDialog({
      title: '엑셀 다운로드 내역',
      content: <ExcelHistoryModal targetUrl='reverse' />,
    });
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={onClickSearch} isPermissionRequired={false}>
          조회
        </Button>
        <Button
          isPermissionRequired={false}
          onClick={performIfNotEmpty(claimList, onClickExcelDownload, '조회된 데이터가 없습니다')}
        >
          엑셀
        </Button>
        <Button onClick={openClaimInquireExcelDownloadHistoryModal}>엑셀 다운로드 요청 내역</Button>
      </FilterHeader>
      <ClaimFilter formField={formField} />

      <GridLayout
        header={<ResultHeader title='반품내역' total={claimListTotal} />}
        grid={
          <DataSheetGrid
            columns={CLAIMS_LIST_COLUMNS}
            value={claimList}
            gutterColumn={CUSTOM_GUTTER_COLUMN}
            onActiveChange={onClickSelectIds}
            customRendererList={[{ id: 'status', component: ClaimStatusCell }]}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={claimInquireList.pageNumber}
            onPageIndexChange={onChangePageIndex}
            total={claimInquireList.claimListTotal}
            pageSize={CLAIM_LIST_PAGE_SIZE}
          />
        }
      />
      {targetClaims && targetClaims.reverseId !== null && (
        <ClaimInbound reverseId={targetClaims.reverseId} />
      )}
      <br />
      <br />
      {targetClaims && targetClaims.outboundId !== null && (
        <ClaimOutbound outboundId={targetClaims.outboundId} />
      )}
    </div>
  );
}

export default ClaimsInquire;
