import { createGlobalStyle } from 'styled-components';

export const GridGlobalStyle = createGlobalStyle<{ height?: number }>`
    :root {
        --dsg-border-color: ${({ theme }) => theme.colors.gray[400]};
        --dsg-cell-disabled-background-color: white;
        --dsg-selection-border-color: ${({ theme }) => theme.colors.blue[400]};
        --dsg-selection-background-color: rgba(69, 147, 252, .1);
        --dsg-selection-disabled-border-color: ${({ theme }) => theme.colors.blue[400]};
        --dsg-selection-disabled-background-color:  rgba(69, 147, 252, .1);
    }

    .dsg-container {
         height: auto !important;
         max-height: 50rem;
         border-left: .1rem solid ${({ theme }) => theme.colors.gray[400]};

         &::-webkit-scrollbar {
          background: ${({ theme }) => theme.colors.gray[100]};
         }
         &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.colors.gray[300]};
         }
     }

    .dsg-scrollable-view{
         border: none;
         box-shadow: none;
    }
    .dsg-cell-header.dsg-cell-gutter{
         box-shadow: none;
    }


    .dsg-cell{
         background-color: white;
    }

    .dsg-cell-disabled {
         background-color: white;
    }

    .dsg-cell-header {
     border-bottom: solid .1rem var(--dsg-border-color);

          &.blue{
               background-color: #ddf9ff !important;
          }
          &.green{
               background-color: #ecffdd !important;
          }
    }


    
    .dsg-cell-gutter{
         background-color: white !important; 

         &+:not(.dsg-cell-header).dsg-cell {
               background-color: white;
         }
    }


   .dsg-input {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.colors.gray[700]}
   }

   .dsg-row-header {
        background-color: #f2f4f6;

        >div:first-child{
             border-left: none;
        }
   }

   .dsg-cell-header-container {
        font-size: 1.2rem;
        color: #222;
        font-weight: ${({ theme }) => theme.fontWeights[500]}
   }

   .dsg-cell-header, .dsg-cell-gutter {
        background: transparent;
   }

   .dsg-row {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.colors.gray[700]};

        &:first-child{
          border-top: none;
        }

        > div:first-child{
             border-left: none;
        }
   }

   .width-100 {
     width: 100%;
   }

   .flex-1 {
     flex: 1;
   }

   .dsg-selection-col-marker, .dsg-selection-row-marker  {
     background: transparent;
   }

`;
