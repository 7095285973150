import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import Flex from '@Components/Flex';
import HoverMenu from '@Components/HoverMenu';
import { useUserMenuAndRoleListQuery } from '@src/Apis/user';
import { currentTabAtom, TabItemAtom, tabListAtom } from '@Stores/Tab/tab.atom';
import { tabState } from '@Stores/Tab/tab.selector';
import { getDecodeToken } from '@Utils/token';

import { HeaderWrapper, LogoutButton } from './Header.style';

function Header() {
  const navigate = useNavigate();
  const accessToken = getDecodeToken(Cookies.get('wms_tk'));
  const setTabState = useSetRecoilState(tabState);
  const resetTabList = useResetRecoilState(tabListAtom);
  const resetCurrentTab = useResetRecoilState(currentTabAtom);
  const tabList = useRecoilValue(tabListAtom);
  const setCurrentTab = useSetRecoilState(currentTabAtom);
  const addTab = (tab: TabItemAtom) => setTabState(tab);
  const { data } = useUserMenuAndRoleListQuery({ userId: accessToken ? accessToken.user_id : -1 });

  const logout = () => {
    Cookies.remove('wms_tk');
    localStorage.removeItem('wms_rftk');
    navigate('/login', { replace: true });

    resetTabList();
    resetCurrentTab();
  };

  const handleMenuClick =
    ({ id, parentId, title, frontFileName }: TabItemAtom) =>
    () => {
      const isExistingTab = tabList.find((tab) => tab.id === id);
      if (isExistingTab) {
        setCurrentTab({ id, parentId, title, frontFileName });
      } else {
        addTab({ id, parentId, title, frontFileName });
      }
    };

  const renderNav = useCallback(() => {
    return (
      <Flex as='nav' gap={16}>
        {data?.userMenuList.map((menu) => (
          <HoverMenu title={menu.title} key={menu.id}>
            {menu.children?.map(({ id, parentId, title, frontFileName }) => (
              <HoverMenu.Item
                key={id}
                onClick={handleMenuClick({ id, parentId, title, frontFileName })}
              >
                {title}
              </HoverMenu.Item>
            ))}
          </HoverMenu>
        ))}
      </Flex>
    );
  }, [data?.userMenuList, handleMenuClick]);

  return (
    <HeaderWrapper>
      <Flex align='center'>
        <h1>Petfriends WMS</h1>
        {renderNav()}
      </Flex>
      <Flex gap={12}>
        <span>{accessToken ? `${accessToken.login_id} 님` : '사용자 ID'}</span>
        <LogoutButton onClick={logout}>로그아웃</LogoutButton>
      </Flex>
    </HeaderWrapper>
  );
}

export default Header;
